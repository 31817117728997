import {
  ChangeEvent,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import Fuse from 'fuse.js';
import useDeepCompareEffect from 'use-deep-compare-effect';

import './search.scss';
import { Svg } from './svg';

export interface FuseOption {
  shouldSort?: boolean;
  distance?: number;
  maxPatternLength?: number;
  minMatchCharLength?: number;
  keys: string[];
}

interface SearchProps {
  className?: string;
  onChange: (query: string) => void;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
}

export const Search = ({
  onChange,
  className,
  onFocus,
  onBlur,
}: SearchProps) => {
  const inputClassnames = classnames('search', className);

  const handleSearchQueryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchQuery = event.target.value.trim();
      onChange(searchQuery);
    },
    [onChange],
  );

  return (
    <div className={inputClassnames}>
      <label className="search__inner">
        <div className="search__icon">
          <Svg name="search" />
        </div>
        <input
          type="text"
          className="search__input input-text"
          placeholder="Поиск"
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleSearchQueryChange}
          data-cy="SEARCH_INPUT"
        />
      </label>
      <div className="search__mat" />
    </div>
  );
};

interface UseSearchProps<T> {
  source: T[];
  options?: Fuse.IFuseOptions<T>;
}

export function useSearch<T>({ source, options }: UseSearchProps<T>) {
  const [query, setQuery] = useState('');
  const [result, setFound] = useState(source);

  useDeepCompareEffect(() => {
    setFound(source);
  }, [source]);

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const searchEngine = useMemo(
    () => new Fuse(source, options),
    [options, source],
  );

  useEffect(() => {
    if (query) {
      timer.current = setTimeout(() => {
        const result = searchEngine.search(query).map((item) => item.item);

        if (result.length) {
          setFound(result);
        }
      }, 300);
    } else {
      reset();
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const reset = useCallback(() => {
    if (timer.current) clearTimeout(timer.current);
    setQuery('');
    setFound(source);
  }, [source]);

  return {
    setQuery,
    result,
    reset,
  };
}
