import React, { Dispatch, SetStateAction, useState } from 'react';

export type ModalTypes = 'auth' | 'register-full' | 'register-partial';
export interface WelcomeModalContextType {
  isShowModal: boolean;
  setShowModalStatus: Dispatch<SetStateAction<boolean>>;
  modalType: ModalTypes;
  showModal: (type: ModalTypes) => void;
}

interface SetModalStatus {
  type: ModalTypes;
  modalStatus: boolean;
}

export const WelcomeModalContext =
  React.createContext<WelcomeModalContextType | null>(null);

export const WelcomeModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isShowModal, setShowModalStatus] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalTypes>('auth');

  const showModal = (type: ModalTypes) => {
    setModalType(type);
    setShowModalStatus(true);
  };

  return (
    <WelcomeModalContext.Provider
      value={{ isShowModal, setShowModalStatus, modalType, showModal }}>
      {children}
    </WelcomeModalContext.Provider>
  );
};
