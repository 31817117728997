import React, { useEffect, useMemo } from 'react';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { useStore } from 'effector-react';
import { DietItem } from './diet-item';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/diet',
    breadcrumb: 'Диета',
  },
];

export const DietPage = () => {
  const {
    metric: { $store: $dietsStore, load: loadListDiets },
  } = useMetric({
    metric: 'diets',
  });
  const list = useStore($dietsStore);
  useEffect(() => {
    if (!list) {
      loadListDiets();
    }
  }, [list, loadListDiets]);

  const formattedList = useMemo(() => {
    if (!list) return [];

    return list.categories.map<any>((item) => ({
      id: item.id.toString(),
      name: item.title,
    }));
  }, [list]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <div className="accordion">
        {formattedList.map((item) => (
          <DietItem key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};
