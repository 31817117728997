import { Controller } from 'react-hook-form';

import { InputTextarea, InputTextareaProps, Textarea } from '../../../atoms';
import { FieldWrapper } from '../../../molecules';

import { FieldProps } from './types';

export interface FieldTextAreaProps<T>
  extends FieldProps<T>,
    Omit<InputTextareaProps, 'name'> {
  onFieldChange?: (value: string | null) => void;
}

export function FieldTextArea<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  disabled,
  onFieldChange,
  ...inputProps
}: FieldTextAreaProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputProps['data-cy']}>
            <InputTextarea
              {...inputProps}
              className={inputClassName}
              disabled={disabled}
              value={value ?? ''}
              onChange={(e) => {
                const value = e.target.value;
                onChange(value);
                onFieldChange?.(value);
              }}
              onBlur={onBlur}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
