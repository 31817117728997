import { CoughProcessStatus } from '@eus/health-default-logic/dist/cough/types';

export enum CoughStatusesText { // TODO переместить в пакет eus
  'success' = 'Завершено',
  'noisy_audio' = 'Ошибка',
  'failed' = 'Ошибка',
  'no_cough' = 'Ошибка',
  'preprocessing' = 'В обработке',
  'pending' = 'В обработке',
}

export const getCoughStatusName = (status: CoughProcessStatus | undefined) => {
  return status ? CoughStatusesText[status] : '';
};

export const getErrorText = (status: CoughProcessStatus | undefined) => {
  switch (status) {
    case 'failed':
      return {
        title: 'Ошибка',
        text: 'Произошла ошибка. Попробуйте еще раз',
      };
    case 'no_cough':
    case 'noisy_audio':
      return {
        title: 'Некачественная аудиозапись',
        text: 'Обнаружено много шумов в аудиозаписи. Для более точного результата отправьте новый запрос, повторив запись кашля.',
      };
    case 'preprocessing':
      return null;
    case 'pending':
      return null;
    default:
      return null;
  }
};
export const getStatusColorClass = (status: CoughProcessStatus | undefined) => {
  switch (status) {
    case 'success':
      return '-text__green';
    case 'failed':
      return '-text__red';
    case 'no_cough':
      return '-text__red';
    case 'noisy_audio':
      return '-text__red';
    case 'preprocessing':
      return '-text__purple';
    case 'pending':
      return '-text__purple';
    default:
      return '';
  }
};

export const CoughErrorStatuses = ['failed', 'noisy_audio', 'no_cough'];
export const CoughPendingStatuses = ['preprocessing', 'pending'];
export const CoughSuccessStatuses = ['success'];
