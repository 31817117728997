import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import React from 'react';
import { InfoList, InfoListItem, InfoListGroups } from '@ui';

export interface CardInfoListProps {
  number: string;
  date: Date;
  status: string;
}

export const CardInfoList: React.FC<CardInfoListProps> = ({
  status,
  date,
  number,
}) => {
  const cardInfoList: InfoListItem[] = [
    {
      label: 'Номер карты',
      value: number,
    },
    ...(status !== 'Block'
      ? [
          {
            label: 'Действует до',
            value: formatDateShort(date),
          },
        ]
      : []),
    {
      label: 'Статус',
      value: status ?? '',
    },
  ];

  const cardInfoGroup: InfoListGroups[] = [
    {
      label: '',
      props: cardInfoList,
    },
  ];
  return <InfoList groups={cardInfoGroup} />;
};
