import React from 'react';
import { ProfilePropsList } from '@features/user/ui/profile';
import { Link } from 'react-router-dom';
import { Avatar, InfoList } from '@ui';

export const ProfileInfoView = ({ ...propsList }: ProfilePropsList) => {
  return (
    <section className="block edit-profile ">
      <div className="block__columns">
        <div className="block__column-left">
          <Avatar
            url={propsList.avatar.pathname}
            sex={propsList.avatar.sex}
            key={propsList.avatar.pathname}
          />
        </div>
        <div className="block__column-right">
          <h2 className="h2 -mb__xxmedium -mb__xs-xsmall">{propsList.name}</h2>
          <div className="edit-profile__info">
            <InfoList groups={propsList.propsGroups} />
          </div>
          {propsList.button.to.pathname && propsList.button.to.state && (
            <Link
              className={
                propsList.button.modClass ??
                'button button__primary -width__xs-100'
              }
              to={propsList.button.to}
              data-cy="EDIT_PROFILE_BTN">
              {propsList.button.text}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};
