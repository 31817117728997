import { useState } from 'react';
import { CheckFormCode, ServerErrorsHandler } from '@eus/react-client';
import { OnSuccessForm } from '@teledoctor/common/dist/hooks';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { UseFormSetError } from 'react-hook-form';
import { addNotification } from '@ui';

const handleError = (setError: UseFormSetError<any>) =>
  formServerErrorHandler({
    commonErrorCase: {
      transformerError: (errorData) => errorData?.[0]?.errors?.[0],
      commonErrorCallback: (errorText) => {
        addNotification({
          id: 'profile-edit-field' + new Date(),
          type: 'error',
          message: errorText,
          target: 'change-form-field',
        });
      },
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler(setError),
    },
  });

const feedParams = new FeedParams({
  needShowLoader: true,
  throwNext: true,
});

export interface UpdateFieldValues {
  code: string;
  value: string;
}

export interface UseEditFieldViaCodeParams {
  onSuccessRequestCode?: () => void;
  onSuccessUpdateField?: () => void;
  updateField: (
    values: UpdateFieldValues,
    feedParams: FeedParams,
  ) => Promise<unknown>;
  requestCodeFromUpdateField: (
    value: string,
    feedParams: FeedParams,
  ) => Promise<void>;
}

export const useEditFieldViaCode = ({
  updateField,
  requestCodeFromUpdateField,
  onSuccessRequestCode,
  onSuccessUpdateField,
}: UseEditFieldViaCodeParams) => {
  const [value, setValue] = useState<string | null>(null);

  const onSuccessCodeForm: OnSuccessForm<CheckFormCode> = async (
    { code },
    setError,
  ) => {
    if (!value) {
      return;
    }
    try {
      await updateField({ code, value }, feedParams);
      setValue(null);
      onSuccessUpdateField?.();
    } catch (error) {
      setError('code', { message: 'Неверный код' });
    }
  };

  const requestCode = (value: string) =>
    requestCodeFromUpdateField(value, feedParams);

  const handleRequestCode = (value: string, setError: UseFormSetError<any>) =>
    requestCode(value)
      .then(() => {
        onSuccessRequestCode?.();
        setValue(value);
      })
      .catch(handleError(setError));

  return {
    onSuccessCodeForm,
    handleRequestCode,
    requestCode,
    value,
    setValue,
  };
};
