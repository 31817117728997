import React from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import classnames from 'classnames';

import './field.scss';
import { checkErrorForField, FieldError } from '../atoms';

export interface FieldWrapperProps<FormValues extends FieldValues> {
  name: string;
  label?: string;
  errors?: FieldErrors<FormValues>;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  errorsClassName?: string;
  inputClassName?: string;
  dataCy?: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const FieldWrapper = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  name,
  label,
  labelClassName,
  errors,
  errorsClassName,
  children,
  disabled = false,
  className,
  inputClassName,
  dataCy,
}: FieldWrapperProps<TFormValues>) => {
  const hasError = errors ? checkErrorForField(name, errors) : false;

  const fieldClassnames = classnames('field', className, {
    'field--has-error': hasError,
    'field--is-disabled': disabled,
  });

  const labelClassnames = classnames(
    'field__label',
    'label',
    { 'field__label--disabled': disabled },
    labelClassName,
  );
  const errorsClassnames = classnames('field__error', 'error', errorsClassName);
  const inputClassnames = classnames('field__input', inputClassName);

  return (
    <div className={fieldClassnames}>
      {Boolean(label) && (
        <label className={labelClassnames} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={inputClassnames}>{children}</div>
      {errors && (
        <FieldError
          dataCy={dataCy}
          errors={errors}
          name={name}
          className={errorsClassnames}
        />
      )}
    </div>
  );
};
