import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $hasHealthTariff } from '@teledoctor/common/dist/features/metrics';
import { ActiveState, EmptyState } from '@features/health/ui';
import { useProfileTdLk } from '@features/api';

const title = 'Здоровье 2.0';

export const HealthMainPage = () => {
  const { load } = useProfileTdLk();
  useEffect(() => {
    load();
  }, [load]);

  const hasHealthTariff = useStore($hasHealthTariff);

  return (
    <div className="health-main">
      {hasHealthTariff ? (
        <ActiveState title={title} />
      ) : (
        <EmptyState title={title} />
      )}
    </div>
  );
};
