import { Controller } from 'react-hook-form';

import { InputRadio, InputRadioProps } from '../../../atoms';
import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';

interface FieldInputRadioProps<T>
  extends FieldProps<T>,
    Omit<InputRadioProps, 'onChange' | 'value' | 'name'> {
  onFieldChange?: (value: any) => void;
}

export function FieldInputRadio<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  initialValue,
  disabled,
  onFieldChange,
  ...inputProps
}: FieldInputRadioProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      defaultValue={initialValue}
      render={({ field: { onChange, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}>
            <InputRadio
              {...inputProps}
              value={value}
              name={name}
              initialValue={initialValue}
              className={inputClassName}
              onChange={(value) => {
                onChange(value);
                onFieldChange?.(value);
              }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
