import React, { useState } from 'react';
import {
  ArrayPath,
  FieldValues,
  Path,
  useController,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { Button } from '../../../atoms';
import { Alert } from '../../../molecules';
import { FField } from './index';

type KeyofFField = keyof typeof FField;
type ComponentProps<T extends KeyofFField> = React.ComponentProps<
  (typeof FField)[T]
>;

type Props<T extends KeyofFField, F extends FieldValues> = {
  FType: T;
  methods: UseFormReturn<F>;
  name: Path<F>;
  buttonText: string;
  deleteText: string;
} & Omit<ComponentProps<T>, 'methods' | 'name'>;

export const FFieldArray = <
  T extends KeyofFField = KeyofFField,
  F extends FieldValues = FieldValues,
>(
  props: Props<T, F>,
) => {
  const { buttonText, deleteText, name, methods, FType, ...otherProps } = props;
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: name as ArrayPath<F>,
  });

  const [deletedElement, setDeletedElement] = useState<{
    element: string;
    index: number;
  }>();

  const resetDeletedElement = () => setDeletedElement(undefined);

  const removeField = (index?: number) => {
    remove(index);
    resetDeletedElement();
  };

  return (
    <div className={styles['field-array']}>
      <div className={styles['field-array__list']}>
        {fields.map((field, index) => (
          <Field
            {...otherProps}
            key={field.id}
            id={field.id}
            name={`${name}.${index}`}
            index={index}
            FType={FType}
            className="field form__field"
            methods={methods}
            setDeletedElement={setDeletedElement}
          />
        ))}
      </div>
      <Button
        className="button__primary"
        title={buttonText}
        data-cy="PROFILE_ADD_ADDITIONAL_PHONE_BTN"
        type="button"
        short
        onClick={() => append('' as {})}
        appearance="outline"
      />
      <Alert
        title="Удаление"
        onClose={resetDeletedElement}
        isOpen={!!deletedElement}
        message={deleteText}
        closeIcon={false}
        popupClassNames={styles['field-array__alert']}
        buttons={[
          {
            buttonWrapperClassName: styles['field-array__alert-btn-wrapper'],
            className: styles['field-array__alert-btn'],
            title: 'Удалить',
            onClick: () => removeField(deletedElement?.index),
            type: 'button',
            danger: true,
          },
          {
            buttonWrapperClassName: styles['field-array__alert-btn-wrapper'],
            className: styles['field-array__alert-btn'],
            title: 'Отмена',
            type: 'button',
            appearance: 'outline',
            onClick: resetDeletedElement,
          },
        ]}
      />
    </div>
  );
};

const Field = (props) => {
  const { id, name, index, FType, methods, setDeletedElement, ...otherProps } =
    props;

  const FieldComponent = FField[FType];
  const { field } = useController({ name, control: methods.control });

  return (
    <div className={styles['field-array__item']}>
      <FieldComponent {...otherProps} id={id} name={name} methods={methods} />
      <button
        className={classnames('modal-close', styles['field-array__delete-btn'])}
        type="button"
        onClick={() => {
          setDeletedElement({ element: field.value, index });
        }}
      />
    </div>
  );
};
