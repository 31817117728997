import { NavLink, Redirect, Switch } from 'react-router-dom';
import './content.scss';
import './menu.scss';
import { Svg } from '@ui';
import { ProtectedRoute } from './protected-route';
import { ContentTemplate } from './template';

import { NotFound } from '@pages';
import { allRoutes, contentRootRoutes } from './routes';
import { useCountNonReadConsultation } from './hooks';
import { useMemo } from 'react';
import { WelcomeModal } from '@features/user/ui';
import { PaymentStatusModal } from '@features/tariffs/ui';

export const Content = () => {
  const menu = useMemo(() => <Menu />, []);

  return (
    <ContentTemplate menu={menu}>
      <Switch>
        {allRoutes.map(({ text, route, component: Component }) => (
          <ProtectedRoute exact key={text} path={`/lk/${route}`}>
            <>
              <Component />
              <WelcomeModal />
              <PaymentStatusModal />
            </>
          </ProtectedRoute>
        ))}
        <Redirect
          path="/lk"
          exact
          to={{ pathname: '/lk/tasks', search: window.location.search }}
        />
        <ProtectedRoute path={`/lk/*`}>
          <NotFound />
        </ProtectedRoute>
      </Switch>
    </ContentTemplate>
  );
};

interface MenuProps {
  onClickMenuItem?: () => void;
}

const Menu = ({ onClickMenuItem }: MenuProps) => {
  const count = useCountNonReadConsultation();

  return (
    <nav className="menu">
      <ul className="menu__list">
        {contentRootRoutes.map(({ route, text, icon, iconActive }) => {
          return (
            <li className="menu__item" key={text}>
              <NavLink
                to={`/lk/${route}`}
                onClick={onClickMenuItem}
                className="menu-link"
                data-route={route}
                data-unread-comments={count > 9 ? '9+' : String(count)}
                activeClassName="menu-link--is-active"
                data-cy={`MAIN_MENU_LINK_${route.toUpperCase()}`}>
                <span className="menu-link__icon">
                  {iconActive && <Svg name={iconActive} />}
                  <Svg name={icon} />
                </span>
                <span className="menu-link__text">{text}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
