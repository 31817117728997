import SelectComplete from '@eus/selects/src/SelectComplete';
import { SelectOption, SelectPropsComplete } from '@eus/selects/src/types';
import { useClientCrm } from '@teledoctor/common/dist/hooks/use-client-crm';
import { Country as CountrySuggestionType } from '@eus/address/src/country';
import componentsBase from '@eus/selects/src/components';
import {
  FiasElement,
  FiasFullAddress,
  Address,
  AddressModel,
} from '@eus/address/src/address';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Colors, FieldWrapper } from '@ui';
import { CSSProperties } from 'react';

export const getValueFieldAddress = (element: FiasElement) => {
  if (!element) {
    return '';
  }
  return element.description
    ? element.description
    : element.name
    ? element.name
    : typeof element === 'string'
    ? element
    : element[0]
    ? element[0]
    : '';
};

export const getFullAddressName = (addressString: Address) => {
  // Повторно преобразуем в строку, чтобы вызвать фабрику
  const address = AddressModel.create(JSON.stringify(addressString));
  let region = getValueFieldAddress(address.region);
  let area = getValueFieldAddress(address.area);
  let city = getValueFieldAddress(address.city);
  let street = getValueFieldAddress(address.street);
  let house = getValueFieldAddress(address.house);
  let housing = getValueFieldAddress(address.housing);
  let flat = getValueFieldAddress(address.flat);

  const regionStr = !!region.length ? `${region} ` : '';
  const areaStr = !!area.length ? `${area} ` : '';
  const prefix =
    region !== city
      ? `${regionStr}${areaStr}${city}`.trim()
      : `${regionStr}${areaStr}`.trim();

  const housingStr = !!housing ? ` ${housing}` : '';
  const flatStr = !!flat ? ` ${flat}` : '';

  return `${prefix} ${street} ${house}${housingStr}${flatStr}`.trim();
};

export interface SelectAddressProps
  extends Partial<Omit<SelectPropsComplete, 'value'>> {
  name: string;
  control: Control<any>;
  errors: FieldErrors<any>;
  fieldClassname?: string;
  onFieldChange?: (value: (SelectOption | null)[]) => void;
  value: Address;
  dataCy: string;
}

type StylesFn = (base: CSSProperties, state: any) => CSSProperties;

const styles: {
  [key: string]: StylesFn;
} = {
  container: (base, state) => ({}),
  indicatorSeparator: (base, state) => ({}),
  dropdownIndicator: () => ({}),
  control: (base, state) => ({}),
  valueContainer: (base, state) => ({}),
  input: (base, state) => {
    return {
      margin: 0,
      padding: '10px 0',
      fontSize: 'inherit',
    };
  },
  menu: (base, state) => ({}),
  menuList: (base, state) => ({}),
  option: (base, state) => ({}),
  loadingIndicator: (base, state) => ({}),
  placeholder: (base, state) => ({}),
};

export const SelectAddress = ({
  name,
  control,
  label,
  errors,
  fieldClassname = '',
  placeholder = 'Введите полный адрес',
  dataCy,
  onFieldChange,
  ...rest
}: SelectAddressProps) => {
  const client = useClientCrm();

  const loadOptions = (search: string) => {
    return new Promise<CountrySuggestionType[]>((resolve, reject) => {
      client({
        url: '/fias/v1/address',
        method: 'get',
        params: {
          search: search,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const preLoadDefaultValue = (countryCode: string | number) => {
    return new Promise<any>((resolve, reject) => {
      client({
        url: '/fias/v1/address',
        method: 'get',
        params: {
          search: countryCode,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const formatLabel = (item: Address) => {
    return getFullAddressName(item);
  };
  const formatValue = (item: Address) => {
    return getFullAddressName(item);
  };

  const CustomControl = (props) => (
    <componentsBase.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-cy': `${dataCy}_inner`,
      }}
    />
  );

  const CustomInput = (props) => (
    <componentsBase.Input {...props} data-cy={`${dataCy}_inner_input`} />
  );

  const CustomOption = (props) => {
    const optionIndex = props.innerProps.id.split('-').pop();
    return (
      <componentsBase.Option
        {...props}
        innerProps={{
          ...props.innerProps,
          'data-cy': `${dataCy}_inner_option_${optionIndex}`,
        }}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={errors}
            name={name}
            disabled={false}
            errorsClassName={''}
            className={fieldClassname}
            labelClassName={''}>
            <SelectComplete
              {...rest}
              loadOptions={loadOptions}
              formatLabel={formatLabel}
              formatValue={formatValue}
              name={name}
              preloadOption={preLoadDefaultValue}
              onChange={(value) => {
                onChange([value]);
                onFieldChange?.([value]);
                onBlur();
              }}
              styles={styles}
              placeholder={placeholder}
              value={value ? value[0] : value}
              onBlur={onBlur}
              classNamePrefix="input-select"
              components={{
                ...componentsBase,
                Control: CustomControl,
                Option: CustomOption,
                Input: CustomInput,
              }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
