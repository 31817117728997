import React, { FC } from 'react';
import { Alert, TitleLine } from '../../ui/molecules';
import support from '@assets/img/support.png';
import { SupportForm } from '@features/user/ui';
import { useProfileTdLk } from '@features/api';
import formatPhone from '@eus/crm-default-logic/src/formatters/phone';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { Breadcrumbs, BreadcrumbsItem } from '@ui';

interface CreateAppealProps {}

const BREADCRUMBS: BreadcrumbsItem[] = [
  {
    path: '/lk/support/',
    breadcrumb: 'Поддержка',
  },
  {
    path: '/lk/support/create-appeal',
    breadcrumb: 'Новое обращение',
  },
];

export const CreateAppeal: FC<CreateAppealProps> = React.memo(() => {
  const { profile } = useProfileTdLk();
  const { phone } = profile || {};
  const {
    value: isSuccessPopupVisible,
    setFalsy: hideSuccessPopup,
    setTruthy: showSuccessPopup,
  } = useBoolean();
  const phoneFormatted = phone ? formatPhone(phone) : undefined;
  return (
    <>
      <TitleLine>
        <Breadcrumbs props={BREADCRUMBS} />
      </TitleLine>
      <section className="support__form-block support-form-block">
        <div className="support-form-block__description support-description">
          <div className="image support-description__image">
            <img src={support} alt="Поддержка " title="Поддержка " />
          </div>
          <div className="support-description__text">
            Опишите проблему или напишите вопрос о сервисе Теледоктор24. Обычно
            отвечаем в течение 24&nbsp;часов.
          </div>
        </div>
        <div className="support-form-block__content">
          <SupportForm
            defaultValues={{
              files: [],
              service_id: 23, // support task
            }}
            contacts={{ phone: phoneFormatted ?? '' }}
            onFormSent={showSuccessPopup}
          />
        </div>
      </section>
      <Alert
        title="Поддержка"
        message="Ваш запрос отправлен. В ближайшее время с Вами свяжется специалист и ответит на вопрос."
        isOpen={isSuccessPopupVisible}
        buttons={[
          { title: 'Продолжить', 'data-cy': 'SUPPORT_ALERT_CONFIRM_BTN' },
        ]}
        onClose={hideSuccessPopup}
      />
    </>
  );
});
