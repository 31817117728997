import React from 'react';

export const DotOutlineShape = (props) => {
  const radius = 6;
  const diameter = radius * 2;
  return props.cx && props.cy ? (
    <svg
      width={diameter}
      height={diameter}
      style={{ overflow: 'visible' }}
      className="chart__shape-stroke chart__shape-nofill">
      <circle cx={props.cx} cy={props.cy} r={radius} strokeWidth={2} />
    </svg>
  ) : null;
};
