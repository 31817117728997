import React from 'react';
import { AddFileDocument } from './../atoms/add-file-document';
import { AddFileAudio } from './../atoms/add-file-audio';
import { AddObjectFileInterface } from '@teledoctor/common/dist/features/audio-player/types';
import './add-file.scss';

export const AddFile: React.FC<AddObjectFileInterface> = ({ file }) => {
  let fileTypeComponent;
  switch (file.fileType) {
    case 'audio':
      fileTypeComponent = <AddFileAudio file={file} />;
      break;
    case 'document':
    default:
      fileTypeComponent = <AddFileDocument file={file} />;
      break;
  }
  return fileTypeComponent;
};
