import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import stylesExplanation from './styles.module.scss';

interface ExplanationInterface {
  modClass?: string;
  tooltip: string;
  symbol?: string;
}

export const ExplanationDark: React.FC<ExplanationInterface> = ({
  modClass,
  tooltip,
  symbol = '!',
}) => {
  const className = classnames(stylesExplanation.explanation, modClass);

  return (
    <div className={className}>
      <div className={stylesExplanation.symbol}>{symbol}</div>
      <div className={stylesExplanation.tooltip}>{tooltip}</div>
    </div>
  );
};
