import empty from '@assets/img/plugs-cards.png';
import { Plug } from '@ui';

export const EmptyStateCard = () => {
  return (
    <Plug
      image={empty}
      label="У Вас пока нет карт. Добавьте существующую карту или перейдите в раздел
  “Тарифы”, чтобы купить новую карту."
    />
  );
};
