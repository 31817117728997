import { Svg } from '@ui';

import './favorite.scss';
import classnames from 'classnames';

interface FavoriteProps {
  isFavorite: boolean;
  className?: string;
  onToggle?: (isFavorite: boolean) => void;
  id?: string | number;
}

export const Favorite = ({
  isFavorite,
  className,
  onToggle,
  id,
}: FavoriteProps) => {
  const chosenClass = classnames('favorite', className, {
    'favorite--is-active': isFavorite,
  });

  const handleToggle = () => {
    onToggle?.(!isFavorite);
  };

  return (
    <div
      className={chosenClass}
      onClick={handleToggle}
      {...(id ? { 'data-cy': `TOGGLE_FAVORITE_BTN_${id}` } : '')}>
      <Svg name="heart" modClass="favorite-icon" />
    </div>
  );
};
