import React from 'react';
import classnames from 'classnames';
import CardDefaultImage from './../../../../static/img/card-new@2x.png';

import './card-logo.scss';

interface CardLogoProps {
  className?: string;
  url: string | undefined | null;
  onClick?: () => void;
}

export const CardLogo: React.FC<CardLogoProps> = ({
  className,
  url,
  onClick,
}) => {
  const classNames = classnames('card', className);

  const handleClick = () => {
    onClick?.();
  };
  const urlChecked = url ?? CardDefaultImage;

  return (
    <div className={classNames} onClick={handleClick}>
      <div className="card__inner">
        <img className="card__logo" src={urlChecked} alt="Лого карты" />
      </div>
    </div>
  );
};
