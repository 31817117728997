import {
  BlobShape,
  DotShape,
  HeartShape,
  DotOutlineShape,
} from '@features/health/ui/health-detail/metric-chart';

export const GetShapeComponent = ({ shape, ...props }) => {
  switch (shape) {
    case 'dot':
      return DotShape(props);
    case 'heart':
      return HeartShape(props);
    case 'blob':
      return BlobShape(props);
    case 'dotOutline':
      return DotOutlineShape(props);
  }
  return null;
};
