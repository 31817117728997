import React, { useMemo } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { FileGroups } from '@teledoctor/common/dist/features/audio-player/types';
import { MessageModal } from '../../../../ui/organisms/modal';
import { ChatItem } from '@eus/react-client/src/chat_v2/types';
import { ChatMessage } from './chat-message';
import format from 'date-fns/esm/format';
import ru from 'date-fns/esm/locale/ru';
import { ChatButtons, ChatButtonsProps } from './chat-buttons';
import './chat-area.scss';

interface ChatMessageAuthor {
  name: string;
  photo: string;
}

interface FileInfo {
  size?: number;
  duration?: string;
  name?: string;
  type: FileGroups | undefined;
}

export interface ChatMessage {
  id: number;
  text?: string;
  time: string;
  type: 'text' | 'image' | 'file' | false;
  author?: ChatMessageAuthor;
  fileInfo?: FileInfo;
  src?: string;
}

interface ChatMessageProps {
  chatItems: ChatItem[];
  chatButtons?: ChatButtonsProps;
}

export const ChatArea: React.FC<ChatMessageProps> = ({
  chatItems,
  chatButtons,
}) => {
  const reversedChatItems: ChatItem[] = useMemo(() => {
    return [...chatItems].reverse();
  }, [chatItems]);
  return (
    <>
      <ScrollToBottom
        debug={false}
        className="chat__area"
        scrollViewClassName="chat__area-scroll"
        initialScrollBehavior="auto"
        followButtonClassName="chat__area-button">
        {reversedChatItems.map((chatItem, index) => {
          switch (chatItem.type) {
            case 'date':
              return (
                <div className="chat__date-divider" key={chatItem.id}>
                  {format(chatItem.payload, 'dd MMMM', { locale: ru })}
                </div>
              );
            case 'cancel':
              return <div key={chatItem.id}>cancel</div>;
            case 'message':
              return (
                <ChatMessage
                  message={chatItem.payload}
                  source={
                    chatItem.payload.type === 'client' ? 'outgoing' : 'incoming'
                  }
                  key={chatItem.payload.code}
                />
              );
            default:
              return <div key={chatItem.id}>nonReadMessages</div>;
          }
        })}
        {!!chatButtons && (
          <div className="chat__message-group chat__message-group-admin">
            <ChatButtons {...chatButtons} />
          </div>
        )}
      </ScrollToBottom>
      <MessageModal />
    </>
  );
};
