import classnames from 'classnames';
import React from 'react';

interface Props {
  title?: string;
  data: number[] | string[];
  normal?: true;
}

export const PulseModalInfoLine = ({ title, data, normal }: Props) => {
  const titleClasses = classnames('pulse-info-table__data-title', {
    'pulse-info-table__data-title--normal': normal,
  });
  const cellClasses = classnames('pulse-info-table__data-cell', {
    'pulse-info-table__data-cell--normal': normal,
  });

  return (
    <>
      {title && <div className={titleClasses}>{title}</div>}
      <div className="pulse-info-table__data-cells">
        {data.map((number, index) => (
          <p className={cellClasses} key={index}>
            {number}
          </p>
        ))}
      </div>
    </>
  );
};
