import { useCallback } from 'react';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';
import { Button } from '@ui';
import { push } from '@features/app/navigation.model';
import styles from './style.module.scss';
import { useChatTdLk, useTasksTdLk } from '@features/api';

interface Props {
  isEnabled: boolean;
  chatId: string;
}

export const ChatClientCare = ({ isEnabled, chatId }: Props) => {
  const { card: defaultCard } = useDefaultCard();
  const { clientCare } = useTasksTdLk();
  const { disableMessagesAtAdditionalPayloadType } = useChatTdLk();

  const clientCareNoHandler = useCallback(() => {
    clientCare({ taskId: Number(chatId), type: 'no' }).then(() => {
      disableMessagesAtAdditionalPayloadType(chatId, 'client_care');
    });
  }, [clientCare, chatId, disableMessagesAtAdditionalPayloadType]);

  return (
    <div className={styles.wrapper}>
      <Button
        title="Да"
        disabled={!isEnabled}
        className={styles.button}
        onClick={() => {
          if (defaultCard) {
            push({
              path: '/lk/tasks/get',
              state: {
                cardClientId: defaultCard.card_client_id,
                relatedTaskId: chatId,
                isClientCare: true,
              },
            });
          }
        }}
      />
      <Button
        title="Нет"
        disabled={!isEnabled}
        className={styles.button}
        onClick={clientCareNoHandler}
      />
    </div>
  );
};
