import { useLayoutEffect } from 'react';
import { useLatest } from '@teledoctor/common/dist/hooks';

const useMatchMedia = (
  query: string,
  handler: (event: Partial<MediaQueryListEvent>) => void,
) => {
  const callback = useLatest(handler);

  useLayoutEffect(() => {
    if (!window.matchMedia) {
      console.error('window.matchMedia is not supported in this environment.');
      return;
    }

    const mediaQuery = window.matchMedia(query);
    const checkMedia = (args: MediaQueryListEvent) => callback.current(args);

    mediaQuery.addEventListener('change', checkMedia);
    callback.current({ matches: mediaQuery.matches });

    return () => {
      mediaQuery.removeEventListener('change', checkMedia);
    };
  }, [callback, query]);
};

export { useMatchMedia };
