import classnames from 'classnames';

import './status.scss';

import {
  STATUS_NEW,
  STATUS_IN_PROCESS,
  STATUS_CANCELLED,
  STATUS_END,
  STATUS_INFO,
} from '@eus/els-api-types/src/task-status-cp';

interface StatusProps {
  id: number;
  className?: string;
  text: string;
}

export const Status = ({ id, text, className }: StatusProps) => {
  const classNames = classnames('status', className, getStatusClassname(id));

  return <div className={classNames}>{text}</div>;
};

const getStatusClassname = (id: number) => {
  switch (id) {
    case STATUS_NEW:
      return 'status--new';
    case STATUS_IN_PROCESS:
      return 'status--inwork';
    case STATUS_INFO:
      return 'status--inwork';
    case STATUS_CANCELLED:
      return 'status--canceled';
    case STATUS_END:
      return 'status--finished';
    default:
      return 'status--finished';
  }
};
