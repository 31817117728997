import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, FField, FormWrapper } from '@ui';

import {
  useAddCardFormSchema,
  AddCardFormValues,
} from '@teledoctor/common/dist/features/cards/forms';
import {
  activationCodeMask,
  cardNumberMask,
  commonFormErrorHandler,
  CommonFormProps,
} from '@teledoctor/common/dist/features/shared/forms';

export const AddCardForm = ({
  onSuccess,
  defaultValues,
}: CommonFormProps<AddCardFormValues>) => {
  const { AddCardFormSchema } = useAddCardFormSchema();
  const methods = useForm<AddCardFormValues>({
    resolver: yupResolver(AddCardFormSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const { setError, handleSubmit } = methods;

  // const submitForm = () => {

  // }

  return (
    <FormWrapper
      onSubmit={handleSubmit(
        (values) => onSuccess(values, setError as any),
        commonFormErrorHandler('add-card'),
      )}>
      <FField.InputMask
        name="number"
        methods={methods}
        label="Номер карты"
        className="form__field"
        mask={/^(?=.{1,16}$)[0-9-/]*$/}
        placeholder="Введите номер карты"
        data-cy="CARDS_ADD_CARD_NUMBER_INPUT"
      />
      <FField.InputMask
        name="code_activation"
        methods={methods}
        label="Код активации"
        className="form__field"
        mask={activationCodeMask}
        placeholder="Введите код активации"
        data-cy="CARDS_ADD_CARD_CODE_INPUT"
      />
      <div className="form__row">
        <Button
          className="button button__primary -width__xs-100"
          title="Продолжить"
          data-cy="CARDS_ADD_CARD_CONTINUE_BTN"
        />
      </div>
    </FormWrapper>
  );
};
