import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import { usePhoneCodes } from '@eus/react-client/src/phone-codes';
import { ProfileInfoView, ProfilePropsList } from '@features/user/ui/profile';
import { useSexSelectValues } from '@teledoctor/common/dist/features/shared/forms';
import { ProfileData } from '@teledoctor/common/dist/features/user/types';
import React, { useEffect, useState } from 'react';
import { useLoadOptionsCountry } from './load-county';

function formatPhone(value: string | number | null, pattern: string) {
  if (!value) {
    return '';
  }
  const v = value.toString().replace(/\D/g, () => '');

  let i = 0;

  return pattern.replace(/9/g, (_) => v[i++] || '');
}

interface ProfileProps {
  user: ProfileData;
}

export const ProfileInfo = ({ user }: ProfileProps) => {
  const {
    avatar_path,
    first_name,
    last_name,
    middle_name,
    country_code,
    email,
    sex,
    phone,
    date_of_birth,
  } = user;

  const { data: countryInfo, loadCountryData } = useLoadOptionsCountry();
  const { getCountryForPhone } = usePhoneCodes();
  const [pattern, setPattern] = useState('');

  useEffect(() => {
    getCountryForPhone('+' + phone, true).then((data) =>
      data?.pm
        ? setPattern(data.pc.replace(/\d/, () => '9') + ' ' + data?.pm)
        : undefined,
    );
  });

  useEffect(() => {
    if (country_code?.[0]?.value) {
      loadCountryData(country_code?.[0]?.value);
    }
  }, [country_code, loadCountryData]);
  const { sexSelectValues } = useSexSelectValues();

  const avatarPath = avatar_path ? avatar_path : '';
  const sexObj = sexSelectValues.find((item) => item.value === sex);
  const sexString = sexObj?.label;

  const propsList: ProfilePropsList = {
    name: [last_name, first_name, middle_name].filter(Boolean).join(' '),
    avatar: {
      pathname: avatarPath,
      sex: sex,
    },
    button: {
      text: 'Редактировать',
      to: {
        pathname: 'profile/edit',
        state: {},
      },
    },
    propsGroups: [
      {
        label: '',
        props: [
          {
            label: 'Номер телефона',
            value: formatPhone(phone || '', pattern),
          },
          {
            label: 'E-mail',
            value: email ? email : '',
          },
          ...(date_of_birth
            ? [
                {
                  label: 'Дата рождения',
                  value: formatDateShort(date_of_birth),
                },
              ]
            : []),
          {
            label: 'Пол',
            value: sexString ? sexString : '',
          },
          ...(countryInfo?.name || countryInfo?.name_short
            ? [
                {
                  label: 'Страна',
                  value: countryInfo?.name_short || countryInfo?.name || '',
                },
              ]
            : []),
          ...(user.address &&
          user.address !== '' &&
          JSON.parse(user.address)?.fullAddressString !== ''
            ? [
                {
                  label: 'Адрес проживания',
                  value: JSON.parse(user.address)?.fullAddressString,
                },
              ]
            : [{ label: 'Адрес проживания', value: 'Не указано' }]),
        ],
      },
    ],
  };

  return <ProfileInfoView {...propsList} />;
};
