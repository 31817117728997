import React, { useState } from 'react';
import { Collapse } from 'react-collapse';

import '@styles/components/accordion.scss';

import { HandbookQA } from '../../types';

interface HandbookItemProps {
  item: HandbookQA;
}

export const HandbookItem: React.FC<HandbookItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { title, answer } = item;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button
      className="accordion__item block"
      role="button"
      data-toggle="collapse"
      aria-expanded={isOpen}
      aria-controls={title}
      onClick={handleClick}
      data-cy="HANDBOOK_TOGGLE">
      <div className="accordion__title">
        <div className="accordion__question">{title}</div>
        <div className={`accordion__toggle toggler-${isOpen && 'checked'}`} />
      </div>
      <Collapse isOpened={isOpen} id={title}>
        <div className="accordion__answer" data-cy="HANDBOOK_ANSWER">
          {answer}
        </div>
      </Collapse>
    </button>
  );
};
