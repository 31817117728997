import SelectComplete from '@eus/selects/src/SelectComplete';
import { SelectOption, SelectPropsComplete } from '@eus/selects/src/types';
import { useClientCrm } from '@teledoctor/common/dist/hooks/use-client-crm';
import { Country as CountrySuggestionType } from '@eus/address/src/country';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Colors, FieldWrapper } from '@ui';
import { CSSProperties } from 'react';
import componentsBase from '@eus/selects/src/components';

export interface SelectCountryProps {
  name: string;
  control: Control<any>;
  errors: FieldErrors<any>;
  fieldClassname?: string;
  onFieldChange?: (value: (SelectOption | null)[]) => void;
  dataCy?: string;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
}

type StylesFn = (base: CSSProperties, state: any) => CSSProperties;

const styles: {
  [key: string]: StylesFn;
} = {
  container: (base, state) => ({}),
  indicatorSeparator: (base, state) => ({}),
  dropdownIndicator: () => ({}),
  control: (base, state) => ({}),
  valueContainer: (base, state) => ({}),
  input: (base, state) => {
    return {
      margin: 0,
      padding: '10px 0',
      fontSize: 'inherit',
    };
  },
  menu: (base, state) => ({}),
  menuList: (base, state) => ({}),
  option: (base, state) => ({}),
  loadingIndicator: (base, state) => ({}),
  placeholder: (base, state) => ({}),
};

export const SelectCountry = ({
  name,
  control,
  label,
  errors,
  fieldClassname = '',
  placeholder = 'Введите страну',
  dataCy = 'select_country',
  onFieldChange,
  ...rest
}: SelectCountryProps) => {
  const client = useClientCrm();

  const loadOptions = (search: string) => {
    return new Promise<CountrySuggestionType[]>((resolve, reject) => {
      client({
        url: '/fias/v1/country',
        method: 'get',
        params: {
          search: search,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const preLoadDefaultValue = (countryCode: string | number) => {
    return new Promise<any>((resolve, reject) => {
      client({
        url: '/fias/v1/country',
        method: 'get',
        params: {
          search: countryCode,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const formatLabel = (item: CountrySuggestionType) => {
    return item.name_short || item.name;
  };
  const formatValue = (item: CountrySuggestionType) => {
    return item.code;
  };

  const CustomControl = (props) => (
    <componentsBase.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-cy': dataCy,
      }}
    />
  );

  const CustomInput = (props) => (
    <componentsBase.Input {...props} data-cy={`${dataCy}_input`} />
  );

  const CustomOption = (props) => {
    return (
      <componentsBase.Option
        {...props}
        innerProps={{
          ...props.innerProps,
          'data-cy': `${dataCy}_option_${props.data.alfa3}`,
        }}
      />
    );
  };

  const CustomClearIndicator = (props) => {
    return (
      componentsBase.ClearIndicator && (
        <componentsBase.ClearIndicator
          {...props}
          innerProps={{
            ...props.innerProps,
            'data-cy': `${dataCy}_clear`,
          }}
        />
      )
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={errors}
            name={name}
            disabled={false}
            errorsClassName={''}
            className={fieldClassname}
            labelClassName={''}
            dataCy={dataCy}>
            <SelectComplete
              {...rest}
              loadOptions={loadOptions}
              formatLabel={formatLabel}
              formatValue={formatValue}
              name={name}
              preloadOption={preLoadDefaultValue}
              onChange={(value) => {
                onChange([value]);
                onFieldChange?.([value]);
                onBlur();
              }}
              styles={styles}
              placeholder={placeholder}
              value={value ? value[0].value : value}
              onBlur={onBlur}
              classNamePrefix="input-select"
              components={{
                ...componentsBase,
                Control: CustomControl,
                Option: CustomOption,
                Input: CustomInput,
                ClearIndicator: CustomClearIndicator,
              }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
};
