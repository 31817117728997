import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import './explanation.scss';
import classnames from 'classnames';

interface ExplanationInterface {
  modClass?: string;
  tooltip: string;
  symbol?: string | JSX.Element;
}

export const Explanation: React.FC<ExplanationInterface> = ({
  modClass,
  tooltip,
  symbol = '?',
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const offsetModifier = React.useMemo(
    () => ({
      name: 'offset',
      options: {
        offset: ({ placement, reference }) => {
          if (placement === 'top-start') {
            return [reference.width / 2, 5];
          } else {
            return [-reference.width / 2, 5];
          }
        },
      },
    }),
    [],
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [offsetModifier],
  });

  const className = classnames('explanation', modClass);

  return (
    <div className={className} ref={setReferenceElement}>
      <div className="explanation__symbol">{symbol}</div>
      <div
        className="explanation__tooltip"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}>
        {tooltip}
      </div>
    </div>
  );
};
