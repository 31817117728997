import './header.scss';
import { AppLinks, Burger, Logout, SupportTel, Svg } from '../atoms';

interface HeaderProps {
  onToggleMobSidebar: () => void;
  onClickLogout: () => void;
}

export const Header = ({ onToggleMobSidebar, onClickLogout }: HeaderProps) => {
  return (
    <header className="header grid__root">
      <div className="header__inner grid__container">
        <div className="header__left">
          <nav className="header__app-links">
            <AppLinks />
          </nav>
          <div className="header__logo-mobile">
            <Svg name="logo" modClass="header__logo" />
          </div>
        </div>
        <div className="header__right">
          <div className="header__support-tel">
            <SupportTel />
          </div>
          <div className="header__logout-btn -d__md-none">
            <Logout
              onClick={onClickLogout}
              dataCy="header_logout_btn_desktop"
            />
          </div>
          <div className="header__burger-wrapper -d__md-none-up">
            <Burger onClick={onToggleMobSidebar} svgModClass="header__burger" />
          </div>
        </div>
      </div>
    </header>
  );
};
