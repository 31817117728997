import { createEvent, createDomain } from 'effector';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const Navigation = createDomain('app/navigation');

export interface HistoryChange {
  pathname: string;
  hash: string;
  search: string;
  action: 'PUSH' | 'POP' | 'REPLACE';
}

/**
 * Events
 */

export const changed = Navigation.createEvent<HistoryChange>(
  'app/navigation:changed',
);
export const push = createEvent<{ path: string; state?: any }>(
  'app/navigation:push',
);
export const replace = createEvent<string>('app/navigation:replace');
export const goBack = createEvent('app/navigation:back');

/**
 * Connections
 */

push.watch(({ path, state }) => {
  history.push(path, state);
});

replace.watch((url) => history.replace(url));

goBack.watch(() => {
  history.goBack();
});

history.listen(({ pathname, search, hash }, action) => {
  changed({ pathname, search, hash, action });
});
