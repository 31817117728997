import React from 'react';
import { EmailForm } from './email-form';
import {
  UpdateFieldValues,
  UpdateFieldViaCodeModal,
} from '@features/user/ui/forms/edit-profile/change-field-via-code';
import mailbox from '@assets/img/mailbox.png';
import { useProfileTdLk } from '@features/api';
import styles from './styles.module.scss';
import { FeedParams } from '@teledoctor/common/dist/lib/requests/wrappers';

interface Props {
  close: () => void;
  isOpen: boolean;
}

const renderCodeDescription = (email: string) => (
  <p>Код отправлен на вашу новую почту {email}. Введите его сюда.</p>
);

const renderHeader = (isOpen: boolean, email?: string | null) =>
  isOpen ? 'Изменить E-mail' : email ? 'Код подтверждения' : '';

export const ChangeEmailForm = (props: Props) => {
  const { isOpen, close } = props;

  const { requestCodeForUpdateEmail, changeEmailByCode } = useProfileTdLk();

  const updateField = (
    { value: email, code }: UpdateFieldValues,
    feedParams: FeedParams,
  ) => changeEmailByCode({ code, email }, feedParams);

  return (
    <UpdateFieldViaCodeModal
      onSuccessRequestCode={close}
      updateField={updateField}
      requestCodeFromUpdateField={requestCodeForUpdateEmail}
      renderCodeDescription={renderCodeDescription}
      isOpen={isOpen}
      onClose={close}
      image={mailbox}
      header={renderHeader}
      codeLabel="Новый e-mail"
      codePlaceholder="Введите новый E-mail"
      headerClassNames={styles.header}
      contentClassNames={styles.content}
      imageClassNames={styles.image}
      popupClassNames={styles.modal}>
      {({ onRequestCode }) => <EmailForm onSuccess={onRequestCode} />}
    </UpdateFieldViaCodeModal>
  );
};
