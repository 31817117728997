import classNames from 'classnames';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
  className?: string;
}

export const AuthBack = ({ onClick, className }: Props) => {
  const classWrapper = classNames(styles.back, className);

  return (
    <div className={classWrapper} onClick={onClick}>
      <div className={styles.arrow} />
      Назад
    </div>
  );
};
