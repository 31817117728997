import React, { useEffect, useState } from 'react';
import {
  TitleLine,
  Breadcrumbs,
  BreadcrumbsItem,
  FileInput,
  Button,
  FileInputInterface,
  addNotification,
} from '@ui';
import { useQuery } from '@router';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import { MetricViewer, MetricWrapper } from '@features/health/ui/health-detail';
import { XRayDetail, XRayModal } from '@features/health/ui/metrics/x-ray';
import {
  XRayErrorStatuses,
  XRayGetStatus,
} from '@eus/health-default-logic/dist/x-ray/functions';
import { XRayStatusesText } from '@eus/health-default-logic/dist/x-ray/types';
import { FileWithRawUri } from '@teledoctor/common/dist/lib/request';
import { dataURItoBlob } from '@lib/data-uri-to-blob';
import { clearNotifications } from '../../../ui';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/xray',
    breadcrumb: 'Рентген',
  },
];

export const XRayPage = () => {
  const [addXRayFile, setAddXRayFile] = useState<FileInputInterface>();
  const query = useQuery();
  const xRayId = query.get('id') || '';
  const {
    metric: { loadXRayList, $xRayCrud, createXRay },
  } = useMetric({ metric: 'xRay' });

  const xRayCrud = useStore($xRayCrud);
  const xRayList = xRayCrud.list;

  useEffect(() => {
    loadXRayList({
      uiBehavior: {
        noLoader: !!xRayCrud.lastUpdated,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!xRayCrud.lastUpdated]);

  const fileInputChange = (files) => {
    setAddXRayFile((files ?? [])[0] ?? null);
  };

  useEffect(() => {
    if (addXRayFile) {
      submitXRay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addXRayFile]);

  const submitXRay = () => {
    clearNotifications();
    if (addXRayFile) {
      const blob = dataURItoBlob(addXRayFile.src);
      const fileObject: FileWithRawUri = {
        uriRaw: blob as any,
        uri: blob as any,
        name: addXRayFile.name,
        type: addXRayFile.type,
      };
      createXRay({
        createData: {
          xray_file: fileObject,
        },
      }).then(
        () => {
          addNotification({
            id: 'xray-' + Date.now(),
            type: 'done',
            message: 'Загрузка успешно завершена',
            target: 'global',
          });
        },
        () => {
          addNotification({
            id: 'xray-' + Date.now(),
            type: 'error',
            message: 'Не удалось загрузить файл, попробуйте еще раз',
            target: 'global',
          });
        },
      );
    }
  };

  const data = xRayList.map((item) => {
    const status = XRayGetStatus(item.status);
    const isError = XRayErrorStatuses.includes(item.status);
    const statusText = !isError ? XRayStatusesText[status] : 'Ошибка';
    return {
      id: item.id,
      name: statusText,
      timestamp: Number(new Date(item.date)),
      ...(isError && { errorStatus: true }),
    };
  });

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
        <div className="titleline__button">
          <FileInput
            onChange={fileInputChange}
            maxFileSize={Infinity}
            fileType={['medical']}>
            <Button
              type="button"
              className="button__primary button__file"
              title="Загрузить снимок"
              data-cy="UPLOAD_XRAY_BTN"
            />
          </FileInput>
        </div>
      </TitleLine>
      <MetricWrapper
        bottomText={
          xRayList.length
            ? 'Для анализа доступен только рентген грудной клетки.\nЗагружайте снимки в формате dcm.'
            : ''
        }>
        <MetricViewer
          data={data}
          elementId={xRayId}
          emptyDataText={'Загрузите рентген легких в формате dcm.'}
          chooseElementText={'Выберите рентген из списка слева'}
          rightColumnRender={() => <XRayDetail />}
          modalRender={() => <XRayModal />}
        />
      </MetricWrapper>
    </>
  );
};
