import { useEffect, useState } from 'react';
import { Controller, get, set, useForm } from 'react-hook-form';

import { Button, FormWrapper, Notifications } from '@ui';
import './form.scss';
import { SelectCard } from './select-card';
import { SelectService } from './select-service';

import { ServerErrorsHandler } from '@eus/react-client';
import { Schema } from '@eus/react-client/src/types/schema';
import { Task } from '@eus/react-client/src/types/tasks';
import { ValidationSchema } from '@eus/react-client/src/validator-schema/validation';
import { useChatTdLk, useTasksTdLk } from '@features/api';
import { FormSchema } from '@features/form-schema';
import { CreateFormValues } from '@teledoctor/common/dist/features/consultations/forms';
import {
  commonFormErrorHandler,
  useErrorMessage,
} from '@teledoctor/common/dist/features/shared/forms';
import { validatePhone } from '@teledoctor/common/dist/features/user/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers/';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { ProcessCommonValidationError } from '@teledoctor/common/dist/lib/requests/interceptors';
import { addNotification } from '../../../../../ui/organisms/notifications/model';

type SubmitValues = CreateFormValues & {
  service_id: number;
};

type Props = {
  schema?: Schema;
  loadSchema: (serviceId: number) => Promise<void>;
  resetSchema: () => void;
  defaultValues: CreateFormValues;
  relatedTaskId?: number;
  isClientCare?: boolean;
  onFormSubmitted?: (task: Task) => void;
};

const customValidationSchema = (schema, data, errorMessage) => {
  const result = ValidationSchema(schema, data, errorMessage);

  schema.forEach((item) => {
    const { type, field, required, min, maxFiles } = item;
    let value = get(data, field);

    if (type === 'phone') {
      if (value && value.length > 1) {
        if (!validatePhone(value)) {
          set(result.errors, field, {
            type: 'validation',
            message: errorMessage.phone,
          });
        }
      } else if (required) {
        set(result.errors, field, {
          type: 'validation',
          message: errorMessage.required,
        });
      }
    }
  });
  return result;
};

export const GetConsultationForm = ({
  schema,
  defaultValues,
  loadSchema,
  resetSchema,
  isClientCare,
  relatedTaskId,
  onFormSubmitted,
}: Props) => {
  const { ErrorMessage: ErrorMessageBase } = useErrorMessage();

  const ErrorMessage = {
    ...ErrorMessageBase,
    min: ErrorMessageBase.min_length_equals,
    maxFiles: ErrorMessageBase.max_files,
  };

  const methods = useForm<CreateFormValues>({
    resolver: (data) =>
      customValidationSchema(
        [
          { type: 'select', field: 'card_client_id', required: true },
          { type: 'select', field: 'service_id', required: true },
          ...(schema ?? []),
        ],
        data,
        ErrorMessage,
      ),
    mode: 'onBlur',
    defaultValues,
    shouldUnregister: true,
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    setError,
    reset: resetForm,
  } = methods;

  const cardIdSelected = watch('card_client_id');
  const serviceIdSelected = watch('service_id');

  const [cardId, setCardId] = useState(cardIdSelected);
  const [serviceId, setServiceId] = useState(serviceIdSelected);

  const { createTask, clientCare } = useTasksTdLk();
  const { disableMessagesAtAdditionalPayloadType } = useChatTdLk();

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (cardIdSelected !== cardId) {
      resetSchema();
      setValue('service_id', null);
      setCardId(cardIdSelected);
    }
  }, [resetSchema, setValue, resetForm, cardIdSelected, cardId]);

  useEffect(() => {
    if (serviceIdSelected !== serviceId) {
      setServiceId(serviceIdSelected);

      if (serviceIdSelected) {
        loadSchema(serviceIdSelected);
      }
    }
  }, [loadSchema, serviceIdSelected, serviceId]);

  const onSubmit = (values: CreateFormValues) => {
    setIsCreating(true);
    const consultation_date =
      values.consultation_date && values.consultation_date.getTime() / 1000;

    const video_datetime = values.video_datetime && {
      date: values.video_datetime.date.getTime() / 1000,
      // @ts-ignore
      hours: values.video_datetime.time.hours,
      // @ts-ignore
      minutes: values.video_datetime.time.minutes,
    };

    const formattedData = {
      ...values,
      ...(video_datetime ? { video_datetime } : {}),
      ...(consultation_date ? { consultation_date } : {}),
    };

    const data = (
      relatedTaskId
        ? { ...formattedData, related_task_id: relatedTaskId }
        : formattedData
    ) as SubmitValues;

    createTask(
      { data },
      new FeedParams({
        errorProcessors: [ProcessCommonValidationError],
        throwNext: true,
        needShowLoader: true,
      }),
    )
      .then((task) => {
        if (!!isClientCare && !!relatedTaskId) {
          clientCare({ taskId: relatedTaskId, type: 'yes' }).then(() => {
            disableMessagesAtAdditionalPayloadType(
              relatedTaskId.toString(),
              'client_care',
            );
          });
        }
        onFormSubmitted?.(task);
      })
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errText) =>
              addNotification({
                target: 'global',
                id: 'create-consultation' + Date.now(),
                message: errText,
                type: 'error',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: (err) => ServerErrorsHandler(setError)(err),
          },
        }),
      )
      .finally(() => setIsCreating(false));
  };
  const handleCardChange = (cardClientId) => {
    setValue('card_client_id', Number(cardClientId));
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(
        onSubmit,
        commonFormErrorHandler('get-consultation'),
      )}
      className="get-consultation"
      data-cy="GET_CONSULT_FORM">
      <div className="block block__content get-consultation__inner">
        <div className="block__columns">
          <div className="block__column-left">
            <Controller
              name="card_client_id"
              control={control}
              render={({ field: { value, onBlur } }) => {
                return (
                  <SelectCard
                    value={value}
                    onSelect={(cardClientId) => {
                      handleCardChange(cardClientId);
                      onBlur();
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="block__column-right">
            <div className="get-consultation__fields">
              <SelectService
                name="service_id"
                methods={methods}
                cardId={cardIdSelected ?? -1}
                // onSelect={handleServiceChange}
              />

              {/* <FField.DateTime
                baseURL={HOST_CRM_API}
                methods={methods}
                name="datetime"
                label="Дата и время"
                datesResource="tasks/video-dates"
                timeResource="tasks/video-schedule"
                className="form__field"
              /> */}

              <FormSchema
                schema={schema}
                methods={methods}
                errors={methods.formState.errors}
                getValues={getValues}
              />
            </div>
            {!!schema && (
              <Button
                disabled={isCreating}
                type="submit"
                title="Получить консультацию"
                className="get-consultation__submit -width__xs-100"
                data-cy="GET_CONSULT_SUBMIT_BTN"
              />
            )}
          </div>
        </div>
      </div>
      <Notifications target="get-consultation" />
    </FormWrapper>
  );
};
