import { Svg } from '@ui';
import styles from './single.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const SingleTariffType = ({ className }: Props) => {
  return (
    <div className={classNames(styles['one-time'], className)}>
      <Svg name="card-patient" modClass={styles['one-time__icon']} />
      Разовая консультация
    </div>
  );
};
