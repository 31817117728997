import React, { useMemo } from 'react';
import { Metrics } from '@teledoctor/common/dist/metrics/type';
import { ValueBlock } from '@features/health/ui/health-detail/metric-chart';
import './values-list.scss';
interface Props {
  data: Metrics[];
}

export const ValuesList = (props: Props) => {
  const rows = useMemo(
    () =>
      props.data.reduce((tot, cur, index) => {
        if (index % 2 === 0) {
          return tot.concat([[cur]]);
        } else {
          return tot.slice(0, -1).concat([[tot[tot.length - 1][0], cur]]);
        }
      }, [] as Metrics[][]),
    [props.data],
  );

  return (
    <div className="metric-chart__info-values">
      {rows.length ? (
        rows.map((item, index) => (
          <div className="metric-chart__info-values-row" key={index}>
            <ValueBlock {...item[0]} />
            {item.length === 2 && <ValueBlock {...item[1]} />}
          </div>
        ))
      ) : (
        <div className="metric-chart__info-values-row">
          <div className="block metric-chart__info-value-item metric-chart__info-empty">
            Нет данных
          </div>
          <div className="block metric-chart__info-value-item metric-chart__info-empty">
            Нет данных
          </div>
        </div>
      )}
    </div>
  );
};
