import React from 'react';
import { Modal, ImageModal } from '../../atoms';
import './img-btn-modal.scss';
import picIcon from '@assets/img/picture-icon.svg';
import { useBoolean } from '@teledoctor/common/dist/hooks';

interface Props {
  src: string;
  modClass?: string;
}

export const ImgBtnModal = ({ src, modClass }: Props) => {
  const {
    value: isVisible,
    setTruthy: openModal,
    setFalsy: closeModal,
  } = useBoolean(false);

  return (
    <>
      <div
        className={`img-btn-modal ${modClass || ''}`}
        onClick={openModal}
        data-cy="SHOW_IMAGE_IN_MODAL_BTN">
        <span className="img-btn-modal__text">Посмотреть снимок</span>{' '}
        <div
          className="img-btn-modal__icon"
          style={{ backgroundImage: `url(${picIcon})` }}
        />
      </div>
      <Modal onClose={closeModal} isOpen={isVisible} className="image-modal">
        <ImageModal src={src} showButtons={false} />
      </Modal>
    </>
  );
};
