import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { Controller, UseFormReturn, UseFormSetError } from 'react-hook-form';
import { ChangeAvatar } from './change-avatar';
import { Button, FField, FFieldArray, FormWrapper } from '@ui';
import { useProfileEditFormSchema } from '@teledoctor/common/dist/features/user/forms';
import { useSexSelectValues } from '@teledoctor/common/dist/features/shared/forms';
import { EditProfileFormData } from '@teledoctor/common/dist/features/user';
import { ServerErrorsHandler } from '@eus/react-client/src/forms/serverErrorsHandler';
import { useProfileTdLk } from '../../../../api';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { addNotification } from '../../../../../ui/organisms/notifications/model';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { formatPhoneWithPlus } from '@teledoctor/common/dist/helpers/phone';
import { SelectCountry } from '@features/shared/ui';
import {
  OnSuccessForm,
  useBoolean,
  useFormWithSchema,
  useUpdateFormField,
} from '@teledoctor/common/dist/hooks';
import { ChangeEmailForm } from './change-email';
import { ChangePhoneForm } from '@features/user/ui/forms/edit-profile/change-phone';
import { removeMaskFromPhone } from '@teledoctor/common/dist/lib/formatters/remove-mask-from-phone';
import { createPortal } from 'react-dom';

const handleError = (setError: UseFormSetError<EditProfileFormData>) =>
  formServerErrorHandler({
    commonErrorCase: {
      commonErrorCallback: (errorText) =>
        addNotification({
          id: 'profile' + new Date(),
          type: 'error',
          message: errorText,
          target: 'global',
        }),
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler<EditProfileFormData>(setError),
    },
  });

const feedParams = new FeedParams({
  needShowLoader: true,
  throwNext: true,
  ignoreError: true,
});

interface ProfileEditFormProps {
  defaultValues: Omit<EditProfileFormData, 'avatar'>;
  onSuccess: () => void;
}

export const ProfileEditForm = ({
  defaultValues,
  onSuccess,
}: ProfileEditFormProps) => {
  const [addressString, setAddressString] = useState(
    defaultValues?.address ? JSON.parse(defaultValues?.address || '') : {},
  );

  const onSubmit: OnSuccessForm<EditProfileFormData> = (values, setError) => {
    const stringifiedAddress = JSON.stringify(values.address);

    const newAddress =
      stringifiedAddress !== '{}' ? JSON.stringify(values.address) : '';

    const data = {
      ...values,
      address: newAddress,
    };

    const nonEmptyValues = Object.keys(data).reduce(
      (res, key) =>
        data[key] !== undefined ? { ...res, [key]: data[key] } : res,
      {},
    ) as EditProfileFormData;

    return sendEditedProfile(nonEmptyValues, feedParams)
      .then(() => onSuccess())
      .catch(handleError(setError));
  };

  const { ProfileEditFormSchema } = useProfileEditFormSchema();
  const { methods, onSubmitForm } = useFormWithSchema<EditProfileFormData>({
    validationSchema: ProfileEditFormSchema,
    mode: 'onChange',
    onValid: onSubmit,
    defaultValues: {
      ...defaultValues,
      phone: formatPhoneWithPlus(defaultValues.phone),
      address: addressString,
      additional_phones:
        defaultValues.additional_phones?.map(formatPhoneWithPlus),
    },
  });

  const { watch, formState, getValues, control } = methods;

  const addressChange = watch('address');

  useEffect(() => {
    const newAddress = getValues('address');
    if (newAddress && newAddress !== addressString) {
      setAddressString(newAddress);
    }
  }, [addressChange, getValues, addressString]);

  const { sendEditedProfile } = useProfileTdLk();

  const sex = useMemo(() => watch('sex'), [watch]);

  const renderAvatar = useCallback(() => <ChangeAvatar sex={sex} />, [sex]);
  const { sexSelectValues } = useSexSelectValues();

  const {
    openChangePhoneModal,
    closeChangePhoneModal,
    isVisibleChangePhoneModal,
  } = usePhoneUpdateModal({ methods, phone: defaultValues.phone });

  const {
    openChangeEmailModal,
    closeChangeEmailModal,
    isVisibleChangeEmailModal,
  } = useEmailUpdateModal({ methods, email: defaultValues.email });

  return (
    <section className="block edit-profile edit-profile__active">
      <div className="block__columns">
        <div className="block__column-left">
          <Controller name="avatar" control={control} render={renderAvatar} />
        </div>
        <div className="block__column-right">
          <FormWrapper className="edit-profile__form" onSubmit={onSubmitForm}>
            <div className="form__row -mb__xmedium -mb__xs-xsmall">
              <FField.Input
                name="first_name"
                methods={methods}
                label="Имя"
                placeholder="Введите Ваше имя"
                type="text"
                className="form__field"
                data-cy="PROFILE_FIRSTNAME_INPUT"
              />
              <FField.Input
                name="last_name"
                methods={methods}
                label="Фамилия"
                placeholder="Введите Вашу фамилию"
                type="text"
                className="form__field"
                data-cy="PROFILE_LASTNAME_INPUT"
              />
              <FField.Input
                name="middle_name"
                methods={methods}
                label="Отчество"
                placeholder="Введите Ваше отчество"
                type="text"
                className="form__field"
                data-cy="PROFILE_MIDDLENAME_INPUT"
              />
              <FField.Select
                name="sex"
                methods={methods}
                label="Пол"
                placeholder="Выберите пол"
                options={sexSelectValues}
                className="form__field"
                dataCy="PROFILE_SEX_SELECT"
              />
              <FField.Date
                name="date_of_birth"
                methods={methods}
                label="Дата рождения"
                className="form__field"
                maxDate={new Date()}
              />
              <SelectCountry
                name="country_code"
                control={control}
                label="Страна"
                errors={formState.errors}
                fieldClassname="form__field"
                isClearable
                dataCy="profile_select_country"
              />
              <FField.Address
                name="address"
                methods={methods}
                label="Адрес проживания"
                placeholder="Укажите адрес проживания"
                className="form__field"
                data-cy="profile_edit_addres_input"
                defaultValue={addressString}
                value={addressString}
                dataCy="profile_address"
              />
              <FField.Phone
                onFocus={(event) => {
                  openChangePhoneModal();
                  event.currentTarget.blur();
                }}
                name="phone"
                label="Номер телефона"
                methods={methods}
                className="form__field"
                dataCyCodeSelect="PROFILE_PHONE_CODE_SELECT"
                data-cy="PROFILE_PHONE_INPUT"
                readOnly
              />
              <FFieldArray
                methods={methods}
                name="additional_phones"
                FType="Phone"
                placeholder="Введите дополнительный номер"
                deleteText="Вы уверены что хотите удалить дополнительный телефон?"
                buttonText="Добавить телефон"
              />
              <FField.Input
                onFocus={(event) => {
                  openChangeEmailModal();
                  event.currentTarget.blur();
                }}
                readOnly
                name="email"
                methods={methods}
                label="E-mail"
                placeholder="Введите email"
                type="email"
                className="form__field"
                data-cy="PROFILE_EMAIL_INPUT"
              />
              <FFieldArray
                FType="Input"
                placeholder="Введите дополнительный email"
                methods={methods}
                deleteText="Вы уверены что хотите удалить дополнительный email?"
                name="additional_emails"
                type="email"
                buttonText="Добавить почту"
              />
            </div>
            <div className="form__row">
              <Button
                className="button button__primary -width__xs-100"
                title="Сохранить"
                data-cy="PROFILE_SUBMIT_BTN"
                type="submit"
              />
            </div>
          </FormWrapper>
          <ChangePhoneForm
            isOpen={isVisibleChangePhoneModal}
            close={closeChangePhoneModal}
          />
          <ChangeEmailForm
            isOpen={isVisibleChangeEmailModal}
            close={closeChangeEmailModal}
          />
        </div>
      </div>
    </section>
  );
};

interface UsePhoneUpdateModalParams {
  methods: UseFormReturn<EditProfileFormData>;
  phone?: string;
}

const usePhoneUpdateModal = ({ phone, methods }: UsePhoneUpdateModalParams) => {
  useUpdateFormField({
    field: 'phone',
    value: phone,
    sanitizeValue: removeMaskFromPhone,
    transformResultValue: formatPhoneWithPlus,
    methods,
  });

  const { value, setTruthy, setFalsy } = useBoolean(false);

  return {
    isVisibleChangePhoneModal: value,
    openChangePhoneModal: setTruthy,
    closeChangePhoneModal: setFalsy,
  };
};

interface UseEmailUpdateModalParams {
  methods: UseFormReturn<EditProfileFormData>;
  email?: string | null;
}

const useEmailUpdateModal = ({ email, methods }: UseEmailUpdateModalParams) => {
  useUpdateFormField({
    field: 'email',
    value: email,
    methods,
  });

  const { value, setTruthy, setFalsy } = useBoolean(false);

  return {
    isVisibleChangeEmailModal: value,
    openChangeEmailModal: setTruthy,
    closeChangeEmailModal: setFalsy,
  };
};
