import React from 'react';
import {
  DataTable,
  DataTableWithModal,
} from '../../health-detail/metric-viewer';
import classnames from 'classnames';

import { AnalysisItemsProps } from './use-decryption-data';
import { Explanation, Svg } from '@ui';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG,
} from '@teledoctor/common/dist/lib/text-constants';

interface Props {
  analysisItems: AnalysisItemsProps[];
  withPager: boolean;
}

export const DetailItemsList = ({ analysisItems, withPager }: Props) => {
  const listHolderClasses = classnames(
    'metric-viewer__detail-scrollable metric-viewer__detail-scrollable--full',
    {
      'metric-viewer__detail-paginated': withPager,
    },
  );

  return (
    <div className={listHolderClasses}>
      {analysisItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.basicInfoData && (
            <div className="metric-viewer__detail-section">
              <DataTable data={item.basicInfoData} />
            </div>
          )}
          {item.isSuccessful && (
            <div className="metric-viewer__detail-section">
              <div className="metric-viewer__detail-title">
                <span>Результат </span>
                <Explanation
                  modClass="metric-viewer__detail-tooltip"
                  tooltip={CHECKUP_INTERPRETATION_DISCLAIMER}
                  symbol={
                    <Svg
                      name="info"
                      modClass="metric-viewer__detail-tooltip-icon"
                    />
                  }
                />
              </div>
            </div>
          )}
          {item.dangerousResultsData && (
            <div className="metric-viewer__detail-section">
              <div className="metric-viewer__detail-subtitle">
                Биомаркеры: стоит обратить внимание
              </div>
              <DataTableWithModal
                data={item.dangerousResultsData}
                testScope="HEALTH_ANALYSES_DECRYPTION"
              />
            </div>
          )}
          {item.normalResultsData && (
            <div className="metric-viewer__detail-section">
              <div className="metric-viewer__detail-subtitle">
                Биомаркеры: в норме
              </div>
              <DataTable data={item.normalResultsData} />
            </div>
          )}
          {item.unreadResultsData && (
            <div className="metric-viewer__detail-section">
              <div className="metric-viewer__detail-subtitle">
                Не удалось определить
              </div>
              <DataTable data={item.unreadResultsData} />
            </div>
          )}
          {!item.isSuccessful && (
            <div className="metric-viewer__detail-error-text">
              {ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG.map(
                (paragraph, index) => (
                  <p className="metric-viewer__detail-error-p" key={index}>
                    {paragraph}
                  </p>
                ),
              )}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
