import React from 'react';

import './styles.scss';
import { TitleLine, Search, useSearch } from '@ui';
import { HandbookList } from '@features/user/handbook/ui';

import { handbookQA } from '@features/user/handbook/data';

export const HandbookPage = () => {
  const { result, setQuery } = useSearch({
    source: handbookQA,
    options: { keys: ['title', 'answer'] },
  });

  return (
    <React.Fragment>
      <TitleLine titleText="Справочник" className="handbook__titleline">
        <Search onChange={setQuery} className="handbook__search" />
      </TitleLine>
      <HandbookList data={result} />
    </React.Fragment>
  );
};
