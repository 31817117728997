import React from 'react';
import { Plug, Pagination } from '@ui';
import { Analyze } from '@eus/health-default-logic/dist/analyze/types';
import { AnalysisBox } from './analysis-box';
import './analyses-list.scss';

interface Props {
  data: Item[];
  onDownloadFile: (id) => void;
}

interface Item {
  title: string;
  data: Analyze[];
}

export const AnalysesList = ({ data, onDownloadFile }: Props) => {
  return data.length ? (
    <div className="analyses-list">
      {data.map((month) => (
        <div className="analyses-list__category" key={month.title}>
          <h3 className="analyses-list__category-title">{month.title}</h3>
          <ul className="analyses-list__list">
            {month.data.map((analysis) => (
              <li className="analyses-list__item" key={analysis.id}>
                <AnalysisBox
                  analysis={analysis}
                  onDownloadFile={onDownloadFile}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  ) : (
    <Plug label="Здесь будет отображаться список загруженных анализов" />
  );
};
