import { TitleLine, Breadcrumbs, BreadcrumbsItem, Plug } from '@ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore, useStoreMap } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MedicinesList } from '@features/health/ui/metrics/medicines';
import addDays from 'date-fns/esm/addDays';
import './med-calendar.scss';
import './medicine-colors.scss';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/medications',
    breadcrumb: 'Лекарства',
  },
];

interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  classNames: string;
}

const colorsClasses = [
  'med-color--red',
  'med-color--orange',
  'med-color--yellow',
  'med-color--green',
  'med-color--aqua',
  'med-color--blue',
  'med-color--violet',
  'med-color--magenta',
  'med-color--light-salmon-pink',
  'med-color--peach-orange',
  'med-color--golden-sand',
  'med-color--pale-green',
  'med-color--blue-lagoon',
  'med-color--pastel-blue',
  'med-color--lilac',
  'med-color--lavender-rose',
  'med-color--crimson-red',
  'med-color--rich-gold',
  'med-color--brown-yellow',
  'med-color--irish-green',
  'med-color--ocean',
  'med-color--congress-blue',
  'med-color--indigo',
  'med-color--dark-magenta',
  'med-color--dusty-red',
  'med-color--coffee',
  'med-color--dusty-yellow',
  'med-color--dusty-green',
  'med-color--hippie-blue',
  'med-color--flat-blue',
  'med-color--deep-lilac',
  'med-color--medium-orchid',
];

export const MedicationsPage = () => {
  const {
    metric: { $store, loadList },
  } = useMetric({ metric: 'medicine' });

  const [calendarRange, setCalendarRange] = useState<{
    start: Date;
    end: Date;
  }>();

  const [selectedRange, setSelectedRange] = useState<{
    start: Date;
    end: Date;
  }>();

  const medicines = useStore($store);

  if (!medicines) {
    loadList();
  }

  const medicinesWithDates = useMemo(() => {
    return medicines?.map((item, index) => {
      let dates: Date[] = [];
      for (let i = 0; i < item.duration; i++) {
        let date = addDays(item.beginDate, i);
        dates.push(date);
      }
      return { ...item, dates };
    });
  }, [medicines]);

  const medicinesInSelectedRange = selectedRange
    ? medicinesWithDates?.filter((item) => {
        return item.dates.some((date) => {
          return (
            date.getTime() >= selectedRange.start.getTime() &&
            date.getTime() <= selectedRange.end.getTime()
          );
        });
      })
    : [];

  const medicinesInMonthRange = useMemo(
    () =>
      medicinesWithDates?.filter((item) => {
        return item.dates.some((date) => {
          let start = calendarRange ? calendarRange.start.getTime() : 0;
          let end = calendarRange ? calendarRange.end.getTime() : 0;
          return date.getTime() >= start && date.getTime() <= end;
        });
      }),
    [calendarRange, medicinesWithDates],
  );
  const calendarMedicinesInMonthRange = medicinesInMonthRange
    ?.map((medicine, index) => {
      let medicineDays: CalendarEvent[] = [];
      for (let i = 0; i < medicine.duration; i++) {
        medicineDays.push({
          id: medicine.id.toString(),
          title: medicine.name,
          start: formatDateShort(medicine.dates[i], 'yyyy-MM-dd'),
          classNames: colorsClasses[index] ?? 'med-color--red',
        });
      }
      return medicineDays;
    })
    .flat();

  const medicinesForList = selectedRange
    ? medicinesInSelectedRange
    : medicinesInMonthRange;

  const medicinesInSelectedRangeWithColors = medicinesForList?.map(
    (monthMedicine) => {
      return {
        ...monthMedicine,
        colorClassName:
          calendarMedicinesInMonthRange?.find(
            (item) => item.id === monthMedicine.id.toString(),
          )?.classNames ?? '',
      };
    },
  );

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      {medicines && (
        <div className="metric-chart">
          <div className="block metric-chart__block">
            <div className="med-calendar">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: '',
                  center: 'prev,title,next',
                  right: '',
                }}
                height="auto"
                firstDay={1}
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                select={(range) => {
                  setSelectedRange({
                    start: range.start,
                    end: addDays(range.end, -1),
                  });
                }}
                unselect={() => {
                  setSelectedRange(undefined);
                }}
                timeZone="GMT"
                dayMaxEvents={true}
                locale={'ru-RU'}
                events={calendarMedicinesInMonthRange}
                eventDisplay="list-item"
                dateClick={(day) => {
                  setSelectedRange({
                    start: day.date,
                    end: day.date,
                  });
                }}
                datesSet={(datesInfo) => {
                  setSelectedRange(undefined);
                  setCalendarRange({
                    start: datesInfo.start,
                    end: datesInfo.end,
                  });
                }}
              />
            </div>
          </div>

          {medicinesInSelectedRangeWithColors && (
            <div className="metric-chart__info">
              {medicinesInSelectedRangeWithColors.length > 0 ? (
                <div className="block -grow__1">
                  <MedicinesList data={medicinesInSelectedRangeWithColors} />
                </div>
              ) : (
                <div className="block -grow__1 metric-chart__info-empty metric-chart__info-no-measures">
                  В этот период нет назначенных лекарств
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!medicines && (
        <Plug label="Добавьте лекарства, назначенные врачем в мобильном приложении. Теледоктор24 поможет контролировать их прием." />
      )}
    </>
  );
};
