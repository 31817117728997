import { useProfileTdLk } from '@features/api';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import {
  Avatar,
  Button,
  FileInput,
  FileInputInterface,
  Modal,
  Notifications,
} from '@ui';
import classnames from 'classnames';
import 'cropperjs/dist/cropper.css';
import { useCallback, useMemo, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import './change-avatar.scss';

interface ChangeAvatarProps {
  sex?: 'М' | 'Ж' | null;
}

export const ChangeAvatar = ({ sex }: ChangeAvatarProps) => {
  const { sendEditedAvatar, profile } = useProfileTdLk();

  const [image, setImage] = useState<FileInputInterface | null>(null);
  const [isNewPhotoLoading, setIsNewPhotoLoading] = useState(false);

  const { value: isVisible, setTruthy, setFalsy } = useBoolean();

  const avatarURL = useMemo(
    () => profile?.avatar_path || profile?.avatar_thumb,
    [profile],
  );

  const handleFileInputChange = useCallback(
    (files: FileInputInterface[]) => {
      const [photo] = files;
      if (photo) {
        setImage(photo);
        setTruthy();
      }
    },
    [setTruthy],
  );

  const handleRemoveAvatar = useCallback(() => {
    sendEditedAvatar(null);
  }, [sendEditedAvatar]);

  return (
    <div className="change-avatar">
      <Avatar
        sex={sex}
        url={avatarURL || ''}
        className={classnames('change-avatar__avatar', {
          'change-avatar__avatar--loading': isNewPhotoLoading,
        })}
      />
      <div className="change-avatar__buttons">
        <FileInput
          fileType={['image']}
          multiple={false}
          maxFileSize={5}
          onChange={handleFileInputChange}
          data-cy="PROFILE_LOAD_PHOTO_INPUT">
          <Button
            type="button"
            className="button__primary button__short change-avatar__button"
            title="Загрузить фото"
            data-cy="PROFILE_LOAD_PHOTO_BTN"
          />
        </FileInput>
        <Button
          type="button"
          className="button button__crimson button__short change-avatar__button"
          title="Удалить фото"
          onClick={handleRemoveAvatar}
          data-cy="PROFILE_PHOTO_REMOVE_BTN"
        />
      </div>
      <AvatarCropperModal
        file={image}
        setLoading={setIsNewPhotoLoading}
        closeModal={setFalsy}
        isVisible={isVisible}
      />
    </div>
  );
};

interface AvatarCropperModalProps {
  file: FileInputInterface | null;
  setLoading: (param: boolean) => void;
  closeModal: () => void;
  isVisible: boolean;
}

const AvatarCropperModal = ({
  file,
  setLoading,
  closeModal,
  isVisible,
}: AvatarCropperModalProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const { sendEditedAvatar, load } = useProfileTdLk();

  const onSave = useCallback(() => {
    setLoading(true);
    closeModal();
    const cropper = cropperRef.current?.cropper;
    const b64Data = cropper?.getCroppedCanvas().toDataURL();
    if (b64Data) {
      fetch(b64Data)
        .then((res) => res.blob())
        .then((blob) => new File([blob], 'avatar'))
        .then((file) => sendEditedAvatar(file))
        .then(() => load())
        .finally(() => {
          setLoading(false);
        });
    }
  }, [closeModal, load, sendEditedAvatar, setLoading]);

  const renderFooterModal = useMemo(
    () => (
      <Button
        title="Сохранить"
        onClick={onSave}
        data-cy="PROFILE_PHOTO_SAVE_BTN"
      />
    ),
    [onSave],
  );

  return (
    <Modal
      onClose={closeModal}
      isOpen={isVisible}
      header="Выбрать миниатюру"
      contentClassNames="change-avatar__modal-content"
      root={<Notifications target="add-card" />}
      footer={renderFooterModal}>
      <div className="change-avatar__crupper-wrap">
        <Cropper
          src={file?.src}
          initialAspectRatio={1}
          style={{ height: 400, width: '100%' }}
          aspectRatio={1}
          guides={false}
          background={false}
          zoomable={false}
          responsive={false}
          ref={cropperRef}
        />
      </div>
    </Modal>
  );
};
