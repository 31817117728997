import React from 'react';
import { Text } from 'recharts';

export type AxisYTextGroupsLabels = [string, string, string];

interface AxisYTextGroupsInterface {
  labels: AxisYTextGroupsLabels;
  height: number;
  xPos: number;
  yPos: number;
}

export const AxisYTextGroups = ({
  labels,
  height,
  xPos,
  yPos,
}: AxisYTextGroupsInterface) => (
  <>
    {labels.map((item, index) => {
      const textMargin = 67;
      let textAnchor;
      let textYPos;

      switch (index) {
        case 0:
          textAnchor = 'start';
          textYPos = yPos + textMargin;
          break;
        case 1:
          textAnchor = 'middle';
          textYPos = (yPos + height) / 2;
          break;
        case 2:
          textAnchor = 'end';
          textYPos = height - textMargin;
          break;
      }
      return (
        <Text
          key={`labeltext-${index}`}
          angle={-90}
          textAnchor={textAnchor}
          x={xPos}
          y={textYPos}
          fontSize={16}
          className="axis-y-line__text">
          {item}
        </Text>
      );
    })}
  </>
);
