import React from 'react';

import lock from '@assets/img/lock.png';
import { Button, Modal, Notifications, NotificationTarget } from '@ui';
import { CreatePasswordForm, EditPasswordForm } from '../forms';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { SuccessEditAlert } from '../modals/success-edit-alert';
import styles from './styles.module.scss';

type EditPasswordType = 'edit' | 'create';

interface Props {
  type: EditPasswordType;
  triggerClassName?: string;
  login?: string;
}

interface ConfigItem {
  triggerTitle: string;
  triggerTestID: string;
  modalTitle: string;
  notificationTarget: NotificationTarget;
}

const config: Record<EditPasswordType, ConfigItem> = {
  edit: {
    triggerTitle: 'Изменить пароль',
    triggerTestID: 'EDIT_PASSWORD_BTN',
    modalTitle: 'Изменить пароль',
    notificationTarget: 'change-password',
  },
  create: {
    triggerTitle: 'Добавить пароль',
    triggerTestID: 'CREATE_PASSWORD_BTN',
    modalTitle: 'Добавить парол',
    notificationTarget: 'create-password',
  },
} as const;

export const EditPassword = (props: Props) => {
  const {
    value: isSuccessVisible,
    setTruthy: openSuccess,
    setFalsy: closeSuccess,
  } = useBoolean(false);

  return isSuccessVisible ? (
    <SuccessEditAlert onClose={closeSuccess} isOpen={isSuccessVisible} />
  ) : (
    <PasswordModal {...props} onSuccess={openSuccess} />
  );
};

const PasswordModal = (props: Props & { onSuccess: () => void }) => {
  const { onSuccess, type = 'edit', triggerClassName, login = '' } = props;

  const { notificationTarget, modalTitle, triggerTestID, triggerTitle } =
    config[type];

  const FormComponent = type === 'edit' ? EditPasswordForm : CreatePasswordForm;

  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(false);

  const onFormSentSuccess = () => {
    close();
    onSuccess();
  };

  return (
    <>
      <Button
        className={triggerClassName}
        onClick={open}
        title={triggerTitle}
        data-cy={triggerTestID}
      />
      <Modal
        onClose={close}
        isOpen={isVisible}
        imageClassNames={styles.image}
        popupClassNames={styles.modal}
        headerClassNames={styles.header}
        contentClassNames={styles.content}
        header={modalTitle}
        className="edit-form-modal"
        root={<Notifications target={notificationTarget} />}
        image={lock}>
        <FormComponent
          login={login}
          onSuccess={onFormSentSuccess}
          className={styles.form}
        />
      </Modal>
    </>
  );
};
