import React from 'react';
import { Svg } from './svg';
import { AddObjectFileInterface } from '@teledoctor/common/dist/features/audio-player/types';
import classnames from 'classnames';

export const AddFileDocument: React.FC<AddObjectFileInterface> = ({ file }) => {
  const fileClasses = classnames('file', file.modClass);
  return (
    <div className={fileClasses}>
      <Svg name={'document'} modClass="file__icon" />
      <div className="file__info">
        <div className="file__title">{file.name}</div>
        <div className="file__detail">
          {Math.ceil(file.size / 1048576) + ' МБ'}
        </div>
      </div>
    </div>
  );
};
