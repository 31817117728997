import { useLoadList } from '@eus/react-web-client/src/hooks/useLoadList';
import {
  usePageSelector,
  useTasksListLoadingSelector,
  useTasksListTotalSelector,
} from '@eus/react-client/src/support/selectors';
import { push } from '@features/app/navigation.model';
import { $asList, changeMode } from '@features/consultations/model';
import { ListModeToggle } from '@features/consultations/ui';
import { ListConsultation } from '@features/consultations/ui/list';
import { ModalAboutSupport } from '@features/support/ui/modal/modal-about/page';
import { useParamsForSearchTask } from '@features/task/ui/list/hooks';
import { PER_PAGE } from '@features/task/ui/list/list';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';
import { Button, Svg, TitleLine } from '@ui';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { useSupportTdLk } from '../../features/api';
import './styles.scss';

export const SupportPage = () => {
  const asList = useStore($asList);

  const { card: defaultCard, loading: laodingCards } = useDefaultCard();
  const { loadPerPage, page, filterLoaded } = useParamsForSearchTask('support');
  const { loadTasksList } = useSupportTdLk();
  const [isOpenAboutModal, setIsOpenAboutModal] = useState<boolean>(false);

  const { curPage, perPage } = useLoadList({
    loadList: loadTasksList,
    defaultPagination: {
      page: 1,
      perPage: PER_PAGE,
    },
  });

  const tasks = usePageSelector(curPage, perPage);

  const total = useTasksListTotalSelector();
  const loading = useTasksListLoadingSelector();

  const clickCreateAppeal = () => {
    if (!defaultCard) {
      push({ path: '/lk/cards' });
    } else {
      push({
        path: 'support/create-appeal',
      });
    }
  };

  const clickAboutBtn = () => {
    setIsOpenAboutModal(true);
  };

  return (
    <div className="support">
      <div className="consultations__header consultations-header">
        <div className="consultations-header__inner">
          <div className="consultations-header__item consultations-header__item--title">
            <h1 className="h1 titleline__title" data-cy="TITLE_H1">
              Поддержка
            </h1>
          </div>
          <div className="consultations-header__item consultations-header__item--btn">
            <Button
              type="button"
              appearance={'outline'}
              title=""
              className="questions-go-btn button__mediumslateblue titleline__button"
              onClick={clickAboutBtn}
              data-cy="SUPPORT_INFO_BTN">
              <span className="questions-go-btn__text">О разделе</span>
              <Svg name="info-with-circle" />
            </Button>
            <Button
              title="Новое обращение"
              className="consultations-get button__mediumslateblue titleline__button"
              onClick={clickCreateAppeal}
              disabled={laodingCards}
              data-cy="SUPPORT_CREATE_NEW_APPEAL_BTN"
            />
          </div>
          <ModalAboutSupport
            isOpen={isOpenAboutModal}
            setIsOpen={setIsOpenAboutModal}
          />
        </div>
      </div>
      <div className={'support__list-wrapper'}>
        <ListConsultation
          showAsList={asList}
          page={page}
          tasks={tasks}
          total={total}
          loading={loading}
          label={'Здесь будут отображаться все Ваши обращения в поддержку'}
          pathNamespace={'support'}
          hideServicesColumn={true}
          isRegularTasks={false}
        />
        <ListModeToggle
          isList={asList}
          onChange={changeMode}
          className="consultations__list-mode-toggle"
        />
      </div>
    </div>
  );
};
