import React from 'react';
import { FileInputInterface } from '../../../../ui/atoms/file-input';
import { DeleteButton } from '../../../../ui/atoms/delete-button';

import './styles.scss';

interface ChatDocumentsList {
  files: FileInputInterface[];
  onDelete?: (id: number) => void;
}

const getShortName = (filename) => {
  const maxLength = 18;
  if (filename.length <= maxLength) {
    return filename;
  }
  const fileExtension = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
  );
  const fileName = filename.substring(0, filename.lastIndexOf('.'));
  const newFilename = fileName.substr(0, maxLength) + '...' + fileExtension;
  return newFilename;
};

export const ChatDocumentsList: React.FC<ChatDocumentsList> = ({
  files,
  onDelete,
}) => {
  const deleteHandler = (id: number) => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const anotherFiles: FileInputInterface[] = [];
  const images: FileInputInterface[] = [];

  files.forEach((file: FileInputInterface) => {
    if (file.fileType !== 'image') {
      anotherFiles.push(file);
    } else {
      images.push(file);
    }
  });

  return (
    <>
      {images.length > 0 && (
        <div className="chat__documents">
          {images.map((file) => {
            let styles = {
              backgroundImage: 'url(' + file.src + ')',
            };
            return (
              <div
                key={file.id}
                className="chat__documents-item chat__documents-image"
                style={styles}>
                <DeleteButton id={file.id} deleteHandler={deleteHandler} />
              </div>
            );
          })}
        </div>
      )}
      {anotherFiles.length > 0 && (
        <div className="chat__documents">
          {anotherFiles.map((file) => {
            return (
              <div
                key={file.id}
                className="chat__documents-item chat__documents-file">
                {getShortName(file.name)}
                <DeleteButton id={file.id} deleteHandler={deleteHandler} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
