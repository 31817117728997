import React from 'react';
import './file-image-cover.scss';

export interface FileImage {
  src: string;
}

interface FileImageCover extends FileImage {
  onClick?: () => void;
}

export const FileImageCover: React.FC<FileImageCover> = ({
  src,
  onClick,
  children,
}) => {
  let styles = {
    backgroundImage: 'url(' + src + ')',
  };
  return (
    <div
      className="file-image-cover"
      style={styles}
      onClick={onClick}
      data-cy="CHAT_IMAGE_PREVIEW">
      {children}
    </div>
  );
};
