import React from 'react';
import { Svg } from '../svg';
import './explanation-gray.scss';

interface Props {
  text: string;
}

export const ExplanationGray = ({ text }: Props) => {
  return (
    <div className="explanation-gray">
      <div className="explanation-gray__info">
        <Svg name="info" modClass="explanation-gray__icon" />
      </div>
      <p className="explanation-gray__text">{text}</p>
    </div>
  );
};
