import {
  useDayMetrics,
  usePeriodMetrics,
} from '@teledoctor/common/dist/metrics/hooks';

export const useSomePeriodHook = (period, metric) => {
  switch (period) {
    case 'day':
      return useDayMetrics.bind(null, metric.byDay);
    case 'week':
      return usePeriodMetrics.bind(null, metric.byPeriod, 7);
    case 'month':
      return usePeriodMetrics.bind(null, metric.byPeriod, 31);
    default:
      return () => ({} as any);
  }
};
