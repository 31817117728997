import React, { useRef } from 'react';

import { MetricsWebInterface } from '@features/health/models/metrics/summary-modules';
import { Metrics as MetricType } from '@eus/health-default-logic/dist/modules/constants';
import { Svg, Switcher } from '@ui';

import './modules-manager-item.scss';
import classnames from 'classnames';
import { IItemProps } from 'react-movable';

interface ModulesManagerItemInterface extends IItemProps {
  module: MetricsWebInterface;
  onChangeModule: (id: MetricType) => void;
}
export const ModulesManagerItem = React.forwardRef(
  (
    { module, onChangeModule, ...props }: ModulesManagerItemInterface,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const onChangeHandler = () => {
      // @ts-ignore
      switcherRef.current.classList.add('switcher__changing');
      onChangeModule(module.id);
      // @ts-ignore
      switcherRef.current.addEventListener(
        'transitionend',
        () => {
          // @ts-ignore
          switcherRef.current.classList.remove('switcher__changing');
        },
        false,
      );
    };
    const iconClasses = classnames(
      'modules-manager__item-icon',
      `modules-manager__item-icon-${module.imageCode}`,
    );
    const switcherRef = useRef(null);
    return (
      <div className="modules-manager__item" ref={ref} {...props}>
        <div className={iconClasses} />
        <div className="modules-manager__item-titles">
          <span className="modules-manager__item-title">{module.name}</span>
          {module.subName && (
            <span className="modules-manager__item-additional">
              {` (${module.subName})`}
            </span>
          )}
        </div>
        <Switcher
          onChange={onChangeHandler}
          mobClass="modules-manager__item-switcher"
          checked={!module.hidden}
          switcherRef={switcherRef}
          textName={module.imageCode}
        />
        <div
          data-movable-handle
          className="modules-manager__item-replacer"
          data-cy={`HEALTH_MODULES_MANAGER_${module.imageCode}`}>
          <Svg
            name="hamburger"
            modClass="modules-manager__item-replacer-icon"
          />
        </div>
      </div>
    );
  },
);
