import {
  MetricsInterface,
  MetricsFormatterProps,
  getFormattedMetricsFabric,
} from '@teledoctor/common/dist/features/metrics/model/summary-modules';
import { Metrics } from '@eus/health-default-logic/dist/modules/constants';
import { Links } from '@features/health/models/metrics/links';
import { displayedMetrics } from '@features/health/models/metrics/displayed-metrics';

interface MetricsFormatterPropsWeb
  extends Omit<MetricsFormatterProps, 'modules'> {
  activeModules: Metrics[];
  hiddenModules: Metrics[];
}

export interface MetricsWebInterface extends MetricsInterface {
  imageCode?: string;
  developing?: boolean;
  hidden?: boolean;
  link?: string;
}

const imageCodeGenerate = (code: string) => code.toLowerCase();

export const getFormattedMetricsWebFabric = ({
  activeModules,
  hiddenModules,
  data,
  healthIndex,
}: MetricsFormatterPropsWeb) => {
  const modules = activeModules.concat(hiddenModules);
  const metrics: MetricsInterface[] = getFormattedMetricsFabric({
    modules,
    data,
    healthIndex,
  });
  return metrics
    .filter((item) => item)
    .map((item) => {
      const isShown = displayedMetrics.includes(item.id);
      return {
        ...item,
        hidden: hiddenModules.includes(item.id),
        imageCode: imageCodeGenerate(item.id),
        ...(Links[item.id] && isShown && { link: Links[item.id] }),
        ...(!isShown && { developing: true }),
      };
    });
};
