import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import {
  createThankOrPretensionSchema,
  errorMessageRu,
} from '@eus/react-client/src/forms/tasks/createThankOrPretension';
import { useMemo } from 'react';

export const useSchema = () => {
  const { ErrorMessage } = useErrorMessage();
  const schema = useMemo(() => {
    return createThankOrPretensionSchema({
      min: errorMessageRu.min,
      required: ErrorMessage.required,
    });
  }, [ErrorMessage]);
  return { schema };
};
