import empty from '@assets/img/plugs-consultations.png';
import emptyQuestions from '@assets/img/empty-list-questions.png';
import { Plug } from '@ui';

interface EmptyStateProps {
  label: string;
  isQuestionsList?: true;
}

export const EmptyState = ({ label, isQuestionsList }: EmptyStateProps) => {
  return (
    <Plug
      image={isQuestionsList ? emptyQuestions : empty}
      label={label}
      className={isQuestionsList ? 'plug__image_question' : ''}
    />
  );
};
