import React, { useEffect, useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  ScatterChart,
  Scatter,
  ReferenceLine,
} from 'recharts';
import {
  AxisYLine,
  AxisXTick,
  GetShapeComponent,
  ChartProps,
} from '@features/health/ui/health-detail/metric-chart';

export const ScatterGraph = React.memo((props: ChartProps) => {
  const {
    period,
    range,
    tickCount,
    showValues,
    gradientSidebar,
    labelText,
    metricData,
    shape,
    referenceBorder,
  } = props;

  return (
    <ResponsiveContainer>
      <ScatterChart
        className="chart"
        margin={{ top: 6, right: 20, bottom: 0, left: 0 }}>
        <XAxis
          dataKey="datetime"
          interval={0}
          tick={<AxisXTick showAllTicks={period === 'week'} />}
          tickLine={false}
          strokeWidth={2}
          height={36}
          tickCount={metricData.lenght}
        />
        <YAxis
          domain={range}
          width={48}
          dataKey="value"
          strokeWidth={2}
          tickSize={12}
          padding={{ bottom: 7 }}
          tickCount={tickCount}
          {...(showValues ? { tickMargin: 10 } : '')}
          {...(!showValues
            ? {
                label: (
                  <AxisYLine
                    gradientSidebar={gradientSidebar}
                    {...(labelText?.length ? { labelText: labelText } : {})}
                  />
                ),
                tickFormatter: () => '',
              }
            : '')}
        />
        <CartesianGrid strokeDasharray="4" />
        <Scatter
          name="value"
          data={metricData}
          shape={<GetShapeComponent shape={shape} />}
          isAnimationActive={false}
        />
        {referenceBorder && (
          <ReferenceLine y={referenceBorder} strokeWidth={2} />
        )}
      </ScatterChart>
    </ResponsiveContainer>
  );
});
