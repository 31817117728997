import { useCallback, useState } from 'react';
import authStyles from './../styles.module.scss';
import { AuthBack, LoginCodeForm } from '@features/user/ui';
import { AuthByPassword } from './by-password/by-password';
import formatPhone from '../helpers/phone';
import { RestorePassword } from './restore-password';
import { AuthorizationScreenTypes, LoginTypes } from './types';
import { useAuthorization } from './use-authorization';

interface Props {
  phone?: string;
  onBackHandler?: () => void;
  type?: LoginTypes;
}

export const AuthPage = (props: Props) => {
  const {
    phone: phoneBase = '',
    onBackHandler,
    type: initialType = 'password',
  } = props;

  const [type, setType] = useState<AuthorizationScreenTypes>(initialType);

  const phone = formatPhone(phoneBase);

  const { requestCodeAuthHandler, onSuccessCode } = useAuthorization({ phone });

  const getScreen = useCallback(() => {
    switch (type) {
      case 'code':
        return (
          <LoginCodeForm
            phone={phone}
            requestCodeAgain={requestCodeAuthHandler}
            onSuccessForm={onSuccessCode}
          />
        );
      case 'password':
        return <AuthByPassword phone={phone} changeScreen={setType} />;
      case 'restore':
        return <RestorePassword phone={phone} />;
    }
  }, [phone, onSuccessCode, requestCodeAuthHandler, type]);

  return (
    <>
      {(onBackHandler || type === 'restore') && (
        <AuthBack
          className={authStyles.back}
          onClick={() => {
            if (type === 'restore') {
              setType(initialType);
            } else {
              onBackHandler?.();
            }
          }}
        />
      )}
      {type !== 'restore' && <h1 className="h1">Авторизация</h1>}
      {!!phone && getScreen()}
    </>
  );
};

export type { LoginTypes } from './types';
