import React from 'react';
import './styles.scss';

interface Props {
  canBeMeasured: boolean;
  noMeasureText: string;
}

export const NoMeasures = (props: Props) => {
  return (
    <div className="block -grow__1 metric-chart__info-empty metric-chart__info-no-measures">
      {props.canBeMeasured ? props.noMeasureText : 'Нет данных для отображения'}
    </div>
  );
};
