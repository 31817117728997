import { addNotification } from '../../ui/organisms/notifications/model';
import {
  show as showLoader,
  hide as hideLoader,
} from '../../ui/organisms/global-loader';
import { $token } from '@teledoctor/common/dist/features/session';
import { createCatchWrapper } from '@teledoctor/common/dist/lib/requests';
import {
  UiParams,
  UiShowAlertError,
} from '@teledoctor/common/dist/lib/requests/types';

const alertErrorFactory: UiShowAlertError = ({ error }) => {
  if (error.code === 500) {
    return addNotification({
      id: 'server' + Date.now(),
      type: 'error',
      message:
        'В настоящий момент мы работаем над улучшением нашего сервиса, поэтому в течение некоторого времени могут возникать технические неполадки.\nПожалуйста, повторите попытку позже.',
      target: 'global',
    });
  }

  if (error.code === 401 && $token.getState()) {
    return addNotification({
      id: 'token' + Date.now(),
      type: 'error',
      message:
        'Ваша сессия истекла. Пожалуйста, авторизуйтесь снова, чтобы продолжить работу.',
      target: 'global',
    });
  }

  if (error.code === 504) {
    if (!navigator.onLine) {
      return addNotification({
        id: 'internet-connection' + Date.now(),
        type: 'error',
        message: 'Нет соединения с интернетом.',
        target: 'global',
      });
    }

    return addNotification({
      id: 'server-timeout' + Date.now(),
      type: 'error',
      message: 'Превышено время ожидания запроса от сервера',
      target: 'global',
    });
  }

  return addNotification({
    id: 'server' + Date.now(),
    type: 'error',
    message: 'Произошла ошибка при отправке запроса, повторите еще раз.',
    target: 'global',
  });
};

export const uiParamsLk: UiParams = {
  showError: alertErrorFactory,
  getHasInternet: () => navigator.onLine,
  showLoader: () => showLoader(),
  hideLoader: () => hideLoader(),
};

export const catchWrapper = createCatchWrapper(uiParamsLk);
