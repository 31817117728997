import React, { useState } from 'react';
import { Modal, ImageModal } from '../../atoms';
import './img-preview.scss';
import classnames from 'classnames';
import { useBoolean } from '@teledoctor/common/dist/hooks';

interface Props {
  src: string;
  shape: 'square' | 'original';
}

export const ImgPreview = ({ src, shape = 'original' }: Props) => {
  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(false);
  const [imageLoading, setImageLoading] = useState(true);

  const imageLoadHandler = () => {
    setImageLoading(false);
  };

  const imgWrapperClasses = classnames('img-preview__img-wrap', {
    'img-preview__img-wrap--square': shape === 'square',
    'img-preview__img-wrap--loading': imageLoading,
  });

  return (
    <>
      <div
        className="img-preview"
        onClick={open}
        data-cy="SHOW_IMAGE_IN_MODAL_BTN">
        <div className={imgWrapperClasses}>
          <img src={src} alt="Фото" onLoad={imageLoadHandler} />
        </div>
      </div>
      <Modal onClose={close} className="image-modal" isOpen={isVisible}>
        <ImageModal src={src} showButtons={false} />
      </Modal>
    </>
  );
};
