import React, { useEffect } from 'react';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { useQuery } from '@router';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import { MetricViewer, MetricWrapper } from '@features/health/ui/health-detail';
import {
  CoughDetail,
  CoughModal,
  getCoughStatusName,
  CoughErrorStatuses,
} from '@features/health/ui/metrics/cough';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/cough',
    breadcrumb: 'Кашель',
  },
];

export const CoughPage = () => {
  const query = useQuery();
  const detailId = query.get('id') || '';
  const {
    metric: { $coughCrud, loadCoughList },
  } = useMetric({ metric: 'cough' });

  const coughCrud = useStore($coughCrud);
  const list = coughCrud.list;
  const wasUpdated = Boolean(coughCrud.lastUpdated);

  useEffect(() => {
    loadCoughList({
      uiBehavior: {
        noLoader: wasUpdated,
      },
    });
  }, [loadCoughList, wasUpdated]);

  const data = list.map((item) => {
    const status = getCoughStatusName(item.status);
    const isError = CoughErrorStatuses.includes(item.status);
    return {
      id: item.id,
      name: status,
      timestamp: new Date(item.date).getTime(),
      errorStatus: isError,
    };
  });

  const CREATE_NEW_ITEM_TEXT =
    'Чтобы загрузить аудио, создайте новый запрос в личном кабинете в мобильном приложении';

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MetricWrapper bottomText={data.length ? CREATE_NEW_ITEM_TEXT : ''}>
        <MetricViewer
          data={data}
          elementId={detailId}
          emptyDataText={CREATE_NEW_ITEM_TEXT}
          chooseElementText={'Выберите файл из списка слева'}
          rightColumnRender={() => <CoughDetail />}
          modalRender={() => <CoughModal />}
        />
      </MetricWrapper>
    </>
  );
};
