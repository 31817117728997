import React from 'react';
import { ImgPreview, ExplanationGray } from '@ui';
import { useBirthmarkData } from './use-birthmark-data';
import { DataMob } from '../../health-detail/metric-viewer/';
import { CHECKUP_INTERPRETATION_DISCLAIMER } from '@teledoctor/common/dist/lib/text-constants';

export const BirthmarkModal = () => {
  const { birthmark, file, tableData } = useBirthmarkData();

  return birthmark ? (
    <>
      <DataMob data={tableData} />
      <div className="metric-viewer__modal-detail-image">
        <p className="metric-viewer__modal-detail-label">Прикрепленный файл</p>
        {file && <ImgPreview src={file.filePath} shape={'square'} />}
      </div>
      <div className="metric-viewer__modal-detail-info">
        <p className="metric-viewer__modal-detail-title">Рекомендации</p>
        <p className="metric-viewer__modal-detail-text">
          {birthmark?.description}
        </p>
      </div>
      <ExplanationGray text={CHECKUP_INTERPRETATION_DISCLAIMER} />
    </>
  ) : (
    <div className="-font__700 -text__red -text__center">Запись не найдена</div>
  );
};
