import { addNotification, Alert } from '@ui';
import React, { useCallback } from 'react';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { useCardsTdLk } from '../../../api/hooks';

interface DeleteUserProps {
  visible: boolean;
  handleCloseModal: () => void;
  cardClientId: number;
  id: number;
}

export const DeleteUserModal = ({
  visible,
  handleCloseModal,
  cardClientId,
  id,
}: DeleteUserProps) => {
  const { deleteMember } = useCardsTdLk();

  const deleteMemberHandler = useCallback(() => {
    deleteMember(
      { cardClientId: cardClientId, memberId: id },
      new FeedParams({ throwNext: true }),
    ).catch(
      formServerErrorHandler({
        commonErrorCase: {
          commonErrorCallback: (errorText) =>
            addNotification({
              id: 'delete-card-member' + Date.now(),
              type: 'error',
              message: errorText,
              target: 'global',
            }),
        },
        fieldsErrorsCase: {
          fieldsErrorsCallback: () => {},
        },
      }),
    );
  }, [deleteMember, cardClientId, id]);

  return (
    <Alert
      isOpen={visible}
      onClose={handleCloseModal}
      title="Пользователь"
      message="Вы действительно хотите удалить пользователя?"
      buttons={[
        {
          title: 'Удалить',
          onClick: deleteMemberHandler,
          'data-cy': 'CARD_USER_DELETE_CONFIRM',
        },
        {
          title: 'Отмена',
          'data-cy': 'CARD_USER_DELETE_CANCEL',
          appearance: 'outline',
        },
      ]}
    />
  );
};
