import classnames from 'classnames';
import React, { useCallback } from 'react';

import './sidebar.scss';
import { Logout, Svg } from '../atoms';

import { toggleSidebarMode } from '@features/app/ui.model';
import { FooterCopyright } from '../atoms/footer-copyright';

interface SidebarProps {
  isMobMenuVisible: boolean;
  menu: React.ReactElement;
  onClickLogout: () => void;
}

export const Sidebar = ({
  menu,
  isMobMenuVisible,
  onClickLogout,
}: SidebarProps) => {
  const sidebarClasses = classnames('sidebar', 'grid-root', {
    'is-visible': isMobMenuVisible,
  });

  const handleToggleMode = useCallback(() => {
    toggleSidebarMode();
  }, []);

  return (
    <div className={sidebarClasses}>
      <div className="sidebar__wrapper" data-scroll-lock-scrollable>
        <div className="sidebar__logo-wrapper -d__md-none">
          <Svg name="logo" modClass="sidebar__logo" />
        </div>
        <div className="sidebar__main">
          <div className="sidebar__menu">{menu}</div>
          <div className="sidebar__hide-menu">
            <button
              className="hide-menu"
              onClick={handleToggleMode}
              data-cy="MAIN_MENU_HIDE_BTN">
              <span className="hide-menu__icon" />
              <span className="hide-menu__text">Скрыть</span>
            </button>
          </div>
        </div>
        <div className="sidebar__footer">
          <div className="sidebar__logout-btn">
            <Logout onClick={onClickLogout} dataCy="header_logout_btn_mobile" />
          </div>
          <div className="footnote sidebar__footnote sidebar__footnote-full">
            <FooterCopyright />
          </div>
          <div className="footnote sidebar__footnote sidebar__footnote-short">
            &copy;&nbsp;ООО &laquo;ЕЮС&raquo;
          </div>
        </div>
      </div>
    </div>
  );
};
