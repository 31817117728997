import { useCallback, useState } from 'react';
import { object, SchemaOf, string } from 'yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './chat-messaging.scss';
import {
  Button,
  FField,
  Field,
  FieldWrapper,
  FileInput,
  FileInputInterface,
  Svg,
  Textarea,
  addNotification,
} from '@ui';
import { ChatDocumentsList } from '@features/chat/ui/chat-documents';

import {
  FormFields,
  FormFieldTypes,
} from '@teledoctor/common/dist/features/shared/forms';
import { MessageToBeSended } from '@eus/chat-default-logic/src/messages';
import { useChat } from '@eus/react-client/src/chat_v2/useChat';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers/form-server-error-handler';
import { ServerErrorsHandler } from '@eus/react-client';

interface ChatMessagingValues {
  message: string;
}

const ChatMessagingSchema: SchemaOf<ChatMessagingValues> = object().shape({
  message: string().required('Обязательно для заполнения'),
});

const fields: FormFields<ChatMessagingValues> = {
  message: {
    name: 'message',
    type: FormFieldTypes.TEXT_AREA,
    placeholder: 'Введите текст',
  },
};

interface ChatMessaging {
  taskId: string;
}

interface MessageSend {
  text?: string;
}

export const ChatMessaging = ({ taskId }: ChatMessaging) => {
  const [addedFiles, setAddedFiles] = useState<FileInputInterface[]>([]);
  const methods = useForm<ChatMessagingValues>({
    resolver: yupResolver(ChatMessagingSchema),
    mode: 'onBlur',
  });

  const { sendMessage } = useChat();

  const messageSend = ({ text = '' }: MessageSend) => {
    const textTrimmed = text.trim();

    if (textTrimmed || addedFiles.length > 0) {
      const message: MessageToBeSended = {
        text: textTrimmed,
        chatId: taskId.toString(),
        files: addedFiles,
      };
      setAddedFiles([]);
      methods.reset();
      sendMessage(message).catch(
        formServerErrorHandler({
          fieldsErrorsCase: {
            fieldsErrorsCallback: ServerErrorsHandler(methods.setError),
          },
          commonErrorCase: {
            commonErrorCallback: (errTxt) => {
              addNotification({
                type: 'error',
                id: 'send-message-in-chat' + Date.now(),
                target: 'global',
                message: errTxt,
              });
            },
          },
        }),
      );
    }
  };

  const fileInputChange = (files: FileInputInterface[]) => {
    setAddedFiles(files);
  };

  const handleSuccess = (values: ChatMessagingValues) => {
    console.log('@VALUES', values);
    messageSend({ text: values.message });
  };

  const handleErrors = (errors: FieldErrors<ChatMessagingValues>) => {
    console.log('@ERRORS', errors);
    if (addedFiles.length) {
      messageSend({});
    }
  };

  const fileDeleteHandler = useCallback(
    (id: number) => {
      setAddedFiles((prev) => prev.filter((file) => file.id !== id));
    },
    [setAddedFiles],
  );

  return (
    <div className="chat__messaging">
      <ChatDocumentsList files={addedFiles} onDelete={fileDeleteHandler} />
      <form
        action=""
        className="chat__control"
        onSubmit={methods.handleSubmit(handleSuccess, handleErrors)}>
        <div className="chat__control-text">
          <div className="form__item chat__control-textarea">
            <FField.TextArea
              name={fields.message.name}
              methods={methods}
              label=""
              placeholder="Введите текст"
              className="chat__control-textarea"
              inputClassName="form__text"
              data-cy="CHAT_MESSAGE_TEXTAREA"
            />
          </div>
          <button
            className="chat__control-clip"
            type="button"
            data-cy="CHAT_MESSAGE_FILE_BTN">
            <FileInput
              maxFileSize={20}
              multiple={true}
              onChange={fileInputChange}
              data-cy="CHAT_MESSAGE_FILE_INPUT">
              <Svg name="clip" modClass="chat__control-clip-icon" />
            </FileInput>
          </button>
        </div>
        <div className="chat__control-buttons">
          <Button
            title=""
            aria-label="Отправить"
            type="submit"
            className="chat__control-button chat__control-button-send"
            data-cy="CHAT_MESSAGE_SUBMIT_BTN">
            <Svg name="airplane" modClass="button__icon" />
          </Button>
        </div>
      </form>
    </div>
  );
};
