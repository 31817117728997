import React from 'react';
import { Svg, ImgPreview, Explanation, Plug } from '@ui';
import { DataTable } from '../../health-detail/metric-viewer/';
import { useBirthmarkData } from './use-birthmark-data';
import { CHECKUP_INTERPRETATION_DISCLAIMER } from '@teledoctor/common/dist/lib/text-constants';

export const BirthmarkDetail = () => {
  const { birthmark, file, tableData } = useBirthmarkData();

  return birthmark ? (
    <>
      <div className="block metric-viewer__detail-block">
        <DataTable data={tableData} />
      </div>
      <div className="block metric-viewer__detail-block -grow__1">
        <div className="metric-viewer__detail-title">
          <span>Рекомендации</span>
          <Explanation
            modClass="metric-viewer__detail-tooltip"
            tooltip={CHECKUP_INTERPRETATION_DISCLAIMER}
            symbol={
              <Svg name="info" modClass="metric-viewer__detail-tooltip-icon" />
            }
          />
        </div>
        <p className="metric-viewer__detail-description">
          {birthmark?.description}
        </p>
        {file && <ImgPreview src={file.filePath} shape={'square'} />}
      </div>
    </>
  ) : (
    <Plug label="Запись не найдена" />
  );
};
