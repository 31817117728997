import classnames from 'classnames';

const TABS_CLASSNAME = 'react-tabs';
const TABS_LIST_CLASSNAME = 'react-tabs__tab-list';
const TABS_TAB_CLASSNAME = 'react-tabs__tab';
const TABS_PANEL_CLASSNAME = 'react-tabs__tab-panel';

const createTabNameSpace = (
  namespaces: string[] | string,
  postfix: string,
): string => {
  return Array.isArray(namespaces)
    ? namespaces.map((namespace) => `${namespace}-${postfix}`).join(' ')
    : `${namespaces}-${postfix}`;
};

export const getTabsClassnames = (namespaces: string[] | string) => {
  return {
    tabs: classnames(TABS_CLASSNAME, createTabNameSpace(namespaces, 'tabs')),
    tabList: classnames(
      TABS_LIST_CLASSNAME,
      createTabNameSpace(namespaces, 'tabs__tab-list'),
    ),
    tab: classnames(
      TABS_TAB_CLASSNAME,
      createTabNameSpace(namespaces, 'tabs__tab'),
    ),
    panel: classnames(
      TABS_PANEL_CLASSNAME,
      createTabNameSpace(namespaces, 'tabs__tab-panel'),
    ),
  };
};
