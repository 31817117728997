import React from 'react';
import { Button, FField, FormWrapper } from '@ui';
import styles from './styles.module.scss';
import { useEmailForm } from '@teledoctor/common/dist/hooks';
import classnames from 'classnames';
import { UseFormSetError } from 'react-hook-form';

interface Props {
  onSuccess: (email: string, setError: UseFormSetError<any>) => Promise<void>;
}

export const EmailForm = (props: Props) => {
  const { onSuccess } = props;

  const { onSubmitForm, methods, isDisabledSubmit } = useEmailForm({
    onValid: ({ email }, setError) => onSuccess(email, setError),
  });

  return (
    <FormWrapper className={styles.form} onSubmit={onSubmitForm}>
      <FField.Input
        name="email"
        methods={methods}
        label="Новый E-mail"
        placeholder="Введите новый E-mail"
        type="email"
        className={classnames(styles.input, 'form__field')}
        data-cy="CHANGE_EMAIL_INPUT"
      />
      <Button title="Продолжить" disabled={isDisabledSubmit} />
    </FormWrapper>
  );
};
