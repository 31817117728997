import { useMemo, useEffect } from 'react';
import { useQuery } from '@router';
import { formatReportParam } from '@eus/health-default-logic/dist/cough/diagnosis';
import { useStore, useStoreMap } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import {
  CoughErrorStatuses,
  getCoughStatusName,
  CoughPendingStatuses,
  CoughSuccessStatuses,
  getErrorText,
  getStatusColorClass,
} from './helpers';
import { FileGroups } from '@teledoctor/common/dist/features/audio-player/types';
import classnames from 'classnames';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';

export const useCoughData = () => {
  const query = useQuery();
  const detailId = query.get('id') || '';

  const {
    metric: { loadAudioCoughLink, $coughCrud, $audioCoughLinksCrud },
  } = useMetric({ metric: 'cough' });

  const linkLoading = useStore(loadAudioCoughLink.pending);

  const cough = useStoreMap({
    store: $coughCrud,
    keys: [detailId],
    fn: (crud, [coughId]) =>
      crud.list.find((el) => el.id === Number(coughId)) || null,
  });

  const audios = useStore($audioCoughLinksCrud);
  const targetAudio = cough?.audios[0];
  const audioLink = audios.list.find(
    (el) => el.entityId === targetAudio?.id.toString(),
  );

  const audio = useMemo(() => {
    if (targetAudio && audioLink) {
      return {
        fileType: 'audio' as FileGroups,
        name: targetAudio?.name,
        size: targetAudio?.size,
        src: audioLink.filePath,
        modClass: 'cough-detail__audio',
      };
    }

    return null;
  }, [audioLink, targetAudio]);

  useEffect(() => {
    if (targetAudio && !linkLoading && !audioLink) {
      loadAudioCoughLink({
        unique: targetAudio.id.toString(),
        uiBehavior: {
          noLoader: true,
        },
      });
    }
  }, [linkLoading, audioLink, targetAudio, loadAudioCoughLink]);

  const NO_RESULT_TEXT = 'Не установлено';

  const data = useMemo(() => {
    const isError = cough?.status
      ? CoughErrorStatuses.includes(cough.status)
      : false;

    const isPending = cough?.status
      ? CoughPendingStatuses.includes(cough.status)
      : true;

    const isSuccess = cough?.status
      ? CoughSuccessStatuses.includes(cough.status)
      : false;

    const status = getCoughStatusName(cough?.status);
    const statusName = status ? status : '';

    const statusColorClass = classnames(
      'status -font__700',
      isError ? '-text__red' : getStatusColorClass(cough?.status),
    );

    let comment: { title: string; text: string } | null = null;

    if (cough?.commentary) {
      comment = { title: 'Комментарий врача', text: cough.commentary };
    } else if (isError) {
      comment = getErrorText(cough?.status);
    }

    const tableData = [
      {
        label: 'Статус заявки',
        value: statusName,
        modClass: statusColorClass,
      },
      {
        label: 'Дата загрузки',
        value: cough?.date
          ? formatTime(new Date(cough.date), 'dd.MM.yyyy HH:mm')
          : '',
      },
      {
        label: 'Диагноз заявки',
        value: cough?.diagnosis
          ? formatReportParam(cough.diagnosis)
          : NO_RESULT_TEXT,
        modClass: !cough?.diagnosis ? '-text__gray' : '',
      },
      {
        label: 'Вероятность диагноза',
        value: cough?.probability ? cough.probability + '%' : NO_RESULT_TEXT,
        modClass: !cough?.probability ? '-text__gray' : '',
      },
      {
        label: 'Продуктивность кашля',
        value: cough?.productivity
          ? formatReportParam(cough.productivity)
          : NO_RESULT_TEXT,
        modClass: !cough?.productivity ? '-text__gray' : '',
      },
      {
        label: 'Интенсивность кашля',
        value: cough?.intensity
          ? formatReportParam(cough.intensity)
          : NO_RESULT_TEXT,
        modClass: !cough?.intensity ? '-text__gray' : '',
      },
    ];

    return {
      cough,
      tableData,
      audio,
      isError,
      isPending,
      isSuccess,
      comment,
    };
  }, [cough, audio]);

  return data;
};
