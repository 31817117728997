import { AttachFile, AttachFileProps } from '@features/shared/ui';
import { FieldWrapper } from './../../../molecules';
import { Controller } from 'react-hook-form';
import { FileFetch } from '@teledoctor/common/dist/lib/request';
import { FieldProps } from './types';
import classNames from 'classnames';
import { FileInputInterface } from '../../../atoms';

export interface FieldFileProps<T>
  extends FieldProps<T>,
    Omit<AttachFileProps, 'value' | 'onChange'> {
  onFieldChange?: (value: string | null) => void;
}

export function FieldFile<T>({
  name,
  label,
  methods,
  preProcessFiles = false,
  className,
  maxFileSize,
  maxFiles,
  fileType,
}: FieldFileProps<T>) {
  const classes = classNames(
    'field--no-border form__field_input-file',
    className,
  );
  return (
    <FieldWrapper
      name={name}
      label={label}
      errors={methods.formState.errors}
      className={classes}>
      <Controller
        control={methods.control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <AttachFile
              defaultValue={value as FileInputInterface[]}
              value={value as FileInputInterface[] | FileFetch[]}
              onChange={onChange}
              preProcessFiles={preProcessFiles}
              maxFileSize={maxFileSize}
              maxFiles={maxFiles}
              fileType={fileType}
            />
          );
        }}
      />
    </FieldWrapper>
  );
}
