import React from 'react';
import './pressure-static-modal-info.scss';
import { PRESSURE_STATIC_MODAL_INFO } from '@teledoctor/common/dist/lib/text-constants';

export const PressureStaticModal = () => {
  return (
    <table className="pressure-static-info-table">
      <thead>
        <tr>
          <th>Категория</th>
          <th>Sys</th>
          <th>Dia</th>
        </tr>
      </thead>
      <tbody>
        {PRESSURE_STATIC_MODAL_INFO.map((row, trIndex) => (
          <tr key={`tr${trIndex}`}>
            {row.map((tdItem, tdIndex) => (
              <td key={`td${tdIndex}`}>{tdItem}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
