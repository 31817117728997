import { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';

import { Svg } from '../../../atoms';
import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';

interface InputPasswordProps {
  className?: string;
  wrapperClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  type?: string;
  value?: string;
}

const InputPassword = ({
  className,
  wrapperClassName,
  value = '',
  ...props
}: InputPasswordProps) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  const inputWrapperClassnames = classnames(
    'input-password',
    wrapperClassName,
    {
      'input-password--active': !passwordHidden,
    },
  );

  const inputClassnames = classnames('input-text', className);

  const handleIconClick = () => {
    setPasswordHidden(!passwordHidden);
  };

  return (
    <div className={inputWrapperClassnames}>
      <input
        {...props}
        value={value}
        className={inputClassnames}
        type={passwordHidden ? 'password' : 'text'}
      />
      <div
        className="input-password__icon-wrapper"
        onClick={handleIconClick}
        data-cy={`${
          props['data-cy'] ? props['data-cy'] + '_EYE' : 'SHOW_PASS_BTN'
        }`}>
        <Svg name="eye" modClass="input-password__icon" />
      </div>
    </div>
  );
};

interface FieldInputPasswordProps<T>
  extends FieldProps<T>,
    Omit<
      InputPasswordProps,
      'defaultValue' | 'onChange' | 'onBlur' | 'wrapperClassName'
    > {
  inputWrapperClassName?: string;
  onFieldChange?: (value: string | null) => void;
}

export function FieldInputPassword<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  inputWrapperClassName,
  errorsClassName,
  labelClassName,
  disabled,
  onFieldChange,
  ...inputPasswordProps
}: FieldInputPasswordProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputPasswordProps['data-cy']}>
            <InputPassword
              {...inputPasswordProps}
              // @ts-ignore
              value={value}
              className={inputClassName}
              wrapperClassName={inputWrapperClassName}
              onChange={(e) => {
                const value = e.target.value;

                onChange(value);
                onFieldChange?.(value);
              }}
              onBlur={onBlur}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
