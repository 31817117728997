// TODO: ON NEW_CRM REWRITE
import { UseFormReturn } from 'react-hook-form';

import { FField } from '@ui';

import { CreateFormValues } from '@teledoctor/common/dist/features/consultations/forms';
import { useServicesListLoadingSelector } from '@eus/react-client';
import { Service } from '@eus/react-client/src/cards/types';
import { useCardsTdLk } from '@features/api';
import { useEffect, useState } from 'react';

interface SelectServiceProps {
  name: string;
  cardId: number;
  methods: UseFormReturn<CreateFormValues>;
  onSelect?: (service: Service) => void;
}

export const SelectService = ({
  name,
  cardId,
  onSelect,
  methods,
}: SelectServiceProps) => {
  const [services, setServices] = useState<Service[]>([]);

  const { loadServices } = useCardsTdLk();

  useEffect(() => {
    if (!cardId) {
      return;
    }

    loadServices(cardId)
      .then((data) => setServices(data))
      .catch((error) => {
        console.error(error);
        setServices([]);
      });
  }, [loadServices, cardId]);
  const areServicesLoading = useServicesListLoadingSelector(cardId);

  const options =
    services?.map((service) => {
      return {
        label: service.name,
        value: service.id,
      };
    }) ?? [];

  const handleSelect = (id: string) => {
    const service = services?.find((service) => `${service.id}` === `${id}`);
    if (service) {
      onSelect?.(service);
    }
  };

  return !areServicesLoading && options.length === 0 ? (
    <div>По данной карте нет доступных услуг</div>
  ) : (
    <FField.Select
      name={name}
      methods={methods}
      label="Услуга"
      placeholder="Выберите услугу"
      options={options}
      className="form__field"
      dataCy="GET_CONSULT_SELECT_SERVICE"
      onFieldChange={handleSelect}
    />
  );
};
