import React, { useMemo, useEffect } from 'react';
import {
  TargetWeightValue,
  WeightValue,
} from '@eus/health-default-logic/dist/weight/types';
import { CommonValuesProps } from '@teledoctor/common/dist/metrics/type';

interface Props extends CommonValuesProps<WeightValue> {
  weightTarget: Partial<TargetWeightValue> | null;
}

const Values = React.memo(({ data, valuesListRender, weightTarget }: Props) => {
  const valuesData = useMemo(() => {
    if (!data || !weightTarget) {
      return [];
    }
    const filteredData = data.filter((item) => item.value);
    const count = filteredData.length;

    return [
      {
        value: count ? `${filteredData[count - 1].value}` : '--',
        descriptionFirstPart: 'Текущий вес, кг',
      },
      {
        value: `${weightTarget?.weight ?? '--'}`,
        descriptionFirstPart: 'Целевой вес, кг',
      },
      {
        value: count ? `${filteredData[0].value}` : '--',
        descriptionFirstPart: 'Начальный вес, кг',
      },
      {
        value: `${weightTarget?.stature ?? '--'}`,
        descriptionFirstPart: 'Рост, см',
      },
    ];
  }, [data, weightTarget]);

  if (!data || !valuesData) {
    return null;
  }

  return valuesListRender({ data: valuesData });
});

export default Values;
