import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useStore } from 'effector-react';

import './main-template.scss';
import { Notifications } from '../organisms/notifications';

import { $isSidebarShort } from '@features/app/ui.model';

interface MainTemplateProps {
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  footer?: React.ReactNode;
}

const isChatPage = (path: string) => /tasks\/\d{1,}/.test(path);

export const MainTemplate: React.FC<MainTemplateProps> = ({
  header,
  sidebar,
  children,
  footer,
}) => {
  const { pathname } = useLocation();
  const isShort = useStore($isSidebarShort);

  const pageClasses = classnames('page', 'page--main-template', {
    'chat-page': isChatPage(pathname),
  });

  return (
    <main className={pageClasses} data-sidebar-short={isShort}>
      <div className="page__wrapper">
        <div className="page__aside">{sidebar}</div>
        <div className="page__header">{header}</div>
        <div className="page__container grid__root">
          <Notifications target="global" />
          <div className="grid__container personal__container">{children}</div>
        </div>
        <div className="page__footer">{footer}</div>
      </div>
    </main>
  );
};
