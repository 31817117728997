import React from 'react';
import { FField } from '@ui';
import { EditMedCardSection } from '@features/health/ui';

export const HealthSection: React.FC<EditMedCardSection> = ({ methods }) => {
  return (
    <div className="edit-medcard__section edit-medcard__paddingX">
      <div className="edit-medcard__row grid__list">
        <div className="label label__bigger edit-medcard__item edit-medcard__label grid__item grid__item-6x grid__item-lg-12x">
          Информация о пациенте
        </div>
        <div className="label label__bigger edit-medcard__item grid__item grid__item-6x -d__lg-none">
          Нормальное значение параметров
        </div>
      </div>
      <div className="edit-medcard__row grid__list">
        <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
          <FField.Input
            name="health.weight"
            methods={methods}
            label="Вес, кг"
            placeholder="Введите вес"
            className="form__field"
            data-cy="HEALTH_USER_WEIGHT_INPUT"
          />
        </div>
        <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
          <FField.Input
            name="health.arterial_pressure"
            methods={methods}
            label="Артериальное давление"
            placeholder="Введите показатель давления"
            className="form__field"
            data-cy="HEALTH_USER_ARTERIAL_PRESSURE_INPUT"
          />
        </div>
      </div>
      <div className="edit-medcard__row grid__list">
        <div className="label label__bigger edit-medcard__item edit-medcard__label grid__item grid__item-12x -d__none -d__lg-block">
          Нормальное значение параметров
        </div>
        <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
          <FField.Input
            name="health.height"
            methods={methods}
            label="Рост, см"
            placeholder="Введите рост"
            className="form__field"
            data-cy="HEALTH_USER_HEIGHT_INPUT"
          />
        </div>
        <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
          <FField.Input
            name="health.pulse"
            methods={methods}
            label="Пульс (уд. мин)"
            placeholder="Введите пульс"
            className="form__field"
            data-cy="HEALTH_USER_PULSE_INPUT"
          />
        </div>
      </div>
    </div>
  );
};
