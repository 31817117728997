import { initApi } from '@teledoctor/common/dist/lib/api/effector/api-creator';
import {
  ErrorRequest,
  UiBehaviorParams,
  WithUiBehavior,
} from '@teledoctor/common/dist/lib/api/types/api.types';
import {
  addNotification,
  NotificationTarget,
  NotificationType,
} from '../ui/organisms/notifications/model';
import { hideLoader, showLoader } from '../ui';
import { $tokenLk } from '@features/user/model';

export interface UiNotificationParams {
  target: NotificationTarget;
  type?: NotificationType;
}

export type UiBehaviorParamsLk<A = {}, R = void> = UiBehaviorParams<
  A & {
    notifyFailParams?: UiNotificationParams;
  },
  R
>;
export type WithUiBehaviorLk<B = {}, A = {}> = WithUiBehavior<
  B,
  A & {
    notifyFailParams?: UiNotificationParams;
  }
>;

// TODO: refactor it with pub/sub model - more effect way

initApi(
  {
    showLoader,
    hideLoader,
    failHandlerDefault: (error: ErrorRequest, params: WithUiBehaviorLk) => {
      console.log('@log ~ error', error, params);
      addNotification({
        id: 'global' + Date.now(),
        type: 'error',
        message: error.reason || 'Произошла ошибка',
        target: 'global',
        ...params?.uiBehavior?.additional?.notifyFailParams,
      });
    },
  },
  {
    platformFailHandler: (err, params) => {
      if (err.status === 401 && $tokenLk.getState()) {
        // logoutWithNotification();
        return false;
      }

      return true;
    },
  },
);
