import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import formatPhone from '@eus/crm-default-logic/src/formatters/phone';

interface GetUserFields {
  phone: string;
  email?: string;
  tariff: string;
  cardNumber: string;
  date_of_birth?: string;
}

export const getUserFio = (
  last_name: string,
  first_name: string,
  middle_name?: string,
) => [last_name, first_name, middle_name].filter(Boolean).join(' ');

export const getUserFields = (props: GetUserFields) => {
  const cardInfo = props.tariff + '  (' + props.cardNumber + ')';
  return [
    {
      label: 'Номер телефона',
      value: formatPhone(props.phone),
    },
    ...(props.email ? [{ label: 'E-mail', value: props.email }] : []),
    {
      label: 'Карта',
      value: cardInfo,
    },
    ...(props.date_of_birth
      ? [
          {
            label: 'Дата рождения',
            value: props.date_of_birth
              ? formatDateShort(new Date(props.date_of_birth))
              : '',
          },
        ]
      : []),
  ];
};
