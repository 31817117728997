import { Country } from '@eus/address/src/country';
import { useClientCrm } from '@teledoctor/common/dist/hooks/use-client-crm';
import { useCallback, useState } from 'react';

export function useLoadOptionsCountry() {
  const [data, setData] = useState<Country | null>(null);
  const client = useClientCrm();

  const loadCountryData = useCallback(
    (search: string) => {
      client({
        url: '/fias/v1/country',
        method: 'get',
        params: {
          search: search,
        },
      })
        .then(({ data }) => {
          setData(data[0]);
        })
        .catch((e) => {
          console.log('Error:', e);
        });
    },
    [client],
  );

  return { data, loadCountryData };
}
