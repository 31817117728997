import './global-loader.scss';
import { Loader } from '../atoms';

import { createLoader } from '@teledoctor/common/dist/lib/effector/loader';
import { useStore } from 'effector-react';

export const { $isVisible, hide, show } = createLoader({
  name: 'web-global-loader',
});

export const GlobalLoader = () => {
  const isVisible = useStore($isVisible);

  return (
    <div className="global-loader">
      <Loader isVisible={isVisible} />
    </div>
  );
};
