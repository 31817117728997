import React from 'react';
import phones from '@assets/img/phones.png';
import styles from './styles.module.scss';
import formatPhone from '@features/user/ui/auth/helpers/phone';
import { PhoneForm } from './phone-form';
import {
  UpdateFieldValues,
  UpdateFieldViaCodeModal,
} from '../change-field-via-code';
import { useProfileTdLk } from '@features/api';
import { FeedParams } from '@teledoctor/common/dist/lib/requests/wrappers';

interface Props {
  close: () => void;
  isOpen: boolean;
}

const renderCodeDescription = (phone: string) => (
  <p>
    Отправили код подтверждения на новый номер
    <br />
    {formatPhone(phone)}
  </p>
);

const renderHeader = (isOpen: boolean, phone?: string | null) =>
  isOpen ? 'Изменить номер телефона' : phone ? 'Код подтверждения' : '';

export const ChangePhoneForm = (props: Props) => {
  const { isOpen, close } = props;

  const { requestCodeForUpdatePhone, changePhoneByCode } = useProfileTdLk();

  const updateField = (
    { value: phone, code }: UpdateFieldValues,
    feedParams: FeedParams,
  ) => changePhoneByCode({ code, phone }, feedParams);

  return (
    <UpdateFieldViaCodeModal
      onSuccessRequestCode={close}
      updateField={updateField}
      requestCodeFromUpdateField={requestCodeForUpdatePhone}
      renderCodeDescription={renderCodeDescription}
      isOpen={isOpen}
      onClose={close}
      image={phones}
      header={renderHeader}
      codeLabel="Код из СМС"
      codePlaceholder="Введите код из смс"
      headerClassNames={styles.header}
      contentClassNames={styles.content}
      imageClassNames={styles.image}
      popupClassNames={styles.modal}>
      {({ onRequestCode }) => <PhoneForm onSuccess={onRequestCode} />}
    </UpdateFieldViaCodeModal>
  );
};
