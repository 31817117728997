import React from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import { FField } from '@ui';
import { UseFormReturn } from 'react-hook-form';
import { PromoCodeFormValues } from '@teledoctor/common/dist/features/tariffs';
import { usePromoCodeControl } from '@teledoctor/common/dist/hooks';

interface Props {
  methods: UseFormReturn<PromoCodeFormValues>;
  className?: string;
}

export const PromoCode = (props: Props) => {
  const { methods, className } = props;
  const { isCollapsed, handleToggleCollapsed } = usePromoCodeControl(methods);

  return (
    <form className={classNames(styles['promocode'], className)}>
      <button
        type="button"
        className={classNames(styles['promocode__btn'], {
          [styles['opened']]: !isCollapsed,
        })}
        onClick={handleToggleCollapsed}>
        Промокод
      </button>
      <Collapse
        isOpened={!isCollapsed}
        // @ts-ignore
        initialStyle={{ width: '100%' }}>
        <FField.Input
          name="promo_code"
          methods={methods}
          placeholder="Введите промокод"
          className={`form__field ${styles['promocode__input']}`}
        />
      </Collapse>
    </form>
  );
};
