import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { canNotCancelConsultationAlert } from '@teledoctor/common/dist/helpers/consultation';

import {
  Button,
  Breadcrumbs,
  BreadcrumbsItem,
  TitleLine,
  Alert,
  showLoader,
  hideLoader,
} from '@ui';
import {
  ConsultationInfo,
  ScheduleConsultation,
  RenderBtnForDateTimePicker,
} from '@features/consultations/ui';
import { Chat } from '@features/chat/ui/chat';
import { ChatFeedback } from '@features/chat/ui/chat-feedback';
import {
  STATUS_CANCELLED,
  STATUS_END,
  STATUS_IN_PROCESS,
  STATUS_NEW,
} from '@eus/els-api-types/src/task-status-cp';
import { ChatCancelModal } from '@features/chat/ui/chat-cancel';
import { useTasksTdLk } from '@features/api';
import {
  useTaskLoadingSelector,
  useTaskSelector,
} from '@eus/react-client/src/tasks/selectors';
import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { processChatMessages as baseProcessChatMessages } from '@eus/react-client/src/utils/chat';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { useButtonsLk } from '@features/chat/hooks/use-buttons-lk';
import { useBoolean } from '@teledoctor/common/dist/hooks';

export const ConsultationPage = () => {
  const {
    value: isAlertOpen,
    setTruthy: openAlert,
    setFalsy: closeAlert,
  } = useBoolean(false);

  let { taskId } = useParams<{ taskId: string }>();

  const taskIdNumber = Number(taskId);

  const task = useTaskSelector({ entityId: taskIdNumber });
  const loading = useTaskLoadingSelector(taskIdNumber);

  const { cancelTask } = useTasksTdLk();

  const [isCancelConsultationOpen, setIsCancelConsultationOpen] =
    useState<boolean>(false);

  const [consultationCancelError, setConsultationCancelError] =
    useState<string>('');

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      path: '/lk/support/',
      breadcrumb: 'Поддержка',
    },
    {
      path: '/lk/support/:taskId',
      breadcrumb: `Запрос №${taskIdNumber}`,
    },
  ];
  const taskIsClosed =
    task?.task_status_id === STATUS_END ||
    task?.task_status_id === STATUS_CANCELLED;

  const { chatButtons, isNotButton } = useButtonsLk(
    Number(taskId),
    taskIsClosed,
  );

  const processChatMessages = useCallback(
    (messages?: MessageUI[]) => {
      const filtered = (messages || []).filter(isNotButton);
      return baseProcessChatMessages(filtered);
    },
    [isNotButton],
  );

  const taskIsComplete = task?.task_status_id === STATUS_END;

  const taskIsActive = [STATUS_NEW, STATUS_IN_PROCESS].some(
    (id) => id === task?.task_status_id,
  );

  const cancelTaskHandler = useCallback(
    (taskId: number) => {
      cancelTask(
        taskId,
        undefined,
        new FeedParams({
          needShowLoader: true,
          throwNext: true,
          ignoreError: true,
        }),
      )
        .then()
        .catch((error) => {
          const errors =
            error.response.data.error || error.response.data.errors;
          let errorMessage = '';
          setConsultationCancelError(
            !errorMessage
              ? 'Специалист уже направил Вам ответ в чате. Для отмены консультации обратитесь в клиентскую службу'
              : errorMessage,
          );
          openAlert();
        });
    },
    [cancelTask, openAlert],
  );

  const renderActionBtns = useCallback(() => {
    const renderBtnForPicker: RenderBtnForDateTimePicker = (
      onClick,
      isDisabled,
    ) => {
      return (
        <Button
          type="button"
          title="Перенести"
          className="titleline__button"
          onClick={onClick}
          data-cy="GET_CONSULT_RESCHEDULE_BTN"
          disabled={isDisabled}
        />
      );
    };
    return (
      <div>
        {taskIsActive && !!task?.can_reschedule && !!task?.medical_area_id && (
          <ScheduleConsultation
            chatId={taskId}
            renderBtn={renderBtnForPicker}
          />
        )}
        {task && taskIsActive && (
          <Button
            type="button"
            danger
            title="Отменить запрос"
            className="titleline__button"
            onClick={() => setIsCancelConsultationOpen(true)}
            data-cy="GET_CONSULT_CANCEL_BTN"
          />
        )}
      </div>
    );
  }, [taskIsActive, task, taskId]);

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [loading]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
        <div className="titleline__right">{renderActionBtns()}</div>
      </TitleLine>
      {task && (
        <ConsultationInfo
          onToggleFavorite={() => {}}
          task={task}
          taskType="support"
          renderActionBtns={renderActionBtns}
        />
      )}
      {task && (
        <Chat
          chatId={taskId}
          task={task}
          processChatMessages={processChatMessages}
          taskIsClosed={taskIsClosed}
          chatButtons={chatButtons}
        />
      )}
      {task && taskIsComplete && <ChatFeedback {...task} />}
      {task && taskIsActive && (
        <ChatCancelModal
          isOpen={isCancelConsultationOpen}
          setIsOpen={setIsCancelConsultationOpen}
          successHandler={() => cancelTaskHandler(task.id)}
          taskTypeText="запрос"
        />
      )}
      <Alert
        isOpen={isAlertOpen}
        onClose={closeAlert}
        {...canNotCancelConsultationAlert(consultationCancelError)}
      />
    </>
  );
};
