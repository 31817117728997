import './health-index-note.scss';
import { Svg } from '@ui';
import { HEALTH_INDEX_SOME_METRICS_MISSING_OR_OLD } from '@teledoctor/common/dist/lib/text-constants';

export const HealthIndexDatesNote = () => {
  return (
    <div className="block health-index-note">
      <p className="health-index-note__title">
        <span>{HEALTH_INDEX_SOME_METRICS_MISSING_OR_OLD.title}</span>
        <span className="health-index-note__warning">
          <Svg name="warning" />
        </span>
      </p>
      <p>{HEALTH_INDEX_SOME_METRICS_MISSING_OR_OLD.text}</p>
    </div>
  );
};
