import React from 'react';

export const FooterCopyright = React.memo(() => {
  const year = new Date().getFullYear();
  return (
    <>
      &copy;&nbsp;{year} "Теледоктор24".
      <br />
      Дистанционная медицинская помощь гражданам.
    </>
  );
});
