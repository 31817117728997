import React from 'react';

interface Props {
  value?: string;
  placeholder?: string;
  onClick?: () => void;
  dataCy: string;
}

export const SelectLike = ({ value, placeholder, onClick, dataCy }: Props) => {
  return (
    <div className="input-select__control" onClick={onClick} data-cy={dataCy}>
      <div className="input-select__value-container input-select__value-container--has-value">
        {value && (
          <div className="input-text input-select__single-value">{value} </div>
        )}
        {!value && placeholder && (
          <div className="input-select__placeholder">{placeholder} </div>
        )}
      </div>
      <div className="input-select__indicators">
        <div className="input-select__indicator input-select__dropdown-indicator" />
      </div>
    </div>
  );
};
