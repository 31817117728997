import React, { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/esm/format';
import { SelectLike } from '../../../ui/atoms/select-like';
import useBoolean from '@teledoctor/common/dist/hooks/use-boolean';
import {
  DatetimePicker,
  DateTimePickerProps,
  Datetime,
} from './datetime-picker';

export interface DateTimePickerInputProps
  extends Omit<
    DateTimePickerProps,
    'isPickerOpen' | 'closePicker' | 'closeAndResetPicker'
  > {}

export const DatetimePickerInput = React.memo(
  (props: DateTimePickerInputProps) => {
    const {
      onChange,
      value,
      datesResource,
      timeResource,
      baseURL,
      emptyText,
      timeHeader,
      dataCy,
    } = props;

    const [stringValue, setStringValue] = useState<string>();

    const {
      value: isDateTimePickerOpen,
      setTruthy: openDateTimePicker,
      setFalsy: closeDateTimePicker,
    } = useBoolean(false);

    const transformToString = (value: Datetime) => {
      const dateString = format(value.date, 'dd.MM.yyyy');
      return `${dateString}\xa0\xa0\xa0${value.time.name}`;
    };

    const onDateTimeSelect = useCallback(
      (dateTime: Datetime) => {
        const { date, time } = dateTime;
        if (!!date && !!time) {
          const dateTimeString = transformToString(dateTime);
          setStringValue(dateTimeString);
          onChange(dateTime);
          closeDateTimePicker();
        }
      },
      [onChange, setStringValue, closeDateTimePicker],
    );

    useEffect(() => {
      if (value) {
        const dateTimeString = transformToString(value);
        setStringValue(dateTimeString);
      }
    }, [value]);

    return (
      <>
        <SelectLike
          placeholder="Выберите дату и время"
          value={stringValue}
          onClick={openDateTimePicker}
          dataCy={dataCy}
        />
        <DatetimePicker
          value={value}
          isPickerOpen={isDateTimePickerOpen}
          closePicker={closeDateTimePicker}
          closeAndResetPicker={() => {}}
          baseURL={baseURL}
          datesResource={datesResource}
          timeResource={timeResource}
          onChange={onDateTimeSelect}
          emptyText={emptyText}
          timeHeader={timeHeader}
          dataCy={dataCy}
        />
      </>
    );
  },
);
