import { addNotification } from '@ui';

import { ChangePasswordForm } from '@eus/react-client/src/auth/forms/changePassword';
import { ServerErrorsHandler } from '@eus/react-client/src/forms/serverErrorsHandler';
import { CommonFormProps } from '@teledoctor/common/dist/features/shared/forms';
import { usePasswordChangeFormSchema } from '@teledoctor/common/dist/features/user/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { useAuthTdLk } from '@features/api';
import {
  OnSuccessForm,
  useFormWithSchema,
} from '@teledoctor/common/dist/hooks';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { UseFormSetError } from 'react-hook-form';

const feedParams = new FeedParams({
  throwNext: true,
  ignoreError: true,
  needShowLoader: true,
});

const handleError = (setError: UseFormSetError<ChangePasswordForm>) =>
  formServerErrorHandler({
    commonErrorCase: {
      commonErrorCallback: (errorText) =>
        addNotification({
          id: 'change-password' + Date.now(),
          type: 'error',
          message: errorText,
          target: 'change-password',
        }),
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler(setError),
    },
  });

interface Params
  extends Omit<CommonFormProps<ChangePasswordForm>, 'onSuccess'> {
  onSuccess: () => void;
}

export const useEditPassword = (params: Params) => {
  const { onSuccess } = params;

  const { changePassword } = useAuthTdLk();

  const onSubmit: OnSuccessForm<ChangePasswordForm> = async (
    values,
    setError,
  ) => {
    try {
      await changePassword(values, feedParams);
      onSuccess();
    } catch (error) {
      handleError(setError)(error);
    }
  };

  const { PasswordChangeFormSchema } = usePasswordChangeFormSchema();

  return useFormWithSchema<ChangePasswordForm>({
    validationSchema: PasswordChangeFormSchema,
    onValid: onSubmit,
    mode: 'onChange',
  });
};
