import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import './input.scss';

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const inputClassnames = classnames('input-text', className);

    return <input ref={ref} {...props} className={inputClassnames} />;
  },
);
