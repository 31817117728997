import React from 'react';
import './delete-button.scss';
import classnames from 'classnames';

interface DeleteButton {
  id: number | string;
  deleteHandler: (id: any) => void;
  className?: string;
  dataCy?: string;
}

export const DeleteButton: React.FC<DeleteButton> = ({
  children,
  id,
  deleteHandler,
  className,
  dataCy,
}) => {
  const classMod = classnames('delete-button', className);
  return (
    <div
      className={classMod}
      onClick={() => deleteHandler(id)}
      data-cy={dataCy || 'FILE_DELETE_BTN'}>
      {children}
    </div>
  );
};
