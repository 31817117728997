import React from 'react';
import './styles.scss';

interface Props {
  canBeMeasured: boolean;
  noMeasureText: string;
}

export const NoMeasuresPlainText = (props: Props) => {
  return (
    <div className="-grow__1 metric-chart__info-empty-plain-text">
      {props.canBeMeasured ? props.noMeasureText : 'Нет данных для отображения'}
    </div>
  );
};
