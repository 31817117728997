import { useTasks } from '@eus/react-client/src/tasks/useTasks';
import { canNotTransferConsultationAlert } from '@teledoctor/common/dist/helpers/consultation';
import { useConsultationTime } from '@features/chat/hooks/use-consultation-time';
import { DatetimePicker } from '@features/shared/ui/datetime-picker';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { Alert } from '@ui';
import { ReactElement, useCallback, useState } from 'react';

export type RenderBtnForDateTimePicker = (
  openPicker: () => void,
  isDisabled?: boolean,
) => ReactElement;

interface Props {
  chatId: string;
  renderBtn: RenderBtnForDateTimePicker;
}

export const ScheduleConsultation = ({ chatId, renderBtn }: Props) => {
  const {
    value: isDateTimePickerOpen,
    setTruthy: openDateTimePicker,
    setFalsy: closeDateTimePicker,
  } = useBoolean(false);

  const {
    value: isAlertOpen,
    setTruthy: openAlert,
    setFalsy: closeAlert,
  } = useBoolean(false);

  const {
    baseURL,
    datesResource,
    timeResource,
    onSelect,
    emptyText,
    isLoading,
  } = useConsultationTime(chatId);

  const [loadingStatus, setLoadingStatus] = useState<boolean>(isLoading);

  const { canRescheduleTask } = useTasks();

  const checkRescheduleTaskOpportunity = useCallback(() => {
    setLoadingStatus(true);
    canRescheduleTask({ taskId: Number(chatId) })
      .then(() => {
        openDateTimePicker();
      })
      .catch((e) => {
        openAlert();
      })
      .finally(() => setLoadingStatus(false));
  }, [openDateTimePicker, canRescheduleTask, chatId, openAlert]);

  return (
    <>
      {renderBtn(checkRescheduleTaskOpportunity, loadingStatus)}

      <DatetimePicker
        closePicker={closeDateTimePicker}
        closeAndResetPicker={() => {}}
        isPickerOpen={isDateTimePickerOpen}
        baseURL={baseURL}
        datesResource={datesResource}
        timeResource={timeResource}
        // @ts-ignore
        onChange={onSelect}
        emptyText={emptyText}
        dataCy="schedule_consultation_datetime_picker"
      />
      <Alert
        isOpen={isAlertOpen}
        onClose={closeAlert}
        {...canNotTransferConsultationAlert}
      />
    </>
  );
};
