import { FField, FormWrapper } from '@ui';
import { useForm } from 'react-hook-form';
import { CATEGORIES_LIST } from '@eus/health-default-logic/dist/analyze/constants';
import './analyses-filter.scss';
import { SelectOption } from '@eus/selects/src/types';

type Props = {
  isFilterActive: boolean;
  handleDateFromChange: (value: Date | Date[]) => void;
  handleDateToChange: (value: Date | Date[]) => void;
  handleCategoryChange: (selected: SelectOption[]) => void;
  handleReset: () => void;
};

export const AnalysesFilter = ({
  isFilterActive,
  handleDateFromChange,
  handleDateToChange,
  handleCategoryChange,
  handleReset,
}: Props) => {
  const methods = useForm({
    defaultValues: { dateFrom: null, dateTo: null, category: [] },
  });

  const reset = () => {
    methods.reset();
    handleReset();
  };

  const categoriesOptions = [
    ...CATEGORIES_LIST,
    { label: 'Без категории', value: null },
  ];

  return (
    <FormWrapper onSubmit={() => {}} className="analyses-filter">
      <FField.Date
        label="Дата от"
        name="dateFrom"
        methods={methods}
        placeholder="Выберите дату"
        className="analyses-filter__date"
        onFieldChange={handleDateFromChange}
      />

      <FField.Date
        label="Дата до"
        name="dateTo"
        methods={methods}
        placeholder="Выберите дату"
        className="analyses-filter__date"
        onFieldChange={handleDateToChange}
      />
      <FField.Select
        name="category"
        options={categoriesOptions}
        methods={methods}
        onFieldChange={handleCategoryChange}
        label="Раздел"
        isClearable={true}
        multiple={true}
        placeholder="Выберите раздел"
        className="analyses-filter__category"
      />
      {isFilterActive && (
        <button type="reset" className="analyses-filter__reset" onClick={reset}>
          Сбросить фильтры
        </button>
      )}
    </FormWrapper>
  );
};
