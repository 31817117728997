import React from 'react';
import { HealthIndexMetricsDatesItem } from '@teledoctor/common/dist/features/metrics/model/health-index';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import { Svg } from '@ui';
import subMinutes from 'date-fns/esm/subMinutes';
import classnames from 'classnames';

export interface Props {
  metricItem: HealthIndexMetricsDatesItem;
}

export const HealthIndexDatesItem = ({ metricItem }: Props) => {
  const prepareTime = (date) =>
    formatDateShort(subMinutes(date, -new Date().getTimezoneOffset()), 'HH:mm');
  const prepareDate = (date) =>
    formatDateShort(
      subMinutes(date, -new Date().getTimezoneOffset()),
      'dd.MM.yyyy',
    );

  const itemClasses = classnames('health-index-dates__item', {
    'health-index-dates__item--empty': metricItem.datetime === undefined,
  });
  const dateClasses = classnames('health-index-dates__date', {
    'health-index-dates__date--old': metricItem.isOld,
  });
  return (
    <li className={itemClasses}>
      <p className="health-index-dates__title">{metricItem.metricsTextName}</p>
      {metricItem.datetime ? (
        <div className="health-index-dates__content">
          <p className={dateClasses}>
            <span className="health-index-dates__date-time">
              {prepareTime(metricItem.datetime)}
            </span>
            <span className="health-index-dates__date-day">
              {prepareDate(metricItem.datetime)}
            </span>
          </p>
          {metricItem.isOld && (
            <span className="health-index-dates__warning">
              <Svg name="warning" />
            </span>
          )}
        </div>
      ) : (
        <span className="health-index-dates__warning">
          <Svg name="warning" />
        </span>
      )}
    </li>
  );
};
