import React, { useCallback } from 'react';
import classnames from 'classnames';

import './input-radio.scss';

interface InputRadioOption {
  label: string;
  value: any;
  name?: string;
}

export interface InputRadioProps {
  options: InputRadioOption[];
  initialValue: any;
  value: any;
  name?: string;
  className?: string;
  onChange: (value: any) => void;
  customRender?: (props: InpuRadioItemProps) => React.ReactElement;
}

export const InputRadio = ({
  options,
  initialValue,
  value,
  name,
  className,
  onChange,
  customRender,
  ...props
}: InputRadioProps) => {
  if (initialValue === null || initialValue === undefined) {
    throw Error('Provide initial value to InputRadio');
  }

  const currentValue = value ?? initialValue;

  const handleChange = useCallback(
    (value: any) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div className="input-radio">
      {options.map((item, index) => {
        const { value } = item;
        let isActive = value === currentValue;

        if (customRender) {
          return customRender({
            item: { ...item, name },
            checked: isActive,
            onChange: handleChange,
          });
        }

        return (
          <InputRadioItem
            key={`${index}-${value}`}
            checked={isActive}
            onChange={handleChange}
            item={item}
            data-cy={`${props['data-cy']}_${value.toUpperCase()}`}
          />
        );
      })}
    </div>
  );
};

interface InpuRadioItemProps {
  checked: boolean;
  onChange: (value: any) => void;
  item: InputRadioOption;
}

export const InputRadioItem = ({
  item,
  checked,
  onChange,
  ...props
}: InpuRadioItemProps) => {
  const { label, value, name } = item;

  const radioItemClassNames = classnames(
    'input-radio__item',
    'input-radio-item',
  );

  const handleChange = () => {
    onChange(value);
  };

  const dataCyValue = props['data-cy']
    ? `${props['data-cy']}_LABEL`
    : 'RADIO_BTN_LABEL';

  return (
    <label className={radioItemClassNames} data-cy={dataCyValue}>
      <input
        type="radio"
        name={name}
        value={value}
        className="hide-check input-radio-item__original"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      <span className="input-radio-item__check" />
      <span className="input-radio-item__label">{label || String(value)}</span>
    </label>
  );
};
