import { WeightDetailRouterProps } from '@teledoctor/common/dist/metrics/components/weight/table';
import './weight-detail.scss';
import { ValuesList } from '@features/health/ui/health-detail/metric-chart/ui/';
import { DataTable } from '@features/health/ui/health-detail/metric-viewer/ui';
import {
  BODY_MASS_INDEX_TEXT,
  BODY_MASS_REFERENCE_DATA,
} from '@teledoctor/common/dist/lib/text-constants';
import { WeightMeter } from './weight-meter';

interface Props {
  data: WeightDetailRouterProps;
}

export const WeightDetail = ({ data }: Props) => {
  const values = [
    {
      value: data.stature ? data.stature.toString() : '-',
      descriptionFirstPart: 'рост, см',
    },
    {
      value: data.weight ? data.weight.toString() : '-',
      descriptionFirstPart: 'вес, кг',
    },
  ];

  return (
    <div className="weight-detail">
      <div className="weight-detail__main">
        <div className="weight-detail__col">
          <WeightMeter bmi={data.bmi} />

          <div className="weight-detail__mydata -d__md-none">
            <ValuesList data={values} />
          </div>
        </div>
        <div className="weight-detail__col weight-detail__about -text__gray">
          {BODY_MASS_INDEX_TEXT}
        </div>
      </div>
      <div className="weight-detail__reference">
        <DataTable data={BODY_MASS_REFERENCE_DATA} />
      </div>
    </div>
  );
};
