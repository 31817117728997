import React, { useEffect, useMemo, useState } from 'react';
import { TitleLine, getTabsClassnames, Button } from '@ui';
import { useStore } from 'effector-react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { push } from '@features/app/navigation.model';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getFormattedMetricsWebFabric } from '@features/health/models/metrics/summary-modules';
import { setTestimonyModule } from '@features/health/ui/health-main/ui/testimony/model';
import { useQuery } from '@router';
import {
  TestimonyList,
  ModulesManagerModal,
  MedicalCard,
} from '@features/health/ui';
import { HealthMainPageInterface } from './types';
import './health-main.scss';
import { $localModuleManagerCodes } from '@features/health/models/summary.model';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';

import { $profileMed } from '@teledoctor/common/dist/features/user';
import { useProfileTdLk } from '../../../../features/api';
import { IS_DEV_BUNDLE } from '@teledoctor/common/dist/config/api';

export const ActiveState: React.FC<HealthMainPageInterface> = ({ title }) => {
  const tabNames = useMemo(() => {
    const tabs = ['medical-card'];
    IS_DEV_BUNDLE && tabs.unshift('testimony');
    return tabs;
  }, []);

  const { profile: user } = useProfileTdLk();
  const medCard = useStore($profileMed);

  const moduleConfig = useStore($localModuleManagerCodes);
  const {
    metric: { $store: $summaryStore, load: loadSummary },
  } = useMetric({
    metric: 'summary',
  });

  const {
    metric: { $store: $healthIndexStore },
  } = useMetric({
    metric: 'healthIndex',
  });

  const summaryData = useStore($summaryStore);
  const healthIndex = useStore($healthIndexStore);
  const testimonyItems = useMemo(
    () =>
      getFormattedMetricsWebFabric({
        activeModules: moduleConfig.active,
        hiddenModules: moduleConfig.additional,
        data: summaryData,
        healthIndex,
      }),
    [moduleConfig, summaryData, healthIndex],
  );

  useEffect(() => {
    IS_DEV_BUNDLE && loadSummary();
  }, [loadSummary]);

  useEffect(() => {
    setTestimonyModule(testimonyItems);
  }, [testimonyItems]);

  const [modulesManagerModalStatus, setModulesManagerModalStatus] =
    useState<boolean>(false);

  const closeModulesManagerModalHandler = () =>
    setModulesManagerModalStatus(false);
  const openModulesManagerModalHandler = () =>
    setModulesManagerModalStatus(true);

  const query = useQuery();
  const { path } = useRouteMatch();

  const tabsClasses = getTabsClassnames(['page--main-template', 'health-main']);
  const tabType = query.get('type') || '';

  const selectedTab = useMemo(() => {
    const index = tabNames.indexOf(tabType);

    return index > -1 ? index : 0;
  }, [tabType, tabNames]);

  const handleTabChange = (index: number): void => {
    push({
      path: `${path}?type=${tabNames[index]}`,
    });
  };

  const redirectTab = IS_DEV_BUNDLE ? 'testimony' : 'medical-card';

  if (!tabType) {
    return <Redirect to={`${path}?type=${redirectTab}`} />;
  }

  return (
    <>
      <TitleLine titleText={title} className="health-main__titleline">
        {tabType === 'testimony' && IS_DEV_BUNDLE && (
          <Button
            className="button__primary titleline__button -d__xs-none"
            title="Менеджер модулей"
            onClick={openModulesManagerModalHandler}
            data-cy="HEALTH_MODULES_MANAGER_BTN"
          />
        )}
      </TitleLine>
      <Tabs
        className={tabsClasses.tabs}
        selectedIndex={selectedTab}
        onSelect={handleTabChange}>
        <TabList className={tabsClasses.tabList}>
          {IS_DEV_BUNDLE && (
            <Tab className={tabsClasses.tab} data-cy="HEALTH_TAB_METRICS">
              Показания
            </Tab>
          )}
          <Tab className={tabsClasses.tab} data-cy="HEALTH_TAB_MEDCARD">
            Мед. карта
          </Tab>
        </TabList>
        {IS_DEV_BUNDLE && (
          <TabPanel className={tabsClasses.panel}>
            <TestimonyList editorHandler={openModulesManagerModalHandler} />
          </TabPanel>
        )}
        <TabPanel className={tabsClasses.panel}>
          {user && medCard && <MedicalCard user={user} medCard={medCard} />}
        </TabPanel>
      </Tabs>
      <ModulesManagerModal
        isOpen={modulesManagerModalStatus}
        onClose={closeModulesManagerModalHandler}
      />
    </>
  );
};
