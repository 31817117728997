import React, { FC } from 'react';
import classnames from 'classnames';
import './styles.scss';
import { Modal, Svg, ModalProps } from '@ui';
import { useBoolean } from '@teledoctor/common/dist/hooks';

interface Props {
  modalProps: Omit<ModalProps, 'onClose' | 'isOpen'>;
}

export const MetricInfo: FC<Props> = ({ children, modalProps }) => {
  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(false);

  return (
    <div className="metric-info">
      <button onClick={open} className={classnames('metric-info__button')}>
        <Svg name="info" modClass="metric-info__icon" />
      </button>

      <Modal onClose={close} isOpen={isVisible} {...modalProps}>
        {children}
      </Modal>
    </div>
  );
};
