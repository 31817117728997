import { canAddUsers } from '@eus/react-client/src/cards/helpers';
import React, { useMemo, useState } from 'react';
import { Card } from '@eus/react-client/src/cards/types';
import { useCardMembersSelector } from '@eus/react-client';
import { CardInfoUserItem } from './info';
import { NavLink } from 'react-router-dom';
import style from './card-users.module.scss';
import classNames from 'classnames';
import { Modal, Svg } from '@ui';
import { CardUser } from './user';

interface Props {
  card: Card;
}

export const CardUsers = ({ card }: Props) => {
  const [isControlOpen, setControlStatus] = useState<boolean>(false);

  const showUsersBlock = useMemo(
    () => canAddUsers(card) || card.count_members > 0,
    [card],
  );

  const members = useCardMembersSelector({ cardClientId: card.card_client_id });

  const usersList = useMemo(() => {
    if (card.max_members > 0) {
      const maxUsersLength = 3;
      let usersList: React.ReactNode[] = [];

      for (let i = 0; i < card.max_members; i++) {
        if (members?.[i] && usersList.length < maxUsersLength - 1) {
          const avatarPath =
            (members[i].avatar_file_id &&
              (members[i].avatar_thumb || members[i].avatar_path)) ||
            '';

          usersList.push(
            <CardInfoUserItem
              tariff={card.tariff_name}
              card_number={card.number}
              key={members[i].id}
              cardClientId={card.card_client_id}
              member={members[i]}
              currentAvatar={avatarPath}
            />,
          );
        } else {
          usersList.push(
            <div className={style['empty-user']} key={`empty-user-${i}`}>
              {usersList.length === maxUsersLength - 1 &&
              card?.count_members > maxUsersLength - 1
                ? `+${card.count_members - usersList.length}`
                : ''}
            </div>,
          );
        }

        if (usersList.length === maxUsersLength) {
          break;
        }
      }

      return usersList;
    }
    return [];
  }, [
    card.max_members,
    members,
    card.card_client_id,
    card.number,
    card.tariff_name,
    card.count_members,
  ]);

  return showUsersBlock ? (
    <div className={style.list}>
      <div className={classNames('label label__bigger', style.count)}>
        Пользователи {card.count_members}/{card.max_members}
      </div>
      {card.max_members > 0 && (
        <div className={style['users__wrapper']}>
          <div className={style.users}>{usersList}</div>
          <div className={style.manage} onClick={() => setControlStatus(true)}>
            <Svg name="gear" modClass={style['manage__icon']} />
            <span className={style['manage__text']}>Управлять</span>
          </div>
        </div>
      )}
      <Modal
        isOpen={isControlOpen}
        onClose={() => setControlStatus(false)}
        popupClassNames={style.control}
        header="Пользователи">
        <div className={style.content}>
          {!!members?.length && (
            <div className={style.members}>
              {members.map((member) => (
                <CardUser
                  member={member}
                  cardClientId={card.card_client_id}
                  key={member.id}
                  cardNumber={card.number}
                  tariff={card.tariff_name}
                />
              ))}
            </div>
          )}
          {canAddUsers(card) && (
            <NavLink
              className={style.button}
              to={`cards/${card.card_client_id}/add-user`}
              data-cy="CARD_INFO_ADD_USER_BTN">
              <Svg name="plus" modClass={style.icon} />
              Добавить пользователя
            </NavLink>
          )}
        </div>
      </Modal>
    </div>
  ) : null;
};
