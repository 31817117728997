import React from 'react';
import { FField, DeleteButton, Button } from '@ui';
import { familyRelForSelect } from '@teledoctor/common/dist/features/user/medcard';
import { useFieldArray } from 'react-hook-form';
import { EditMedCardSection } from '@features/health/ui';
import './style.scss';

export const ContactsSection: React.FC<EditMedCardSection> = ({ methods }) => {
  const {
    fields: contactsList,
    append: contactsListAdd,
    remove: contactsListRemove,
  } = useFieldArray({
    control: methods.control,
    name: 'contacts',
  });

  const addContactHandler = () => {
    contactsListAdd({
      fio: undefined,
      phone: undefined,
      family_rel: undefined,
    });
  };

  const deleteContactHandler = (index) => {
    contactsListRemove(index);
  };
  return (
    <div className="edit-medcard__section edit-medcard__paddingX">
      <div className="label label__bigger edit-medcard__label">
        Контакты на случай ЧП
      </div>
      <div className="edit-medcard__contacts">
        {!!contactsList?.length && (
          <>
            {contactsList.map((contact, index) => (
              <div className="edit-medcard__contacts-item" key={contact.id}>
                <div className="edit-medcard__row grid__list">
                  <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
                    <FField.Select
                      options={familyRelForSelect}
                      name={`contacts.${index}.family_rel` as const}
                      methods={methods}
                      label="Степень родства"
                      placeholder="Выберите из списка"
                      className="form__field"
                      dataCy="HEALTH_USER_FAMILY_REL_SELECT"
                    />
                  </div>
                  <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
                    <FField.Input
                      name={`contacts.${index}.fio` as const}
                      methods={methods}
                      label="Форма обращения"
                      placeholder="Введите имя"
                      className="form__field"
                      data-cy="HEALTH_USER_FAMILY_FIO_INPUT"
                    />
                  </div>
                </div>
                <div className="edit-medcard__row grid__list">
                  <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
                    <FField.Phone
                      name={`contacts.${index}.phone` as const}
                      methods={methods}
                      label="Номер телефона"
                      placeholder=""
                      className="form__field"
                      data-cy="HEALTH_USER_FAMILY_PHONE_INPUT"
                    />
                  </div>
                  <div className="edit-medcard__item -d__flex -align__center grid__item grid__item-6x grid__item-lg-12x">
                    <DeleteButton
                      id={`${contact.id}`}
                      deleteHandler={() => deleteContactHandler(index)}
                      className="edit-medcard__delete-icon"
                      dataCy="HEALTH_USER_FAMILY_DELETE_CONTACT_BTN">
                      <div className="edit-medcard__delete-text">
                        Удалить контакт
                      </div>
                    </DeleteButton>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="edit-medcard__row grid__list">
        <div className="edit-medcard__item grid__item grid__item-6x grid__item-lg-12x">
          <Button
            title="Добавить контакт"
            short={true}
            onClick={addContactHandler}
            type="button"
            data-cy="HEALTH_USER_FAMILY_ADD_CONTACT_BTN"
          />
        </div>
      </div>
    </div>
  );
};
