import React from 'react';

import { Button, FField, FormWrapper } from '@ui';
import styles from './form.module.scss';

import { useCreatePassword } from './use-create-password';
import classnames from 'classnames';

interface PropsChangePasswordForm {
  onSuccess: () => void;
  className?: string;
  login: string;
}

export const CreatePasswordForm = (props: PropsChangePasswordForm) => {
  const { onSuccess, className, login } = props;
  const { onSubmitForm, methods, isDisabledSubmit } = useCreatePassword({
    onSuccess,
    login,
  });

  return (
    <FormWrapper onSubmit={onSubmitForm} className={className}>
      <div className={styles.form}>
        <FField.InputPassword
          name="new_password"
          methods={methods}
          label="Новый пароль"
          placeholder="Введите новый пароль"
          className="form__field"
          data-cy="CREATE_PASSWORD_NEW_PASSWORD_INPUT"
        />
        <FField.InputPassword
          name="confirm_new_password"
          methods={methods}
          label="Повторите новый пароль"
          placeholder="Введите новый пароль еще раз"
          className="form__field"
          data-cy="CREATE_PASSWORD_NEW_PASSWORD_INPUT_CONFIRM"
        />
      </div>
      <div className={classnames('form__row', styles.button)}>
        <Button
          title="Продолжить"
          type="submit"
          className="-width__xs-100"
          disabled={isDisabledSubmit}
          data-cy="CREATE_PASSWORD_SUBMIT_BTN"
        />
      </div>
    </FormWrapper>
  );
};
