import { FormWrapper, Button } from '@ui';
import { HealthPersonalInfo } from '@eus/health-default-logic/src/medcard/types';
import { FieldErrors, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  updateProfileMedFx,
  $clientId,
  updatedProfileMed,
} from '@teledoctor/common/dist/features/user';

import './style.scss';
import { Contact } from '@eus/health-default-logic/dist/medcard/types';
import { replace } from '@features/app/navigation.model';
import { HealthSection } from './health/health-section';
import { ContactsSection } from './contacts/contacts-section';
import { AnamnesisSection } from './anamnesis/anamnesis-section';
import { useSchema } from './schema';

export interface EditMedCardSection {
  methods: UseFormReturn<HealthPersonalInfo>;
}

export const MedicalCardEditForm = ({
  health,
  contacts,
  anamnesis,
}: HealthPersonalInfo) => {
  const { schema } = useSchema();
  const methods = useForm<HealthPersonalInfo>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: { health, contacts, anamnesis },
  });

  const handleSuccess = (values: HealthPersonalInfo) => {
    const clientId = $clientId.getState();
    let contactsArray: Array<Contact> = [];
    if (values.contacts) {
      contactsArray = values.contacts.reduce((newArray, item, index) => {
        if (item.phone || item.fio || item.family_rel) {
          const contact: Contact = {
            ...item,
            id: index,
          };
          newArray.push(contact);
        }
        return newArray;
      }, [] as Contact[]);
    }
    updateProfileMedFx({
      payload: {
        clientId: `${clientId!}`,
        health: { ...values.health },
        ...(contactsArray && { contacts: contactsArray }),
        anamnesis: { ...values.anamnesis },
      },
    });
    updatedProfileMed.watch(() => {
      replace('/lk/health?type=medical-card');
    });
  };

  const handleErrors = (errors: FieldErrors<HealthPersonalInfo>) => {
    console.log('@ERRORS', errors);
  };

  return (
    <section className="block edit-medcard">
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleErrors)}>
        <div className="edit-medcard__sections">
          <HealthSection methods={methods} />
          <ContactsSection methods={methods} />
          <AnamnesisSection methods={methods} />
        </div>
        <div className="edit-medcard__paddingX">
          <Button
            className="-width__xs-100"
            title="Сохранить"
            data-cy="HEALTH_USER_SAVE_BTN"
          />
        </div>
      </FormWrapper>
    </section>
  );
};
