import React from 'react';
import { Alert, Svg, AlertButtonsProps } from '@ui';
import { push } from '@features/app/navigation.model';
import styles from './styles.module.scss';
import classnames from 'classnames';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const buttons: AlertButtonsProps['buttons'] = [
  {
    title: 'Продолжить редактирование',
    onClick: () => push({ path: '/lk/profile/edit' }),
    appearance: 'outline',
    short: true,
    buttonWrapperClassName: styles['alert-button-wrapper'],
    className: styles['alert-button'],
    'data-cy': 'CREATE_PASSWORD_ALERT_CONFIRM_BTN',
  },
  {
    title: 'Вернуться на главную',
    onClick: () => push({ path: '/lk/tasks' }),
    short: true,
    buttonWrapperClassName: styles['alert-button-wrapper'],
    className: styles['alert-button'],
    'data-cy': 'CREATE_PASSWORD_ALERT_CONFIRM_BTN',
  },
];

export const SuccessEditAlert = (props: Props) => {
  const { onClose, isOpen } = props;

  return (
    <Alert
      popupClassNames={styles.modal}
      headerClassNames={styles.header}
      contentClassNames={styles.content}
      footerClassNames={styles.footer}
      buttonsClassNames={styles.buttons}
      title={
        <div>
          <Svg name="check-round" modClass={classnames(styles['check-icon'])} />
          <h3 className={styles.title}>Изменения успешно сохранены!</h3>
        </div>
      }
      onClose={onClose}
      isOpen={isOpen}
      message="Ваши данные были обновлены. Спасибо, что держите нас&nbsp;в&nbsp;курсе самой актуальной информации."
      buttons={buttons}
    />
  );
};
