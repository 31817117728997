import { createEvent, createStore } from 'effector';

import { withPersist } from '@lib/with-persist';

/**
 * Stores
 */

export const $isSidebarShort = createStore(false, {
  name: 'app/ui:is-sidebar-short',
});
withPersist($isSidebarShort);

/**
 * Events
 */

export const toggleSidebarMode = createEvent('app/ui:toggle-sidebar-mode');

/**
 * Connections
 */

$isSidebarShort.on(toggleSidebarMode, (state) => !state);
