import React from 'react';
import './data-table.scss';

interface Props {
  data: DataTableItemProps[];
}

export type DataTableItemProps = {
  label: string;
  value?: string | null;
  modClass?: string;
};

export const DataTable = ({ data }: Props) => {
  return (
    <table className="data-table">
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td className="data-table__label">{item.label}</td>
            <td className={`data-table__value ${item.modClass || ''}`}>
              {item.value || ''}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
