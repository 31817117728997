import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface FieldErrorProps<FormValues> {
  className?: string;
  name: string;
  errors: FieldErrors<FormValues>;
  dataCy?: string;
}

export const FieldError = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  name,
  className,
  errors,
  dataCy,
}: FieldErrorProps<TFormValues>) => {
  return (
    <ErrorMessage
      errors={errors}
      rs={errors}
      // TODO: fix typeings
      // @ts-ignore get right typings
      name={name}
      render={({ message }) => (
        <div
          data-cy={dataCy ? `${dataCy}_ERROR` : 'ERROR_MESSAGE'}
          className={className}>
          {message}
        </div>
      )}
    />
  );
};

export function checkErrorForField(name: string, errors: FieldErrors) {
  return name.includes('.')
    ? Boolean(
        Object.keys(name.split('.').reduce((o, i) => o[i] || {}, errors))
          .length,
      )
    : Boolean(errors[name]);
}
