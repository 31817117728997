import { useQuery } from '@router';
import { useHistory } from 'react-router-dom';
import { PER_PAGE } from './list';

export function useParamsForSearchTask(parentUrl: string) {
  const query = useQuery();
  const history = useHistory();

  const loadPerPage = Number(query.get('perPage')) || PER_PAGE;
  const loadOrder = query.get('order') || 'DESC';
  const loadSort = query.get('sort') || 'id';
  const page = Number(query.get('page')) || 1;
  const filterLoaded = query.get('filter')
    ? JSON.parse(decodeURI(query.get('filter') || '')!)
    : {};

  const searchHandle = (query: string) => {
    history.push(
      `${parentUrl}?filter=` +
        JSON.stringify({ ...filterLoaded, search: query }) +
        `&order=${loadOrder}&sort=${loadSort}&page=${page}&perPage=${loadPerPage}`,
    );
  };

  return {
    loadPerPage,
    loadOrder,
    loadSort,
    page,
    filterLoaded,
    searchHandle,
  };
}
