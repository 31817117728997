import React, { useCallback } from 'react';
import { addNotification, Button, FField } from '@ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTasksTdLk } from '@features/api/hooks';
import { CreateThankOrPretensionData } from '@eus/react-client/src/forms/tasks/createThankOrPretension';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { ServerErrorsHandler } from '@eus/react-client';
import { useSchema } from './schema';

interface Props {
  taskId: number;
  onSuccess: () => void;
}

export const ChatClaimForm = ({ taskId, onSuccess }: Props) => {
  const { schema } = useSchema();
  const methods = useForm<CreateThankOrPretensionData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const { setError } = methods;

  const { createPretension, loadTask } = useTasksTdLk();

  const sendForm = useCallback(
    (values: CreateThankOrPretensionData) => {
      createPretension(
        {
          taskId: taskId,
          data: values,
        },
        new FeedParams({
          throwNext: true,
          ignoreError: true,
          needShowLoader: true,
        }),
      )
        .then(() => {
          onSuccess();
          addNotification({
            target: 'global',
            id: 'create claim in taskId ' + taskId + ' ' + Date.now(),
            type: 'done',
            message: 'Претензия отправлена',
          });
          loadTask(taskId);
        })
        .catch(
          formServerErrorHandler({
            commonErrorCase: {
              commonErrorCallback: (errText) => {
                addNotification({
                  target: 'chat-claim-modal',
                  id: 'create thanks in taskId ' + taskId + ' ' + Date.now(),
                  type: 'error',
                  message: errText,
                });
              },
            },
            fieldsErrorsCase: {
              fieldsErrorsCallback: ServerErrorsHandler(setError),
            },
          }),
        );
    },
    [createPretension, taskId, setError, onSuccess, loadTask],
  );
  return (
    <form
      action=""
      onSubmit={methods.handleSubmit(sendForm)}
      data-cy="CONSULT_CLAIM_FORM">
      <div className="form__row -mb__xmedium -mb__xs-small">
        <div className="form__item">
          <FField.TextArea
            className="form__field"
            name="description"
            methods={methods}
            data-cy="CONSULT_CLAIM_FORM_TEXTAREA"
          />
        </div>
      </div>
      <div className="form__row">
        <Button
          appearance="solid"
          title="Отправить"
          type="submit"
          data-cy="CONSULT_CLAIM_FORM_SUBMIT_BTN"
        />
      </div>
    </form>
  );
};
