import useBoolState from '@eus/react-client/src/hooks/useBoolState';
import { Button, Modal, Svg } from '@ui';
import styles from './style.module.scss';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  PAYMENT_STATUS_PARAM,
  PAYMENT_STATUSES,
  PaymentStatuses,
} from '@teledoctor/common/dist/features/tariffs';

interface ModalInfoProps {
  title: string;
  description?: string;
  info: ReactNode;
  link: {
    href: string;
    title: string;
  };
}

export const PaymentStatusModal = () => {
  const [isModalOpen, openModal, closeModal] = useBoolState(false);
  const [type, setType] = useState<PaymentStatuses>();

  const { push } = useHistory();

  const isTypeCorrect = useMemo(() => {
    return (
      type === PAYMENT_STATUSES.SUCCESS || type === PAYMENT_STATUSES.FAILURE
    );
  }, [type]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(PAYMENT_STATUS_PARAM)) {
      const paymentStatus = searchParams.get(PAYMENT_STATUS_PARAM);
      if (
        paymentStatus === PAYMENT_STATUSES.SUCCESS ||
        paymentStatus === PAYMENT_STATUSES.FAILURE
      ) {
        setType(paymentStatus);
        openModal();
      }
    }
  }, [openModal]);

  const modalInfo: ModalInfoProps | undefined = useMemo(() => {
    switch (type) {
      case PAYMENT_STATUSES.SUCCESS:
        return {
          title: 'Оплата прошла успешно',
          description: 'Спасибо, что выбираете Теледоктор24!',
          info: 'Карта отобразится в личном кабинете в течение 5 минут.',
          link: {
            href: 'tasks',
            title: 'На главную',
          },
        };
      case PAYMENT_STATUSES.FAILURE:
        return {
          title: 'Платеж не прошел',
          info: (
            <>
              Возможно, это был временный сбой.
              <br />
              Попробуйте снова
            </>
          ),
          link: {
            href: 'tariffs',
            title: 'Оплатить',
          },
        };
    }
  }, [type]);

  const closeModalHandler = useCallback(() => {
    closeModal();
    push({ search: '' });
  }, [closeModal, push]);

  return isTypeCorrect ? (
    <Modal
      closeIcon={false}
      contentClassNames={styles.content}
      isOpen={isModalOpen}
      onClose={closeModalHandler}>
      <div className={styles.icon}>
        <Svg name={`payment-${type}`} modClass={styles[`icon-${type}`]} />
      </div>
      <h1 className={styles.title}>{modalInfo?.title}</h1>
      {!!modalInfo?.description && (
        <div className={styles.description}>{modalInfo.description}</div>
      )}
      <div className={styles.info}>{modalInfo?.info}</div>
      <Button
        className={styles.button}
        fullWidth
        title={modalInfo?.link.title}
        onClick={() => {
          closeModal();
          push({ pathname: `/lk/${modalInfo?.link.href}` });
        }}
      />
    </Modal>
  ) : null;
};
