import classnames from 'classnames';

import './plug.scss';

interface PlugProps {
  className?: string;
  image?: string;
  label: string;
  altText?: string;
}

export const Plug = ({ image, label, altText, className }: PlugProps) => {
  return (
    <div className={classnames('plug', className)}>
      {image && (
        <div className="image plug__image">
          <img src={image} alt={altText} />
        </div>
      )}
      <div className="plug__text">{label}</div>
    </div>
  );
};
