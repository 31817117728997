import React from 'react';
import { Metrics } from '@teledoctor/common/dist/metrics/type';
import './value-block.scss';

export const ValueBlock = React.memo(
  ({ value, descriptionFirstPart, descriptionSecondPart }: Metrics) => {
    return (
      <div className="block metric-chart__info-value-item">
        <p className="metric-chart__info-value">{value}</p>
        <p className="metric-chart__info-value-description">
          {descriptionFirstPart} <br />
          {descriptionSecondPart}
        </p>
      </div>
    );
  },
);
