import { useAxiosInstance, useDateTime } from '@eus/react-client/src/hooks';
import { Time } from '@eus/react-client/src/types/datetime';
import { ERROR_TEXTS } from '@teledoctor/common/dist/lib/text-constants';
import { addNotification } from '@ui';
import classNames from 'classnames';
import format from 'date-fns/esm/format';
import addDays from 'date-fns/esm/addDays';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar, {
  CalendarProps,
  CalendarTileProperties,
} from 'react-calendar';
import { Button, Modal, Notifications } from '../../../ui/';
import './datetime-picker.scss';
import { startOfDay } from 'date-fns';

export type Datetime = {
  date: Date;
  time: Time;
};

export type DateTimePickerProps = Pick<CalendarProps, 'minDate' | 'maxDate'> & {
  isPickerOpen: boolean;
  closePicker: () => void;
  closeAndResetPicker: () => void;
  baseURL: string;
  onChange: (value: Datetime) => void;
  value?: Datetime;
  datesResource?: string;
  timeResource?: string;
  minDate?: Date;
  maxDate?: Date;
  emptyText?: string;
  timeHeader?: string;
  dataCy: string;
};

const formatDateToDateString = (date: Date) => format(date, 'yyyy-MM-dd');

export const DatetimePicker = React.memo((props: DateTimePickerProps) => {
  const {
    isPickerOpen,
    closePicker,
    onChange,
    value,
    datesResource,
    timeResource,
    baseURL,
    emptyText = 'Нет свободного времени',
    timeHeader = 'Доступные интервалы',
    dataCy,
  } = props;

  const axiosInstance = useAxiosInstance({ baseURL });

  const { state, minDate, maxDate, disabledDates, loadDates, loadTime } =
    useDateTime({
      client: axiosInstance,
    });

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    value?.date,
  );
  const [selectedTime, setSelectedTime] = useState<Time | undefined>(
    value?.time,
  );

  useEffect(() => {
    if (value) {
      setSelectedDate(value.date);
      setSelectedTime(value.time);
    }
  }, [value]);

  const reset = () => {
    setSelectedDate(undefined);
    setSelectedTime(undefined);
  };

  const closeAndReset = () => {
    closePicker();
    if (!value) {
      reset();
    }
  };

  useEffect(() => {
    if (!datesResource) return;
    loadDates({ resource: datesResource }).catch((err) => {
      console.log('loadDates err', err);
      addNotification({
        target: 'datetime-picker',
        type: 'error',
        message: ERROR_TEXTS.LOAD_TASKS_DATES,
        id: 'load-dates-for-task-' + Date.now(),
      });
    });
  }, [loadDates, datesResource]);

  useEffect(() => {
    if (!timeResource || !selectedDate) return;
    loadTime({ resource: timeResource, date: selectedDate }).catch((err) => {
      console.log('loadTime err', err);
      addNotification({
        target: 'datetime-picker',
        type: 'error',
        message: ERROR_TEXTS.LOAD_TASKS_TIMES,
        id: 'load-times-for-task-' + Date.now(),
      });
    });
  }, [selectedDate, loadTime, timeResource]);

  const selectDate = useCallback(
    (calendarDate: Date) => {
      const dayWithoutTime = startOfDay(calendarDate);
      setSelectedDate(dayWithoutTime);
      setSelectedTime(undefined);
    },
    [setSelectedDate],
  );

  const selectTime = useCallback(
    (time: Time) => {
      setSelectedTime(time);
    },
    [setSelectedTime],
  );

  const saveHandler = useCallback(() => {
    if (!!selectedDate && !!selectedTime) {
      onChange({
        date: selectedDate,
        time: selectedTime,
      });
      closePicker();
    }
  }, [selectedDate, selectedTime, onChange, closePicker]);

  const isEmpty = useMemo(
    () =>
      !state.time?.entities ||
      !state.time?.entities?.length ||
      !state.time.entities.some(
        (time) => time.enabled === undefined || time.enabled,
      ),
    [state.time],
  );

  const renderTime = useCallback(() => {
    if (selectedDate && state.time.loading) {
      return <p className="datetime-picker__info">Подождите...</p>;
    }
    if (isEmpty) {
      return <p className="datetime-picker__info">{emptyText}</p>;
    }
    return (
      <div className="datetime-picker__times">
        {state.time.entities!.map((item) => {
          const isEnabled = item.enabled === undefined ? true : item.enabled;

          const btnClassnames = classNames('datetime-picker__time-btn', {
            'datetime-picker__time-btn--active': item === selectedTime,
          });

          return (
            isEnabled && (
              <div className="datetime-picker__time" key={item.id}>
                <button
                  className={btnClassnames}
                  type="button"
                  key={item.id}
                  onClick={() => selectTime(item)}
                  data-cy={`${dataCy}_time_btn_${item.id}`}>
                  {item.name}
                </button>
              </div>
            )
          );
        })}
      </div>
    );
  }, [
    state.time,
    selectedTime,
    emptyText,
    isEmpty,
    selectTime,
    selectedDate,
    dataCy,
  ]);

  const checkDisabledDates = useCallback(
    ({ date }: CalendarTileProperties) => {
      const dateString = formatDateToDateString(date);
      return Boolean(
        disabledDates.find(
          (disabledDateItem) =>
            formatDateToDateString(disabledDateItem) === dateString,
        ),
      );
    },
    [disabledDates],
  );

  return (
    <Modal
      isOpen={isPickerOpen}
      onClose={closeAndReset}
      popupClassNames="modal__popup--datetime-picker"
      contentClassNames="modal__content--dateime-picker">
      <div className="input-date datetime-picker">
        <div className="datetime-picker__calendar-block">
          <Calendar
            locale="ru"
            prev2Label={null}
            next2Label={null}
            prevLabel=""
            nextLabel=""
            tileDisabled={checkDisabledDates}
            minDate={minDate || props.minDate || new Date()}
            maxDate={maxDate || props.maxDate || addDays(new Date(), 30)}
            value={selectedDate}
            defaultValue={value?.date}
            onChange={(value) => {
              if (!Array.isArray(value)) {
                selectDate(value);
              }
            }}
          />
        </div>
        <div className="datetime-picker__times-block">
          {!selectedDate && (
            <p className="datetime-picker__info">Выберите дату</p>
          )}
          {!!timeResource && selectedDate && (
            <>
              {!isEmpty && (
                <h3 className="datetime-picker__title"> {timeHeader} </h3>
              )}
              {renderTime()}
            </>
          )}
        </div>
        <div className="datetime-picker__btn-block">
          <Button
            type="button"
            className="datetime-picker__confirm-btn"
            data-cy={`${dataCy}_submit_btn`}
            onClick={saveHandler}
            disabled={!selectedDate || (!!timeResource && !selectedTime)}
            title="Подтвердить"
          />
        </div>
      </div>
      <Notifications target="datetime-picker" />
    </Modal>
  );
});
