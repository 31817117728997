import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { SINGLE } from '@eus/els-api-types/src/td-type-tariff-for-buy';
import {
  CHILDISH,
  FAMILY,
  PERSONAL,
  TD_CATEGORY_TARIFF_FOR_BUY,
} from '@eus/els-api-types/src/td-category-tariff-for-buy';
import {
  numberFormat,
  plural,
} from '@teledoctor/common/dist/lib/formatters/format-number';
import { TariffTdCalculated } from '@teledoctor/common/dist/features/tariffs';
import {
  useTariffsExpiring,
  usePromoCodeForm,
} from '@teledoctor/common/dist/hooks';
import { useGetTariffsMerchantLinkTdLk } from '@features/api';
import { SingleTariffType } from '@features/tariffs/ui';
import { Button, hideLoader, showLoader, Svg } from '@ui';
import { PromoCode } from '../promo-code';
import styles from './style.module.scss';

interface Props {
  tariff: TariffTdCalculated;
}

export const TariffItem = ({ tariff }: Props) => {
  const { getTariffsMerchantLink, loading } = useGetTariffsMerchantLinkTdLk();

  useEffect(() => {
    loading ? showLoader() : hideLoader();
  }, [loading]);

  const { handleSubmitPromoCode: handleSubmitTariffMerchantLink, methods } =
    usePromoCodeForm(async (values) => {
      await getTariffsMerchantLink(tariff.id, values.promo_code);
    });

  const tariffTypeClass = useMemo(() => {
    switch (tariff.category_id) {
      case PERSONAL:
        return styles['type__personal'];
      case FAMILY:
        return styles['type__family'];
      case CHILDISH:
        return styles['type__children'];
      default:
        return '';
    }
  }, [tariff.category_id]);

  const tariffDuration = useMemo(() => {
    switch (tariff.expiring_interval_type) {
      case 'year':
        return `${tariff.expiring_interval_count} ${plural(
          tariff.expiring_interval_count,
          'год',
          'года',
          'лет',
        )}`;
      case 'month':
        return `${tariff.expiring_interval_count} ${plural(
          tariff.expiring_interval_count,
          'месяц',
          'месяца',
          'месяцев',
        )}`;
      case 'day':
        return `${tariff.expiring_interval_count} ${plural(
          tariff.expiring_interval_count,
          'день',
          'дня',
          'дней',
        )}`;
    }
  }, [tariff.expiring_interval_count, tariff.expiring_interval_type]);

  const isSingle = tariff.type === SINGLE;

  const { expiringTime, isExpiring } = useTariffsExpiring(
    tariff.expirationDate,
  );

  const itemClassName = useMemo(() => {
    return classNames(
      styles.item,
      tariff.isActive && styles['item__active'],
      !isSingle && isExpiring && styles['item__expiring'],
    );
  }, [tariff.isActive, isSingle, isExpiring]);

  return (
    <div
      className={classNames(
        'grid__item grid__item-6x grid__item-sm-12x',
        styles.grid,
      )}>
      <div className={itemClassName}>
        {tariff.isActive && (
          <div
            className={classNames(
              styles['active-label'],
              !isSingle && isExpiring && styles['active-label__expiring'],
            )}>
            Активная
            {!isSingle && (
              <div className={styles['active-label__tooltip']}>
                {expiringTime}
              </div>
            )}
          </div>
        )}
        {isSingle && <SingleTariffType />}
        <div className={styles.labels}>
          {!!tariff.category_id && (
            <div
              className={classNames(
                styles.type,
                styles.label,
                tariffTypeClass,
              )}>
              Тариф {TD_CATEGORY_TARIFF_FOR_BUY[tariff.category_id]}
            </div>
          )}
          <div
            className={classNames(
              styles.duration,
              styles.label,
              !!tariff.category_id && styles['duration-with-margin'],
            )}>
            Доступ {tariffDuration}
          </div>
        </div>
        <div className={styles.title}>{tariff.name}</div>
        <div className={styles.description}>{tariff.description}</div>
        <div className={styles.info}>
          <div className={styles['info__item']}>
            <Svg name="human-icon" modClass={styles['info__icon']} />
            <div className={styles['info__description']}>
              Пользователи: {tariff.count_of_users}
            </div>
          </div>
          <div className={styles['info__item']}>
            <Svg name="talk" modClass={styles['info__icon']} />
            <div className={styles['info__description']}>
              <div className={styles['info__consultations']}>
                {tariff.count_of_services}
              </div>
              {plural(
                tariff.count_of_services,
                'консультация',
                'консультации',
                'консультаций',
              )}
            </div>
          </div>
        </div>
        <PromoCode methods={methods} className={styles['promocode']} />
        {isSingle ? (
          <div className={styles['one-time-cost']}>
            <div className={styles['one-time-cost__description']}>
              1 консультация
            </div>
            <div className={styles['one-time-cost__prices']}>
              {!!tariff.cost_with_sale && (
                <div
                  className={classNames(
                    styles['one-time-cost__previous'],
                    styles['cost__previous'],
                  )}>
                  {numberFormat(tariff.cost, 0, '', ' ')} ₽
                </div>
              )}
              <div className={styles.cost}>
                {numberFormat(tariff.cost_with_sale ?? tariff.cost, 0, '', ' ')}{' '}
                ₽
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.costs}>
            <div className={styles['full-cost']}>
              {!!tariff.cost_with_sale && (
                <div
                  className={classNames(
                    styles['full-cost__previous'],
                    styles['cost__previous'],
                  )}>
                  {numberFormat(tariff.cost, 0, '', ' ')} ₽
                </div>
              )}
              <div className={styles.cost}>
                {numberFormat(tariff.cost_with_sale ?? tariff.cost, 0, '', ' ')}{' '}
                ₽
              </div>
            </div>
            <div
              className={classNames(
                styles['by-one-cost'],
                tariff.isActive && styles['by-one-cost__bordered'],
              )}>
              <div className={styles['by-one-cost__description']}>
                1 консультация
              </div>
              <div className={styles['by-one-cost__prices']}>
                {(!!tariff.cost_of_service_with_sale ||
                  !!tariff.cost_of_service) && (
                  <>
                    {!!tariff.cost_of_service_with_sale &&
                      !!tariff.cost_of_service && (
                        <div className={styles['by-one-cost__previous']}>
                          {numberFormat(tariff.cost_of_service, 0, '', ' ')} ₽
                        </div>
                      )}

                    <div className={styles['by-one-cost__current']}>
                      {numberFormat(
                        // @ts-ignore
                        tariff.cost_of_service_with_sale ??
                          tariff.cost_of_service,
                        0,
                        '',
                        ' ',
                      )}{' '}
                      ₽
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <Button
          title={`Купить${tariff.isActive ? ' еще' : ''}`}
          type="button"
          fullWidth
          onClick={handleSubmitTariffMerchantLink}
        />
      </div>
    </div>
  );
};
