import React from 'react';

import './auth-template.scss';
import { Svg } from '../atoms';

export const AuthTemplate: React.FC = ({ children }) => {
  return (
    <main className="page page--auth-template">
      <div className="page__wrapper">
        <div className="page__container">
          <Svg name="logo" modClass="auth__logo" />
          {children}
        </div>
      </div>
    </main>
  );
};
