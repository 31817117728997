import React from 'react';
import { Svg } from './svg';

import './support-tel.scss';

export const SupportTel = () => {
  return (
    <div className="support-24h">
      <span className="support-24h__text">Круглосуточная поддержка:</span>
      <a
        href="tel:8 (800) 222 05 13"
        className="support-24h__contact"
        data-cy="HEADER_CALL_SUPPORT_LINK">
        <Svg name="phone" modClass="support-24h__icon" />
        <span className="support-24h__tel">8 (800) 222 05 13</span>
      </a>
    </div>
  );
};
