import React from 'react';

import './testimony-list.scss';
import { TestimonyItem, TestimonyItemStatusTypes } from './testimony-item';

import { Svg } from '@ui';
import { useStore } from 'effector-react';
import { $testimonyModules } from './model';
import { moduleCommingSoonCommon } from '@teledoctor/common/dist/features/metrics/model/summary';

export interface TestimonyListInterface {
  editorHandler: () => void;
}

export const TestimonyList: React.FC<TestimonyListInterface> = ({
  editorHandler,
}) => {
  const allTestimonyItems = useStore($testimonyModules);
  const testimonyItems = allTestimonyItems.filter(
    (item) => !item.hidden && !item.developing,
  );

  const developingItems = moduleCommingSoonCommon
    .concat(allTestimonyItems.filter((item) => item.developing))
    .map((item) => {
      return {
        ...item,
        id: TestimonyItemStatusTypes.DEV,
        developing: true,
      };
    });
  return (
    <div className="testimony__list">
      {testimonyItems.map((item, index) => (
        <TestimonyItem {...item} key={`testimony-item-${index}`} />
      ))}
      <div className="testimony__editor" onClick={editorHandler}>
        <Svg name="squares-plus" />
        <div className="testimony__editor-text">Изменить</div>
      </div>
      {developingItems.map((item, index) => (
        <TestimonyItem {...item} key={`testimony-developing-item-${index}`} />
      ))}
    </div>
  );
};
