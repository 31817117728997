import { useMemo } from 'react';
import { CheckFormCode } from '@eus/react-client';
import { OnSuccessForm } from '@teledoctor/common/dist/hooks';
import { CodeForm as BaseCodeForm } from './index';

interface Props {
  phone?: string;
  email?: string;
  requestCodeAgain: (login: string) => void;
  onSuccessForm: OnSuccessForm<CheckFormCode>;
  placeholder?: string;
  description?: string;
  label?: string;
}

export const LoginCodeForm = (props: Props) => {
  const {
    phone,
    email,
    requestCodeAgain,
    onSuccessForm,
    placeholder,
    description,
    label,
  } = props;

  const requestCodeAgainHandler = () => {
    const source = phone ?? email;
    if (source) {
      requestCodeAgain(source);
    }
  };

  const getInfo: { placeholder: string; description: string } | null =
    useMemo(() => {
      if (!phone && !email) {
        return null;
      }

      const placeholder = phone ? 'SMS' : 'E-mail';
      const description = phone
        ? `номер ${phone}`
        : `почту ${email?.substring(0, 3)}***@${email?.split('@')[1]}`;

      return {
        placeholder: `Код из ${placeholder}`,
        description: `Код отправили на ${description}`,
      };
    }, [phone, email]);

  return (
    <BaseCodeForm
      sourceCode={phone ?? email}
      requestCodeAgain={requestCodeAgainHandler}
      description={description ?? getInfo?.description}
      onSuccessForm={onSuccessForm}
      label={label}
      placeholder={placeholder ?? getInfo?.placeholder}
    />
  );
};
