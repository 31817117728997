import classnames from 'classnames';
import { Svg } from '@ui';

import './list-mode-toggle.scss';

interface ListModeToggleProps {
  className?: string;
  isList: Boolean;
  onChange: (isList: boolean) => void;
}

export const ListModeToggle = ({
  isList,
  className,
  onChange,
}: ListModeToggleProps) => {
  const classNames = classnames('list-mode-toggle', className);

  const toggleListClassnames = classnames(
    'list-mode-toggle__item',
    isList && 'list-mode-toggle__item-checked',
  );
  const toggleCardClassnames = classnames(
    'list-mode-toggle__item',
    !isList && 'list-mode-toggle__item-checked',
  );

  return (
    <div className={classNames}>
      <button onClick={() => onChange(true)} className={toggleListClassnames}>
        <Svg name="hamburger" />
      </button>
      <button onClick={() => onChange(false)} className={toggleCardClassnames}>
        <Svg name="squares" />
      </button>
    </div>
  );
};
