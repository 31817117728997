import { LoginCodeForm } from '@features/user/ui/forms';
import { addNotification, Button } from '@ui';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RestorePasswordScreenTypes } from '../types';
import styles from './../../styles.module.scss';
import { useAuthTdLk } from '@features/api';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';
import { getErrorTextForRestorePassword } from '@teledoctor/common/dist/lib/error-handlers/helpers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import { CheckFormCode } from '@eus/react-client';
import { OnSuccessForm } from '@teledoctor/common/dist/hooks';

interface Props {
  phone: string;
  changeScreen: Dispatch<SetStateAction<RestorePasswordScreenTypes>>;
  resetState: () => void;
  restorePasswordByPhone: (
    phone?: string | undefined,
    recaptchaToken?: string | undefined,
  ) => Promise<unknown>;
  verifyCode: OnSuccessForm<CheckFormCode>;
  getCodeAgainHandler: () => Promise<void>;
}

const notifyError = (id: string, message: string) => {
  addNotification({
    id: `${id}-${Date.now()}`,
    message:
      message || 'Произошла неизвестная ошибка. Попробуйте еще раз позже.',
    type: 'error',
    target: 'authorization',
  });
};

export const RestorePasswordByPhone = ({
  phone,
  changeScreen,
  resetState,
  restorePasswordByPhone,
  verifyCode,
  getCodeAgainHandler,
}: Props) => {
  const { checkIdentify } = useAuthTdLk();
  const { askHcaptchaToken, component, isCaptchaApiReady } =
    useHCaptcha(SITE_KEY);
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
  const { ErrorMessage } = useErrorMessage();

  const restoreByPhone = useCallback(async () => {
    const restorePassword = async () => {
      try {
        const recaptchaToken = await askHcaptchaToken();
        await restorePasswordByPhone(phone, recaptchaToken);
        setCodeHasBeenSent(true);
      } catch (error) {
        const errText = getErrorTextForRestorePassword(error);
        notifyError('restore-password-send-code', errText);
      }
    };

    try {
      const { exist } = await checkIdentify(
        { login: phone },
        new FeedParams({
          throwNext: true,
          needShowLoader: true,
        }),
      );

      if (!exist) {
        notifyError(
          'restore-password-by-phone',
          ErrorMessage.phone_does_not_exist,
        );
        return;
      }
      await restorePassword();
    } catch (error) {
      console.log('restorePassword error:', error);
    }
  }, [
    ErrorMessage.phone_does_not_exist,
    checkIdentify,
    askHcaptchaToken,
    restorePasswordByPhone,
    phone,
  ]);

  useEffect(() => {
    if (isCaptchaApiReady) {
      restoreByPhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaApiReady]);

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {component}
      {!!codeHasBeenSent && (
        <LoginCodeForm
          phone={phone}
          requestCodeAgain={getCodeAgainHandler}
          onSuccessForm={verifyCode}
        />
      )}
      <Button
        appearance="textual"
        fullWidth
        title="Восстановить через E-mail"
        onClick={() => changeScreen('email')}
        className={styles['password-button']}
      />
    </>
  );
};
