import { Button, Modal } from '@ui';
import styles from './styles.module.scss';
import { useProfileTdLk } from '@features/api';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WelcomeModalContext } from './context';
import { push } from '@features/app/navigation.model';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';

export const WelcomeModal = () => {
  const context = useContext(WelcomeModalContext);
  const { profile, load } = useProfileTdLk();

  const { card: defaultCard, loading: loadingCards } = useDefaultCard();

  useEffect(() => {
    if (!profile && context?.isShowModal) {
      load();
    }
  }, [profile, load, context]);

  const name = useMemo(
    () =>
      profile && profile.first_name !== '-' ? `, ${profile.first_name}` : '',
    [profile],
  );

  const closeModalHandler = useCallback(() => {
    if (context) {
      context.setShowModalStatus(false);
    }
  }, [context]);

  const getConsultation = useCallback(() => {
    closeModalHandler();
    if (!defaultCard) {
      push({ path: 'cards' });
    } else {
      push({
        path: 'tasks/get',
        state: { cardClientId: defaultCard.card_client_id },
      });
    }
  }, [closeModalHandler, defaultCard]);

  const getAuthMessage = useCallback(() => {
    switch (context?.modalType) {
      case 'auth':
        return (
          <p>
            Мы рады видеть вас снова!
            <br />
            Если у вас есть вопросы или нужна помощь, мы всегда здесь.
            <br />
            <br />
            Заботьтесь о себе и оставайтесь здоровым!
          </p>
        );
      case 'register-full':
        return (
          <p>
            Если у вас есть сертификат, который вы хотите добавить в свой
            профиль, вы можете сделать это прямо сейчас или позже.
          </p>
        );
      case 'register-partial':
        return (
          <p>
            Не волнуйтесь, вы всегда можете вернуться к этому шагу позже! Все
            необходимые изменения можно будет легко внести в настройках вашего
            личного кабинета, когда вам будет удобно.
            <br />
            <br />
            Если у вас есть сертификат, который вы хотите добавить в свой
            профиль, вы можете сделать это прямо сейчас или позже.
          </p>
        );
    }
  }, [context]);

  return !!profile && !!context ? (
    <Modal
      closeIcon={false}
      popupClassNames={styles.modal}
      contentClassNames={styles.content}
      isOpen={context.isShowModal}
      onClose={closeModalHandler}>
      <div className={styles.icon}>🎉</div>
      <div className={styles.title}>
        {context.modalType === 'auth'
          ? `Добро пожаловать${name}!`
          : 'Поздравляем с успешной регистрацией!'}
      </div>
      {getAuthMessage()}
      <div className={styles.footer}>
        {context.modalType !== 'auth' && (
          <Button
            type="button"
            title="Добавить сертификат"
            className={styles.button}
            onClick={() => {
              closeModalHandler();
              push({ path: '/lk/cards', state: { showAddCard: true } });
            }}
          />
        )}
        <Button
          type="button"
          title="Получить консультацию"
          className={styles.button}
          onClick={getConsultation}
          disabled={loadingCards}
        />
      </div>
    </Modal>
  ) : null;
};
