import React from 'react';
import { Card } from '@eus/react-client/src/cards/types';
import style from './card-detail.module.scss';
import { CardLogo } from '../../card-logo';
import { CardInfoList } from './info';
import { formatCardNumber } from '@features/consultations/ui/consultation-info/helper';
import { getStatusIdText } from '@eus/react-client/src/cards/helpers';
import { CardUsers } from './users';

interface Props {
  card: Card;
}

export const CardDetail = ({ card }: Props) => {
  return (
    <div className={style.detail}>
      <CardLogo url={card.logo} className={style.card} />
      <div className={style.list}>
        <CardInfoList
          number={formatCardNumber(card.number)}
          date={new Date(card.expiration_date * 1000)}
          status={getStatusIdText(card.card_status_id)}
        />
      </div>
      <CardUsers card={card} />
    </div>
  );
};
