import './pagination-mobile.scss';

interface Props {
  currentPage: number;
  total: number;
  isLastPage: boolean;
  handlePrevClick: () => void;
  handleNextClick: () => void;
}

export const PaginationMobile = ({
  currentPage,
  total,
  isLastPage,
  handlePrevClick,
  handleNextClick,
}) => {
  return (
    <div className="pagination-mobile">
      <button
        className="pagination-mobile__link pagination-mobile__link--prev"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      />
      {currentPage}/{total}
      <button
        className="pagination-mobile__link pagination-mobile__link--next"
        onClick={handleNextClick}
        disabled={isLastPage}
      />
    </div>
  );
};
