import { createAppBehaviorHook } from '@teledoctor/common/dist/features/app-behavior/app-behavior-hook-creator';

const { hookSignals, hookBehavior } = createAppBehaviorHook({
  storage: {
    getItem: (key: string) => Promise.resolve(localStorage.getItem(key)),
    setItem: (key: string, value: string) =>
      Promise.resolve(localStorage.setItem(key, value)),
    removeItem: (key: string) => Promise.resolve(localStorage.removeItem(key)),
  },
});

export const useAppBehavior = function () {
  hookBehavior();
};

export const useAppBehaviorSignals = hookSignals;
