import React from 'react';
import { Button, FField, FormWrapper } from '@ui';
import styles from './styles.module.scss';
import { usePhoneForm } from '@teledoctor/common/dist/hooks';
import classnames from 'classnames';
import { UseFormSetError } from 'react-hook-form';

export const PhoneForm = (props: {
  onSuccess: (phone: string, setError: UseFormSetError<any>) => Promise<void>;
}) => {
  const { onSuccess } = props;

  const { onSubmitForm, methods, isDisabledSubmit } = usePhoneForm({
    onValid: ({ phone }, setError) => onSuccess(phone, setError),
  });

  return (
    <FormWrapper className={styles.form} onSubmit={onSubmitForm}>
      <FField.Phone
        name="phone"
        methods={methods}
        label="Новый номер телефона"
        className={classnames(styles.input, 'form__field')}
        data-cy="CHANGE_PHONE_INPUT"
      />
      <Button title="Продолжить" disabled={isDisabledSubmit} />
    </FormWrapper>
  );
};
