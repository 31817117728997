import classnames from 'classnames';
import sprite from './sprite.svg';

interface SvgProps {
  name: string;
  modClass?: string;
}

export const Svg = ({ name, modClass }: SvgProps) => {
  const svgClass = classnames('svg', name && `svg-${name}`, modClass);

  return (
    <span className={svgClass}>
      <svg preserveAspectRatio="none">
        <use xlinkHref={`${sprite}#${name}`} />
      </svg>
    </span>
  );
};
