import { SetStateAction } from 'react';
import formatPhone from './phone';

interface RequestCodeProps {
  setNumber: (value: SetStateAction<string>) => void;
  requestMethod: (number: string) => void;
  phone?: string;
}

export const requestCode = ({
  setNumber,
  requestMethod,
  phone,
}: RequestCodeProps) => {
  const number = phone ?? localStorage.getItem('numberAfterPurchase');
  if (number) {
    setNumber(formatPhone(number));
    localStorage.removeItem('numberAfterPurchase');
    requestMethod(number);
  }
};
