import React from 'react';
import { ChatClaimForm } from '@features/chat/ui/chat-feedback/forms/chat-claim-form';
import { Modal, Notifications } from '@ui';
interface Props {
  onCloseHandler: () => void;
  isOpen: boolean;
  taskId: number;
}

export const ChatClaimModal = ({ onCloseHandler, isOpen, taskId }: Props) => {
  return (
    <Modal
      onClose={onCloseHandler}
      isOpen={isOpen}
      header="Претензия"
      root={<Notifications target="chat-claim-modal" />}>
      <div className="description -mb__xsmall -mb__xs-small">
        <p>Оставьте комментарий о работе наших специалистов</p>
      </div>
      <ChatClaimForm taskId={taskId} onSuccess={onCloseHandler} />
    </Modal>
  );
};
