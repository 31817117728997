import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { FileInputInterface } from '@ui';
import { ChatMessageText } from '../chat-message-text/chat-message-text';
import { isImage, isAudio } from '@eus/react-client/src/utils/file-detectors';
import { ChatImageWrapper } from './chat-image-wrapper';
import { ChatAudioFile } from './chat-audio-file';
import { ChatDocFile } from './chat-doc-file';
import format from 'date-fns/esm/format';

interface Props {
  files: VariedFile[];
  time: string;
  user: MessageUI['user'];
  messageType: MessageUI['type'];
}

export type VariedFile = FileInputInterface | MessageUI['files'][number];

export const getFileUrl = (fileElem: VariedFile): string => {
  if ('src' in fileElem) {
    return fileElem.src;
  }
  return fileElem.url;
};

export const ChatFiles = ({ files, time, user, messageType }: Props) => {
  return (
    <>
      {files.map((file) => {
        const fileName = file.name ?? '';
        if (isImage(fileName)) {
          const fileUrl = getFileUrl(file);
          return (
            <div className="chat__message chat__message-image" key={file.id}>
              <ChatImageWrapper src={fileUrl} />
              <div className="chat__message-time">{time}</div>
            </div>
          );
        }

        if (isAudio(fileName)) {
          return (
            <ChatAudioFile
              user={user}
              messageType={messageType}
              file={file}
              key={file.id}>
              <div className="chat__message-time">{time}</div>
            </ChatAudioFile>
          );
        }
        return (
          <ChatDocFile
            file={file}
            user={user}
            messageType={messageType}
            key={file.id}>
            <div className="chat__message-time">{time}</div>
          </ChatDocFile>
        );
      })}
    </>
  );
};
