import React from 'react';
import { FileInputInterface } from '../atoms/file-input';
import { AddFile } from '../molecules/add-file';
import { DeleteButton } from '../atoms/delete-button';
import { FileImageCover } from '../atoms/file-image-cover';

interface FormFilesList {
  files: FileInputInterface[];
  onDelete?: (id: number) => void;
}

export const FormFilesList: React.FC<FormFilesList> = ({ files, onDelete }) => {
  const anotherFiles: FileInputInterface[] = [];
  const images: FileInputInterface[] = [];

  const deleteHandler = (id: number) => {
    if (onDelete) {
      onDelete(id);
    }
  };

  files.forEach((file: FileInputInterface) => {
    if (file.fileType !== 'image') {
      anotherFiles.push(file);
    } else {
      images.push(file);
    }
  });

  return (
    <>
      {anotherFiles.length > 0 && (
        <div className="form__field form__file-document-row">
          <div className="grid__list">
            {anotherFiles.map((file) => {
              return (
                <div key={file.id} className="grid__item form__file-document">
                  <div className="form__file">
                    <DeleteButton id={file.id} deleteHandler={deleteHandler} />
                    <AddFile file={file} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {images.length > 0 && (
        <div className="form__field form__file-image-row">
          {images.map((image) => {
            return (
              <div key={image.id} className="form__file-image">
                <div className="form__file">
                  <DeleteButton id={image.id} deleteHandler={deleteHandler} />
                  <FileImageCover src={image.src} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
