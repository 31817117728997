import React from 'react';

export const BlobShape = (props) => {
  const width = 13;
  const heigth = 18;
  return props.cx && props.cy ? (
    <svg
      x={props.cx - Math.floor(width / 2)}
      y={props.cy - Math.floor(heigth / 2)}
      width={width}
      height={heigth}
      viewBox={`0 0 ${width} ${heigth}`}
      style={{ overflow: 'visible' }}
      className="chart__shape-fill">
      <path d="M13 11.5714C13 15.1218 10.0899 18 6.5 18C2.91015 18 0 15.1218 0 11.5714C0 8.02103 6.5 0 6.5 0C6.5 0 13 8.02103 13 11.5714Z" />
    </svg>
  ) : null;
};
