import React from 'react';
import './table.scss';

interface Props {
  data: TableItemProps[];
  testScope: string;
}

export type TableItemProps = {
  isLink?: boolean;
  name: string;
  description: string;
  value: string | null;
  onPressItem: (item) => void;
};

export const Table = ({ data, testScope }: Props) => {
  return (
    <div className="block -grow__1 metric-chart__table">
      <div className="metric-chart__table-inner">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={`metric-chart__table-item${
                item.isLink ? ' _clickable' : ''
              }`}
              {...(item.isLink && {
                onClick: () => item.onPressItem(item),
              })}
              data-cy={`${testScope}_ITEM_${index}`}>
              {item.name && (
                <span className="metric-chart__table-data metric-chart__table-name">
                  {item.name}
                </span>
              )}
              {item.description && (
                <span className="metric-chart__table-data metric-chart__table-description">
                  {item.description}
                </span>
              )}
              {item.value && (
                <span className="metric-chart__table-data metric-chart__table-value">
                  {item.value}
                </span>
              )}
              {item.isLink && (
                <span className="metric-chart__table-data metric-chart__table-arrow" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
