import React from 'react';
import empty from '@assets/img/plugs-testimony.png';
import { Plug, TitleLine } from '@ui';
import { HealthMainPageInterface } from './types';

export const EmptyState: React.FC<HealthMainPageInterface> = ({ title }) => {
  return (
    <>
      <TitleLine titleText={title} />
      <Plug
        image={empty}
        label="Для доступа к сервису добавьте и активируйте карту Health 2.0"
      />
    </>
  );
};
