import React, { useState } from 'react';
import './data-table.scss';
import { Modal } from '@ui';
import { useBoolean } from '@teledoctor/common/dist/hooks';

interface Props {
  data: DataTableWithModalItemProps[];
  testScope: string;
}

export type DataTableWithModalItemProps = {
  label: string;
  value?: string | null;
  modClass?: string;
  infoMessage: string;
};

export const DataTableWithModal = ({ data, testScope }: Props) => {
  const {
    value: isModalOpen,
    setTruthy: openModal,
    setFalsy: closeModal,
  } = useBoolean(false);

  const [modalHeader, setModalHeader] = useState(null);
  const [modalText, setModalText] = useState(null);

  const showModal = ({ header, text }) => {
    setModalHeader(header);
    setModalText(text);
    openModal();
  };

  return (
    <>
      <table className="data-table">
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="data-table__label">{item.label}</td>
              <td className={`data-table__value ${item.modClass || ''}`}>
                <button
                  className="data-table__clickable"
                  title="Подробнее"
                  onClick={() =>
                    showModal({
                      header: item.label,
                      text: item.infoMessage,
                    })
                  }
                  data-cy={`${testScope}_RESULT_BTN`}>
                  <span>{item.value || ''}</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal header={modalHeader} isOpen={isModalOpen} onClose={closeModal}>
        <div>{modalText}</div>
      </Modal>
    </>
  );
};
