import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { findIndex } from 'lodash';
import {
  useCardsListLoadingSelector,
  useCardsListSelector,
} from '@eus/react-client';
import { useCardsTdLk } from '@features/api';
import { CardLogo } from '@features/card/ui/card-logo';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { InfoList, InfoListGroups } from '@ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectCardModal } from './select-card-modal';
import {
  cardIsInactive,
  cardIsActive,
  getStatusIdText,
} from '@eus/react-client/src/cards/helpers';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import './select-card.scss';
import { Card } from '@eus/react-client/src/cards/types';
import { formatCardNumber } from '../../consultation-info/helper';
import 'swiper/swiper.scss';
import classNames from 'classnames';

SwiperCore.use([Navigation]);

interface SelectCardProps {
  value: number;
  onSelect: (cardId: number) => void;
}

export const SelectCard = ({ onSelect, value }: SelectCardProps) => {
  const { value: isOpen, setTruthy: open, setFalsy: close } = useBoolean(false);
  const [swiper, setSwiper] = useState<SwiperCore>();

  // const cards = useStore($cardsActive);
  // const loading = useStore(getAllCardsFx.pending);

  // const cards = useCardsListSelector();

  const loadingCards = useCardsListLoadingSelector();
  const { loadCardsList, state } = useCardsTdLk();
  const cards = state.cards.entities;

  const activeCards =
    (
      cards?.filter((card) => card !== null && cardIsActive(card)) as Card[]
    )?.map((card) => ({
      ...card,
      number: formatCardNumber(card.number),
    })) || [];

  useEffect(() => {
    if (!cards && !loadingCards) {
      loadCardsList({ pagination: { page: 1, perPage: 50 } });
    }
  }, [loadCardsList, cards, loadingCards]);

  const card = activeCards?.find(
    (card) => `${card?.card_client_id}` === `${value}`,
  );

  const disabled = cards && cards.length === 1;

  const getInfoGroups = useCallback((card) => {
    return [
      {
        label: '',
        props: [
          {
            label: 'Тариф',
            value: card.tariff_name,
          },
          {
            label: 'Номер карты',
            value: card.number,
          },
          {
            label: 'Статус',
            value: getStatusIdText(card.card_status_id) ?? '',
          },
          {
            label: 'Действует до',
            value: formatDateShort(new Date(card.expiration_date * 1000)),
          },
        ],
      },
    ];
  }, []);

  const handleOpenClick = () => {
    if (!disabled) {
      open();
    }
  };

  const handleSelect = (id: number) => {
    onSelect(id);

    const selectedIndex = findIndex(
      activeCards,
      (card) => card.card_client_id === id,
    );

    if (swiper?.activeIndex !== selectedIndex) {
      swiper?.slideTo(selectedIndex);
    }

    close();
  };

  const navigationClass = useMemo(
    () => classNames('navigation__el select-card__navigation-el'),
    [],
  );

  // return null;
  return activeCards.length ? (
    <div className="select-card">
      <Swiper
        slidesPerView={1}
        noSwipingClass={'is-disabled'}
        pagination={false}
        navigation={{
          prevEl: '.navigation__prev',
          nextEl: '.navigation__next',
          disabledClass: 'navigation__disabled',
        }}
        spaceBetween={20}
        onSlideChange={(props) => {
          if (activeCards[props.activeIndex].card_client_id) {
            onSelect(activeCards[props.activeIndex].card_client_id);
          }
        }}
        onSwiper={(swiper) => setSwiper(swiper)}>
        {activeCards.map((card) => {
          return (
            <SwiperSlide key={card.id}>
              <div
                className="select-card__image"
                onClick={handleOpenClick}
                data-disabled={loadingCards || activeCards.length === 1}
                data-cy="GET_CONSULT_SELECT_CARD">
                <CardLogo url={card.logo} />
              </div>
              <InfoList groups={getInfoGroups(card)} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="navigation">
        <div
          className={classNames(
            'navigation__prev select-card__navigation-prev',
            navigationClass,
          )}
        />
        <div
          className={classNames(
            'navigation__next select-card__navigation-next',
            navigationClass,
          )}
        />
      </div>
      {activeCards && (
        <SelectCardModal
          isOpen={isOpen}
          onClose={close}
          data={activeCards}
          value={card?.card_client_id}
          onSelect={handleSelect}
        />
      )}
    </div>
  ) : null;
};
