import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import {
  AuthPage,
  PhoneCheckSimple,
  RegisterPage,
  LoginTypes,
} from '@features/user/ui';
import { AuthBlock } from '@features/user/ui/block';
import {
  AuthTemplate,
  Notifications,
  useCheckIdentifyAuthType,
  usePurchaseFromQuery,
} from '@ui';
import { useCallback, useState } from 'react';

export const AuthNewPage = () => {
  const [login, setLogin] = useState<string | null>(null);
  const [authType, setAuthType] = useState<AuthTypes | null>(null);
  const [loginType, setLoginType] = useState<LoginTypes | null>(null);
  const [isChecked, setCheckingStatus] = useState<boolean>(false);

  const { handleCheckIdentify, checkIdentify } = useCheckIdentifyAuthType({
    setAuthType,
    setLoginType,
    setCheckingStatus,
    beforeCheckingHandler: setLogin,
  });

  const { isPurchase } = usePurchaseFromQuery({
    onPurchase: checkIdentify,
  });

  const onBackHandler = () => {
    setLoginType(null);
    setCheckingStatus(false);
    setAuthType(null);
  };

  const getAuthComponent = useCallback(() => {
    if (!isChecked || !login || !authType) {
      return null;
    }

    switch (authType) {
      case 'auth':
        if (!loginType) {
          return null;
        }

        return (
          <AuthPage
            type={loginType}
            phone={login}
            onBackHandler={onBackHandler}
          />
        );
      case 'register':
        return (
          <RegisterPage
            phone={login}
            onBackHandler={!isPurchase ? onBackHandler : undefined}
          />
        );
    }
  }, [isChecked, isPurchase, authType, login, loginType]);

  return (
    <AuthTemplate>
      <AuthBlock>
        {!isChecked || !authType ? (
          <>
            <h1 className="h1">Вход/Регистрация</h1>
            <PhoneCheckSimple
              onSuccessPhoneCheckHandler={handleCheckIdentify}
              phone={login}
            />
          </>
        ) : (
          getAuthComponent()
        )}
        <Notifications target="authorization" />
      </AuthBlock>
    </AuthTemplate>
  );
};
