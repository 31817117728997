import { ServerErrorsHandler } from '@eus/react-client';
import { getSelectOptions } from '@eus/react-client/src/tasks/schema';
import { Task } from '@eus/react-client/src/types/tasks';
import { useChatTdLk, useQuestionsTdLk, useTasksTdLk } from '@features/api';
import { SelectCard } from '@features/consultations/ui/forms/get-consultation/select-card';
import { yupResolver } from '@hookform/resolvers/yup';
import { HOST_CRM_API } from '@teledoctor/common/dist/config/api';
import { CreateFormValues } from '@teledoctor/common/dist/features/consultations/forms';
import { useCreateTaskSchema } from '@teledoctor/common/dist/features/questions/molecules/schema';
import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import { ProfileData } from '@teledoctor/common/dist/features/user';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers/';
import { getTimestampWithoutTimezone } from '@teledoctor/common/dist/lib/formatters/format-date';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { ProcessCommonValidationError } from '@teledoctor/common/dist/lib/requests/interceptors';
import { addNotification } from '@teledoctor/lk/src/ui/organisms/notifications/model';
import { Button, FField, FormWrapper } from '@ui';
import addDays from 'date-fns/esm/addDays';
import { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

interface AskQuestionsFormProps {
  contacts: Pick<ProfileData, 'phone'>;
  cardClientId?: number;
  isClientCare?: boolean;
  relatedTaskId?: number;
  onFormSubmitted?: (task: Task) => void;
}

const answerTypeOptions = getSelectOptions('answer_type').slice(0, 2);

export const AskQuestionsForm: FC<AskQuestionsFormProps> = ({
  contacts,
  onFormSubmitted,
}) => {
  const { createTaskSchema } = useCreateTaskSchema();
  const { ErrorMessage } = useErrorMessage();
  const schema = createTaskSchema(ErrorMessage);

  const { state: routerState } = useLocation<AskQuestionsFormProps>();

  const { createTask } = useQuestionsTdLk();
  const { clientCare } = useTasksTdLk();
  const { disableMessagesAtAdditionalPayloadType } = useChatTdLk();

  const { cardClientId, isClientCare, relatedTaskId } = routerState ?? {};

  const defaultValues = {
    files: [],
    card_client_id: cardClientId,
  };

  const methods = useForm<CreateFormValues & { description: string }>({
    defaultValues,
    mode: 'onBlur',
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setError, control, setValue, watch } = methods;

  const answerWayRadioOptions = useMemo(
    () => [
      {
        label: answerTypeOptions[0].label,
        value: answerTypeOptions[0].value,
      },
      {
        label: answerTypeOptions[1].label + ' ' + contacts.phone,
        value: answerTypeOptions[1].value,
      },
    ],
    [contacts.phone],
  );

  const [isTaskBeingSent, setIsTaskBeingSent] = useState<boolean>(false);

  const handleCardChange = (cardClientId) => {
    setValue('card_client_id', Number(cardClientId));
  };

  const onSubmit = (values: CreateFormValues) => {
    setIsTaskBeingSent(true);
    const datetime = values.datetime && {
      date: getTimestampWithoutTimezone(values.datetime.date) / 1000,
      hours: values.datetime.time.hours,
      minutes: values.datetime.time.minutes,
    };

    const data = {
      ...values,
      ...(datetime ? { datetime } : {}),
    } as any;

    createTask(
      { data },
      new FeedParams({
        errorProcessors: [ProcessCommonValidationError],
        throwNext: true,
        needShowLoader: true,
      }),
    )
      .then((task) => {
        if (!!isClientCare && !!relatedTaskId) {
          clientCare({ taskId: relatedTaskId, type: 'yes' }).then(() => {
            disableMessagesAtAdditionalPayloadType(
              relatedTaskId.toString(),
              'client_care',
            );
          });
        }
        onFormSubmitted?.(task);
      })
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errText) =>
              addNotification({
                target: 'global',
                id: 'create-question' + Date.now(),
                message: errText,
                type: 'error',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: (err) => ServerErrorsHandler(setError)(err),
          },
        }),
      )
      .finally(() => setIsTaskBeingSent(false));
  };

  const onError = (err) => {
    console.log('err', err);
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit, onError)}
      className="get-consultation"
      data-cy="ask_questions_form">
      <div className="block block__content get-consultation__inner">
        <div className="block__columns">
          <div className="block__column-left">
            <Controller
              name="card_client_id"
              control={control}
              render={({ field: { value, onBlur } }) => {
                return (
                  <SelectCard
                    value={value}
                    onSelect={(cardClientId) => {
                      handleCardChange(cardClientId);
                      onBlur();
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="block__column-right">
            <FField.DateTime
              baseURL={HOST_CRM_API}
              methods={methods}
              name="datetime"
              label="Дата и время"
              timeResource="questions/available-time"
              minDate={new Date()}
              maxDate={addDays(new Date(), 30)}
              className="form__field"
              dataCy="ask_questions_datetime_picker"
            />
            <FField.Select
              name="answer_type"
              label="Способ ответа"
              className="form__field"
              methods={methods}
              options={answerWayRadioOptions}
              dataCy="ask_questions_select_answer_type"
            />
            <FField.TextArea
              name="description"
              label="Суть вопроса"
              placeholder="Опишите проблему"
              methods={methods}
              className="form__field"
              data-cy="ask_questions_comment_input"
            />
            <FField.File
              name="files"
              methods={methods}
              className="form__field"
              maxFileSize={20}
            />
            <div className="form__field">
              <Button
                title="Отправить"
                className="-width__xs-100"
                appearance="solid"
                data-cy="ask_questions_submit_btn"
                disabled={isTaskBeingSent}
              />
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};
