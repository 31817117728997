import classnames from 'classnames';
import { Controller, FieldValues } from 'react-hook-form';
import { checkErrorForField } from '../../../atoms';

import { FieldWrapper, InputPhone, InputPhoneProps } from '../../../molecules';

import { FieldProps } from './types';

export interface FieldPhoneProps<T extends FieldValues>
  extends FieldProps<T>,
    Omit<InputPhoneProps, 'value' | 'onBlur' | 'onChange'> {
  onFieldChange?: (value: any) => void;
  dataCyCodeSelect?: string;
}

export function FieldPhone<T extends FieldValues>({
  name,
  label,
  methods,
  className,
  errorsClassName,
  labelClassName,
  inputClassName,
  disabled,
  readOnly,
  onFieldChange,
  dataCyCodeSelect,
  ...inputPhoneProps
}: FieldPhoneProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={classnames(className, {
              'field--no-border': !disabled && !readOnly,
            })}
            labelClassName={labelClassName}
            dataCy={inputPhoneProps['data-cy']}>
            <InputPhone
              {...inputPhoneProps}
              dataCyCodeSelect={dataCyCodeSelect}
              className={inputClassName}
              // @ts-ignore
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              onChange={(val) => {
                onChange(val);
                onFieldChange?.(val);
              }}
              onBlur={onBlur}
              hasError={checkErrorForField(name, methods.formState.errors)}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
