import React, { useMemo } from 'react';
import classnames from 'classnames';
import './alert.scss';
import { Modal, ModalProps } from '../atoms/modal';
import { Button, ButtonProps } from '../atoms/button';

export interface ButtonAlertProps extends ButtonProps {
  buttonWrapperClassName?: string;
}

interface AlertProps extends ModalProps {
  isOpen: boolean;
  title: React.ReactNode;
  message: React.ReactNode;
  onClose: () => void;
  buttons: ButtonAlertProps[];
  className?: string;
  short?: boolean;
  buttonsClassNames?: string;
}

export const Alert = ({
  isOpen,
  title,
  message,
  buttons,
  className,
  onClose,
  short = true,
  buttonsClassNames,
  ...modalProps
}: AlertProps) => {
  const alertClassNames = classnames('alert', className);

  const renderMessage = useMemo(() => {
    return typeof message === 'string' ? <p>{message}</p> : message;
  }, [message]);
  return (
    <Modal
      short={short}
      {...modalProps}
      className={alertClassNames}
      isOpen={isOpen}
      header={title}
      footer={
        <AlertButtons
          className={buttonsClassNames}
          onClose={onClose}
          buttons={buttons}
        />
      }
      onClose={onClose}>
      {renderMessage}
    </Modal>
  );
};

export interface AlertButtonsProps {
  onClose: () => void;
  buttons: ButtonAlertProps[];
  className?: string;
}

const AlertButtons = ({ buttons, onClose, className }: AlertButtonsProps) => {
  return (
    <div className={classnames('alert__buttons', className)}>
      {buttons.map((buttonProps, index) => {
        const { buttonWrapperClassName, onClick, ...otherProps } = buttonProps;
        const handleClick = () => {
          onClick?.();
          onClose();
        };

        return (
          <div
            className={classnames('alert__button', buttonWrapperClassName)}
            key={index}>
            <Button {...otherProps} onClick={handleClick} />
          </div>
        );
      })}
    </div>
  );
};
