import React from 'react';
import { PULSE_MODAL_INFO } from '@teledoctor/common/dist/lib/text-constants';
import { PulseModalInfoLine } from './pulse-modal-info-line';
import './pulse-modal-info.scss';

export const PulseModal = () => {
  return (
    <div className="pulse-info-table">
      <table className="pulse-info-table__table">
        <tbody>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">Пульс</div>
            </td>
            <td>
              <PulseModalInfoLine title="Возраст" data={PULSE_MODAL_INFO.age} />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <PulseModalInfoLine
                title="Нормальная частота пульса, уд/мин."
                data={PULSE_MODAL_INFO.normal}
                normal={true}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">50%</div>
            </td>
            <td>
              <PulseModalInfoLine
                title="Умеренная активность: разминка"
                data={PULSE_MODAL_INFO.warmUp}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">60%</div>
            </td>
            <td>
              <PulseModalInfoLine
                title="Зона сжигания жира: фитнес-нагрузки"
                data={PULSE_MODAL_INFO.fitness}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">70%</div>
            </td>
            <td>
              <PulseModalInfoLine
                title="Аэробная зона: повышение кардиовыносливости"
                data={PULSE_MODAL_INFO.aerobic}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">80%</div>
            </td>
            <td>
              <PulseModalInfoLine
                title="Анаэробная зона: повышение выносливости. укрепление мышц"
                data={PULSE_MODAL_INFO.anaerobic}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">90%</div>
            </td>
            <td>
              <PulseModalInfoLine
                title=" Максимальные нагрузки: тренировка для улучшения скорости"
                data={PULSE_MODAL_INFO.max1}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="pulse-info-table__left-title">100%</div>
            </td>
            <PulseModalInfoLine data={PULSE_MODAL_INFO.max2} />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
