import { Store } from 'effector';

import {
  withPersist as withPersistBase,
  PersistConfig,
} from '@teledoctor/common/dist/lib/effector/with-persist';
import { asyncStorageAdapter } from './adapter';

export const withPersist = <State>(
  store: Store<State>,
  config?: Partial<PersistConfig>,
) => {
  const { key = store.shortName, adapter = asyncStorageAdapter } = config || {};

  return withPersistBase(store, {
    key,
    adapter,
  });
};
