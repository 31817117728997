import { useEffect, useCallback } from 'react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import { AnalysesFilter } from './analyses-filter';
import { AnalysesList } from './analyses-list';
import { AnalysesListFiltered } from './analyses-list-filtered';
import { Plug, Modal, Svg, Button } from '@ui';
import { useWindowSize } from '@lib/use-window-size';
import './analyses-list-page.scss';
import { SelectOption } from '@eus/selects/src/types';
import { useBoolean } from '@teledoctor/common/dist/hooks';

export const AnalysesListPage = () => {
  const {
    metric: {
      $analyzesCrud,
      $analyzesGroupedByMonth,
      loadAnalyzesList,
      loadFileAnalyze,
      $filter,
      filterUpdated,
      $filteredAnalyzesResultList,
      $isFilterActive,
      resetAllFilters,
    },
  } = useMetric({ metric: 'analyzes' });

  const currentFilter = useStore($filter);

  const analyzesListCrud = useStore($analyzesCrud);
  const analyzesListData = useStore($analyzesGroupedByMonth);
  const filteredListData = useStore($filteredAnalyzesResultList);
  const isFilterActive = useStore($isFilterActive);
  const listWasUpdated = Boolean(analyzesListCrud.lastUpdated);

  useEffect(() => {
    loadAnalyzesList({
      uiBehavior: {
        noLoader: false,
      },
    });
  }, [loadAnalyzesList, listWasUpdated]);

  const shouldRenderMobileFilter = useWindowSize().width < 993;

  const {
    value: isMobFilterVisible,
    setTruthy: openMobFilter,
    setFalsy: closeMobFilter,
  } = useBoolean(false);

  const onDownloadFile = useCallback(
    async (id) => {
      loadFileAnalyze({ unique: id.toString() }).then(({ filePath }) => {
        window.open(filePath, '_self');
      });
    },
    [loadFileAnalyze],
  );
  const handleCategoryChange = useCallback(
    (selected: SelectOption[]) => {
      filterUpdated({
        ...currentFilter,
        categories: selected.map((item) => item.value),
      });
    },
    [filterUpdated, currentFilter],
  );

  const handleDateFromChange = useCallback(
    (date: Date | Date[]) => {
      if (typeof date === 'string') {
        filterUpdated({
          ...currentFilter,
          dateFrom: date,
        });
      }
    },
    [filterUpdated, currentFilter],
  );
  const handleDateToChange = useCallback(
    (date: Date | Date[]) => {
      if (typeof date === 'string') {
        filterUpdated({
          ...currentFilter,
          dateTo: date,
        });
      }
    },
    [filterUpdated, currentFilter],
  );

  const handleReset = useCallback(() => {
    resetAllFilters();
    if (shouldRenderMobileFilter) {
      closeMobFilter();
    }
  }, [resetAllFilters, shouldRenderMobileFilter, closeMobFilter]);

  const renderFilterComponent = () => {
    return (
      <AnalysesFilter
        isFilterActive={isFilterActive}
        handleCategoryChange={handleCategoryChange}
        handleDateFromChange={handleDateFromChange}
        handleDateToChange={handleDateToChange}
        handleReset={handleReset}
      />
    );
  };

  return analyzesListData.length > 0 ? (
    <div className="analyses-list-page">
      {shouldRenderMobileFilter && (
        <>
          <div className="analyses-list-page__mob-filter-line">
            <button
              onClick={openMobFilter}
              className={`analyses-list-page__mob-filter-btn ${
                isFilterActive ? '_active' : ''
              }`}>
              <Svg
                name="filter"
                modClass="analyses-list-page__mob-filter-icon"
              />
              <span>Фильтры</span>
            </button>
          </div>

          <Modal
            isOpen={isMobFilterVisible}
            onClose={closeMobFilter}
            className="analyses-list-page__mob-filter">
            {renderFilterComponent()}
            <Button
              title="Применить фильтры"
              className="analyses-list-page__filter-btn-apply"
              onClick={closeMobFilter}
            />
          </Modal>
        </>
      )}

      <div className="analyses-list-page__block block">
        {!shouldRenderMobileFilter && renderFilterComponent()}

        {!isFilterActive && (
          <AnalysesList
            data={analyzesListData}
            onDownloadFile={onDownloadFile}
          />
        )}
        {isFilterActive && (
          <AnalysesListFiltered
            data={filteredListData}
            onDownloadFile={onDownloadFile}
          />
        )}
      </div>
    </div>
  ) : (
    <Plug label="Здесь будет отображаться список загруженных анализов" />
  );
};
