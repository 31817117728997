import classnames from 'classnames';
import React from 'react';

import './burger.scss';
import { Svg } from './svg';

interface BurgerProps {
  onClick?: () => void;
  modClass?: string;
  svgModClass?: string;
}

export const Burger = ({ svgModClass, modClass, onClick }: BurgerProps) => {
  const burgerClasses = classnames('burger', modClass);

  return (
    <button className={burgerClasses} onClick={onClick}>
      <Svg name="hamburger" modClass={svgModClass} />
      <span className="burger__text">Меню</span>
    </button>
  );
};
