import React from 'react';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { CircleGraph } from '@features/health/ui/health-detail/metric-chart/';
import {
  getHealthIndexData,
  calcValueForProgressBar,
} from '@teledoctor/common/dist/features/metrics/model/health-index';
import {
  HealthIndexScale,
  HealthIndexDates,
} from '@features/health/ui/metrics/health-index';
import { NoMeasuresPlainText } from '@features/health/ui/health-detail/metric-chart';
import {
  HEALTH_INDEX_UNDER_GRAPH_TEXT,
  HEALTH_INDEX_NO_METRICS,
} from '@teledoctor/common/dist/lib/text-constants';
import './page.scss';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/health-index',
    breadcrumb: 'Индекс здоровья',
  },
];

export const HealthIndexPage = () => {
  const { index, indexAvailable, summaryData, metricsDates } =
    getHealthIndexData();

  const isDataNotEmpty = (summaryData) => {
    return (
      summaryData &&
      summaryData.constructor === Object &&
      Object.keys(summaryData).length > 0
    );
  };

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      {isDataNotEmpty(summaryData) ? (
        <div className="metric-chart health-index">
          <div className="block metric-chart__block health-index__chart-block">
            <div className="health-index__graph">
              <div className="health-index__graph-inner">
                <CircleGraph
                  indexData={indexAvailable ? index : 0}
                  calcValueForProgressBar={calcValueForProgressBar}
                />
                <HealthIndexScale
                  indexData={index}
                  disabled={!indexAvailable}
                />
              </div>
            </div>
            {indexAvailable && (
              <div className="health-index__description">
                {HEALTH_INDEX_UNDER_GRAPH_TEXT}
              </div>
            )}
          </div>
          {indexAvailable ? (
            <div className="metric-chart__info">
              <HealthIndexDates data={metricsDates} />
            </div>
          ) : (
            <NoMeasuresPlainText
              noMeasureText={HEALTH_INDEX_NO_METRICS}
              canBeMeasured={true}
            />
          )}
        </div>
      ) : null}
    </>
  );
};
