import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
} from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import classnames from 'classnames';

import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';

interface InputProps {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  defaultValue?: string | number | readonly string[] | undefined;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const inputClassnames = classnames('input-text', className);

    return <input {...props} ref={ref} className={inputClassnames} />;
  },
);

export interface FieldInputProps<T extends FieldValues>
  extends FieldProps<T>,
    Omit<InputProps, 'defaultValue'> {
  onFieldChange?: (value: string | null) => void;
}

export function FieldInput<T extends FieldValues>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  onFocus,
  disabled = false,
  readOnly = false,
  onFieldChange,
  ...inputProps
}: FieldInputProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, name, value, ref } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputProps['data-cy']}>
            <Input
              {...inputProps}
              value={value}
              // defaultValue={value}
              disabled={disabled}
              onFocus={disabled ? undefined : onFocus}
              readOnly={readOnly}
              ref={ref}
              className={inputClassName}
              onChange={(e) => {
                const value = e.target.value;
                onFieldChange?.(value);
                onChange(value);
              }}
              onBlur={onBlur}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
