import React from 'react';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';
import { useEcgData } from './use-ecg-data';

import './ecg-modal.scss';

export const EcgModal = () => {
  const { ecgTable, ecg, result } = useEcgData();

  return (
    <div className="ecg-modal">
      <div className="result">
        <div className="result__graph" />
        <div className="result__data">
          <p className="result__data-label">Результат</p>
          <p className="result__data-value">{result?.title}</p>
        </div>
        <div className="result__data">
          <p className="result__data-label">Дата и время</p>
          <p className="result__data-value">
            {ecg && formatTime(new Date(ecg.datetime), 'dd.MM.yyyy HH:mm')}
          </p>
        </div>
      </div>
      <div className="result-table">
        {ecgTable.slice(1).map((item, index) => {
          return (
            <div key={index} className="result-table__item">
              <p className="result-table__item-title">{item.name}</p>
              {['min', 'medium', 'max'].map((key, index) => (
                <div key={index} className="item-row">
                  <p className="item-row__label">{ecgTable[0][key]}</p>
                  <p className="item-row__value">{item[key]}</p>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {result?.diseases.length ? (
        <div className="ecg-diseas">
          <p className="ecg-diseas__title">{result.title}</p>
          <p className="ecg-diseas__text">{result.diseases}</p>
        </div>
      ) : null}
    </div>
  );
};
