import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from '../button';
import './image-modal.scss';
import { closeMessageModal } from '../../organisms/modal/model';

interface ImageWrapper {
  width: string;
}

interface Props {
  src: string;
  showButtons?: boolean;
}

export const ImageModal: React.FC<Props> = ({ src, showButtons = false }) => {
  const [imageWrapperSize, setImageWrapperSize] = useState<
    ImageWrapper | undefined
  >(); // задает обертке картинки ширину оригинальной картинки
  const [imageLoading, setImageLoading] = useState(true);

  const imageModalCloseHandler = () => {
    setImageWrapperSize(undefined);
    setImageLoading(true);
    closeMessageModal();
  };

  const imageLoadHandler = (event) => {
    const image = event.target;
    const width = image.width;
    if (width) {
      setImageWrapperSize({
        width: `${width}px`,
      });
      setImageLoading(false);
    }
  };

  const imagePictureBlockClasses = classnames('image-modal__picture-block', {
    'image-modal__picture-block--loading': imageLoading,
  });
  return (
    <>
      <div className={imagePictureBlockClasses} style={imageWrapperSize}>
        <img
          src={src}
          alt=""
          className="image-modal__picture"
          onLoad={(event) => imageLoadHandler(event)}
        />
      </div>
      {showButtons && (
        <div className="image-modal__buttons">
          <a
            href={src}
            download
            className="image-modal__button button button__mediumslateblue button__short"
            data-cy="IMAGE_MODAL_DOWNLOAD_LINK">
            Скачать
          </a>
          <Button
            title="Закрыть"
            short={true}
            appearance="outline"
            type="button"
            className="image-modal__button"
            onClick={imageModalCloseHandler}
            data-cy="IMAGE_MODAL_CLOSE_BTN"
          />
        </div>
      )}
    </>
  );
};
