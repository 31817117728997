import { withPersist } from '@lib/with-persist';
import { clientMetrics } from '@teledoctor/common/dist/features/metrics';

/**
 * Stores
 */

export const $localModuleManagerCodes =
  clientMetrics.summary.$moduleManagerCodes.map((state) => state);

withPersist(clientMetrics.summary.$moduleManagerCodes, {
  key: 'module-manager-items',
});
