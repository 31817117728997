import React from 'react';
import { AppLinks } from '../atoms';
import './footer.scss';
import { FooterCopyright } from '../atoms/footer-copyright';

export const Footer = () => {
  return (
    <div className="footer">
      <div className={'footer__links'}>
        <AppLinks />
      </div>
      <div className="footer__footnote-full">
        <FooterCopyright />
      </div>
    </div>
  );
};
