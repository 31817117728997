import { Member } from '@eus/react-client/src/types/cards';
import React, { useMemo } from 'react';
import style from './user.module.scss';
import { Avatar } from '@ui';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { DeleteUserModal, UserModal } from '../../../../modals';
import { getUserFields, getUserFio } from '../helpers';

interface Props {
  member: Member;
  cardClientId: number;
  cardNumber: string;
  tariff: string;
}

export const CardUser = ({
  member,
  cardClientId,
  cardNumber,
  tariff,
}: Props) => {
  const {
    value: isVisibleDelete,
    setTruthy: openDelete,
    setFalsy: closeDelete,
  } = useBoolean(false);

  const {
    value: isVisibleUser,
    setTruthy: openUser,
    setFalsy: closeUser,
  } = useBoolean(false);

  const {
    last_name,
    first_name,
    middle_name,
    id,
    sex,
    avatar_file_id,
    avatar_thumb,
    avatar_path,
  } = member;

  const onDeleteHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    openDelete();
  };

  const avatarPath = (avatar_file_id && (avatar_thumb || avatar_path)) || '';

  const fio = useMemo(
    () => getUserFio(last_name, first_name, middle_name ?? undefined),
    [last_name, first_name, middle_name],
  );

  const fields = useMemo(() => {
    return getUserFields({
      cardNumber,
      phone: member.phone,
      tariff,
      ...(member.date_of_birth && { date_of_birth: member.date_of_birth }),
      email: member.email,
    });
  }, [member, cardNumber, tariff]);

  return (
    <>
      <div className={style.item} key={id} onClick={openUser}>
        <Avatar
          sex={sex ? 'М' : 'Ж'}
          url={avatarPath}
          className={style.avatar}
        />
        <div className={style.info}>
          <div className={style.name}>{fio}</div>
        </div>
        <div className={style.delete} onClick={onDeleteHandler} />
      </div>
      <DeleteUserModal
        cardClientId={cardClientId}
        id={id}
        handleCloseModal={closeDelete}
        visible={isVisibleDelete}
      />
      <UserModal
        avatarUri={avatarPath}
        closeUser={closeUser}
        fio={fio}
        sex={sex}
        visibleUser={isVisibleUser}
        cardClientId={cardClientId}
        id={id}
        fields={fields}
      />
    </>
  );
};
