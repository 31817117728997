import { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import ReactPaginate from 'react-paginate';
import { useHistory, useRouteMatch } from 'react-router-dom';
import '@styles/components/pagination.scss';
import nextLabel from '@assets/img/src_img_static_nav-end.png';
import prevLabel from '@assets/img/src_img_static_nav-start.png';
import { Button, PaginationMobile } from '../../atoms';
import { useQuery } from '@router';
import { push } from '@features/app/navigation.model';

const ITEM_CLASS = 'pagination__item';
const LINK_CLASS = 'pagination__link';

const linkClassnames = classnames(ITEM_CLASS, LINK_CLASS);
const activeLinkClassnames = classnames(
  ITEM_CLASS,
  LINK_CLASS,
  `${LINK_CLASS}-active`,
);
const goClassnames = classnames(ITEM_CLASS, `${ITEM_CLASS}-fastjump`);
const previousLinkClassnames = classnames(goClassnames, `${ITEM_CLASS}-start`);
const nextLinkClassnames = classnames(goClassnames, `${ITEM_CLASS}-end`);

interface Props {
  perPage: number;
  total: number;
  onChange: ({ begining, end }: { begining: number; end: number }) => void;
  onShowMore?: () => void;
  showMoreBtn: boolean;
  isPagerMobile?: boolean;
}

export const Pagination = ({
  total,
  perPage,
  onChange,
  onShowMore,
  showMoreBtn,
  isPagerMobile = false,
}: Props) => {
  const query = useQuery();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [showMorePages, setShowMorePages] = useState(0);
  const params = query.toString();
  const paramsPath = params ? `?${params}` : '?';
  const fullPath = `${path}/${paramsPath}`;
  const page = Number(query.get('page') || '1');
  const pageIndex = page - 1;
  const pageCount = Math.ceil(total / perPage);
  const lastPage = page === pageCount;
  const withShowMore = onShowMore && !lastPage;

  useEffect(() => {
    const offset = pageIndex * perPage;
    onChange({
      begining: offset,
      end: offset + perPage + showMorePages * perPage,
    });
  }, [onChange, pageIndex, perPage, showMorePages]);

  useEffect(() => {
    if (pageCount !== 0 && page > pageCount) {
      push({
        path: `${fullPath}&page=${page - 1}`,
      });
    }
  }, [pageCount, page, path, fullPath]);

  useEffect(() => {
    const historySubscription = history.listen((_, action) => {
      if (action === 'POP') {
        setShowMorePages(0);
      }
    });
    return historySubscription;
  }, [history]);

  const handlePageClick = ({ selected }) => {
    setShowMorePages(0);
    query.delete('page');
    const params = query.toString();
    const paramsPath = params ? `?${params}` : '?';
    const fullPath = `${path}${paramsPath}`;
    push({
      path: `${fullPath}&page=${selected + 1}`,
    });
  };

  const handleShowMoreClick = useCallback(() => {
    setShowMorePages((current) => current + 1);
    onShowMore?.();
  }, [onShowMore]);

  const ShowMoreButton = useMemo(() => {
    return (
      withShowMore &&
      showMoreBtn && (
        <Button
          title="Показать еще"
          onClick={handleShowMoreClick}
          className="-mb__xs-xxtiny"
          data-cy="PAGINATION_SHOW_MORE_BTN"
        />
      )
    );
  }, [handleShowMoreClick, withShowMore, showMoreBtn]);

  const handlePrevClick = () => {
    if (page > 1) {
      query.delete('page');
      const params = query.toString();
      const paramsPath = params ? `?${params}` : '?';
      const fullPath = `${path}${paramsPath}`;
      push({
        path: `${fullPath}&page=${page - 1}`,
      });
    }
  };

  const handleNextClick = () => {
    if (page < total) {
      query.delete('page');
      const params = query.toString();
      const paramsPath = params ? `?${params}` : '?';
      const fullPath = `${path}${paramsPath}`;
      push({
        path: `${fullPath}&page=${page + 1}`,
      });
    }
  };
  if (total <= perPage) {
    return null;
  }

  const paginationClasses = classnames(
    'paginated-list__pager -d__flex -align__center -column__xs',
    withShowMore && showMoreBtn
      ? '-justify__between'
      : !showMoreBtn
      ? '-justify__center'
      : '-justify__end',
  );

  if (total <= perPage) {
    return null;
  }

  return (
    <div className={paginationClasses}>
      {ShowMoreButton}
      {!isPagerMobile && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          hrefBuilder={(current: number) => `${fullPath}&page=${current}`}
          containerClassName="pagination"
          activeLinkClassName={activeLinkClassnames}
          pageLinkClassName={linkClassnames}
          previousLinkClassName={previousLinkClassnames}
          nextLinkClassName={nextLinkClassnames}
          nextLabel={<img src={nextLabel} width="11" height="16" />}
          previousLabel={<img src={prevLabel} width="11" height="16" />}
          forcePage={pageIndex}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
        />
      )}
      {isPagerMobile && (
        <PaginationMobile
          currentPage={page}
          total={total}
          isLastPage={lastPage}
          handleNextClick={handleNextClick}
          handlePrevClick={handlePrevClick}
        />
      )}
    </div>
  );
};
