import React from 'react';
import { Modal, ModalProps, Notifications } from '@ui';
import lock from '@assets/img/lock.png';
import { SuccessEditAlert } from '../../../modals/success-edit-alert';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import {
  useEditFieldViaCode,
  UseEditFieldViaCodeParams,
} from './use-edit-field-via-code';
import { CodeForm } from '../../code';
import styles from './styles.module.scss';
import { UseFormSetError } from 'react-hook-form';

interface Props
  extends Omit<ModalProps, 'header'>,
    Omit<UseEditFieldViaCodeParams, 'onSuccessUpdateField'> {
  isOpen: boolean;
  header: (isOpen: boolean, value?: string | null) => React.ReactNode;
  children: (renderProps: {
    onRequestCode: (
      value: string,
      setError: UseFormSetError<any>,
    ) => Promise<void>;
  }) => React.ReactNode;
  renderCodeDescription?: (value: string) => React.ReactNode;
  codeLabel?: string;
  codePlaceholder?: string;
}

export const UpdateFieldViaCodeModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    children,
    requestCodeFromUpdateField,
    updateField,
    onSuccessRequestCode,
    renderCodeDescription,
    image,
    headerClassNames,
    contentClassNames,
    imageClassNames,
    popupClassNames,
    header,
    codeLabel,
    codePlaceholder,
  } = props;

  const {
    value: isSuccessVisible,
    setTruthy: openSuccessAlert,
    setFalsy: closeSuccessAlert,
  } = useBoolean(false);

  const { handleRequestCode, setValue, value, onSuccessCodeForm, requestCode } =
    useEditFieldViaCode({
      onSuccessUpdateField: openSuccessAlert,
      onSuccessRequestCode,
      requestCodeFromUpdateField,
      updateField,
    });

  return (
    <>
      <Modal
        isOpen={isOpen || !!value}
        onClose={isOpen ? onClose : () => setValue(null)}
        image={isOpen ? image : value ? lock : undefined}
        headerClassNames={isOpen ? headerClassNames : styles.header}
        contentClassNames={isOpen ? contentClassNames : styles.content}
        imageClassNames={isOpen ? imageClassNames : styles.image}
        popupClassNames={isOpen ? popupClassNames : styles.modal}
        root={<Notifications target="change-form-field" />}
        header={header(isOpen, value)}>
        {isOpen && !value
          ? children({
              onRequestCode: handleRequestCode,
            })
          : !!value && (
              <>
                <div className={styles['code-description']}>
                  {renderCodeDescription?.(value)}
                </div>
                <CodeForm
                  sourceCode={value}
                  onSuccessForm={onSuccessCodeForm}
                  className={styles['code-form']}
                  inputCodeClassName={styles['input-code']}
                  sendButtonClassName={styles['send-code-button']}
                  label={codeLabel}
                  placeholder={codePlaceholder}
                  requestCodeAgain={requestCode}
                />
              </>
            )}
      </Modal>
      <SuccessEditAlert isOpen={isSuccessVisible} onClose={closeSuccessAlert} />
    </>
  );
};

export type { UpdateFieldValues } from './use-edit-field-via-code';
