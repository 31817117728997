import React, { useMemo } from 'react';
import {
  TitleLine,
  Breadcrumbs,
  BreadcrumbsItem,
  getTabsClassnames,
  Explanation,
  Svg,
} from '@ui';
import { push } from '@features/app/navigation.model';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useQuery } from '@router';
import { useRouteMatch, Redirect } from 'react-router';
import { MetricWrapper } from '@features/health/ui/health-detail/';

import { AnalysesListPage } from '@features/health/ui/metrics/analyses/';
import { AnalysesDecryptionPage } from '@features/health/ui/metrics/analyses-decryption/';
import { CHECKUP_INTERPRETATION_DISCLAIMER } from '@teledoctor/common/dist/lib/text-constants';

const tabNames = ['loaded', 'decoding'];

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/analyses',
    breadcrumb: 'Мои анализы',
  },
];

export const AnalysesPage = () => {
  const query = useQuery();
  const { path } = useRouteMatch();

  const tabsClasses = getTabsClassnames(['page--main-template']);
  const tabType = query.get('type') || '';

  const selectedTab = useMemo(() => {
    const index = tabNames.indexOf(tabType);

    return index > -1 ? index : 0;
  }, [tabType]);

  const handleTabChange = (index: number): void => {
    push({
      path: `${path}?type=${tabNames[index]}`,
    });
  };

  if (!tabType) {
    return <Redirect to={`${path}?type=all`} />;
  }

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MetricWrapper bottomText="Чтобы загрузить анализ, создайте новый запрос в мобильном приложении">
        <Tabs
          className={tabsClasses.tabs}
          selectedIndex={selectedTab}
          onSelect={handleTabChange}>
          <TabList className={tabsClasses.tabList}>
            <Tab className="react-tabs__tab react-tabs__tab--long">
              Загруженные
            </Tab>
            <Tab className="react-tabs__tab react-tabs__tab--long">
              Расшифровка анализа
            </Tab>
          </TabList>
          <TabPanel className={tabsClasses.panel}>
            <AnalysesListPage />
          </TabPanel>
          <TabPanel className={tabsClasses.panel}>
            <AnalysesDecryptionPage />
          </TabPanel>
        </Tabs>
      </MetricWrapper>
    </>
  );
};
