import React from 'react';
import { Plug, Pagination } from '@ui';
import { Analyze } from '@eus/health-default-logic/dist/analyze/types';
import { AnalysisBox } from './analysis-box';
import './analyses-list.scss';

interface Props {
  data: Analyze[];
  onDownloadFile: (id) => void;
}

export const AnalysesListFiltered = ({ data, onDownloadFile }: Props) => {
  return data.length ? (
    <div className="analyses-list">
      <ul className="analyses-list__list">
        {data.map((item) => (
          <li className="analyses-list__item" key={item.id}>
            <AnalysisBox analysis={item} onDownloadFile={onDownloadFile} />
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Plug label="По выбранным параметрам анализов не&nbsp;найдено." />
  );
};
