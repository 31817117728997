import React from 'react';
import { DataTable } from '../../health-detail/metric-viewer';
import { Explanation, Svg, ImgBtnModal, Plug } from '@ui';
import doctorOk from '@assets/img/doctor-ok.svg';
import { useXRayData } from './use-xray-data';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  CHECKUP_WAIT_PROCESSING,
} from '@teledoctor/common/dist/lib/text-constants';

export const XRayDetail = () => {
  const {
    tableData,
    xRay,
    withDeviations,
    deviationsData,
    withoutDeviations,
    isError,
    recommendationTitle,
    isInProcessing,
    errorMessagesHtml,
  } = useXRayData();

  return xRay ? (
    <>
      <div className="block metric-viewer__detail-block">
        <DataTable data={tableData} />
      </div>

      <div className="block metric-viewer__detail-block -grow__1">
        {withoutDeviations && (
          <img
            className="metric-viewer__detail-image-ok"
            src={doctorOk}
            alt="Всё в порядке!"
            data-cy="XRAY_RESULT_NO_PROBLEMS_IMAGE"
          />
        )}
        {recommendationTitle && (
          <div className="metric-viewer__detail-title">
            <span>{recommendationTitle}</span>
            {withDeviations && (
              <Explanation
                modClass="metric-viewer__detail-tooltip"
                tooltip={CHECKUP_INTERPRETATION_DISCLAIMER}
                symbol={
                  <Svg
                    name="info"
                    modClass="metric-viewer__detail-tooltip-icon"
                  />
                }
              />
            )}
          </div>
        )}
        {isError && (
          <p
            className="metric-viewer__detail-description"
            dangerouslySetInnerHTML={{ __html: errorMessagesHtml }}
          />
        )}
        {isInProcessing && (
          <p className="metric-viewer__detail-description metric-viewer__detail-description--empty">
            {CHECKUP_WAIT_PROCESSING}
          </p>
        )}
        {withDeviations && deviationsData.length && (
          <div className="metric-viewer__detail-scrollable">
            <DataTable data={deviationsData} />
          </div>
        )}
        {!isInProcessing && xRay?.image && (
          <ImgBtnModal
            src={xRay.image}
            modClass="metric-viewer__detail-pic-btn"
          />
        )}
      </div>
    </>
  ) : (
    <Plug label="Запись не найдена" />
  );
};
