import React, { useEffect, useState } from 'react';
import { Svg } from '@ui';
import classnames from 'classnames';
import { ChatMarkModal } from '@features/chat/ui/chat-feedback/modals/chat-mark-modal';
import './set-rating.scss';
import { useTasksTdLk } from '@features/api';

interface SetRating {
  id: number;
  rating?: number | null;
}

const maxRating = 5;

export const SetRating: React.FC<SetRating> = ({ rating, id }) => {
  const [currentMark, setCurrentMark] = useState<number>(rating as number);
  const [markModal, setMarkModal] = useState<boolean>(false);

  const { setRating } = useTasksTdLk();

  useEffect(() => {
    setCurrentMark(rating as number);
  }, [rating]);

  const closeMarkModalHandler = () => {
    setCurrentMark(rating as number);
    setMarkModal(false);
  };
  const showMarkModalHandler = (newMark) => {
    setCurrentMark(newMark);
    setMarkModal(true);
  };
  const setMarkHandler = () => {
    const rating = {
      taskId: id,
      rating: currentMark,
    };
    setMarkModal(false);
    setRating(rating);
  };

  const markListClasses = classnames('consultation-end__mark-list', {
    'consultation-end__mark-list-no-mark': currentMark === 0,
  });

  return (
    <React.Fragment>
      <div className={markListClasses}>
        {Array.from(Array(maxRating).keys()).map((index) => {
          let itemClasses = classnames(
            'favorite',
            'consultation-end__mark-item',
            {
              'consultation-end__mark-item-checked': index + 1 === currentMark,
            },
          );
          return (
            <div
              key={index + 1}
              className={itemClasses}
              onClick={(e) => showMarkModalHandler(index + 1)}
              data-cy={`CONSULT_SET_RATING_ITEM_${index + 1}`}>
              <Svg name="heart" modClass="consultation-end__mark-icon" />
            </div>
          );
        })}
      </div>
      <ChatMarkModal
        onClose={closeMarkModalHandler}
        isOpen={markModal}
        setMarkHandler={setMarkHandler}
      />
    </React.Fragment>
  );
};
