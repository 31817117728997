import { createEvent, createStore } from 'effector';
import { ModalProps } from '../../atoms';

export interface MessageModalInterface
  extends Omit<ModalProps, 'onClose' | 'isOpen'> {
  onClose?: () => void;
  component: JSX.Element;
}

export const showMessageModal =
  createEvent<MessageModalInterface>('message-modal:show');
export const closeMessageModal = createEvent('message-modal:close');

export const $messageModal = createStore<MessageModalInterface | null>(null);

$messageModal
  .on(showMessageModal, (store, modal) => modal)
  .on(closeMessageModal, (store) => null);
