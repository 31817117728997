import React, { useState } from 'react';
import { Svg } from './svg';
import classnames from 'classnames';
import AudioPlayer from '@teledoctor/common/dist/features/audio-player/audio-player';
import {
  AudioFileInfo,
  AddObjectFileInterface,
} from '@teledoctor/common/dist/features/audio-player/types';
import './audio-player.scss';

export const AddFileAudio: React.FC<AddObjectFileInterface> = ({ file }) => {
  const fileClasses = classnames('file', file.modClass);
  const [audioPlayingStatus, setAudioPlayingStatus] = useState<boolean>(false);
  const [audioFileInfo, setAudioFileInfo] = useState<AudioFileInfo | null>(
    null,
  );
  const buttonPlayHandler = () => setAudioPlayingStatus(!audioPlayingStatus);

  return (
    <div className={fileClasses}>
      <div
        onClick={buttonPlayHandler}
        className="file__audio-play-btn"
        data-cy="AUDIO_PLAY_BTN">
        <Svg
          name={audioPlayingStatus ? 'pause' : 'play'}
          modClass="file__icon audio-player__icon"
        />
      </div>
      <div className="file__info">
        <AudioPlayer
          name={'Аудиозапись'}
          setAudioInfo={setAudioFileInfo}
          playingStatus={audioPlayingStatus}
          url={file.src ?? ''}
          autoplay={false}
          onAudioEnd={() => setAudioPlayingStatus(false)}
        />

        <div className="file__detail">
          {audioFileInfo && audioFileInfo.duration + ' мин'}
        </div>
      </div>
    </div>
  );
};
