import { AddressPickerInput } from '@features/shared/ui/address-input';
import { Controller } from 'react-hook-form';
import { FieldWrapper } from '../../../molecules';

export function FieldAddress<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  onFieldChange,
  disabled,
  placeholder,
  defaultValue,
  dataCy = 'field_address',
}: any) {
  return (
    <Controller
      name={name}
      control={methods.control}
      // @ts-ignore
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}>
            <AddressPickerInput
              placeholder={placeholder}
              methods={methods}
              defaultAddressValue={defaultValue}
              value={value}
              dataCy={dataCy}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
