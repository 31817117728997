import React, { useEffect } from 'react';
import { MetricViewer } from '@features/health/ui/health-detail/';
import {
  AnalysisDecryptionDetail,
  AnalysisDecryptionModal,
} from '@features/health/ui/metrics/analyses-decryption';
import { useStore } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import {
  getTitleByStatus,
  getSvgForAnalysisDecryptionStatus,
} from '@teledoctor/common/dist/features/metrics/model/analyze-decryption';
import { useQuery } from '@router';

export const AnalysesDecryptionPage = () => {
  const query = useQuery();

  const {
    metric: { $analyzeDecryptionCrud, loadAnalyzeDecryptionList },
  } = useMetric({ metric: 'analyzeDecryption' });

  const analyzesDecryptionCrud = useStore($analyzeDecryptionCrud);
  const analyzesDecryptionList = analyzesDecryptionCrud.list;
  const decryptionListWasUpdated = !!analyzesDecryptionCrud.lastUpdated;

  useEffect(() => {
    loadAnalyzeDecryptionList({
      uiBehavior: {
        noLoader: decryptionListWasUpdated,
      },
    });
  }, [decryptionListWasUpdated, loadAnalyzeDecryptionList]);

  const decryptionListData = analyzesDecryptionList.map((item) => {
    let fileNameArray = item.nameFile.split('.');
    let fileExtension =
      fileNameArray.length > 1 ? `.${fileNameArray.pop()}` : '';
    let name = `${item.customName}${fileExtension}`;
    let date = new Date(item.createdAt).getTime();
    let status = getTitleByStatus(item.status);
    let modClass = `data-list__item-container--decryption`;
    return {
      id: item.id,
      name: name,
      timestamp: date,
      status: status,
      statusSvg: getSvgForAnalysisDecryptionStatus(item.status),
      modClass: modClass,
    };
  });

  const decryptionId = query.get('id') || '';

  return (
    <MetricViewer
      data={decryptionListData}
      elementId={decryptionId}
      emptyDataText={
        'Воспользуйтесь мобильным приложением Теледоктор24, чтобы загружать и просматривать Ваши файлы с анализами'
      }
      chooseElementText={'Выберите анализ из списка слева'}
      rightColumnRender={() => <AnalysisDecryptionDetail />}
      modalRender={() => <AnalysisDecryptionModal />}
    />
  );
};
