import React from 'react';
import classnames from 'classnames';

import './button.scss';

export interface ButtonProps {
  id?: string;
  title?: string;
  appearance?: 'solid' | 'outline' | 'transparent' | 'textual';
  danger?: boolean;
  short?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  noUppercase?: boolean;
  'data-cy'?: string;
  fullWidth?: boolean;
  form?: string;
}

export const Button: React.FC<ButtonProps> = ({
  id = '',
  title = 'Button',
  children,
  appearance = 'solid',
  danger = false,
  short = false,
  type,
  className,
  onClick,
  disabled,
  noUppercase = false,
  fullWidth = false,
  ...props
}) => {
  const buttonClass = classnames(
    'button',
    {
      button__primary: appearance === 'solid' && !danger,
      button__crimson: appearance === 'solid' && danger,
      button__transparent: appearance === 'transparent',
      button__textual: appearance === 'textual',
      'button__mediumslateblue-outline button__outline':
        appearance === 'outline' && !danger,
      button__short: short,
      'button--is-disabled': disabled,
      'button--no-uppercase': noUppercase,
      'button--full-width': fullWidth,
    },
    className,
  );

  return (
    <button
      {...(id ? { id } : '')}
      onClick={onClick}
      type={type}
      className={buttonClass}
      disabled={disabled}
      {...props}>
      {title}
      {children}
    </button>
  );
};
