import { Avatar, Button, Modal } from '@ui';
import React from 'react';
import { DeleteUserModal } from '@features/card/ui/modals/delete-user';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { Sex } from '@eus/react-client/src/types/user';
import style from './user-info.module.scss';

interface UserModalProps {
  visibleUser: boolean;
  closeUser: () => void;
  avatarUri: string;
  sex: Sex;
  fio: string;
  id: number;
  cardClientId: number;
  fields: { label: string; value: string }[];
}

export const UserModal = ({
  visibleUser,
  closeUser,
  avatarUri,
  sex,
  fio,
  id,
  cardClientId,
  fields,
}: UserModalProps) => {
  const {
    value: isVisibleDelete,
    setTruthy: openDelete,
    setFalsy: closeDelete,
  } = useBoolean(false);

  return (
    <React.Fragment>
      <Modal
        header="Пользователь"
        isOpen={visibleUser}
        onClose={closeUser}
        footer={
          <Button
            title="Удалить пользователя"
            className="width__100"
            onClick={() => {
              closeUser();
              openDelete();
            }}
            data-cy="CARD_INFO_USER_MODAL_DELETE_BTN"
          />
        }>
        <div className={style.info}>
          <div className={style.left}>
            <div className={style['image__block']}>
              <Avatar
                sex={sex ? 'М' : 'Ж'}
                url={avatarUri}
                className={style.image}
              />
            </div>
          </div>
          <div className={style.right}>
            <div className={style.name}>{fio}</div>
            <div className="info__list">
              {fields
                .filter(({ value }) => Boolean(value))
                .map((item) => (
                  <div className="info__item" key={item.label}>
                    <div className="info__label">{item.label}</div>
                    <div className="info__value">{item.value}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <DeleteUserModal
        id={id}
        cardClientId={cardClientId}
        visible={isVisibleDelete}
        handleCloseModal={closeDelete}
      />
    </React.Fragment>
  );
};
