import { HandbookQA } from '../../types';

import { HandbookItem } from './item';

interface HandbookListProps {
  data: HandbookQA[];
}

export const HandbookList = ({ data }: HandbookListProps) => {
  return (
    <div className="accordion">
      {data.map((item, index) => {
        return <HandbookItem item={item} key={index} />;
      })}
    </div>
  );
};
