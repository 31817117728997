import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import './consultation-info.scss';
import { Favorite } from '@features/shared/ui/favorite';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import { Task } from '@eus/react-client/src/tasks/types';
import { TYPES_ALL } from '@eus/els-api-types/src/task-answer-type';
import { STATUSES_ALL } from '@eus/els-api-types/src/task-status-cp';
import { useCardsTdLk } from '@features/api';
import { Card } from '@eus/react-client/src/cards/types';
import { formatCardNumber } from './helper';

enum ConsultationInfoLabels {
  service = 'Услуга',
  description = 'Вопрос',
  answer_way = 'Способ ответа',
  card_id = 'Номер карты',
  created_at = 'Дата создания',
  status = 'Статус',
}

interface ConsultationInfo {
  onToggleFavorite?: (isFavorite: boolean) => void;
  task: Task;
  taskType: 'consultation' | 'question' | 'support';
  renderActionBtns?: () => ReactElement;
}

export const ConsultationInfo: React.FC<ConsultationInfo> = ({
  onToggleFavorite,
  task,
  taskType,
  renderActionBtns,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [card, setCard] = useState<Card>();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    id,
    service_name,
    description,
    answer_type,
    card_client_id,
    created_at,
    task_status_id,
    is_favorite,
  } = task;
  const isSupport = taskType === 'support';
  const isQuestion = taskType === 'question';

  const { loadCard } = useCardsTdLk();

  useEffect(() => {
    if (card_client_id && !card) {
      loadCard(card_client_id).then(setCard);
    }
  }, [card_client_id, loadCard, card]);

  const answerType = TYPES_ALL[answer_type!];
  const statusName = STATUSES_ALL[task_status_id];

  useEffect(() => {
    if (containerRef.current) {
      if (isOpen) {
        const containerPosition = blockPositionCalc(containerRef.current).top;
        const scrollHeight = documentHeightCalc();
        containerRef.current.style.height = `${
          scrollHeight - containerPosition
        }px`;
      } else {
        containerRef.current.style.height = '0px';
      }
    }
  }, [isOpen]);

  const classNames = classnames('consultation-info__block', {
    'toggle-active': isOpen,
  });

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="block consultation-info">
      <div className="consultation-info__title">{service_name}</div>
      <div className={classNames} ref={containerRef}>
        <div className="consultation-info__list">
          {service_name && !isQuestion && (
            <div className="consultation-info__item">
              <div className="label">{ConsultationInfoLabels.service}</div>
              <div className="consultation-info__value">{service_name}</div>
            </div>
          )}

          {description && !isQuestion && (
            <div className="consultation-info__item">
              <div className="label">{ConsultationInfoLabels.description}</div>
              <div className="consultation-info__value">{description}</div>
            </div>
          )}

          {answerType && (
            <div
              className={`consultation-info__item${
                isQuestion ? ' consultation-info__item_question-answer-way' : ''
              }`}>
              <div className="label">{ConsultationInfoLabels.answer_way}</div>
              <div className="consultation-info__value">{answerType}</div>
            </div>
          )}

          {!isSupport && (
            <div
              className={`consultation-info__item${
                isQuestion ? ' consultation-info__item_question-number' : ''
              }`}>
              <div className="label">{ConsultationInfoLabels.card_id}</div>
              <div className="consultation-info__value">
                {!card?.number
                  ? ''
                  : isQuestion
                  ? formatCardNumber(card?.number)
                  : card?.number}
                {!isQuestion && card?.tariff_name
                  ? `, ${card?.tariff_name}`
                  : ''}
              </div>
            </div>
          )}

          {created_at && (
            <div className="consultation-info__item">
              <div className="label">{ConsultationInfoLabels.created_at}</div>
              <div className="consultation-info__value">
                {formatDateShort(new Date(created_at * 1000))}
              </div>
            </div>
          )}

          {statusName && (
            <div className="consultation-info__item">
              <div className="label">{ConsultationInfoLabels.status}</div>
              <div className="consultation-info__value">{statusName}</div>
            </div>
          )}
        </div>

        {renderActionBtns?.()}
      </div>

      {!isSupport && !isQuestion && !!onToggleFavorite && (
        <Favorite
          isFavorite={is_favorite}
          onToggle={onToggleFavorite}
          className="consultation-info__chosen"
          id={id}
        />
      )}

      <div
        className="consultation-info__toggle"
        onClick={handleOpen}
        data-cy="CONSULT_TOGGLE_MORE_INFO"
      />
    </div>
  );
};

function blockPositionCalc(container) {
  let box = container.getBoundingClientRect();

  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset,
  };
}

function documentHeightCalc() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
}
