import { IS_DEV } from '@constants';
import { useAuthTdLk, useProfileTdLk } from '@features/api/hooks';
import { useAppBehavior } from '@features/app-behavior/use-app-behavior';
import { AuthNewPage, DevUIPage, NotFoundPage } from '@pages';
import { AuthTemplate, Loader } from '@ui';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Content } from './stacks';
import { useCallback, useEffect, useState } from 'react';
import { RegisterDetailPage } from '@features/user/ui/auth/register/detail';

export const Routes = () => {
  const [isProfileLoading, setProfileLoadingStatus] = useState<boolean>(true);

  const { authLoading, token } = useAuthTdLk();
  useAppBehavior();
  const { profile, load } = useProfileTdLk();

  useEffect(() => {
    if (!token && !authLoading) {
      localStorage.removeItem('isProfileCorrect');
    }
  }, [token, authLoading]);

  useEffect(() => {
    if (!profile && !!token && !localStorage.getItem('isProfileCorrect')) {
      setProfileLoadingStatus(true);
      load().then(() => setProfileLoadingStatus(false));
    } else {
      setProfileLoadingStatus(false);
    }
  }, [load, profile, token]);

  const checkLogin = useCallback(() => {
    if (!localStorage.getItem('isProfileCorrect')) {
      const isCorrect =
        !!profile?.first_name &&
        profile?.first_name !== '-' &&
        !!profile?.last_name &&
        profile?.last_name !== '-' &&
        !!profile?.country_code;

      if (isCorrect) {
        localStorage.setItem('isProfileCorrect', 'done');
      }

      return isCorrect;
    } else {
      return !!localStorage.getItem('isProfileCorrect');
    }
  }, [profile]);

  const getRoutes = useCallback(() => {
    return (
      <Switch>
        {!token && <Route path="/auth" component={AuthNewPage} />}
        <Redirect
          path="/"
          exact
          to={
            token && checkLogin()
              ? { pathname: '/lk', search: `${window.location.search}` }
              : '/auth'
          }
        />
        {!!token && !checkLogin() ? (
          <Switch>
            <Redirect path="/lk" to="/auth" />
            <Route path="/auth" component={RegisterDetailPage} />
          </Switch>
        ) : (
          <Switch>
            <Redirect path="/auth" exact to="/lk" />
            <Route path="/lk" component={Content} />
          </Switch>
        )}

        {IS_DEV && <Route path="/dev" component={DevUIPage} />}

        <Route path="*" component={NotFoundPage} />
      </Switch>
    );
  }, [checkLogin, token]);

  return authLoading || isProfileLoading ? (
    <AuthTemplate>
      <Loader isVisible />
    </AuthTemplate>
  ) : (
    getRoutes()
  );
};
