import { useHistory } from '@eus/react-web-client/src/hooks/useHistory';
import { useLoadList } from '@eus/react-web-client/src/hooks/useLoadList';

import {
  usePageSelector,
  useTasksListLoadingSelector,
  useTasksListTotalSelector,
} from '@eus/react-client/src/tasks/selectors';
import { useQuestionsTdLk, useTasksTdLk } from '@features/api';
import { push } from '@features/app/navigation.model';
import { $asList, changeMode } from '@features/consultations/model';
import { GoToQuestionsBtn, ListModeToggle } from '@features/consultations/ui';
import { ListConsultation } from '@features/consultations/ui/list';
import { useParamsForSearchTask } from '@features/task/ui/list/hooks';
import { PER_PAGE } from '@features/task/ui/list/list';
import '@styles/components/pagination.scss';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';
import { Button, getTabsClassnames, Search } from '@ui';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './styles.scss';
import { Banners } from '@features/banners';

const getTab = (filter) => {
  return filter?.is_active ? 2 : filter?.is_favorite ? 1 : 0;
};

export const ConsultationsPage = () => {
  const [hasQuestionWithNonRead, setHasQuestionWithNonRead] =
    useState<boolean>(false);
  const asList = useStore($asList);
  const history = useHistory();

  const { loadPerPage, page, filterLoaded, searchHandle } =
    useParamsForSearchTask('tasks');

  const {
    card: defaultCard,
    loading: loadingCards,
    total: totalCard,
  } = useDefaultCard();

  const [selectedTab, setSelectedTab] = useState<number>(getTab(filterLoaded));

  const { loadTasksList } = useTasksTdLk();

  const { curPage, perPage } = useLoadList({
    loadList: loadTasksList,
    defaultPagination: {
      page: 1,
      perPage: PER_PAGE,
    },
  });

  const { loadTasksList: loadQuestionsList, state } = useQuestionsTdLk();

  const tasks = usePageSelector(curPage, perPage);

  const total = useTasksListTotalSelector();
  const loading = useTasksListLoadingSelector();

  const tabsClasses = getTabsClassnames([
    'page--main-template',
    'consultations',
  ]);

  const handleTabChange = (index: number): void => {
    history.push(
      `tasks?filter={${
        index === 1 ? '"is_favorite":1' : index === 2 ? '"is_active":1' : ''
      }}&order=DESC&sort=id&page=1&perPage=${loadPerPage}`,
    );
    setSelectedTab(index);
  };

  const handleGetConsultation = useCallback(() => {
    if (!defaultCard) {
      push({ path: 'cards' });
    } else {
      push({
        path: 'tasks/get',
        state: { cardClientId: defaultCard.card_client_id },
      });
    }
  }, [defaultCard]);

  const clickGoToQuestions = useCallback(() => {
    push({
      path: 'tasks/questions',
    });
  }, []);

  useEffect(() => {
    loadQuestionsList({
      sort: {
        field: 'new_messages_client',
        order: 'DESC',
      },
    });
  }, [loadQuestionsList]);

  useEffect(() => {
    if (
      state?.tasks.entities &&
      state.tasks.entities.filter((task) => task?.new_messages_in_chat).length >
        0
    ) {
      setHasQuestionWithNonRead(true);
    }
  }, [state]);

  useEffect(() => {
    const reload = () => {
      loadQuestionsList({
        sort: {
          field: 'new_messages_client',
          order: 'DESC',
        },
      });
    };

    const timerID = setInterval(() => reload(), 30000);
    return () => clearInterval(timerID);
  }, [loadQuestionsList]);

  return (
    <React.Fragment>
      <Banners />
      <div className="consultations__header consultations-header">
        <div className="consultations-header__inner">
          {/* <div className="consultations-header__item consultations-header__item--search">
            <Search
              onChange={searchHandle}
              className="consultations-header__search"
            />
          </div> */}
          <div className="consultations-header__item consultations-header__item--title">
            <h1 className="h1 titleline__title" data-cy="TITLE_H1">
              Консультации
            </h1>
          </div>
          <div className="consultations-header__item consultations-header__item--btn">
            <GoToQuestionsBtn
              clickGoToQuestions={clickGoToQuestions}
              hasQuestionWithNonRead={hasQuestionWithNonRead}
            />
            <Button
              title="Получить консультацию"
              className="consultations-get button__mediumslateblue titleline__button"
              onClick={handleGetConsultation}
              disabled={loadingCards}
              data-cy="GET_CONSULT_BTN_OVER_LIST"
            />
          </div>
        </div>
      </div>
      <Tabs
        className={tabsClasses.tabs}
        selectedIndex={selectedTab}
        onSelect={handleTabChange}>
        <TabList className={tabsClasses.tabList}>
          <Tab className={tabsClasses.tab} data-cy="TAB_CONSULT_ALL">
            Все
          </Tab>
          <Tab className={tabsClasses.tab} data-cy="TAB_CONSULT_FAVORITE">
            Избранные
          </Tab>
          <Tab className={tabsClasses.tab} data-cy="TAB_CONSULT_ACTIVE">
            Активные
          </Tab>
        </TabList>
        <TabPanel className={tabsClasses.panel} data-cy="TABPANEL_CONSULT_ALL">
          <ListConsultation
            showAsList={asList}
            filter={'all'}
            page={page}
            tasks={tasks}
            total={total}
            loading={loading}
          />
        </TabPanel>
        <TabPanel
          className={tabsClasses.panel}
          data-cy="TABPANEL_CONSULT_FAVORITE">
          <ListConsultation
            showAsList={asList}
            filter={'all'}
            page={page}
            tasks={tasks}
            total={total}
            loading={loading}
          />
        </TabPanel>
        <TabPanel
          className={tabsClasses.panel}
          data-cy="TABPANEL_CONSULT_ACTIVE">
          <ListConsultation
            showAsList={asList}
            filter={'all'}
            page={page}
            tasks={tasks}
            total={total}
            loading={loading}
          />
        </TabPanel>
        <ListModeToggle
          isList={asList}
          onChange={changeMode}
          className="consultations__list-mode-toggle"
        />
      </Tabs>
    </React.Fragment>
  );
};
