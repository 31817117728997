import { Button, Svg } from '@ui';
import React, { FC } from 'react';
import './go-to-questions-btn.scss';

interface GoToQuestionsBtnPros {
  clickGoToQuestions: () => void;
  hasQuestionWithNonRead: boolean;
}

export const GoToQuestionsBtn: FC<GoToQuestionsBtnPros> = React.memo(
  (props) => {
    const { clickGoToQuestions, hasQuestionWithNonRead } = props;
    return (
      <Button
        title={''}
        className={'questions-go-btn button__mediumslateblue titleline__button'}
        onClick={clickGoToQuestions}
        data-cy="go_to_questions_list"
        appearance={'outline'}>
        <span className="questions-go-btn__text">Вопросы по услугам</span>
        <Svg
          name="questions"
          modClass={hasQuestionWithNonRead ? 'svg-questions-with-label' : ''}
        />
      </Button>
    );
  },
);
