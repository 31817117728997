import React from 'react';
import { ModulesManagerItem } from '@features/health/ui';
import { MetricsWebInterface } from '@features/health/models/metrics/summary-modules';
import { Metrics as MetricType } from '@eus/health-default-logic/dist/modules/constants';
import { List, arrayMove } from 'react-movable';

import './modules-manager-list.scss';

interface ModulesManagerListInterface {
  modules: MetricsWebInterface[];
  onChangeItemHandler: (id: MetricType) => void;
  onChangeListHandler: (changedList: MetricsWebInterface[]) => void;
}

export const ModulesManagerList: React.FC<ModulesManagerListInterface> = ({
  modules,
  onChangeItemHandler,
  onChangeListHandler,
}) => {
  const onChangeItem = (changedId: MetricType) => {
    onChangeItemHandler(changedId);
  };
  return (
    <List
      lockVertically
      values={modules}
      onChange={({ oldIndex, newIndex }) => {
        onChangeListHandler(arrayMove(modules, oldIndex, newIndex));
        return;
      }}
      renderList={({ children, props }) => (
        <div className="modules-manager__list" {...props}>
          {children}
        </div>
      )}
      renderItem={({ value, props }) => (
        <ModulesManagerItem
          onChangeModule={onChangeItem}
          module={value}
          {...props}
        />
      )}
    />
  );
};
