import React, { useMemo } from 'react';
import {
  TitleLine,
  Breadcrumbs,
  BreadcrumbsItem,
  getTabsClassnames,
} from '@ui';
import { push } from '@features/app/navigation.model';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useQuery } from '@router';
import { useRouteMatch } from 'react-router';
import {
  MetricChart,
  MetricInfo,
  MetricChartCommonProps,
} from '@features/health/ui/health-detail/';
import Table, {
  TableProps,
} from '@teledoctor/common/dist/metrics/components/oxygen/table';
import Values from '@teledoctor/common/dist/metrics/components/oxygen/values';
import { CommonValuesProps } from '@teledoctor/common/dist/metrics/type';
import { OXYGEN_MODAL_INFO } from '@teledoctor/common/dist/lib/text-constants';

const tabNames = ['day', 'week', 'month'];

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/oxygen',
    breadcrumb: 'SpO2',
  },
];

export const OxygenPage = () => {
  const query = useQuery();
  const { path } = useRouteMatch();

  const tabsClasses = getTabsClassnames(['page--main-template']);
  const tabType = query.get('type') || '';

  const selectedTab = useMemo(() => {
    const index = tabNames.indexOf(tabType);

    return index > -1 ? index : 0;
  }, [tabType]);

  const handleTabChange = (index: number): void => {
    push({
      path: `${path}?type=${tabNames[index]}`,
    });
  };

  const props: MetricChartCommonProps<TableProps, CommonValuesProps> = {
    metric: 'oxygen',
    range: [80, 100],
    tickCount: 11,
    shape: 'blob',
    renderMetricTable: (props) => <Table {...props} />,
    renderMetricValues: (props) => <Values {...props} />,
  };

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <Tabs
        className={tabsClasses.tabs}
        selectedIndex={selectedTab}
        onSelect={handleTabChange}>
        <TabList className={tabsClasses.tabList}>
          <Tab className={tabsClasses.tab}>День</Tab>
          <Tab className={tabsClasses.tab}>Неделя</Tab>
          <Tab className={tabsClasses.tab}>Месяц</Tab>
        </TabList>
        <TabPanel className={tabsClasses.panel}>
          <MetricChart period="day" {...props} />
        </TabPanel>
        <TabPanel className={tabsClasses.panel}>
          <MetricChart period="week" {...props} />
        </TabPanel>
        <TabPanel className={tabsClasses.panel}>
          <MetricChart period="month" {...props} />
        </TabPanel>
        <MetricInfo modalProps={{ header: 'Что такое SpO2' }}>
          <>
            {OXYGEN_MODAL_INFO.map((paragraph, index) => (
              <p className="modal__content-p" key={index}>
                {paragraph}
              </p>
            ))}
          </>
        </MetricInfo>
      </Tabs>
    </>
  );
};
