import classnames from 'classnames';

import { InfoList, Modal } from '@ui';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';

// import { Card } from '@teledoctor/common/dist/features/cards';
// import { Card } from '@teledoctor/common/dist/features/cards';
import { CardLogo } from '@features/card/ui/card-logo';
import { InfoListGroups } from '../../../../../ui/atoms/info-list';
import { Card } from '@eus/react-client/src/cards/types';
import { getStatusIdText } from '@eus/react-client/src/cards/helpers';

interface SelectCardModelProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (id: number) => void;
  data: Card[];
  value?: number;
}

export const SelectCardModal = ({
  isOpen,
  onClose,
  onSelect,
  data,
  value,
}: SelectCardModelProps) => {
  return (
    <Modal
      header="Выбрать карту"
      onClose={onClose}
      className="select-card-modal"
      isOpen={isOpen && Boolean(value)}>
      <SelectCardList data={data} onSelect={onSelect} selected={value} />
    </Modal>
  );
};

interface SelectCardListProps {
  data: Card[];
  selected?: number;
  onSelect: (id: number) => void;
}

const SelectCardList = ({ data, selected, onSelect }: SelectCardListProps) => {
  return (
    <div className="select-card__list" data-cy="GET_CONSULT_SELECT_CARD_LIST">
      {data.map((card) => {
        const selectCardItemClasses = classnames('select-card__item', {
          'select-card__item-selected': selected === card.card_client_id,
        });

        const info = [
          {
            label: 'Тариф',
            value: card.tariff_name,
          },
          {
            label: 'Номер карты',
            value: card.number,
          },
          {
            label: 'Статус',
            value: getStatusIdText(card.card_status_id) ?? '',
          },
          {
            label: 'Действует до',
            value: formatDateShort(new Date(card.expiration_date * 1000)),
          },
        ];

        const groups: InfoListGroups[] = [
          {
            label: '',
            props: info,
          },
        ];

        return (
          <div
            key={card.card_client_id}
            className={selectCardItemClasses}
            onClick={() => {
              onSelect(card.card_client_id);
            }}
            data-cy={`GET_CONSULT_SELECT_CARD_VARIANT_${card.card_client_id}`}>
            <div className="select-card__card">
              <CardLogo url={card.logo} />
            </div>
            <div className="select-card__info">
              <InfoList groups={groups} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
