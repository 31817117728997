export enum Links {
  'PRESSURE' = 'pressure-dynamic',
  'ACCURATE_PRESSURE' = 'pressure-static',
  'PULSE' = 'pulse',
  'OXYGEN' = 'oxygen',
  'TEMPERATURE' = 'temperature',
  'DIET' = 'diet',
  'WEIGHT' = 'weight',
  'ECG' = 'ecg',
  'XRAY' = 'xray',
  'BIRTHMARK' = 'birthmark',
  'INDEX' = 'health-index',
  'X_RAY' = 'xray',
  'ANALYZES' = 'analyses',
  'COUGH' = 'cough',
  'MEDICINES' = 'medications',
}
