import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import './styles.scss';
import { Breadcrumbs, BreadcrumbsItem, InfoList, TitleLine } from '@ui';
import { AddUserForm } from '@features/card/ui';

import { push } from '@features/app/navigation.model';

import { InfoListGroups } from '../../ui/atoms/info-list';
import { useCardsTdLk, useProfileTdLk } from '../../features/api';
import { useCardsListSelector } from '@eus/react-client';
import { CardLogo } from '@features/card/ui/card-logo';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import { STATUSES_ALL } from '@eus/els-api-types/src/card-status';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/cards/',
    breadcrumb: 'Карты',
  },
  {
    path: '/lk/cards/:cardId/add-user',
    breadcrumb: ' Добавить пользователя',
  },
];

export const AddUserPage = () => {
  const { cardClientId } = useParams<{ cardClientId: string }>() || {};

  const cardClientIdNumber = Number(cardClientId);
  const { profile } = useProfileTdLk();
  const { addMember } = useCardsTdLk();
  const cards = useCardsListSelector();
  const currentCard = cards?.find(
    (card) => card?.card_client_id === cardClientIdNumber,
  );
  const currentInfo = useMemo(() => {
    if (currentCard) {
      return [
        {
          label: 'Тариф',
          value: currentCard.tariff_name,
        },
        {
          label: 'Номер карты',
          value: currentCard.number.toString(),
        },
        {
          label: 'Статус',
          value: STATUSES_ALL[currentCard.card_status_id] ?? '',
        },
        {
          label: 'Действует до',
          value: formatDateShort(new Date(currentCard.expiration_date * 1000)),
        },
      ];
    }
    return [];
  }, [currentCard]);

  const currentInfoGroup: InfoListGroups[] = [
    {
      label: '',
      props: currentInfo,
    },
  ];

  const addMemberRequest = useCallback(
    (values) => {
      return addMember(
        values,
        new FeedParams({
          needShowLoader: true,
          throwNext: true,
          ignoreError: true,
        }),
      ).then((newUser) => {
        push({
          path: '/lk/cards',
        });
      });
    },
    [addMember],
  );

  return (
    <React.Fragment>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>

      <div className="add-user">
        <div className="add-user__inner">
          <div className="add-user__card">
            <CardLogo url={currentCard?.logo} />
            <div className="add-user__info">
              <InfoList groups={currentInfoGroup} />
            </div>
          </div>
          <div className="add-user__form">
            <AddUserForm
              onSuccess={addMemberRequest}
              defaultValues={{
                cardClientId: Number(cardClientId),
                account_phone_reference: profile?.phone,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
