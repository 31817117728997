import classNames from 'classnames';
import './toggler.scss';

interface Props {
  onLeft?: () => void;
  onRight?: () => void;
  text: string;
}

export const Toggler = (props: Props) => {
  return (
    <div className="metric-chart__toggler">
      <div
        className={classNames(
          'metric-chart__toggler-button metric-chart__toggler-left',
          {
            'metric-chart__toggler-disabled': !props.onLeft,
          },
        )}
        onClick={props.onLeft}
      />
      <div className="metric-chart__toggler-text">{props.text}</div>
      <div
        className={classNames(
          'metric-chart__toggler-button metric-chart__toggler-right',
          {
            'metric-chart__toggler-disabled': !props.onRight,
          },
        )}
        onClick={props.onRight}
      />
    </div>
  );
};
