import { useCallback, useContext } from 'react';
import { useAuthTdLk } from '@features/api';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { addNotification, Notification } from '@ui';
import { WelcomeModalContext } from '@features/user/ui';
import { CheckFormCode, ServerErrorsHandler } from '@eus/react-client';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { OnSuccessForm } from '@teledoctor/common/dist/hooks';
import { UseFormSetError } from 'react-hook-form';

const handleSuccessCodeError = (setError: UseFormSetError<CheckFormCode>) =>
  formServerErrorHandler({
    commonErrorCase: {
      commonErrorCallback: (errorText) =>
        addNotification({
          id: 'auth' + Date.now(),
          type: 'error',
          message: errorText,
          target: 'global',
        }),
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler(setError),
    },
  });

const successCodeFeedParams = new FeedParams({
  needShowLoader: true,
  ignoreError: false,
  throwNext: true,
});

const handleRequestCodeError = (error: any) => {
  const notificationParams: Omit<Notification, 'message'> = {
    id: `auth-notification-${Date.now()}`,
    target: 'authorization',
    type: 'error',
  };

  if (Array.isArray(error)) {
    error.forEach((err) =>
      addNotification({
        ...notificationParams,
        message: err,
      }),
    );
  } else {
    addNotification({ ...notificationParams, message: error });
  }
};

const handleCheckIdentifyError = () => {
  addNotification({
    target: 'authorization',
    id: `auth-notification-${Date.now()}`,
    type: 'error',
    message: 'Пользователь с таким номером не найден. Зарегистрируйтесь',
  });
};

export interface Params {
  phone: string;
}

export const useAuthorization = (params: Params) => {
  const { phone } = params;

  const welcome = useContext(WelcomeModalContext);

  const { checkIdentify, requestCodeTd, authByCode } = useAuthTdLk();

  const requestCodeAuthHandler = useCallback(
    async (login: string) => {
      const requestCode = async (login: string) => {
        try {
          await requestCodeTd(login);
        } catch (error) {
          handleRequestCodeError(error);
        }
      };

      try {
        const response = await checkIdentify(
          { login },
          new FeedParams({ needShowLoader: true }),
        );

        if (!response) {
          return;
        }

        if (!response.exist) {
          handleCheckIdentifyError();
          return;
        }

        await requestCode(login);
      } catch (error) {
        console.error('Ошибка при обработке запроса:', error);
      }
    },
    [checkIdentify, requestCodeTd],
  );

  const onSuccessCode: OnSuccessForm<CheckFormCode> = useCallback(
    async ({ code }, setError) => {
      try {
        await authByCode({ code, phone }, setError, successCodeFeedParams);

        if (welcome) {
          welcome.showModal('auth');
        }
      } catch (error) {
        handleSuccessCodeError(setError)(error);
      }
    },
    [authByCode, welcome, phone],
  );

  return {
    requestCodeAuthHandler,
    onSuccessCode,
  };
};
