import './medicine-item.scss';
import { Medicine } from '@eus/health-default-logic/dist/medicine/types';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import addDays from 'date-fns/esm/addDays';

export interface MedicineWithColor extends Medicine {
  colorClassName: string;
}
interface Props {
  medicine: MedicineWithColor;
}

export const MedicineItem = ({ medicine }: Props) => {
  const endDate = addDays(medicine.beginDate, medicine.duration - 1);
  const datesText = `${formatDateShort(
    medicine.beginDate,
    'dd.MM',
  )}-${formatDateShort(endDate, 'dd.MM')}`;

  return (
    <div className="medicine-item">
      <div className="medicine-item__title">
        <span className={`medicine-item__dot ${medicine.colorClassName}`} />
        <span className="medicine-item__name">{medicine.name}</span>
      </div>
      <div className="medicine-item__info">
        <div className="medicine-item__dates">{datesText}</div>
        <div className="medicine-item__dose">
          {' '}
          {`${medicine.portionSize.toString()} ${medicine.portionType}`}
        </div>
        <div className="medicine-item__takings">{`${medicine.takings.length} р./день`}</div>
      </div>
    </div>
  );
};
