import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Button, addNotification } from '@ui';
import {
  isServiceUnavailable,
  isServiceOfferedByPhone,
} from '@teledoctor/common/dist/features/consultations';

import {
  Card,
  Service as ServiceType,
} from '@eus/react-client/src/cards/types';
import { getServiceCount } from '@eus/react-client/src/cards/helpers';
import { useCallback, useState } from 'react';
import { push } from '@features/app/navigation.model';
import style from './card-info-service-item.module.scss';
import classNames from 'classnames';

interface CardInfoServicesItemProps {
  service: ServiceType;
  cardClientId: Card['card_client_id'];
  withLimit?: boolean;
}

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const CardInfoServicesItem = ({
  service,
  cardClientId,
  withLimit = true,
}: CardInfoServicesItemProps) => {
  const [isShowExpandButton, setExpandButtonStatus] = useState<boolean>(false);
  const [isFullInformation, setInformationStatus] = useState<boolean>(false);

  const onSelectService = useCallback(() => {
    if (isServiceUnavailable(service)) {
      addNotification({
        message: service.not_serviced_text
          ? service.not_serviced_text
          : 'Извините, услуга недоступна',
        id: 'no-service' + new Date().getTime(),
        type: 'error',
        target: 'global',
      });
      window.scroll({ top: 0, behavior: 'smooth' });
    } else if (isServiceOfferedByPhone(service.id)) {
      addNotification({
        message:
          'Для получения услуги обратитесь по бесплатному номеру телефона 5070',
        id: 'no-service-please-call' + new Date().getTime(),
        type: 'error',
        target: 'global',
      });
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      push({
        path: 'tasks/get',
        state: { cardClientId: cardClientId, serviceId: service.id },
      });
    }
  }, [service, cardClientId]);

  const handleReflow = (rleState) => {
    const { clamped, text } = rleState;
    setExpandButtonStatus(clamped);
  };

  const ButtonComponent = () => (
    <Button
      className={classNames(
        'button button__primary button__short -width__100',
        style.button,
      )}
      title={'Получить'}
      onClick={onSelectService}
      data-cy={`CARD_INFO_GET_SERVICE_BTN_${service.id}`}
    />
  );

  return (
    <div className={style.item}>
      <div
        className={classNames(
          style.wrapper,
          !isFullInformation && style['wrapper__with-margin'],
        )}>
        <div className={style.header}>
          <div className={style.name}>{service.name}</div>
        </div>
        {(!!service.description || withLimit) && (
          <div className={style.text}>
            <div className={style.info}>
              {withLimit && (
                <div className={style.limit}>{getServiceCount(service)}</div>
              )}
              {!!service.description && (
                <>
                  <div
                    className={classNames(
                      style.description,
                      withLimit && style['description__with-margin'],
                    )}>
                    {isFullInformation ? (
                      service.description
                    ) : (
                      <ResponsiveEllipsis
                        text={service.description}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                        onReflow={handleReflow}
                      />
                    )}
                  </div>
                  {isShowExpandButton && (
                    <div
                      className={style.expand}
                      onClick={() => setInformationStatus((prev) => !prev)}>
                      {isFullInformation ? 'Свернуть' : 'Показать больше'}
                    </div>
                  )}
                </>
              )}
            </div>
            {isFullInformation && <ButtonComponent />}
          </div>
        )}
      </div>
      {!isFullInformation && <ButtonComponent />}
    </div>
  );
};
