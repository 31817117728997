import React from 'react';

export const HeartShape = (props) => {
  const width = 15;
  const heigth = 13;
  return props.cx && props.cy ? (
    <svg
      x={props.cx - Math.floor(width / 2)}
      y={props.cy - Math.floor(heigth / 2)}
      width={width}
      height={heigth}
      viewBox={`0 0 ${width} ${heigth}`}
      style={{ overflow: 'visible' }}
      className="chart__shape-fill">
      <path d="M13.81 1.27222C13.0336 0.451832 11.9681 0 10.8097 0C9.94389 0 9.15092 0.266662 8.45284 0.792517C8.10059 1.05795 7.78141 1.3827 7.5 1.76173C7.2187 1.38281 6.89941 1.05795 6.54705 0.792517C5.84908 0.266662 5.05611 0 4.19025 0C3.03188 0 1.96632 0.451832 1.18984 1.27222C0.42263 2.08302 0 3.19069 0 4.39134C0 5.62711 0.472755 6.7583 1.48773 7.95137C2.39571 9.01857 3.70068 10.1019 5.21187 11.3564C5.72788 11.7849 6.31279 12.2705 6.92013 12.7877C7.08057 12.9246 7.28645 13 7.5 13C7.71343 13 7.91943 12.9246 8.07964 12.788C8.68698 12.2706 9.27223 11.7847 9.78848 11.3561C11.2994 10.1018 12.6044 9.01857 13.5124 7.95126C14.5274 6.7583 15 5.62711 15 4.39123C15 3.19069 14.5774 2.08302 13.81 1.27222Z" />
    </svg>
  ) : null;
};
