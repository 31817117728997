import { Task } from '@eus/react-client/src/types/tasks';
import { useChatTdLk, useTasksTdLk } from '@features/api';
import { HOST_CRM_API } from '@teledoctor/common/dist/config/api';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { useState } from 'react';
import { getTimestampWithoutTimezone } from '@teledoctor/common/dist/lib/formatters/format-date';

export type ScheduleDateTime = {
  date: Date;
  time: {
    enabled: boolean;
    id: number;
    name: string;
    night: boolean;
  };
};

export interface UseConsultationTimeReturn {
  baseURL: string;
  datesResource: string;
  timeResource: string;
  onSelect: (val) => Promise<any>;
  emptyText: string;
}

export const useConsultationTime = (taskId: string) => {
  const { disableMessagesAtAdditionalPayloadType } = useChatTdLk();
  const { setDatetime } = useTasksTdLk();
  const [isLoading, setIsLoading] = useState(false);

  return {
    baseURL: HOST_CRM_API,
    datesResource: `tasks/${taskId}/available-dates`,
    timeResource: `tasks/${taskId}/available-slots`,
    onSelect: (val: ScheduleDateTime) => {
      const clearedTimezoneTimestamp = getTimestampWithoutTimezone(val.date);

      setIsLoading(true);

      return setDatetime(
        {
          taskId: Number(taskId),
          slotId: val.time.id,
          consultationDate: clearedTimezoneTimestamp / 1000,
        },
        new FeedParams({ throwNext: true, needShowLoader: true }),
      )
        .then(() => {
          disableMessagesAtAdditionalPayloadType(taskId, 'select_timeslot');
        })
        .catch((err) => {
          console.log('err :>> ', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    emptyText: 'Доступных интервалов нет, выберите другую дату.',
    isLoading,
  };
};
