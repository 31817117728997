import React, { useEffect, useState } from 'react';
import { Button, FileInput, FileInputInterface, FormFilesList } from '@ui';
import { FileFetch } from '@teledoctor/common/dist/lib/request';
import { uniqByKey } from '@teledoctor/common/dist/lib/unique-by-key';
import { dataURItoBlob } from '@lib/data-uri-to-blob';
import { FileGroups } from '@teledoctor/common/dist/features/audio-player/types';

export interface AttachFileProps {
  value: FileFetch[] | FileInputInterface[];
  onChange: (files: FileFetch[] | FileInputInterface[]) => void;
  preProcessFiles?: boolean;
  defaultValue?: FileInputInterface[];
  maxFileSize?: number;
  fileType?: FileGroups[];
  maxFiles?: number;
}

export const AttachFile = ({
  value,
  onChange,
  preProcessFiles = true,
  defaultValue,
  fileType,
  maxFileSize,
  maxFiles,
}: AttachFileProps) => {
  const [files, setFiles] = useState<FileInputInterface[]>(defaultValue || []);

  useEffect(() => {
    if (value.length !== files.length) {
      setFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fileInputChange = (newFiles: FileInputInterface[]) => {
    const allFiles = uniqByKey([...files, ...newFiles], 'id');

    setFiles(allFiles);
    preProcessFiles
      ? onChange?.(mapToFileFetch(allFiles))
      : onChange?.(allFiles);
  };

  const deleteFileHandler = (id: number) => {
    const restFiles = files.filter((file) => file.id !== id);
    setFiles(restFiles);
    preProcessFiles
      ? onChange?.(mapToFileFetch(restFiles))
      : onChange?.(restFiles);
  };

  return (
    <React.Fragment>
      <FormFilesList files={files} onDelete={deleteFileHandler} />
      <FileInput
        multiple={true}
        onChange={fileInputChange}
        maxFileSize={maxFileSize}
        maxFiles={maxFiles}
        fileType={fileType}>
        <Button
          type="button"
          title="Прикрепить файл"
          appearance="outline"
          short={true}
          data-cy="ATTACH_FILE_BTN"
        />
      </FileInput>
    </React.Fragment>
  );
};

const mapToFileFetch = (files: FileInputInterface[]) => {
  const mappedFiles = files.map((file) => {
    return {
      uri: dataURItoBlob(file.src) as any,
      name: file.name,
      type: file.type,
    };
  });

  return mappedFiles;
};
