import { useAuthTdLk } from '@features/api';
import {
  formServerErrorHandler,
  getErrorRequestCode,
} from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { addNotification, Notification } from '../organisms';
import { LoginTypes } from '@features/user/ui/';

interface Props {
  setAuthType: Dispatch<SetStateAction<null | AuthTypes>>;
  setLoginType: Dispatch<SetStateAction<null | LoginTypes>>;
  setCheckingStatus: Dispatch<SetStateAction<boolean>>;
  beforeCheckingHandler?: (login: string) => void;
}

export const useCheckIdentifyAuthType = ({
  setAuthType,
  setLoginType,
  setCheckingStatus,
  beforeCheckingHandler,
}: Props) => {
  const { checkIdentify: checkIdentifyBase, requestCodeTd } = useAuthTdLk();

  const checkIdentify = useCallback(
    async (login: string, onExist?: () => Promise<void>) => {
      beforeCheckingHandler?.(login);
      try {
        const { exist } = await checkIdentifyBase(
          { login },
          new FeedParams({ needShowLoader: true }),
        );

        if (exist) {
          await onExist?.();
        } else {
          setAuthType('register');
        }
      } catch (error) {
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText) =>
              addNotification({
                id: 'checkIdentify' + Date.now(),
                type: 'error',
                message: errorText,
                target: 'authorization',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: () => {},
          },
        })(error);
      } finally {
        setCheckingStatus(true);
      }
    },
    [checkIdentifyBase, setAuthType, setCheckingStatus, beforeCheckingHandler],
  );

  const handleCheckIdentify = useCallback(
    async ({ phone }: { phone: string }, loginType: 'code' | 'password') => {
      const requestCode = async () => {
        try {
          const { exist } = await requestCodeTd(
            phone,
            new FeedParams({
              throwNext: true,
              needShowLoader: true,
            }),
          );

          if (exist) {
            setLoginType(loginType);
            setAuthType('auth');
          } else {
            setAuthType('register');
          }
        } catch (error) {
          // const { errorText, errorArrayText, secondsUntilRepeat } =
          //   getErrorRequestCode(error);
          const notificationParams: Omit<Notification, 'message'> = {
            id: `auth-notification-${Date.now()}`,
            target: 'authorization',
            type: 'error',
          };

          Array.isArray(error)
            ? error.map((error) =>
                addNotification({
                  ...notificationParams,
                  message: error,
                }),
              )
            : addNotification({
                ...notificationParams,
                message: error,
              });
        }
      };

      await checkIdentify(phone, async () => {
        if (loginType === 'password') {
          setAuthType('auth');
          setLoginType(loginType);
          setCheckingStatus(true);
        } else {
          await requestCode();
        }
      });
    },
    [
      checkIdentify,
      setAuthType,
      setCheckingStatus,
      setLoginType,
      requestCodeTd,
    ],
  );

  return {
    handleCheckIdentify,
    checkIdentify,
  };
};
