import { makeRangeMapper } from '@teledoctor/common/dist/lib/range-mapper';

export const WeightMeter = ({ bmi }) => {
  const BMImin = 16;
  const BMImax = 40;
  const rotMin = 4;
  const rotMax = 180;

  const matrixTimes = ([[a, b], [c, d]]: number[][], [x, y]: number[]) => [
    a * x + b * y,
    c * x + d * y,
  ];
  const rotateMatrix = (x: number): number[][] => [
    [Math.cos(x), -Math.sin(x)],
    [Math.sin(x), Math.cos(x)],
  ];
  const vectorAdd = ([a1, a2]: number[], [b1, b2]: number[]) => [
    a1 + b1,
    a2 + b2,
  ];

  const createArc = (
    [cx, cy]: number[],
    [rx, ry]: number[],
    [startAngle, sweepAnlge]: number[],
    rotation: number,
  ) => {
    startAngle = (startAngle / 180) * Math.PI;
    sweepAnlge = ((sweepAnlge / 180) * Math.PI) % (2 * Math.PI);
    const rotMatrix = rotateMatrix((rotation / 180) * Math.PI);
    const [sX, sY] = vectorAdd(
      matrixTimes(rotMatrix, [
        rx * Math.cos(startAngle),
        ry * Math.sin(startAngle),
      ]),
      [cx, cy],
    );
    const [endX, endY] = vectorAdd(
      matrixTimes(rotMatrix, [
        rx * Math.cos(startAngle + sweepAnlge),
        ry * Math.sin(startAngle + sweepAnlge),
      ]),
      [cx, cy],
    );
    const largeArc = sweepAnlge > Math.PI ? 1 : 0;
    const sweep = sweepAnlge > 0 ? 1 : 0;
    const path = `A ${[
      rx,
      ry,
      (rotation / (2 * Math.PI)) * 360,
      largeArc,
      sweep,
      endX,
      endY,
    ]
      .map((v) => v.toFixed(0))
      .join(' ')}`;
    return {
      path,
      startPoint: [sX, sY].map((v) => +v.toFixed(0)),
      endPoint: [endX, endY].map((v) => +v.toFixed(0)),
    };
  };

  const makeWeightRangeMapper = (BMI: number) => {
    if (BMI <= 18.5) {
      return makeRangeMapper({ x1: rotMin, x2: 60, y1: BMImin, y2: 18.49 });
    }
    if (BMI > 18.5 && BMI <= 25) {
      return makeRangeMapper({ x1: 60, x2: 120, y1: 18.5, y2: 25 });
    }
    return makeRangeMapper({ x1: 120, x2: rotMax, y1: 25, y2: BMImax });
  };

  const WeightMeterStatic = () => (
    <>
      <path
        d="M4.533 135h-1.09v-7.225l-2.185.803v-.984l3.105-1.166h.17V135zm7.43-8.537v.92h-.2c-.843.015-1.515.265-2.015.75-.5.484-.789 1.166-.867 2.045.45-.516 1.062-.774 1.84-.774.742 0 1.334.262 1.775.785.445.524.668 1.2.668 2.028 0 .879-.24 1.582-.72 2.109-.477.528-1.118.791-1.923.791-.816 0-1.478-.312-1.986-.937-.508-.629-.762-1.438-.762-2.426v-.416c0-1.57.334-2.77 1.002-3.598.672-.832 1.67-1.258 2.995-1.277h.193zm-1.424 3.844c-.371 0-.713.111-1.025.334-.313.222-.53.502-.65.838v.398c0 .703.157 1.269.474 1.699.316.43.71.645 1.184.645.488 0 .87-.18 1.148-.539.281-.36.422-.83.422-1.412 0-.586-.143-1.059-.428-1.418a1.35 1.35 0 00-1.125-.545z"
        fill="currentColor"
      />
      <path
        d="M81.486 11h-1.09V3.775l-2.185.803v-.984l3.105-1.166h.17V11zm8.35-6.305c0 .426-.113.805-.34 1.137a2.27 2.27 0 01-.908.78c.445.19.797.47 1.055.837.261.367.392.783.392 1.248 0 .739-.25 1.326-.75 1.764-.496.437-1.15.656-1.963.656-.82 0-1.478-.219-1.974-.656-.492-.441-.739-1.03-.739-1.764 0-.46.125-.877.375-1.248a2.37 2.37 0 011.05-.844 2.236 2.236 0 01-.897-.779 2.01 2.01 0 01-.328-1.13c0-.72.23-1.29.691-1.712.46-.421 1.068-.632 1.822-.632.75 0 1.356.21 1.817.632.465.422.697.993.697 1.711zm-.885 3.979c0-.477-.152-.865-.457-1.166-.3-.301-.695-.451-1.183-.451-.489 0-.881.148-1.178.445-.293.297-.44.687-.44 1.172 0 .484.143.865.428 1.142.29.278.69.416 1.201.416.508 0 .906-.138 1.196-.416.289-.28.433-.662.433-1.142zm-1.629-5.432c-.425 0-.771.133-1.037.399-.262.261-.392.619-.392 1.072 0 .433.129.785.386 1.055.262.265.61.398 1.043.398.434 0 .78-.133 1.037-.398.262-.27.393-.622.393-1.055 0-.434-.135-.787-.404-1.06-.27-.274-.612-.41-1.026-.41zm4.155 9.457l-.616-.422c.367-.511.559-1.039.575-1.582v-.978h1.06v.85c0 .394-.098.788-.293 1.183-.191.395-.434.71-.726.95zm2.777-5.976l.433-4.254h4.372V3.47h-3.452l-.257 2.326c.418-.246.892-.37 1.423-.37.778 0 1.395.259 1.852.774.457.512.686 1.205.686 2.08 0 .88-.239 1.573-.715 2.08-.473.504-1.135.756-1.987.756-.753 0-1.369-.209-1.845-.627-.477-.418-.748-.996-.815-1.734h1.026c.066.488.24.857.521 1.107.281.246.652.37 1.113.37.504 0 .899-.173 1.184-.516.289-.344.434-.819.434-1.424 0-.57-.157-1.027-.47-1.371-.308-.348-.72-.522-1.236-.522-.472 0-.843.104-1.113.31l-.287.235-.867-.222z"
        fill="currentColor"
      />
      <path
        d="M247.561 11h-5.59v-.78l2.953-3.28c.437-.497.738-.899.902-1.208.168-.312.252-.634.252-.966 0-.446-.135-.81-.404-1.096-.27-.285-.629-.428-1.078-.428-.539 0-.959.154-1.26.463-.297.305-.445.73-.445 1.277h-1.084c0-.785.252-1.42.755-1.904.508-.484 1.186-.726 2.034-.726.793 0 1.42.209 1.881.627.461.414.691.966.691 1.658 0 .84-.535 1.84-1.606 3l-2.285 2.478h4.284V11zm1.646-4.277l.434-4.254h4.371V3.47h-3.451l-.258 2.326c.418-.246.892-.37 1.424-.37.777 0 1.394.259 1.851.774.457.512.686 1.205.686 2.08 0 .88-.239 1.573-.715 2.08-.473.504-1.135.756-1.987.756-.753 0-1.369-.209-1.845-.627-.477-.418-.748-.996-.815-1.734h1.026c.066.488.24.857.521 1.107.281.246.653.37 1.113.37.504 0 .899-.173 1.184-.516.289-.344.434-.819.434-1.424 0-.57-.157-1.027-.469-1.371-.309-.348-.721-.522-1.236-.522-.473 0-.844.104-1.114.31l-.287.235-.867-.222z"
        fill="currentColor"
      />
      <path
        d="M326.547 132.135h1.183v.885h-1.183V135h-1.09v-1.98h-3.885v-.639l3.821-5.912h1.154v5.666zm-3.744 0h2.654v-4.184l-.129.235-2.525 3.949zm11.256-.774c0 1.27-.217 2.213-.651 2.83-.433.618-1.111.926-2.033.926-.91 0-1.584-.301-2.021-.902-.438-.606-.665-1.508-.68-2.707v-1.447c0-1.254.217-2.186.65-2.795.434-.61 1.114-.914 2.039-.914.918 0 1.594.294 2.028.884.433.586.656 1.493.668 2.719v1.406zm-1.084-1.482c0-.918-.129-1.586-.387-2.004-.258-.422-.666-.633-1.225-.633-.554 0-.959.209-1.213.627-.254.418-.384 1.061-.392 1.928v1.734c0 .922.133 1.604.398 2.045.27.438.676.656 1.219.656.535 0 .932-.207 1.189-.621.262-.414.399-1.066.411-1.957v-1.775z"
        fill="currentColor"
      />
      <path
        d="M93.809 18l4.157 7.53"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M242.969 18l-4.158 7.53"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M20.023 132.578l7.956.836"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M307.022 133.422l7.957-.836"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </>
  );

  const BMI = Math.min(BMImax, Math.max(BMImin, bmi));
  const getRotation = makeWeightRangeMapper(BMI);
  const rotationDeg = getRotation(BMI).toFixed(0);

  const arcLeftUp = createArc([168, 148], [134, 134], [184, 86], 0);
  const arcLeftDown = createArc([168, 148, 0], [84, 84], [270, -84], 0);

  const arcRightUp = createArc([168, 148], [134, 134], [270, 84], 0);
  const arcRightDown = createArc([168, 148, 0], [84, 84], [354, -84], 0);

  return (
    <div className="weight-detail__meter">
      <svg
        className="weight-detail__svg"
        viewBox="0 0 336 148"
        width={336}
        height={148}>
        <defs>
          <linearGradient id="grad1" x1="0" y1="1" x2="1" y2="0">
            <stop offset="0" stopColor="#F02660" stopOpacity="1" />
            <stop offset="0.5" stopColor="#DAC32F" stopOpacity="1" />
            <stop offset="1" stopColor="#30DE40" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0" stopColor="#30DE40" stopOpacity="1" />
            <stop offset="0.5" stopColor="#DAC32F" stopOpacity="1" />
            <stop offset="1" stopColor="#F02660" stopOpacity="1" />
          </linearGradient>
        </defs>
        <WeightMeterStatic />
        <g>
          <path
            fill="url(#grad1)"
            d={`M 44 148 Q 34 148 ${arcLeftUp.startPoint} ${arcLeftUp.path} L ${arcLeftDown.startPoint} ${arcLeftDown.path} Q 84 148 74 148 Z`}
          />
          <path
            fill="url(#grad2)"
            d={`M ${arcRightDown.startPoint} ${arcRightDown.path} L ${arcRightUp.startPoint} ${arcRightUp.path} Q 302 148 292 148 L 262 148 Q 252 148 ${arcRightDown.startPoint}`}
          />
        </g>
      </svg>
      <div
        className="weight-detail__meter-arrow-area"
        style={{ transform: `rotate(${rotationDeg}deg)` }}>
        <span className="weight-detail__meter-arrow" />
      </div>
      <div className="weight-detail__meter-text">
        <div className="weight-detail__meter-num">{bmi}</div>
        <div>ИМТ</div>
      </div>
    </div>
  );
};
