import React from 'react';
import './styles.scss';
import { SetRating } from '@features/chat/ui/chat-feedback/set-rating';
import { ChatThanks } from '@features/chat/ui/chat-feedback/chat-thanks';
import { ChatClaim } from '@features/chat/ui/chat-feedback/chat-claim';
import { Task as BaseTask } from '@eus/react-client/src/types/tasks';

export interface ChatFeedbackForm {
  taskId: BaseTask['id'];
}
export interface ChatFeedback extends ChatFeedbackForm {
  text: BaseTask['pretension_text'] | BaseTask['thank_text'];
}

export const ChatFeedback: React.FC<BaseTask> = ({
  id,
  rating,
  thank_text,
  pretension_text,
}) => {
  return (
    <div className="consultation-end reset-container-paddingX">
      <h1 className="h2 consultation-end__title">Консультация завершена</h1>
      <SetRating rating={rating} id={id} />
      <div className="consultation-end__detail-list">
        <div className="consultation-end__detail-item">
          <ChatThanks text={thank_text} taskId={id} />
        </div>
        <div className="consultation-end__detail-item">
          <ChatClaim text={pretension_text} taskId={id} />
        </div>
      </div>
    </div>
  );
};
