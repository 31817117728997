import React, { ReactNode } from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from '@router';
import { Modal, Plug, Svg } from '@ui';
import { useWindowSize } from '@lib/use-window-size';
import { push } from '@features/app/navigation.model';
import './metric-viewer.scss';
import { DataList, MetricViewerDataItem } from './ui/data-list/data-list';
import { useBoolean } from '@teledoctor/common/dist/hooks';

type Props = {
  data: MetricViewerDataItem[];
  elementId: number | string;
  chooseElementText: string;
  emptyDataText: string;
  rightColumnRender: () => ReactNode;
  modalRender: () => ReactNode;
};

export const MetricViewer = ({
  data,
  elementId,
  chooseElementText,
  emptyDataText,
  rightColumnRender,
  modalRender,
}: Props) => {
  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(!!elementId);

  const size = useWindowSize();

  const { path } = useRouteMatch();

  const query = useQuery();

  const onItemClick = (id) => {
    query.delete('page');
    const paramType = query.get('type');
    if (paramType) {
      query.set('type', paramType);
    }
    if (id) {
      query.set('id', id);
    }
    const fullQueryString = query.toString();
    push({
      path: `${path}?${fullQueryString}`,
    });
    open();
  };

  const onModalClose = () => {
    push({
      path: `${path}?${query.toString()}`,
    });
    close();
  };

  return data.length ? (
    <>
      <div className="metric-viewer">
        <div className="block metric-viewer__left">
          <DataList
            data={data}
            activeElementId={elementId}
            onItemClick={onItemClick}
          />
        </div>
        <div className="metric-viewer__right">
          {elementId ? (
            rightColumnRender()
          ) : (
            <p className="metric-viewer__right-empty-text">
              {chooseElementText}
            </p>
          )}
        </div>
      </div>
      <Modal
        onClose={onModalClose}
        isOpen={isVisible && size.width < 768}
        className="metric-viewer__modal-detail">
        {modalRender()}
      </Modal>
    </>
  ) : (
    <Plug label={emptyDataText} />
  );
};
