import { ImgPreview, ExplanationGray } from '@ui';
import { useXRayData } from './use-xray-data';
import { DataMob } from '../../health-detail/metric-viewer/';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  CHECKUP_WAIT_PROCESSING,
} from '@teledoctor/common/dist/lib/text-constants';

export const XRayModal = () => {
  const {
    tableData,
    xRay,
    withDeviations,
    deviationsData,
    withoutDeviations,
    isError,
    recommendationTitle,
    isInProcessing,
    errorMessagesHtml,
  } = useXRayData();

  return xRay ? (
    <>
      <DataMob data={tableData} />
      {!isInProcessing && xRay?.image && (
        <div className="metric-viewer__modal-detail-image">
          <p className="metric-viewer__modal-detail-label">
            Прикрепленный файл
          </p>
          <ImgPreview src={xRay.image} shape={'original'} />
        </div>
      )}

      <div className="metric-viewer__modal-detail-info">
        {recommendationTitle && (
          <p className="metric-viewer__modal-detail-title">
            {recommendationTitle}
          </p>
        )}
        {withDeviations && deviationsData.length && (
          <DataMob data={deviationsData} />
        )}
        {isError && (
          <p
            className="metric-viewer__modal-detail-text"
            dangerouslySetInnerHTML={{ __html: errorMessagesHtml }}
          />
        )}
        {isInProcessing && (
          <p className="metric-viewer__modal-detail-text">
            {CHECKUP_WAIT_PROCESSING}
          </p>
        )}
      </div>
      {withDeviations && (
        <ExplanationGray text={CHECKUP_INTERPRETATION_DISCLAIMER} />
      )}
    </>
  ) : (
    <div className="-font__700 -text__red -text__center">Запись не найдена</div>
  );
};
