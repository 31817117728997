import { Metrics } from '@eus/health-default-logic/dist/modules/constants';
import { IS_DEV_BUNDLE } from '@teledoctor/common/dist/config/api';

type MetricType = keyof typeof Metrics;

const hiddenMetrics: MetricType[] = [
  'INDEX',
  'PRESSURE',
  'ACCURATE_PRESSURE',
  'PULSE',
  'OXYGEN',
  'ECG',
  'TEMPERATURE',
  'WEIGHT',
  'BIRTHMARK',
  'DIET',
  'ANALYZES',
  'COUGH',
  'MEDICINES',
  'X_RAY',
];

export const displayedMetrics: MetricType[] = [
  ...(IS_DEV_BUNDLE ? hiddenMetrics : []),
];
