import { Banner } from '@features/banners/types';
import styles from './styles.module.scss';

import React, { useEffect } from 'react';
import { BannerItem } from '../banner-item';
import classNames from 'classnames';
import { useTariffsForBannersTdLk } from '@features/api';
import { showLoader, hideLoader } from '@ui';

export const Banners = () => {
  const { banners, loadBanners, loading } = useTariffsForBannersTdLk();

  useEffect(() => {
    loading ? showLoader() : hideLoader();
  }, [loading]);

  return (
    <div className={classNames('grid__list', styles.banners)}>
      {!!banners && banners.single && (
        <BannerItem
          withDiscount={banners.single.withDiscount}
          id={banners.single.id}
          type="single"
          discount={banners.single.discount}
        />
      )}
      {!!banners && banners.subscription && (
        <BannerItem
          withDiscount={banners.subscription.withDiscount}
          discount={banners.subscription.discount}
          type="subscription"
        />
      )}
    </div>
  );
};
