import { useCallback, useMemo } from 'react';
import { GlobalLoader, Pagination } from '../../../ui';
import { Task } from '@eus/react-client/src/tasks/types';
import { ConsultationList } from '@features/task/ui/list';
import { EmptyState } from '@features/task/ui/list/empty-state';

interface Props {
  tasks: (Task | null)[];
  total?: number;
  loading?: boolean;
  hideServicesColumn?: true;
  filterIds?: number[];
  showAsList: boolean;
  filter?: 'all' | 'active' | 'favorite';
  isRegularTasks?: boolean;
  pathNamespace?: string;
  isQuestionsList?: true;
  label?: string;
  taskFilter?: any;
  page?: number;
}

export const ListConsultation = ({
  tasks,
  total,
  loading,
  hideServicesColumn,
  showAsList,
  filter: type = 'all',
  isRegularTasks = true,
  pathNamespace = 'tasks',
  isQuestionsList,
  label,
  taskFilter,
  page = 0,
}: Props) => {
  const nonEmptyTasks = useMemo(() => {
    return (tasks?.filter((task) => task !== null) as Task[]) || [];
  }, [tasks]);

  const listComponent = useCallback(
    (array) => (
      <ConsultationList
        tasks={array}
        showAsList={showAsList}
        pathNamespace={pathNamespace}
        isRegularTasks={isRegularTasks}
        hideServicesColumn={hideServicesColumn}
      />
    ),
    [showAsList, isRegularTasks, pathNamespace, hideServicesColumn],
  );

  // to do new loader
  if (loading) return <GlobalLoader />;

  return !nonEmptyTasks.length ? (
    <EmptyState
      label={
        label
          ? label
          : type === 'active'
          ? 'Здесь будут отображаться Ваши активные консультации'
          : type === 'favorite'
          ? 'Здесь будут отображаться Ваши избранные консультации'
          : 'Здесь будут отображаться все Ваши консультации'
      }
      isQuestionsList={isQuestionsList}
    />
  ) : (
    <div className="paginated-list -d__flex -column">
      {listComponent(nonEmptyTasks)}
      <Pagination
        perPage={6}
        total={total || 0}
        onShowMore={() => {}}
        onChange={() => {}}
        showMoreBtn={false}
        isPagerMobile={false}
      />
    </div>
  );
};
