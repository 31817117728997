import React from 'react';
import { Metrics as MetricType } from '@eus/health-default-logic/dist/modules/constants';

import './testimony-item.scss';
import classnames from 'classnames';
import { MetricsWebInterface } from '@features/health/models/metrics/summary-modules';
import { Link } from 'react-router-dom';

const developingText = 'Раздел находится в разработке.';
export enum TestimonyItemStatusTypes {
  DEV = 'developing',
}

interface TestimonyItemInterface extends Omit<MetricsWebInterface, 'id'> {
  id: MetricType | TestimonyItemStatusTypes;
}

export const TestimonyItem: React.FC<TestimonyItemInterface> = ({
  developing = false,
  ...item
}) => {
  const itemClass = classnames(
    'testimony__item',
    item.imageCode && `testimony__item-${item.imageCode}`,
    developing && 'testimony__item-developing',
  );
  const descriptionClass = classnames(
    'testimony__item-description',
    developing && 'testimony__item-description-developing',
  );

  const TestimonyItemInner = (
    <span className="testimony__item-text">
      <span className="testimony__item-title">{item.name}</span>
      {item.subName && (
        <span className="testimony__item-additional">{item.subName}</span>
      )}
      <span className="testimony__item-footer">
        {item.value && (
          <span className="testimony__item-value">{item.value}</span>
        )}
        {(item.description || developing) && (
          <span className={descriptionClass}>
            {developing ? developingText : item.description}
          </span>
        )}
      </span>
    </span>
  );

  return (
    <article className="testimony__item-wrapper">
      {item.link ? (
        <Link
          className={itemClass}
          to={`health/${item.link}`}
          data-cy={`HEALTH_MODULES_ITEM_${item.link}`}>
          {TestimonyItemInner}
        </Link>
      ) : (
        <span className={itemClass}>{TestimonyItemInner}</span>
      )}
    </article>
  );
};
