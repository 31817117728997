import { Alert, Button, Modal } from '@ui';
import promo from '@assets/img/present.png';
import React from 'react';
import './add-card-success.scss';
import classnames from 'classnames';

interface CompletingAddingCardProps {
  onClose: () => void;
  isOpen: boolean;
  card: string | undefined;
  image?: string | null;
}

export const AddCardSuccessModal = ({
  onClose,
  isOpen,
  card,
  image,
}: CompletingAddingCardProps) => {
  const modalImgClassnames = classnames('add-card-success__image', {
    'add-card-success__image-card': image,
  });

  return (
    <Alert
      short={false}
      onClose={onClose}
      className="add-card-success"
      isOpen={isOpen}
      title="Поздравляем"
      image={image || promo}
      imageClassNames={modalImgClassnames}
      message={
        <React.Fragment>
          <p>
            Карта <span className="bold">{card}</span> добавлена в Ваш личный
            кабинет.
          </p>
          {/* <p>Активируйте ее, чтобы воспользоваться услугами тарифа.</p> */}
        </React.Fragment>
      }
      buttons={[
        {
          title: 'Продолжить',
          'data-cy': 'CARDS_CARD_ADDED_SUCCESS_CONTINUE_BTN',
        },
      ]}
    />
  );
};
