import React from 'react';
import classnames from 'classnames';

import './input-checkbox.scss';

export interface InputCheckboxProps {
  onChange?: (value: any) => void;
  name?: string;
  label?: string;
  hasError?: boolean;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  customRender?: () => React.ReactElement;
}

export const InputCheckbox = ({
  className,
  name,
  label,
  checked,
  disabled,
  onChange,
  customRender,
  ...props
}: InputCheckboxProps) => {
  const inputClassNames = classnames('input-checkbox', className, {
    'input-checkbox--is-disabled': disabled,
  });

  const renderContent = () => {
    if (label) {
      return <span className="input-checkbox__label">{label}</span>;
    }
    if (customRender) {
      return customRender();
    }
    return null;
  };

  const handleChange = () => {
    onChange?.(!checked);
  };
  const dataCyValue = props['data-cy']
    ? `${props['data-cy']}_LABEL`
    : 'CHECKBOX_LABEL';

  return (
    <label className={inputClassNames} data-cy={dataCyValue}>
      <input
        name={name}
        disabled={disabled}
        type="checkbox"
        className="hide-check input-checkbox__original"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      <span className="input-checkbox__check" />
      {renderContent()}
    </label>
  );
};
