import { DataTable } from '../../health-detail/metric-viewer';
import { Explanation, Svg, AddFileAudio, Plug } from '@ui';
import { useCoughData } from './use-cough-data';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  CHECKUP_WAIT_PROCESSING,
} from '@teledoctor/common/dist/lib/text-constants';
import './cough-detail.scss';

export const CoughDetail = () => {
  const { cough, tableData, audio, isError, isPending, isSuccess, comment } =
    useCoughData();
  return cough ? (
    <>
      <div className="block metric-viewer__detail-block">
        <DataTable data={tableData} />

        {audio && (
          <div className="cough-detail">
            <div className="cough-detail__audio-label">Прикрепленный файл</div>
            <div className="cough-detail__audio-line">
              <AddFileAudio file={audio} />
              {isError && (
                <div className="cough-detail__audio-warning">
                  <Svg name="warning" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="block metric-viewer__detail-block -grow__1 -d__flex -column">
        {(isSuccess || isError) && (
          <>
            <div className="metric-viewer__detail-title">
              <span> {comment ? comment.title : 'Рекомендации'} </span>
              {isSuccess && (
                <Explanation
                  modClass="metric-viewer__detail-tooltip"
                  tooltip={CHECKUP_INTERPRETATION_DISCLAIMER}
                  symbol={
                    <Svg
                      name="info"
                      modClass="metric-viewer__detail-tooltip-icon"
                    />
                  }
                />
              )}
            </div>
            {comment && (
              <p className="metric-viewer__detail-description">
                {comment.text}
              </p>
            )}
          </>
        )}

        {isPending && (
          <p className="metric-viewer__detail-description--empty">
            {CHECKUP_WAIT_PROCESSING}
          </p>
        )}
      </div>
    </>
  ) : (
    <Plug label="Запись не найдена" />
  );
};
