import { history } from '@features/app/navigation.model';
import '@lib/api';
import { Routes } from '@router';
import { GlobalLoader } from '@ui';
import { Router } from 'react-router-dom';

import { localStorageWeb } from '@teledoctor/common/dist/lib/api/storages';
import NewCrmProvider from '@teledoctor/common/dist/features/api/crm-provider';
import { DevProvider } from '@features/debug';
import { IS_DEV } from './constants/dev';
import { AppBehaviorContextProvider } from '@teledoctor/common/dist/features/app-behavior/app-behavior-context';
import { useClientCrm } from '@teledoctor/common/dist/hooks';
import { TranslationProvider } from '@eus/react-client/src/translation/context';
import ru from './translations/ru.json';
import { WelcomeModalContextProvider } from '@features/user/ui';

export const App = () => {
  const clientCrmAuth = useClientCrm();
  return (
    <TranslationProvider translations={{ ru }}>
      <AppBehaviorContextProvider>
        <NewCrmProvider storage={localStorageWeb} client={clientCrmAuth}>
          <Router history={history}>
            <DevProvider isDev={IS_DEV}>
              <WelcomeModalContextProvider>
                <Routes />
              </WelcomeModalContextProvider>
            </DevProvider>
          </Router>
          <GlobalLoader />
        </NewCrmProvider>
      </AppBehaviorContextProvider>
    </TranslationProvider>
  );
};
