import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Phone, usePhoneCodes } from '@eus/react-client/src/phone-codes';

import './input-phone.scss';
import { FieldWrapper } from '../field-wrapper';
import { Modal, Button, Input, Svg, useSearch } from '../../atoms';

import { useSearchDebounce } from '@eus/react-client/src/hooks';

interface CountryPhone {
  // phone meta id
  id: string;
  // phone code
  pc: string;
  // phone mask
  pm: string;
  // phone length
  pl: number;
  // country name
  cn: string;
}

interface InputPhoneModalProps {
  current: Phone;
  visible: boolean;
  onSelectCountryCode: (payload: Phone) => void;
  onClose: () => void;
}

export const InputPhoneModal = ({
  visible,
  onClose,
  current,
  onSelectCountryCode,
}: InputPhoneModalProps) => {
  const { search } = usePhoneCodes();

  const { searchValue, searchHandler, setSearchValue } = useSearchDebounce(
    console.log,
  );

  const reset = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const countriesData = useMemo(() => {
    if (!isNaN(parseInt(searchValue, 10))) {
      return search(searchValue, 20, true);
    } else {
      return search(searchValue, 20, false);
    }
  }, [search, searchValue]);

  const onClickButton = () => {
    if (searchValue.length) {
      const val = searchValue[0];
      const searchResults = search(val, 1, true);
      onSelectCountryCode(searchResults.data[0]);
    }
    onClose();
  };

  const onClickItem = (val: Phone) => {
    onSelectCountryCode(val);
    onClose();
  };

  return (
    <Modal
      short
      isOpen={visible}
      onClose={onClose}
      onAfterOpen={reset}
      className="input-phone-modal"
      header={
        <React.Fragment>
          <h1 className="h2 modal__title" data-cy="MODAL_TITLE">
            Код страны
          </h1>
          <FieldWrapper name="search" className="input-phone-modal__search-bar">
            <Svg name="search" modClass="search-icon" />
            <Input
              placeholder="Поиск"
              onChange={searchHandler}
              className="input-text"
              data-cy="PHONE_CODE_SEARCH_INPUT"
            />
          </FieldWrapper>
        </React.Fragment>
      }
      footer={
        <Button
          title="Выбрать"
          onClick={onClickButton}
          data-cy="PHONE_CODES_SELECT_BTN"
        />
      }>
      <ul
        className="input-phone-modal__list"
        role="listbox"
        data-cy="PHONE_CODES_LIST">
        {countriesData.data.map((item) => (
          <ListItem
            key={item.id}
            onSelectItem={onClickItem}
            data={item}
            isSelected={item.id === current.id}
          />
        ))}
      </ul>
    </Modal>
  );
};

interface ListItemProps {
  data: Phone;
  isSelected: boolean;
  onSelectItem: (data: CountryPhone) => void;
}

const ListItem = ({ data, onSelectItem, isSelected }: ListItemProps) => {
  const { cn: countryName, pc: phoneCode } = data;

  const handleClick = () => {
    onSelectItem(data);
  };

  return (
    <li
      onClick={handleClick}
      className="country-code input-phone-modal__item"
      role="option"
      data-is-selected={isSelected}
      data-cy={`PHONE_CODE_VARIANT_${phoneCode}`}>
      <span className="country-code__country">{countryName}</span>
      <span className="country-code__code">(+{phoneCode})</span>
    </li>
  );
};
