import React from 'react';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';
import { useEcgData } from './use-ecg-data';

import './ecg-detail.scss';

export const EcgDetail = () => {
  const { ecgTable, ecg, result } = useEcgData();

  return (
    <>
      <div className="block ecg-result">
        <div className="ecg-result__graph" />
        <table>
          <tbody>
            <tr>
              <td>Результат</td>
              <td>{result?.title}</td>
            </tr>
            <tr>
              <td>Дата и время</td>
              <td>
                {ecg && formatTime(new Date(ecg.datetime), 'dd.MM.yyyy HH:mm')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="block ecg-table">
        <table>
          <tbody>
            {ecgTable.map(({ name, min, max, medium }, index) => {
              return (
                <tr key={index}>
                  <td>{name}</td>
                  <td>{min}</td>
                  <td>{medium}</td>
                  <td>{max}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {result?.diseases.length ? (
        <div className="block ecg-diseas">
          <p className="ecg-diseas__title">{result.title}</p>
          <p className="ecg-diseas__text">{result.diseases}</p>
        </div>
      ) : null}
    </>
  );
};
