import {
  CardsPage,
  ConsultationsPage,
  GetConsultationPage,
  ConsultationPage,
  HandbookPage,
  ProfilePage,
  SupportPage,
  SupportDetailPage,
  AddUserPage,
  ProfileEditPage,
  HealthMainPage,
  EditMedicalCard,
  PressureDynamicPage,
  PressureStaticPage,
  PulsePage,
  OxygenPage,
  TemperaturePage,
  DietPage,
  WeightControlPage,
  XRayPage,
  EcgPage,
  BirthmarkPage,
  HealthIndexPage,
  AnalysesPage,
  CoughPage,
  MedicationsPage,
  QuestionPage,
  AskQuestion,
  QuestionDetailPage,
  CreateAppeal,
  TariffsPage,
} from '@pages';
import { IS_DEV_BUNDLE } from '@teledoctor/common/dist/config/api';

import { Routes } from '../../types';

export const contentRootRoutes: Routes = [
  {
    route: 'tasks',
    text: 'Консультации',
    icon: 'talk',
    component: ConsultationsPage,
  },
  {
    route: 'cards',
    text: 'Карты',
    icon: 'cards',
    component: CardsPage,
  },
  {
    route: 'tariffs',
    text: 'Выбрать тариф',
    icon: 'tariffs',
    component: TariffsPage,
  },
  {
    route: 'profile',
    text: 'Профиль',
    icon: 'profile',
    component: ProfilePage,
  },
  {
    route: 'handbook',
    text: 'Справочник',
    icon: 'handbook',
    component: HandbookPage,
  },
  {
    route: 'support',
    text: 'Поддержка',
    icon: 'support',
    component: SupportPage,
  },
  {
    route: 'health',
    text: 'Здоровье 2.0',
    icon: 'health',
    iconActive: 'health-active',
    component: HealthMainPage,
  },
];

const contentAdditionalRoutes: Routes = [
  {
    route: 'tasks/get',
    text: 'Получить консультацию',
    icon: 'talk',
    component: GetConsultationPage,
  },
  {
    route: 'tasks/questions',
    text: 'Вопросы по услугам',
    icon: 'talk',
    component: QuestionPage,
  },
  {
    route: 'tasks/questions/ask',
    text: 'Задать вопрос',
    icon: 'talk',
    component: AskQuestion,
  },
  {
    route: 'tasks/questions/:id',
    text: 'Консультация',
    icon: 'talk',
    component: QuestionDetailPage,
  },

  {
    route: 'tasks/:taskId',
    text: 'Консультация',
    icon: 'talk',
    component: ConsultationPage,
  },
  {
    route: 'support/create-appeal',
    text: 'Поддержка',
    icon: 'support',
    component: CreateAppeal,
  },
  {
    route: 'support/:taskId',
    text: 'Поддержка',
    icon: 'support',
    component: SupportDetailPage,
  },
  {
    route: 'cards/:cardClientId/add-user',
    text: 'Добавить пользователя',
    icon: 'cards',
    component: AddUserPage,
  },
  {
    route: 'profile/edit',
    text: 'Редактировать пользователя',
    icon: 'profile',
    component: ProfileEditPage,
  },
];

const hiddenRoutes: Routes = [
  {
    route: 'health/pressure-dynamic',
    text: 'Давление (в динамике)',
    icon: 'health',
    iconActive: 'health-active',
    component: PressureDynamicPage,
  },
  {
    route: 'health/pulse',
    text: 'Пульс',
    icon: 'health',
    iconActive: 'health-active',
    component: PulsePage,
  },
  {
    route: 'health/oxygen',
    text: 'SpO2',
    icon: 'health',
    iconActive: 'health-active',
    component: OxygenPage,
  },
  {
    route: 'health/temperature',
    text: 'Температура',
    icon: 'health',
    iconActive: 'health-active',
    component: TemperaturePage,
  },
  {
    route: 'health/pressure-static',
    text: 'Давление (в статике)',
    icon: 'health',
    iconActive: 'health-active',
    component: PressureStaticPage,
  },
  {
    route: 'health/diet',
    text: 'Диеты',
    icon: 'health',
    iconActive: 'health-active',
    component: DietPage,
  },
  {
    route: 'health/weight',
    text: 'Контроль веса',
    icon: 'health',
    iconActive: 'health-active',
    component: WeightControlPage,
  },
  {
    route: 'health/ecg',
    text: 'ЭКГ',
    icon: 'health',
    iconActive: 'health-active',
    component: EcgPage,
  },
  {
    route: 'health/birthmark',
    text: 'Родинки',
    icon: 'health',
    iconActive: 'health-active',
    component: BirthmarkPage,
  },
  {
    route: 'health/health-index',
    text: 'Индекс здоровья',
    icon: 'health',
    iconActive: 'health-active',
    component: HealthIndexPage,
  },
  {
    route: 'health/analyses',
    text: 'Мои анализы',
    icon: 'health',
    iconActive: 'health-active',
    component: AnalysesPage,
  },
  {
    route: 'health/cough',
    text: 'Кашель',
    icon: 'health',
    iconActive: 'health-active',
    component: CoughPage,
  },
  {
    route: 'health/medications',
    text: 'Лекарства',
    icon: 'health',
    iconActive: 'health-active',
    component: MedicationsPage,
  },
];

const healthAdditionalRoutes: Routes = [
  {
    route: 'health/edit-medical-card',
    text: 'Редактировать медкарту',
    icon: 'health',
    iconActive: 'health-active',
    component: EditMedicalCard,
  },
  {
    route: 'health/xray',
    text: 'Рентген',
    icon: 'health',
    iconActive: 'health-active',
    component: XRayPage,
  },
];

export const allRoutes = contentRootRoutes.concat(
  contentAdditionalRoutes,
  healthAdditionalRoutes,
  IS_DEV_BUNDLE ? hiddenRoutes : [],
);
