import React from 'react';
import {
  AxisYTextGroups,
  GradientYLegend,
} from '@features/health/ui/health-detail/metric-chart';
import { Props } from 'recharts/types/component/Label';
import { AxisYTextGroupsLabels } from '../text-labels';

interface ViewBox {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

interface AxisYLineProps extends Omit<Props, 'viewBox'> {
  gradientSidebar?: boolean;
  labelText?: AxisYTextGroupsLabels;
  viewBox?: ViewBox;
}

export const AxisYLine = ({
  gradientSidebar,
  labelText,
  ...props
}: AxisYLineProps): JSX.Element => {
  const gradientSidebarWidth = 10;
  const textMargin = 12;
  const textX = !gradientSidebar
    ? textMargin + gradientSidebarWidth
    : textMargin;
  return (
    <>
      {gradientSidebar && props?.viewBox?.height && props?.viewBox.y && (
        <GradientYLegend
          width={gradientSidebarWidth}
          height={props.viewBox.height}
          yPos={props.viewBox.y}
        />
      )}
      {labelText && props?.viewBox?.height && props?.viewBox?.y && (
        <AxisYTextGroups
          labels={labelText}
          height={props.viewBox.height}
          yPos={props.viewBox.y}
          xPos={textX}
        />
      )}
    </>
  );
};
