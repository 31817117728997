import './list.scss';
import { TaskItem } from './item';
import { ConsultationListHeader } from './list-header';
import { Task } from '@eus/react-client/src/types/tasks';

interface Props {
  tasks: Task[];
  showAsList: boolean;
  pathNamespace: string;
  isRegularTasks?: boolean;
  hideServicesColumn?: true;
}

export const PER_PAGE = 6;

export const TasksList = ({
  tasks,
  showAsList,
  pathNamespace,
  isRegularTasks = true,
  hideServicesColumn,
}: Props) => {
  return (
    <>
      {showAsList && tasks.length > 0 && (
        <ConsultationListHeader hideServicesColumn={hideServicesColumn} />
      )}
      <div className="consultations__list">
        <div className="consultations-list" data-is-list={showAsList}>
          <div className="list-cards">
            <div className="list-cards__inner">
              {tasks.map((task) => {
                return (
                  <div key={task.id} className="list-cards__item">
                    <TaskItem
                      task={task}
                      pathNamespace={pathNamespace}
                      isRegularTasks={isRegularTasks}
                      hideServicesColumn={hideServicesColumn}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
