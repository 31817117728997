import React from 'react';

type stylesFn = (base: React.CSSProperties, props: any) => React.CSSProperties;

export const fontStyles = {};

const mainColor = '#777ff9';

export const colors = {
  dftInput: 'transparent',
  focusInput: mainColor,
  hoverOption: mainColor,
  errorInput: '#f02660',
  textColor: '#212121',
};

const styles: {
  [key: string]: stylesFn;
} = {
  indicatorsContainer: (base, props) => ({}),
  control: (base, props) => ({}),
  valueContainer: (base) => ({}),
  singleValue: (base, props) => ({}),
  input: (base, props) => ({ display: 'none' }),
  menu: (base, props) => ({}),
  placeholder: (base, props) => ({}),
  menuList: (base) => ({}),
  option: (base) => ({}),
  clearIndicator: (base) => ({}),
};

export default styles;
