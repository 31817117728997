import { ChangePasswordAfterRestoreFormValues } from '@teledoctor/common/dist/features/user/forms';
import { Button, FField, FormWrapper } from '@ui';
import { usePasswordCreateForm } from '@teledoctor/common/dist/hooks';

interface Props {
  onSuccess: (values: ChangePasswordAfterRestoreFormValues) => Promise<any>;
}

export const CreatePassword = ({ onSuccess }: Props) => {
  const { methods, onSubmitForm } = usePasswordCreateForm({
    onValid: onSuccess,
  });

  return (
    <FormWrapper onSubmit={onSubmitForm}>
      <FField.InputPassword
        name="new_password"
        placeholder="Новый пароль"
        methods={methods}
        className="form__field"
        data-cy="RESTORE_PASSWORD_CODE_INPUT"
      />
      <FField.InputPassword
        name="confirm_new_password"
        placeholder="Повторите новый пароль"
        methods={methods}
        className="form__field"
        data-cy="RESTORE_PASSWORD_CODE_INPUT"
      />
      <Button fullWidth title="Сохранить" />
    </FormWrapper>
  );
};
