import './medicines-list.scss';

import { MedicineWithColor, MedicineItem } from './medicine-item';

interface Props {
  data: MedicineWithColor[];
}

export const MedicinesList = ({ data }: Props) => {
  return (
    <div className="medicines-list">
      <div className="medicines-list__scrollable">
        <ul className="medicines-list__list">
          {data.map((medicine) => {
            return (
              <li className="medicines-list__item" key={medicine.id}>
                <MedicineItem medicine={medicine} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
