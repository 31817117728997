import React from 'react';
import { DataMob, DataTableWithModal } from '../../health-detail/metric-viewer';
import classnames from 'classnames';
import { ExplanationGray } from '@ui';
import { AnalysisItemsProps } from './use-decryption-data';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG,
} from '@teledoctor/common/dist/lib/text-constants';

interface Props {
  analysisItems: AnalysisItemsProps[];
  withPager: boolean;
}

export const DetailItemsListModal = ({ analysisItems, withPager }: Props) => {
  return (
    <div>
      {analysisItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.basicInfoData && <DataMob data={item.basicInfoData} />}
          {item.isSuccessful && (
            <div className="metric-viewer__modal-detail-info">
              <div className="metric-viewer__modal-detail-title">Результат</div>
            </div>
          )}
          {item.dangerousResultsData && (
            <div className="metric-viewer__modal-detail-info">
              <div className="metric-viewer__modal-detail-subtitle">
                Биомаркеры: стоит обратить внимание
              </div>
              <DataTableWithModal
                data={item.dangerousResultsData}
                testScope="HEALTH_ANALYSES_DECRYPTION"
              />
            </div>
          )}
          {item.normalResultsData && (
            <div className="metric-viewer__modal-detail-info">
              <div className="metric-viewer__modal-detail-subtitle">
                Биомаркеры: в норме
              </div>
              <DataMob data={item.normalResultsData} />
            </div>
          )}
          {item.unreadResultsData && (
            <div className="metric-viewer__modal-detail-info">
              <div className="metric-viewer__modal-detail-subtitle">
                Не удалось определить
              </div>
              <DataMob data={item.unreadResultsData} />
            </div>
          )}
          {!item.isSuccessful && (
            <div className="metric-viewer__detail-error-text">
              {ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG.map(
                (paragraph, index) => (
                  <p className="metric-viewer__detail-error-p" key={index}>
                    {paragraph}
                  </p>
                ),
              )}
            </div>
          )}

          {item.isSuccessful && (
            <ExplanationGray text={CHECKUP_INTERPRETATION_DISCLAIMER} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
