import { useAuth } from '@eus/react-client';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useFileAccess = (path: string, loadOnStart = true) => {
  const [loading, setLoading] = useState(false);
  const { state } = useAuth();
  const loadedPath = useRef<string | null>(null);
  const alreadyFullPath = path.includes('sign=') || !path.startsWith('https');

  const load = useCallback(() => {
    setLoading(true);
    return fetch(path, { headers: { Authorization: `Bearer ${state.token}` } })
      .then((data) => {
        return data.text();
      })
      .then((text) => {
        loadedPath.current = text;
        setLoading(false);
      })
      .then(() => loadedPath.current);
  }, [path, state.token]);

  useEffect(() => {
    if (alreadyFullPath) {
      return;
    }
    if (loadOnStart) {
      load();
    }
  }, [alreadyFullPath, load, loadOnStart]);

  return {
    loading: alreadyFullPath ? false : loading,
    load: alreadyFullPath ? () => Promise.resolve(path) : load,
    path: alreadyFullPath ? path : loadedPath.current,
  };
};
