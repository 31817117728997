import { Schema } from '@eus/react-client/src/types/schema';
import { getSelectOptions } from '@eus/react-client/src/tasks/schema';
import {
  FieldValues,
  Control,
  UseFormReturn,
  FieldError,
  DeepMap,
  get,
} from 'react-hook-form';
import { FField } from '../../ui/organisms';
import {
  FieldDateProps,
  FieldDateTimeProps,
  FieldFileProps,
  FieldInputProps,
  FieldPhoneProps,
  FieldSelectProps,
  FieldTextAreaProps,
} from '../../ui/organisms/form/field';

type Props = {
  schema?: Schema;
  errors: DeepMap<FieldValues, FieldError>;
  // control: Control;
  methods: UseFormReturn<any, any>;
  getValues?: any;
  axiosInstance?: any;
};

const getPlaceholder = (label: string, type: string) => {
  if (label === 'Страна') {
    return 'Выберите страну';
  }
  if (type === 'select') {
    return 'Выберите из списка';
  }
  return 'Введите данные';
};

export const FormSchema = (props: Props) => {
  const { schema, errors, methods, axiosInstance, getValues } = props;

  if (!schema) return null;

  return (
    <>
      {schema.map((field) => {
        const {
          type,
          field: name,
          required,
          label,
          values,
          resource,
          resource_label,
          value,
          placeholder: fieldPlaceholder,
          ...rest
        } = field;

        // const error = get(errors, name)?.message;
        const placeholder = fieldPlaceholder || getPlaceholder(label, type);

        let defaultValue: typeof value | Date;
        if (value) {
          if (type === 'date') {
            if (Array.isArray(value)) {
              defaultValue = new Date(value[0]);
            } else {
              defaultValue = new Date(value);
            }
          } else {
            defaultValue = value;
          }
        }

        switch (type) {
          case 'text':
          case 'email':
            return (
              <FField.Input
                key={field.field}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
                data-cy={`get_consult_input_${name}`}
              />
            );

          case 'select':
            return (
              <FField.Select
                key={field.field}
                placeholder={placeholder}
                name={name}
                methods={methods}
                label={label}
                // @ts-ignore
                options={
                  typeof values?.[0] === 'string'
                    ? values.map((item) => {
                        return { label: item, value: item };
                      })
                    : values || getSelectOptions(name)
                }
                className="form__field"
                dataCy={`get_consult_select_${name}`}
              />
            );

          case 'textarea':
            return (
              <FField.TextArea
                key={field.field}
                placeholder={placeholder}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
                data-cy={`get_consult_textarea_${name}`}
              />
            );

          case 'phone':
            return (
              <FField.Phone
                key={field.field}
                placeholder={placeholder}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
                data-cy={`get_consult_inputphone_${name}`}
              />
            );

          case 'date':
            return (
              <FField.Date
                key={field.field}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
              />
            );

          case 'year':
            return (
              <FField.Date
                key={field.field}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
                format="yyyy"
                maxDetail="decade"
                maxDate={new Date()}
                minDate={new Date('1920')}
                formatChangedValue={(data) => {
                  return Array.isArray(data)
                    ? data.map((item) => item.getFullYear())
                    : data.getFullYear();
                }}
                // @ts-ignore
                formatCurrentValue={(data) => {
                  if (Array.isArray(data)) {
                    return data.map((item) =>
                      item ? new Date(String(item)) : null,
                    );
                  }
                  return data ? new Date(String(data)) : null;
                }}
              />
            );

          case 'datetime':
            return (
              <FField.DateTime
                key={field.field}
                // @ts-ignore
                baseURL={rest.baseURL}
                // @ts-ignore
                datesResource={rest.datesResource}
                // @ts-ignore
                timeResource={rest.timeResource}
                name={name}
                methods={methods}
                label={label}
                className="form__field"
                dataCy="form_schema_datetime_picker"
              />
            );

          case 'file':
            return (
              <FField.File
                key={field.field}
                name={name}
                methods={methods}
                className="form__field"
                maxFileSize={20}
              />
            );
          case 'info':
            return (
              <div className="form__info" key={field.field}>
                {defaultValue}
              </div>
            );

          default:
            return null;
        }
      })}
    </>
  );
};
