import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore, useStoreMap } from 'effector-react';
import { useParams } from 'react-router-dom';

import { Button, Breadcrumbs, BreadcrumbsItem, TitleLine, Alert } from '@ui';
import { ConsultationInfo } from '@features/consultations/ui/consultation-info/consultation-info';
import { Chat } from '@features/chat/ui/chat';
import { ChatFeedback } from '@features/chat/ui/chat-feedback';
import {
  STATUS_CANCELLED,
  STATUS_END,
} from '@eus/els-api-types/src/task-status-cp';
import { ChatCancelModal } from '@features/chat/ui/chat-cancel';
import { useChatTdLk, useSupportTdLk, useTasksTdLk } from '@features/api';
import {
  useTaskLoadingSelector,
  useTaskSelector,
} from '@eus/react-client/src/support/selectors';
import { MessageUI, ChatItem } from '@eus/react-client/src/chat_v2/types';
import { processChatMessages as baseProcessChatMessages } from '@eus/react-client/src/utils/chat';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';

export const SupportDetailPage = () => {
  let { taskId } = useParams<{ taskId: string }>();

  const taskIdNumber = Number(taskId);

  const task = useTaskSelector({ entityId: taskIdNumber });
  const loading = useTaskLoadingSelector(taskIdNumber);
  const { cancelTask: cancelSupportTask } = useSupportTdLk();

  const [isCancelConsultationOpen, setIsCancelConsultationOpen] =
    useState<boolean>(false);

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      path: '/lk/support/',
      breadcrumb: 'Поддержка',
    },
    {
      path: '/lk/support/:taskId',
      breadcrumb: `Запрос №${taskIdNumber}`,
    },
  ];

  const processChatMessages = useCallback(
    (messages?: MessageUI[]) => baseProcessChatMessages(messages),
    [],
  );

  const taskIsClosed =
    task?.task_status_id === STATUS_END ||
    task?.task_status_id === STATUS_CANCELLED;

  const cancelTaskHandler = useCallback(
    (taskId: number) => {
      cancelSupportTask(
        taskId,
        undefined,
        new FeedParams({
          needShowLoader: true,
          throwNext: true,
          ignoreError: true,
        }),
      )
        .then()
        .catch();
    },
    [cancelSupportTask],
  );

  const renderActionBtns = useCallback(() => {
    return (
      <div>
        {task && !taskIsClosed && (
          <Button
            type="button"
            danger
            title="Отменить запрос"
            className="titleline__button"
            onClick={() => setIsCancelConsultationOpen(true)}
            data-cy="GET_CONSULT_CANCEL_BTN"
          />
        )}
      </div>
    );
  }, [task, taskIsClosed]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
        <div className="titleline__right">{renderActionBtns()}</div>
      </TitleLine>
      {task && (
        <ConsultationInfo
          onToggleFavorite={() => {}}
          task={task}
          taskType="support"
          renderActionBtns={renderActionBtns}
        />
      )}
      {task && (
        <Chat
          chatId={taskId}
          task={task}
          processChatMessages={processChatMessages}
          taskIsClosed={taskIsClosed}
        />
      )}
      {task && !taskIsClosed && (
        <ChatCancelModal
          isOpen={isCancelConsultationOpen}
          setIsOpen={setIsCancelConsultationOpen}
          successHandler={() => cancelTaskHandler(task.id)}
          taskTypeText="запрос"
        />
      )}
      {/* {task && taskIsComplete && <ChatFeedback {...task} />} В оценку не включаются запросы на поддержку */}
    </>
  );
};
