import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { Header, MainTemplate, Sidebar, Footer, Congrats } from '@ui';

import { useAuthTdLk } from '../../../features/api';

interface ContentTemplateProps {
  menu: React.ReactElement;
  children?: React.ReactChild;
}

export const ContentTemplate = ({ menu, children }: ContentTemplateProps) => {
  const [isMobSidebarOpen, setMobSidebarOpen] = useState(false);

  const { logoutTD } = useAuthTdLk();

  const handleOpen = useCallback(() => {
    disablePageScroll();
    setMobSidebarOpen(true);
  }, []);

  const handleMobMenuClose = useCallback(() => {
    enablePageScroll();
    setMobSidebarOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    handleMobMenuClose();
    logoutTD();
  }, [handleMobMenuClose, logoutTD]);

  const toggleMobSidebar = useCallback(() => {
    isMobSidebarOpen ? handleMobMenuClose() : handleOpen();
  }, [handleMobMenuClose, handleOpen, isMobSidebarOpen]);

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      const isBackdrop = (e.target as any)?.classList.contains('sidebar');
      const isScreenSmall = window.matchMedia('(max-width: 992px)').matches;
      if (!isBackdrop || !isScreenSmall) return;
      handleMobMenuClose();
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleMobMenuClose]);

  const sidebar = useMemo(
    () => (
      <Sidebar
        menu={React.cloneElement(menu, {
          onClickMenuItem: handleMobMenuClose,
        })}
        isMobMenuVisible={isMobSidebarOpen}
        onClickLogout={handleLogout}
      />
    ),
    [handleLogout, handleMobMenuClose, isMobSidebarOpen, menu],
  );

  const header = useMemo(
    () => (
      <Header onToggleMobSidebar={toggleMobSidebar} onClickLogout={logoutTD} />
    ),
    [logoutTD, toggleMobSidebar],
  );

  const footer = useMemo(() => <Footer />, []);

  return (
    <MainTemplate sidebar={sidebar} header={header} footer={footer}>
      {children}
      <Congrats />
    </MainTemplate>
  );
};
