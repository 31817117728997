import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import formatPhone from '@eus/crm-default-logic/src/formatters/phone';
import { Member } from '@eus/react-client/src/types/cards';
import { UserModal } from '@features/card/ui/modals';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { Avatar } from '@ui';
import style from './info.module.scss';
import classNames from 'classnames';
import { getUserFields, getUserFio } from '../helpers';

interface CardInfoUserItemProps {
  card_number: string;
  tariff: string;
  currentAvatar: string | undefined;
  member: Member;
  cardClientId: number;
}

export const CardInfoUserItem = ({
  cardClientId,
  member,
  card_number,
  currentAvatar = '',
  tariff,
}: CardInfoUserItemProps) => {
  const { last_name, first_name, middle_name } = member;

  const {
    value: isVisibleUser,
    setTruthy: openUser,
    setFalsy: closeUser,
  } = useBoolean(false);

  const fio = getUserFio(last_name, first_name, middle_name ?? undefined);

  const fields = getUserFields({
    cardNumber: card_number,
    phone: member.phone,
    tariff,
    ...(member.date_of_birth && { date_of_birth: member.date_of_birth }),
    email: member.email,
  });

  return (
    <div className={style.item}>
      <div onClick={openUser} data-cy="CARD_INFO_USER">
        <Avatar
          sex={member.sex ? 'М' : 'Ж'}
          url={currentAvatar}
          className={classNames(
            'background-image',
            style.avatar,
            style.pointer,
          )}
        />
      </div>
      <UserModal
        avatarUri={currentAvatar}
        closeUser={closeUser}
        fio={fio}
        sex={member.sex}
        visibleUser={isVisibleUser}
        cardClientId={cardClientId}
        id={member.id}
        fields={fields}
      />
    </div>
  );
};
