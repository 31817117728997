import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { useStoreMap } from 'effector-react';

import '@styles/components/accordion.scss';
import './diet-item.scss';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';

type Props = {
  item: { id: string; name: string };
};

export const DietItem = ({ item }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    metric: { $store: $dietsStore, load: loadListDiets },
  } = useMetric({
    metric: 'diets',
  });

  const markups = useStoreMap({
    store: $dietsStore,
    keys: [item.id],
    fn: (all, [categoryId]) => {
      return all?.diets
        .filter((diet) => diet.categoryId === Number(categoryId))
        .map((diet) => diet.content);
    },
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button
      className="accordion__item block"
      role="button"
      data-toggle="collapse"
      aria-expanded={isOpen}
      aria-controls={item.name}>
      <div
        className="accordion__title"
        onClick={handleClick}
        data-cy={`HEALTH_DIET_ITEM_${item.id}`}>
        <div className="accordion__question">{item.name}</div>
        <div className={`accordion__toggle toggler-${isOpen && 'checked'}`} />
      </div>
      <Collapse isOpened={isOpen} id={item.name}>
        {markups?.map((item, index) => (
          <div
            className="accordion__answer diet-markups"
            key={index}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </Collapse>
    </button>
  );
};
