import useBoolean from '@teledoctor/common/dist/hooks/use-boolean';
import React, { useMemo } from 'react';
import { SelectLike } from '../../../ui/atoms/select-like';
import { AddressPicker, AddressPickerProps } from './address-picker';

export interface AddressPickerInputProps
  extends Omit<
    AddressPickerProps,
    'isPickerOpen' | 'closePicker' | 'closeAndResetPicker'
  > {
  placeholder?: string;
  value: any;
  dataCy: string;
}

export const AddressPickerInput = React.memo(
  (props: AddressPickerInputProps) => {
    const { placeholder, methods, defaultAddressValue, value, dataCy } = props;

    const fullAddressString = useMemo(
      () => defaultAddressValue.fullAddressString,
      [defaultAddressValue],
    );

    const {
      value: isAddressPickerOpen,
      setTruthy: openAddressPicker,
      setFalsy: closeAddressPicker,
    } = useBoolean(false);

    return (
      <>
        <SelectLike
          placeholder={placeholder || 'Укажите адрес проживания'}
          value={fullAddressString}
          onClick={openAddressPicker}
          dataCy={dataCy}
        />
        <AddressPicker
          isPickerOpen={isAddressPickerOpen}
          closePicker={closeAddressPicker}
          methods={methods}
          defaultAddressValue={defaultAddressValue}
          dataCy={dataCy}
        />
      </>
    );
  },
);
