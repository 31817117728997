import { useAppBehaviorSignals } from '@features/app-behavior/use-app-behavior';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

interface ProtectedRoute extends RouteProps {
  children: React.ReactChild;
}

export const ProtectedRoute = ({ children, ...rest }: ProtectedRoute) => {
  const { hasToken } = useAppBehaviorSignals();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
