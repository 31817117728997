import React, { useMemo, useEffect, useState } from 'react';
import { TitleLine, Breadcrumbs, BreadcrumbsItem, Modal } from '@ui';
import {
  MetricChart,
  MetricChartCommonProps,
} from '@features/health/ui/health-detail/';
import Table, {
  TableProps,
  WeightDetailRouterProps,
} from '@teledoctor/common/dist/metrics/components/weight/table';
import { CommonValuesProps } from '@teledoctor/common/dist/metrics/type';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import Values from './values';
import { TargetWeightValue } from '@eus/health-default-logic/dist/weight/types';
import { WeightDetail } from '../../../features/health/ui/metrics/weight-control';
import { useBoolean } from '@teledoctor/common/dist/hooks';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/weight',
    breadcrumb: 'Контроль веса',
  },
];

export const WeightControlPage = () => {
  const { metric } = useMetric({ metric: 'weight' });
  const {
    value: isDetailVisible,
    setFalsy: closeDetail,
    setTruthy: openDetail,
  } = useBoolean(false);

  const [detailModalHeader, setDetailModalHeader] = useState<string | null>(
    null,
  );
  const [detailModalData, setDetailModalData] =
    useState<WeightDetailRouterProps | null>(null);

  const showModalDetail = (data: WeightDetailRouterProps) => {
    setDetailModalHeader(data.title);
    setDetailModalData(data);
    openDetail();
  };

  useEffect(() => {
    metric.getTarget();
  }, [metric]);

  const weightTarget: Partial<TargetWeightValue> | null = useStore(
    metric.$target,
  );

  const props: MetricChartCommonProps<TableProps, CommonValuesProps> = {
    metric: 'weight',
    range: [40, 160],
    tickCount: 8,
    referenceBorder: weightTarget?.weight,
    renderMetricTable: (props) => {
      return (
        <Table
          {...props}
          onPress={(itemData) => showModalDetail(itemData)}
          stature={weightTarget?.stature}
        />
      );
    },
    renderMetricValues: (props) => (
      <Values {...props} weightTarget={weightTarget} />
    ),
  };

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MetricChart period="month" {...props} modClass="-mt__none" />
      {detailModalData && (
        <Modal
          header={detailModalHeader}
          isOpen={isDetailVisible}
          onClose={closeDetail}
          className="weight-detail-modal">
          <WeightDetail data={detailModalData} />
        </Modal>
      )}
    </>
  );
};
