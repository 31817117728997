import { Time } from '@eus/react-client/src/types/datetime';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FField, Modal } from '../../../ui/';
import './address-picker.scss';
import { getValueFieldAddress, SelectAddress } from './select-address';
import { Address } from '@eus/address/src';

export type Datetime = {
  date: Date;
  time: Time;
};

export interface AddressFields {
  fullAddress?: string;
  region?: string;
  area?: string;
  city?: string;
  street?: string;
  house?: string;
  housing?: string;
  flat?: string;
  label?: string;
  value?: string;
}

export interface AddressPickerProps {
  isPickerOpen: boolean;
  closePicker: () => void;
  methods: any;
  defaultAddressValue: any;
  dataCy: string;
}

export const AddressPicker = React.memo((props: AddressPickerProps) => {
  const { closePicker, isPickerOpen, methods, defaultAddressValue, dataCy } =
    props;
  const [addressSearch, setAddressSearch] = useState<AddressFields>({});

  const defaultValues = useMemo(
    () => ({
      fullAddress: defaultAddressValue.fullAddressString,
      region: getValueFieldAddress(defaultAddressValue?.region),
      area: getValueFieldAddress(defaultAddressValue?.area),
      city: getValueFieldAddress(defaultAddressValue?.city),
      street: getValueFieldAddress(defaultAddressValue?.street),
      house: getValueFieldAddress(defaultAddressValue?.house),
      housing: getValueFieldAddress(defaultAddressValue.housing),
      flat: getValueFieldAddress(defaultAddressValue.flat),
    }),
    [defaultAddressValue],
  );

  const methodsAddress = useForm<AddressFields>({
    mode: 'onBlur',
    defaultValues,
    shouldUnregister: true,
  });

  const changeFullAddress = useCallback(
    (value: any) => {
      const newAddress = value[0];
      setAddressSearch(newAddress);
      const region = getValueFieldAddress(newAddress?.region) || '';
      const area = getValueFieldAddress(newAddress?.area) || '';
      const city = getValueFieldAddress(newAddress?.city) || '';
      const street = getValueFieldAddress(newAddress?.street) || '';
      const house = getValueFieldAddress(newAddress?.house) || '';
      const housing = getValueFieldAddress(newAddress?.housing) || '';
      const flat = getValueFieldAddress(newAddress?.flat) || '';

      methodsAddress.setValue('region', region);
      methodsAddress.setValue('area', area);
      methodsAddress.setValue('city', city);
      methodsAddress.setValue('street', street);
      methodsAddress.setValue('house', house);
      methodsAddress.setValue('housing', housing);
      methodsAddress.setValue('flat', flat);
    },
    [methodsAddress],
  );

  const checkUserInput = (search, currentValue) => {
    if (
      search &&
      (search?.description === currentValue || search?.name === currentValue)
    ) {
      return false;
    }
    return true;
  };

  const getFullAddress = (data) => {
    return data
      .filter((item) => typeof item === 'string' && item !== '')
      .join(', ');
  };

  const region = methodsAddress.watch('region');
  const area = methodsAddress.watch('area');
  const city = methodsAddress.watch('city');
  const street = methodsAddress.watch('street');
  const house = methodsAddress.watch('house');
  const housing = methodsAddress.watch('housing');
  const flat = methodsAddress.watch('flat');

  // eslint-disable-next-line complexity
  const onSubmit = () => {
    // todo в дальнейшем можно заменить модальное окно с компонентом address picker, чтобы форма не была вложена в форму
    const region = methodsAddress.getValues('region');
    const area = methodsAddress.getValues('area');
    const city = methodsAddress.getValues('city');
    const street = methodsAddress.getValues('street');
    const house = methodsAddress.getValues('house');
    const housing = methodsAddress.getValues('housing');
    const flat = methodsAddress.getValues('flat');
    let userInput =
      checkUserInput(addressSearch?.region, region) ||
      checkUserInput(addressSearch?.area, area) ||
      checkUserInput(addressSearch?.city, city) ||
      checkUserInput(addressSearch?.street, street) ||
      checkUserInput(addressSearch?.house, house) ||
      checkUserInput(addressSearch?.housing, housing) ||
      checkUserInput(addressSearch?.flat, flat);

    getFullAddress(
      [region, area, city, street, house, housing, flat].filter(
        (item) => typeof item === 'string' && item !== '',
      ),
    );

    const newData = {
      region: !checkUserInput(addressSearch?.region, region)
        ? addressSearch?.region
        : { name: region },

      area: !checkUserInput(addressSearch?.area, area)
        ? addressSearch?.area
        : { name: area },

      city: !checkUserInput(addressSearch?.city, city)
        ? addressSearch?.city
        : { name: city },

      street: !checkUserInput(addressSearch?.street, street)
        ? addressSearch?.street
        : { name: street },

      house: !checkUserInput(addressSearch?.house, house)
        ? addressSearch?.house
        : { name: house },

      housing: !checkUserInput(addressSearch?.housing, housing)
        ? addressSearch?.housing
        : { name: housing },

      flat: !checkUserInput(addressSearch?.flat, flat)
        ? addressSearch?.flat
        : { name: flat },
      ifnsfl: '',
      ifnsflName: '',
      oktmo: '',
      postal: '',
      description: '',
      fullAddressString: !userInput
        ? addressSearch?.label ||
          addressSearch?.value ||
          getFullAddress([region, area, city, street, house, housing, flat])
        : getFullAddress([region, area, city, street, house, housing, flat]),
    };

    methods.setValue('address', newData);
    closePicker();
  };

  return (
    <Modal
      header={'Адрес проживания'}
      isOpen={isPickerOpen}
      onClose={closePicker}>
      <form onSubmit={methodsAddress.handleSubmit(onSubmit)}>
        <SelectAddress
          value={defaultAddressValue}
          name={'fullAddress'}
          control={methods.control}
          errors={methods.errors}
          onFieldChange={changeFullAddress}
          dataCy={`${dataCy}_select`}
        />
        <div className="address-picker__field">
          <FField.Input
            name={'region'}
            methods={methodsAddress}
            label={'Регион'}
            placeholder={'Введите регион'}
            data-cy={`${dataCy}_input_region`}
          />

          <div className="address-picker__field">
            <FField.Input
              value={area}
              name={'area'}
              methods={methodsAddress}
              label={'Район'}
              placeholder={'Введите район'}
              data-cy={`${dataCy}_input_area`}
            />
          </div>
          <div className="address-picker__field">
            <FField.Input
              value={city}
              name={'city'}
              methods={methodsAddress}
              label={'Город'}
              placeholder={'Введите название города'}
              data-cy={`${dataCy}_input_city`}
            />
          </div>
          <div className="address-picker__field">
            <FField.Input
              value={street}
              name={'street'}
              methods={methodsAddress}
              label={'Улица'}
              placeholder={'Введите название улицы'}
              data-cy={`${dataCy}_input_street`}
            />
          </div>
          <div className="address-picker__field">
            <FField.Input
              value={house}
              name={'house'}
              methods={methodsAddress}
              label={'Дом, строение'}
              placeholder={'Укажите номер дома'}
              data-cy={`${dataCy}_input_house`}
            />
          </div>
          <div className="address-picker__field">
            <FField.Input
              value={housing}
              name={'housing'}
              methods={methodsAddress}
              label={'корпус'}
              placeholder={'Укажите корпус'}
              data-cy={`${dataCy}_input_housing`}
            />
          </div>
          <div className="address-picker__field">
            <FField.Input
              value={flat}
              name={'flat'}
              methods={methodsAddress}
              label={'квартира'}
              placeholder={'Укажите номер квартиры'}
              data-cy={`${dataCy}_input_flat`}
            />
          </div>
          <div className="address-picker__btn-wrap">
            <Button
              type="button"
              title="Сохранить"
              onClick={onSubmit}
              data-cy={`${dataCy}_save_btn`}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
});
