import { Button, FField, FormWrapper } from '@ui';
import styles from './form.module.scss';

import { ChangePasswordForm } from '@eus/react-client/src/auth/forms/changePassword';
import { CommonFormProps } from '@teledoctor/common/dist/features/shared/forms';
import { useEditPassword } from './use-edit-password';
import classnames from 'classnames';

interface Props extends Omit<CommonFormProps<ChangePasswordForm>, 'onSuccess'> {
  onSuccess: () => void;
  className?: string;
}

export const EditPasswordForm = ({
  onSuccess,
  defaultValues,
  className,
}: Props) => {
  const { methods, isDisabledSubmit, onSubmitForm } = useEditPassword({
    onSuccess,
    defaultValues,
  });

  return (
    <FormWrapper onSubmit={onSubmitForm} className={className}>
      <div className={styles.form}>
        <FField.InputPassword
          name="old_password"
          methods={methods}
          label="Текущий пароль"
          placeholder="Введите пароль"
          className="form__field"
          data-cy="EDIT_PASSWORD_OLD_PASSWORD_INPUT"
        />
        <FField.InputPassword
          name="new_password"
          methods={methods}
          label="Новый пароль"
          placeholder="Введите новый пароль"
          className="form__field"
          data-cy="EDIT_PASSWORD_NEW_PASSWORD_INPUT"
        />
        <FField.InputPassword
          name="new_password_confirm"
          methods={methods}
          label="Подтвердить пароль"
          placeholder="Введите пароль еще раз"
          className="form__field"
          data-cy="EDIT_PASSWORD_NEW_PASSWORD_INPUT_CONFIRM"
        />
      </div>
      <div className={classnames('form__row', styles.button)}>
        <Button
          title="Продолжить"
          type="submit"
          disabled={isDisabledSubmit}
          className="-width__xs-100"
          data-cy="EDIT_PASSWORD_SUBMIT_BTN"
        />
      </div>
    </FormWrapper>
  );
};
