import { Button, FField, FormWrapper } from '@ui';
import { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.scss';
import { AuthorizationScreenTypes } from '../types';
import { useAuthByPassword } from './use-auth-by-password';

interface Props {
  phone: string;
  changeScreen: Dispatch<SetStateAction<AuthorizationScreenTypes>>;
}

export const AuthByPassword = ({ phone, changeScreen }: Props) => {
  const { methods, onSubmitForm } = useAuthByPassword({ phone });

  const {
    formState: { isValid, isSubmitting },
  } = methods;

  return (
    <FormWrapper className="auth-form login-form" onSubmit={onSubmitForm}>
      <div className="form__fields">
        <FField.InputPassword
          id="enter-form-password"
          name="password"
          methods={methods}
          placeholder="Введите пароль"
          className="form__field"
          data-cy="AUTH_PASSWORD_INPUT"
        />
      </div>
      <div className="auth-form__footer">
        <Button
          className={styles.next}
          disabled={!isValid || isSubmitting}
          title="Далее"
          fullWidth
        />
        <Button
          type="button"
          title="Забыли пароль?"
          fullWidth
          disabled={isSubmitting}
          appearance="textual"
          onClick={() => changeScreen('restore')}
        />
      </div>
    </FormWrapper>
  );
};
