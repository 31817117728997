import { ServerErrorsHandler } from '@eus/react-client';
import { useAuthTdLk } from '@features/api';
import {
  LoginFormValues,
  useLoginFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { addNotification } from '@ui';
import { useContext } from 'react';
import { WelcomeModalContext } from '@features/user/ui/modals';
import { useFormWithSchema } from '@teledoctor/common/dist/hooks';
import { UseFormSetError } from 'react-hook-form';

const feedParams = new FeedParams({
  needShowLoader: true,
  ignoreError: false,
  throwNext: true,
});
interface Params {
  phone: string;
}

const handleError = (setError: UseFormSetError<LoginFormValues>) =>
  formServerErrorHandler({
    commonErrorCase: {
      commonErrorCallback: (errorText) =>
        addNotification({
          id: 'auth' + Date.now(),
          type: 'error',
          message: errorText,
          target: 'global',
        }),
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler(setError),
    },
  });

export const useAuthByPassword = (params: Params) => {
  const { phone } = params;

  const { LoginFormSchema } = useLoginFormSchema();

  const { auth } = useAuthTdLk();

  const welcome = useContext(WelcomeModalContext);

  return useFormWithSchema<LoginFormValues>({
    onValid: async ({ login, password }, setError) => {
      try {
        await auth({ password, login }, setError, feedParams);
        if (welcome) {
          welcome.showModal('auth');
        }
      } catch (error) {
        handleError(setError)(error);
      }
    },
    validationSchema: LoginFormSchema,
    mode: 'onChange',
    defaultValues: { login: phone },
  });
};
