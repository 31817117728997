import React, { useState } from 'react';
import { Button } from '@ui';
import { ChatThanksModal } from '@features/chat/ui/chat-feedback/modals/chat-thanks-modal';
import { ChatFeedback } from '@features/chat/ui/chat-feedback/chat-feedback';
import { Task as BaseTask } from '@eus/react-client/src/types/tasks';
import { useBoolean } from '@teledoctor/common/dist/hooks';

interface Props {
  text?: BaseTask['thank_text'];
  taskId: BaseTask['id'];
}

export const ChatThanks: React.FC<Props> = ({ text, taskId }) => {
  const {
    value: isThanksModalOpen,
    setFalsy: closeThanksModalHandler,
    setTruthy: showThanksModalHandler,
  } = useBoolean(false);

  return text ? (
    <div className="consultation-end__label">
      <div className="label">Благодарность</div>
      {text}
    </div>
  ) : (
    <>
      <Button
        onClick={showThanksModalHandler}
        appearance="solid"
        title="Оставить благодарность"
        className="consultation-end__detail-button"
        data-cy="CONSULT_FEEDBACK_THANKS_BTN"
      />
      <ChatThanksModal
        onCloseHandler={closeThanksModalHandler}
        isOpen={isThanksModalOpen}
        taskId={taskId}
      />
    </>
  );
};
