import React from 'react';
import doctorAvatar from '@assets/img/doctor.png';
import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { MessageType } from '@eus/chat-default-logic/src/messages';

interface Props {
  messageType: MessageType;
  user: MessageUI['user'];
}

export const ChatMessageSenderInfo = ({ messageType, user }: Props) => {
  const userName =
    messageType === 'admin' || messageType === 'auto'
      ? // user?.name
        'СПЕЦИАЛИСТ' ?? 'Теледоктор24' ?? ''
      : '';

  return (
    <>
      {(messageType === 'admin' || messageType === 'auto') && (
        <div className="chat__message-avatar">
          <div
            className="chat__message-avatar-image"
            style={{
              backgroundImage: 'url(' + doctorAvatar + ')',
            }}
          />
        </div>
      )}

      {!!userName && <div className="chat__message-author">{userName}</div>}
    </>
  );
};
