import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTaskSelector } from '@eus/react-client/src/questions/selectors';
import { Breadcrumbs, BreadcrumbsItem, Button, TitleLine } from '@ui';
import {
  STATUS_CANCELLED,
  STATUS_END,
} from '@eus/els-api-types/src/task-status-cp';
import { ChatCancelModal } from '@features/chat/ui/chat-cancel';
import { Chat } from '@features/chat';
import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { useButtonsLk } from '@features/chat/hooks/use-buttons-lk';
import { processChatMessages as baseProcessChatMessages } from '@eus/react-client/src/utils/chat';
import { ConsultationInfo } from '@features/consultations/ui/consultation-info';
import { useQuestionsTdLk } from '@features/api';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';

interface QuestionDetailPageProps {}

export const QuestionDetailPage: FC<QuestionDetailPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const taskIdNumber = Number(id);
  const { cancelTask } = useQuestionsTdLk();
  const [isCancelConsultationOpen, setIsCancelConsultationOpen] =
    useState<boolean>(false);

  const task = useTaskSelector({ entityId: taskIdNumber });

  const BREADCRUMBS_CONFIG: BreadcrumbsItem[] = useMemo(
    () => [
      {
        path: '/lk/tasks',
        breadcrumb: 'Консультации',
      },
      {
        path: '/lk/tasks/questions',
        breadcrumb: 'Вопросы по услугам',
      },
      {
        path: '/lk/tasks/questions/:id',
        breadcrumb: `№${taskIdNumber}`,
      },
    ],
    [taskIdNumber],
  );

  const taskIsClosed = useMemo(
    () =>
      task?.task_status_id === STATUS_END ||
      task?.task_status_id === STATUS_CANCELLED,
    [task?.task_status_id],
  );

  const { chatButtons, isNotButton } = useButtonsLk(Number(id), taskIsClosed);

  const processChatMessages = useCallback(
    (messages?: MessageUI[]) => {
      const filtered = (messages || []).filter(isNotButton);
      return baseProcessChatMessages(filtered);
    },
    [isNotButton],
  );

  const cancelTaskHandler = useCallback(
    (taskId: number) => {
      cancelTask(
        taskId,
        undefined,
        new FeedParams({
          needShowLoader: true,
          throwNext: true,
          ignoreError: true,
        }),
      )
        .then()
        .catch();
    },
    [cancelTask],
  );

  const renderActionBtns = useCallback(() => {
    return (
      <div>
        {task && !taskIsClosed && (
          <Button
            type="button"
            danger
            title="Отменить запрос"
            className="titleline__button "
            onClick={() => setIsCancelConsultationOpen(true)}
            data-cy="cancel_question_btn"
          />
        )}
      </div>
    );
  }, [task, taskIsClosed]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={BREADCRUMBS_CONFIG} />
        <div className="titleline__right">{renderActionBtns()}</div>
      </TitleLine>
      {task && (
        <ConsultationInfo
          onToggleFavorite={() => {}}
          task={task}
          taskType="question"
          renderActionBtns={renderActionBtns}
        />
      )}
      {task && (
        <Chat
          chatId={id}
          task={task}
          processChatMessages={processChatMessages}
          taskIsClosed={taskIsClosed}
          chatButtons={chatButtons}
        />
      )}
      {task && !taskIsClosed && (
        <ChatCancelModal
          isOpen={isCancelConsultationOpen}
          setIsOpen={setIsCancelConsultationOpen}
          successHandler={() => cancelTaskHandler(task.id)}
          taskTypeText="запрос"
        />
      )}
    </>
  );
};
