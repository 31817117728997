import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { RestorePasswordByPhone } from './by-phone';
import { RestorePasswordScreenTypes } from './types';
import { RestorePasswordByEmail } from './by-email';
import { CheckFormCode, ServerErrorsHandler, useAuth } from '@eus/react-client';
import { addNotification } from '@ui';
import { getErrorTextForRestorePassword } from '@teledoctor/common/dist/lib/error-handlers/helpers';
import { CreatePassword } from './create-password';
import {
  OnSuccessForm,
  useRestorePasswordTd,
} from '@teledoctor/common/dist/hooks';
import { useHCaptcha } from '@eus/react-web-client/src/hooks/useHCaptcha';
import { SITE_KEY } from '@teledoctor/common/dist/consts/recaptcha';

interface Props {
  phone: string;
}

export const RestorePassword = ({ phone }: Props) => {
  const [type, setType] = useState<RestorePasswordScreenTypes>('phone');

  const {
    restorePasswordByPhone,
    verifyCode,
    changePassword,
    getCodeAgain,
    resetState,
    restorePasswordByEmail,
  } = useRestorePasswordTd(phone);

  const { askHcaptchaToken, component } = useHCaptcha(SITE_KEY);

  const verifySendedCode: OnSuccessForm<CheckFormCode> = useCallback(
    ({ code }, setError) => {
      return verifyCode({ restore_code: code })
        .then(() => {
          setType('createPassword');
        })
        .catch((err: AxiosError) => {
          let errorText = getErrorTextForRestorePassword(err);
          const errors = err.response?.data?.errors;

          if (errors && !Array.isArray(errors)) {
            ServerErrorsHandler(setError)(err);
            return;
          } else if (errorText) {
            setError('code', {
              type: 'server',
              message: errorText,
            });
          } else {
            addNotification({
              id: 'restore-password-' + new Date(),
              message:
                'Произошла неизвестная ошибка. Попробуйте еще раз позже.',
              type: 'error',
              target: 'authorization',
            });
          }
        });
    },
    [verifyCode],
  );

  const getCodeAgainHandler = useCallback(async () => {
    const recaptchaToken = await askHcaptchaToken();
    getCodeAgain(recaptchaToken);
  }, [askHcaptchaToken, getCodeAgain]);

  const getScreen = useCallback(() => {
    switch (type) {
      case 'phone':
        return (
          <RestorePasswordByPhone
            getCodeAgainHandler={getCodeAgainHandler}
            phone={phone}
            changeScreen={setType}
            resetState={resetState}
            restorePasswordByPhone={restorePasswordByPhone}
            verifyCode={verifySendedCode}
          />
        );
      case 'email':
        return (
          <RestorePasswordByEmail
            verifyCode={verifySendedCode}
            getCodeAgainHandler={getCodeAgainHandler}
            restorePasswordByEmail={restorePasswordByEmail}
          />
        );
      case 'createPassword':
        return <CreatePassword onSuccess={changePassword} />;
    }
  }, [
    phone,
    resetState,
    restorePasswordByPhone,
    type,
    verifySendedCode,
    getCodeAgainHandler,
    restorePasswordByEmail,
    changePassword,
  ]);

  return (
    <>
      {component}
      <h1 className="h1">
        {type !== 'createPassword'
          ? 'Восстановление пароля'
          : 'Создайте пароль'}
      </h1>
      {getScreen()}
    </>
  );
};
