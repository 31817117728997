interface Props {
  title: string;
  avatar: string;
  texts: (string | null)[];
}

export const ChatSpecialist = ({ avatar, texts, title }: Props) => {
  return (
    <div className="chat__message-text">
      {!!avatar && (
        <div className="chat__message-photo">
          <img
            className="chat__message-photo"
            src={avatar}
            alt="Фото специалиста"
          />
        </div>
      )}
      {!!title && <p className="chat__message-text-title">{title}</p>}
      {!!texts &&
        texts.map((item) => {
          return (
            <p className="chat__message-text-p" key={item}>
              {item}
            </p>
          );
        })}
    </div>
  );
};
