import './logout.scss';
import { Svg } from './svg';

interface LogoutProps {
  onClick: () => void;
  dataCy: string;
}

export const Logout = ({ onClick, dataCy }: LogoutProps) => {
  return (
    <button className="logout" onClick={() => onClick()} data-cy={dataCy}>
      <Svg name="logout" modClass="logout__icon" />
      <span>Выйти</span>
    </button>
  );
};
