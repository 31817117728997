import { useCallback } from 'react';
import { PaginatedList, Plug } from '@ui';
import { useAnalysisDecryptionData } from './use-decryption-data';
import { DataMob } from '../../health-detail/metric-viewer/';
import { DetailItemsListModal } from './detail-items-list-modal';

export const AnalysisDecryptionModal = () => {
  const {
    decryption,
    isError,
    isDone,
    isInProcessing,
    commonInfo,
    errorText,
    analysisItems,
  } = useAnalysisDecryptionData();

  const listComponent = useCallback(
    (array) => (
      <DetailItemsListModal
        analysisItems={array}
        withPager={analysisItems.length > 1}
      />
    ),
    [analysisItems],
  );
  return decryption ? (
    <div>
      {isDone && (
        <PaginatedList
          array={analysisItems}
          perPage={1}
          renderListComponent={listComponent}
          showMoreBtn={false}
          isPagerMobile={true}
        />
      )}

      {(isError || isInProcessing) && <DataMob data={commonInfo} />}

      {isError && errorText.length && (
        <div className="metric-viewer__detail-error-text">
          {errorText.map((paragraph, index) => (
            <p key={index} className="metric-viewer__detail-error-p">
              {paragraph}
            </p>
          ))}
        </div>
      )}
      {isInProcessing && (
        <div className="metric-viewer__detail-error-text">
          <p className="metric-viewer__detail-error-p">
            Обратите внимание, обработка может занять какое-то время вплоть до
            суток.
          </p>
          <p className="metric-viewer__detail-error-p">
            Мы направим уведомление на телефон, когда результаты будут готовы.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div className="-font__700 -text__red -text__center">Запись не найдена</div>
  );
};
