import { useHistory } from '@eus/react-web-client/src/hooks/useHistory';
import { useLoadList } from '@eus/react-web-client/src/hooks/useLoadList';
import {
  usePageSelector,
  useTasksListLoadingSelector,
  useTasksListTotalSelector,
} from '@eus/react-client/src/questions/selectors';
import { useQuestionsTdLk } from '@features/api';
import { push } from '@features/app/navigation.model';
import { $asList, changeMode } from '@features/consultations/model';
import { ListConsultation } from '@features/consultations/ui/list';
import { ListModeToggle } from '@features/consultations/ui/list-mode-toggle';
import { useParamsForSearchTask } from '@features/task/ui/list/hooks';
import { PER_PAGE } from '@features/task/ui/list/list';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  getTabsClassnames,
  Search,
  TitleLine,
} from '@ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './styles.scss';

interface QuestionProps {}

const BREADCRUMBS: BreadcrumbsItem[] = [
  {
    path: '/lk/tasks/',
    breadcrumb: 'Консультации',
  },
  {
    path: '/lk/tasks/questions',
    breadcrumb: 'Вопросы по услугам',
  },
];

const getTab = (filter) => {
  return filter?.is_active ? 1 : 0;
};

export const QuestionPage: FC<QuestionProps> = () => {
  const history = useHistory();
  const {
    card: defaultCard,
    loading: loadingCards,
    total: totalCard,
  } = useDefaultCard();
  const asList = useStore($asList);
  const { loadPerPage, page, filterLoaded, searchHandle } =
    useParamsForSearchTask('questions');

  const [selectedTab, setSelectedTab] = useState<number>(getTab(filterLoaded));

  const { loadTasksList: loadQuestionsList } = useQuestionsTdLk();

  const { curPage, perPage } = useLoadList({
    loadList: loadQuestionsList,
    defaultPagination: {
      page: page,
      perPage: PER_PAGE,
    },
  });

  const tasks = usePageSelector(curPage, perPage);
  const total = useTasksListTotalSelector();
  const loading = useTasksListLoadingSelector();

  const tabsClasses = getTabsClassnames([
    'page--main-template',
    'consultations',
  ]);

  const handleTabChange = (index: number): void => {
    history.push(
      `questions?filter={${
        index === 1 ? '"is_active":1' : ''
      }}&order=DESC&sort=id&page=1&perPage=${loadPerPage}`,
    );
    setSelectedTab(index);
  };

  const clickGoToQuestions = useCallback(() => {
    if (!defaultCard) {
      push({ path: '/lk/cards' });
    } else {
      push({
        path: 'questions/ask',
        state: { cardClientId: defaultCard.card_client_id },
      });
    }
  }, [defaultCard]);

  return (
    <React.Fragment>
      <TitleLine className={'questions__title-line'}>
        {/* <div className="">
          <Search
            onChange={searchHandle}
            className="consultations-header__search"
          />
        </div> */}
        <Breadcrumbs
          props={BREADCRUMBS}
          additionalClassName={'questions__breadcrumbs'}
        />

        <div className="questions__item--btn">
          <Button
            title="Задать вопрос"
            className="consultations-get button__mediumslateblue titleline__button"
            onClick={clickGoToQuestions}
            disabled={loadingCards}
            data-cy="ask_new_questions_btn"
          />
        </div>
      </TitleLine>
      <Tabs
        className={tabsClasses.tabs}
        selectedIndex={selectedTab}
        onSelect={handleTabChange}>
        <TabList className={tabsClasses.tabList}>
          <Tab className={tabsClasses.tab} data-cy="tab_questions_all">
            Все
          </Tab>
          <Tab className={tabsClasses.tab} data-cy="tab_questions_active">
            Активные
          </Tab>
        </TabList>
        <TabPanel
          className={tabsClasses.panel}
          data-cy="tab_panel_questions_all">
          <ListConsultation
            total={total}
            tasks={tasks || []}
            showAsList={asList}
            isRegularTasks={false}
            pathNamespace={'questions'}
            isQuestionsList={true}
            loading={loading}
            label={'Здесь будут отображаться все Ваши вопросы по услугам'}
            hideServicesColumn={true}
          />
        </TabPanel>
        <TabPanel
          className={tabsClasses.panel}
          data-cy="tab_panel_questions_active">
          <ListConsultation
            total={total}
            tasks={tasks || []}
            showAsList={asList}
            isRegularTasks={false}
            filter={'active'}
            pathNamespace={'questions'}
            isQuestionsList={true}
            loading={loading}
            label={'Здесь будут отображаться Ваши активные вопросы по услугам'}
            hideServicesColumn={true}
          />
        </TabPanel>
        <ListModeToggle
          isList={asList}
          onChange={changeMode}
          className="consultations__list-mode-toggle"
        />
      </Tabs>
    </React.Fragment>
  );
};
