import React from 'react';
import { formatHealthIndex } from '@eus/health-default-logic/dist/health-index/formatters';
import { IndexDataInterface } from './types';
import './health-index-scale.scss';

export const HealthIndexScale = ({
  indexData,
  disabled,
}: IndexDataInterface) => {
  return (
    <div className="health-index__scale health-index-scale">
      <div className="health-index-scale__checkpoint health-index-scale__top health-index-scale__left">
        86 - 100
        <br />
        Отлично
      </div>
      <div className="health-index-scale__checkpoint health-index-scale__top health-index-scale__right">
        1 - 50
        <br />
        Плохо
      </div>
      <div className="health-index-scale__checkpoint health-index-scale__bottom health-index-scale__left">
        71 - 85
        <br />
        Хорошо
      </div>
      <div className="health-index-scale__checkpoint health-index-scale__bottom health-index-scale__right">
        51 - 70
        <br />
        Удовл-но
      </div>
      <div className="health-index-scale__values">
        <div className="health-index-scale__text">Индекс здоровья</div>
        <div className="health-index-scale__value">
          {!disabled && indexData}
        </div>
        <div className="health-index-scale__text">
          {disabled ? 'Нет данных' : formatHealthIndex(indexData)}
        </div>
      </div>
      <div className="health-index-scale__tick health-index-scale__tick-vertical health-index-scale__tick-top" />
      <div className="health-index-scale__tick health-index-scale__tick-horizontal health-index-scale__tick-right" />
      <div className="health-index-scale__tick health-index-scale__tick-vertical health-index-scale__tick-bottom" />
      <div className="health-index-scale__tick health-index-scale__tick-horizontal health-index-scale__tick-left" />
    </div>
  );
};
