import { Controller } from 'react-hook-form';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';
import { months } from '@eus/crm-default-logic/src/formatters/date';
import {
  DatetimePicker,
  DateTimePickerProps,
  Datetime,
} from '@features/shared/ui/datetime-picker';
import {
  DatetimePickerInput,
  DateTimePickerInputProps,
} from '@features/shared/ui/datetime-picker-input';

export interface FieldDateTimeProps<T>
  extends FieldProps<T>,
    Omit<DateTimePickerInputProps, 'onChange' | 'className' | 'disabled'> {
  className?: string;
  onFieldChange?: (value: Datetime) => void;
}

export function FieldDateTime<T>({
  dataCy,
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  onFieldChange,
  disabled,
  baseURL,
  datesResource,
  timeResource,
  ...inputDateTimeProps
}: FieldDateTimeProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      // @ts-ignore
      defaultValue={inputDateTimeProps.value}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputDateTimeProps['data-cy']}>
            <DatetimePickerInput
              value={value}
              baseURL={baseURL}
              datesResource={datesResource}
              timeResource={timeResource}
              onChange={(value) => {
                onChange(value);
                onFieldChange?.(value);
              }}
              dataCy={dataCy}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
