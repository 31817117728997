import { createEvent, createStore } from 'effector';

import { withPersist } from '@lib/with-persist';

/**
 * Stores
 */

export const $asList = createStore(false, {
  name: 'consultations:show-as-list',
});
withPersist($asList);

/**
 * Events
 */

export const changeMode = createEvent<boolean>('consultations:toggle-mode');

/**
 * Connections
 */

$asList.on(changeMode, (_, payload) => payload);
