import { useEffect, useMemo, useState } from 'react';
import { TariffItem } from '@features/tariffs/ui';
import './../variables.scss';
import styles from './style.module.scss';
import classNames from 'classnames';
import { useCardsTdLk, useTariffsForBuyTdLk } from '@features/api';
import { showLoader, hideLoader } from '@ui';
import {
  useCardsListLoadingSelector,
  useCardsListSelector,
} from '@eus/react-client';
import {
  TariffTdCalculated,
  getCalculatedTariffs,
} from '@teledoctor/common/dist/features/tariffs';

export const Tariffslist = () => {
  const [listLoadingStatus, setListLoadingStatus] = useState<boolean>(false);

  const { loading: tariffsLoading, tariffs } = useTariffsForBuyTdLk();

  const { loadCardsList } = useCardsTdLk();
  const cards = useCardsListSelector();
  const cardsLoading = useCardsListLoadingSelector();

  useEffect(() => {
    loadCardsList({ pagination: { page: 1, perPage: 50 } });
  }, [loadCardsList]);

  useEffect(() => {
    listLoadingStatus ? showLoader() : hideLoader();
  }, [listLoadingStatus]);

  useEffect(() => {
    setListLoadingStatus(!cardsLoading && !tariffsLoading ? false : true);
  }, [cardsLoading, tariffsLoading]);

  const calculatedTariffs: TariffTdCalculated[] = useMemo(
    () =>
      tariffs?.length
        ? cards?.length
          ? getCalculatedTariffs(tariffs, cards)
          : tariffs
        : [],
    [tariffs, cards],
  );

  return (
    <div className={classNames('grid__list', styles.tariffs)}>
      {!!calculatedTariffs.length &&
        calculatedTariffs.map((tariff) => (
          <TariffItem key={`tariff-item-${tariff.id}`} tariff={tariff} />
        ))}
    </div>
  );
};
