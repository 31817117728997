import { Controller } from 'react-hook-form';
import classnames from 'classnames';

import {
  InputCheckbox,
  checkErrorForField,
  InputCheckboxProps,
} from '../../../atoms';
import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';

interface FieldInputRadioProps<T>
  extends FieldProps<T>,
    Omit<InputCheckboxProps, 'onChange' | 'checked' | 'name' | 'hasError'> {
  inputLabel?: string;
  onFieldChange?: (value: any) => void;
}

export function FieldInputCheckbox<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  disabled,
  onFieldChange,
  inputLabel,
  ...inputProps
}: FieldInputRadioProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={classnames(className, 'field--no-border')}
            labelClassName={labelClassName}>
            <InputCheckbox
              {...inputProps}
              // @ts-ignore
              checked={value}
              name={name}
              label={inputLabel}
              className={inputClassName}
              onChange={(value) => {
                onChange(value);
                onFieldChange?.(value);
              }}
              hasError={checkErrorForField(name, methods.formState.errors)}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
