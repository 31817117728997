import { ServerErrorsHandler } from '@eus/react-client';
import { CreateTaskData } from '@eus/react-client/src/support/forms/createTask';
import { getSelectOptions } from '@eus/react-client/src/tasks/schema';
import { useSupportTdLk } from '@features/api';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  commonFormErrorHandler,
  useErrorMessage,
} from '@teledoctor/common/dist/features/shared/forms';
import { ProfileData } from '@teledoctor/common/dist/features/user';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import {
  addNotification,
  Button,
  FField,
  FileInputInterface,
  FormWrapper,
} from '@ui';
import { useState } from 'react';
import { DeepPartial, UnpackNestedValue, useForm } from 'react-hook-form';
import { useCreateTaskSchema } from './schema';

interface SupportFormProps {
  contacts: Pick<ProfileData, 'phone'>;
  defaultValues?: UnpackNestedValue<DeepPartial<SupportFormValues>>;
  onFormSent: () => void;
}

export interface SupportFormValues extends CreateTaskData {}

const answerTypeOptions = getSelectOptions('answer_type').slice(0, 2);

export const SupportForm = ({
  defaultValues,
  contacts,
  onFormSent,
}: SupportFormProps) => {
  const { createTaskSchema } = useCreateTaskSchema();
  const { ErrorMessage } = useErrorMessage();
  const schema = createTaskSchema(ErrorMessage);

  const methods = useForm<SupportFormValues>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues,
  });

  const [isTaskBeingSent, setIsTaskBeingSent] = useState(false);
  const { createTask: createSupportTask } = useSupportTdLk();

  const answerWayRadioOptions = [
    {
      label: answerTypeOptions[0].label,
      value: answerTypeOptions[0].value,
    },
    {
      label: answerTypeOptions[1].label + ' ' + contacts.phone,
      value: answerTypeOptions[1].value,
    },
  ];

  const handleSuccess = (values: SupportFormValues) => {
    setIsTaskBeingSent(true);
    return createSupportTask(
      { data: values },
      new FeedParams({
        throwNext: true,
        ignoreError: true,
        needShowLoader: true,
      }),
    )
      .then(() => {
        onFormSent();
        methods.reset(defaultValues);
      })
      .catch(
        ServerErrorsHandler(methods.setError, (err) =>
          addNotification({
            message: err,
            target: 'global',
            id: `create support task ${Date.now()}`,
            type: 'error',
          }),
        ),
      )
      .finally(() => {
        setIsTaskBeingSent(false);
      });
  };

  return (
    <FormWrapper
      className="support-form"
      onSubmit={methods.handleSubmit(
        handleSuccess,
        commonFormErrorHandler('support'),
      )}>
      <FField.Select
        name="answer_type"
        label="Способ ответа"
        className="form__field"
        methods={methods}
        options={answerWayRadioOptions}
        dataCy="aupport-appeal_select_answer_type"
      />
      <FField.TextArea
        name="description"
        label="Суть вопроса"
        placeholder="Опишите проблему"
        methods={methods}
        className="form__field"
        data-cy="SUPPORT_COMMENT_INPUT"
      />
      <FField.File
        name="files"
        methods={methods}
        className="form__field"
        maxFileSize={20}
      />
      <div className="form__field">
        <Button
          title="Отправить"
          className="-width__xs-100"
          appearance="solid"
          data-cy="SUPPORT_SUBMIT_BTN"
          disabled={isTaskBeingSent}
        />
      </div>
    </FormWrapper>
  );
};
