import React, { useState } from 'react';
import { getAvatarPath } from '@teledoctor/common/dist/lib/avatar';

import './avatar.scss';
import classnames from 'classnames';

export type AvatarSexType = 'М' | 'Ж' | null | undefined;

interface AvatarProps {
  url: string;
  sex?: AvatarSexType;
  className?: string;
  children?: React.ReactChild;
}

export const Avatar = ({
  url,
  sex = null,
  className,
  children,
}: AvatarProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={classnames('avatar', className, {
        'avatar--is-loading': loading,
      })}>
      <div className="avatar__image">
        <img
          src={url || getAvatarPath(sex)}
          onLoad={() => setLoading(false)}
          onError={() => setLoading(false)}
        />
      </div>
      {children}
    </div>
  );
};
