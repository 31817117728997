import { isIOS, isAndroid } from 'react-device-detect';
import googleplayLogo from '@assets/img/google-play.png';
import appstoreLogo from '@assets/img/app-store.png';

import './app-links.scss';

const nonTargetPlatforms = !isAndroid && !isIOS;

export const AppLinks = () => {
  return (
    <div className="app-links">
      {(isAndroid || nonTargetPlatforms) && (
        <a
          href="https://play.google.com/store/apps/details?id=com.teledoctor24.app"
          target="_blank"
          className="app-links__item image">
          <img src={googleplayLogo} alt="Google play" title="Google play" />
        </a>
      )}
      {(isIOS || nonTargetPlatforms) && (
        <a
          href="https://apps.apple.com/ru/app/%D1%82%D0%B5%D0%BB%D0%B5%D0%B4%D0%BE%D0%BA%D1%82%D0%BE%D1%8024/id1422683292"
          target="_blank"
          className="app-links__item image">
          <img src={appstoreLogo} alt="App store" title="App store" />
        </a>
      )}
    </div>
  );
};
