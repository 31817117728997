import React from 'react';
import { createEvent } from 'effector';
import './my-cards.scss';
import { EmptyStateCard } from './empty-state';
import { CardItem } from '@features/card/ui';
import { Card } from '@eus/react-client/src/cards/types';

export const openedCard = createEvent<number>();

export interface CardsItem {
  tariff?: string;
  number?: string;
  date?: string;
  status?: string;
}

export interface Cards extends CardsItem {
  id: number;
}

interface CardProps {
  data: (Card | null)[];
}

export const CardModel: React.FC<CardProps> = ({ data }) => {
  if (!data.length) {
    return <EmptyStateCard />;
  }

  return (
    <div className="my-cards">
      {data.map((card) => {
        return card && <CardItem key={card.id} item={card} />;
      })}
    </div>
  );
};
