import { File } from '@eus/react-client/src/types/file';
import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import * as yup from 'yup';
import { CreateTaskData } from '@eus/react-client/src/support/forms/createTask';
import { useCallback } from 'react';

export const useCreateTaskSchema = () => {
  const { ErrorMessage } = useErrorMessage();
  const createTaskSchema = useCallback(
    (errorMessage: typeof ErrorMessage): yup.SchemaOf<CreateTaskData> => {
      return yup.object().shape({
        service_id: yup.number().required(),
        description: yup
          .string()
          .trim()
          .required(() => errorMessage.required)
          .min(3, (params) => errorMessage.min_length_equals(params.min)),
        files: yup.array(),
        answer_type: yup.mixed().required(() => errorMessage.required),
        tech_os: yup.string(),
        //   .required(() => errorMessage.required)
        //   .required(() => errorMessage.required)
        //   .max(15, (params) => errorMessage.max_length_equals(params.max)),
        tech_smartphone: yup.string(),
        //   .required(() => errorMessage.required)
        //   .max(30, (params) => errorMessage.max_length_equals(params.max)),
        tech_mp_version: yup.string(),
        //   .required(() => errorMessage.required)
        //   .max(17, (params) => errorMessage.max_length_equals(params.max)),
      });
    },
    [],
  );
  return { createTaskSchema };
};
