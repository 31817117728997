import React from 'react';
import './health-index-dates.scss';
import { HealthIndexDatesNote } from './health-index-note';
import type { HealthIndexMetricsDatesItem } from '@teledoctor/common/dist/features/metrics/model/health-index';
import { HealthIndexDatesItem } from './health-index-dates-item';

interface Props {
  data: HealthIndexMetricsDatesItem[];
}

export const HealthIndexDates = ({ data }: Props) => {
  const hasOldData = data.some((metricItem) => metricItem.isOld);
  const hasMissingMeasures = data.some(
    (metricItem) => metricItem.datetime === undefined,
  );
  return (
    <>
      <div className="block -grow__1 metric-chart__table">
        <ul className="health-index-dates">
          {data.map((metricItem) => (
            <HealthIndexDatesItem
              metricItem={metricItem}
              key={metricItem.metricsName}
            />
          ))}
        </ul>
      </div>
      {(hasOldData || hasMissingMeasures) && <HealthIndexDatesNote />}
    </>
  );
};
