import { cardIsActive } from '@eus/react-client/src/cards/helpers';
import { CardInfo } from './info';
import { Card } from '@eus/react-client/src/cards/types';
import style from './card-item.module.scss';
import { CardDetail } from './detail';
import { EmptyInfo } from './info/empty';
import { SingleTariffType } from '@features/tariffs/ui';
import { SINGLE } from '@eus/els-api-types/src/td-type-tariff-for-buy';
import { useMemo } from 'react';

export interface CardItemProps {
  item: Card;
}

export const CardItem = ({ item }: CardItemProps) => {
  const isSingle = useMemo(
    () => !!item.card_type && item.card_type === SINGLE,
    [item.card_type],
  );

  return (
    <div className={style.item}>
      <div className={style.header}>
        <div className={style.tariff}>{item.tariff_name}</div>
        {(!!item.limit || item.limit === 0) &&
          (!!item.used || item.used === 0) &&
          (
            <div className={style.limit}>
              {item.limit - item.used}/{item.limit} осталось
            </div>
          )!}
      </div>
      {isSingle && <SingleTariffType className={style['one-time']} />}
      <div className={style.detail}>
        <div className={style.services}>
          {cardIsActive(item) ? <CardInfo card={item} /> : <EmptyInfo />}
        </div>
        <CardDetail card={item} />
      </div>
    </div>
  );
};
