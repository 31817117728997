import classNames from 'classnames';
import detailStyles from './../styles.module.scss';
import { Button, FField } from '@ui';
import { SelectCountry } from '@features/shared/ui';
import { UseFormReturn } from 'react-hook-form';
import { RegistrationInfoFormValues } from '@teledoctor/common/dist/features/user/forms';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  methods: UseFormReturn<RegistrationInfoFormValues, any>;
  onNextClick?: () => void;
}

export const RegisterFirstStepPage = ({ methods, onNextClick }: Props) => {
  const [isValid, setValidStatus] = useState<boolean>(false);

  const onNextHandler = useCallback(() => {
    if (onNextClick) {
      methods.trigger();
      if (isValid) {
        onNextClick();
      }
    }
  }, [onNextClick, methods, isValid]);

  const formWatcher = methods.watch();

  useEffect(() => {
    setValidStatus(methods.formState.isValid);
  }, [formWatcher, methods]);

  return (
    <>
      <h1 className={classNames('h1', detailStyles.title)}>Регистрация</h1>
      <div className={detailStyles.description}>
        <div className={detailStyles['description__bold']}>
          Добро пожаловать!
        </div>
        <br />
        Похоже, что вы здесь впервые и ещё не зарегистрированы в нашей системе,
        пожалуйста, заполните форму ниже. Это не займет много времени!
      </div>

      <FField.Input
        name="last_name"
        methods={methods}
        label="Фамилия"
        placeholder="Введите Вашу фамилию"
        type="text"
        className="form__field"
        data-cy="PROFILE_LASTNAME_INPUT"
      />
      <FField.Input
        name="first_name"
        methods={methods}
        label="Имя"
        placeholder="Введите Ваше имя"
        type="text"
        className="form__field"
        data-cy="PROFILE_FIRSTNAME_INPUT"
      />
      <SelectCountry
        name="country_code"
        control={methods.control}
        label="Страна"
        errors={methods.formState.errors}
        fieldClassname="form__field"
        isClearable={true}
        dataCy="profile_select_country"
      />
      <div className={detailStyles.footnote}>
        Проходя регистрацию, вы принимаете условия{' '}
        <a href="" className={detailStyles['footnote__link']}>
          пользовательского соглашения
        </a>
        , и даете согласие на обработку персональных данных
      </div>
      <Button title="Далее" type="button" fullWidth onClick={onNextHandler} />
    </>
  );
};
