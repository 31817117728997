import React, { useEffect, useMemo } from 'react';
import { TitleLine, Breadcrumbs, BreadcrumbsItem, Svg } from '@ui';
import { useQuery } from '@router';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';

import {
  BirthmarkDetail,
  BirthmarkModal,
} from '@features/health/ui/metrics/birthmarks';
import { MetricViewer, MetricWrapper } from '@features/health/ui/health-detail';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/birthmark',
    breadcrumb: 'Родинки',
  },
];

export const BirthmarkPage = () => {
  const query = useQuery();
  const birthmarkId = query.get('id') || '';

  const {
    metric: { loadBirthmarksList, $birthmarksCrud },
  } = useMetric({ metric: 'birthmarks' });

  const birthmarksCrud = useStore($birthmarksCrud);
  const birthmarksList = birthmarksCrud.list;

  const birthmarksListSortedByDate = birthmarksList.sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  useEffect(() => {
    loadBirthmarksList({
      uiBehavior: {
        noLoader: !!birthmarksCrud.lastUpdated,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!birthmarksCrud.lastUpdated]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MetricWrapper
        bottomText={
          birthmarksListSortedByDate
            ? 'Чтобы загрузить снимок, создайте новый запрос в личном кабинете в мобильном приложении'
            : ''
        }>
        <MetricViewer
          data={birthmarksListSortedByDate.map((item) => ({
            id: item.id,
            name: `${item.probability}% ${item.disease}`,
            timestamp: item.timestamp,
          }))}
          elementId={birthmarkId}
          emptyDataText={
            'Чтобы загрузить снимок, создайте новый запрос в мобильном приложении.'
          }
          chooseElementText={'Выберите родинку из списка слева'}
          rightColumnRender={() => <BirthmarkDetail />}
          modalRender={() => <BirthmarkModal />}
        />
      </MetricWrapper>
    </>
  );
};
