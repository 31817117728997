import { useMemo, FC, useState, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import { Card, Service } from '@eus/react-client/src/cards/types';
import { useServicesListSelector } from '@eus/react-client/src/cards/selectors';
import { CardInfoServicesItem } from '@features/card/ui/card/info/service';
import { EmptyInfo } from './empty';
import style from './card-info.module.scss';
import classNames from 'classnames';
import { useCardsTdLk } from '@features/api';

interface CardInfoProps {
  card: Card;
}

interface ServicesList {
  head: Service[];
  tail?: Service[];
}

const $DISPLAYED_SERVICES = 3;

export const CardInfo: FC<CardInfoProps> = ({ card }) => {
  const [services, setServices] = useState<Service[]>();
  const [isCollapsed, setCollapsedStatus] = useState<boolean>(false);

  const { loadServices } = useCardsTdLk();

  useEffect(() => {
    if (!card.card_client_id) {
      return;
    }

    loadServices(card.card_client_id)
      .then((data) => setServices(data))
      .catch((error) => {
        console.error(error);
        setServices([]);
      });
  }, [loadServices, card.card_client_id]);

  const servicesList: ServicesList = useMemo(() => {
    if (!!services && services.length > 0) {
      if (services!.length > $DISPLAYED_SERVICES) {
        const copiedServices = [...services];
        const tail = copiedServices!.splice($DISPLAYED_SERVICES);
        return { head: copiedServices, tail };
      } else {
        return { head: services };
      }
    } else {
      return { head: [] };
    }
  }, [services]);

  return !!services && services.length > 0 ? (
    <>
      {servicesList.head.map((service) => {
        return (
          <CardInfoServicesItem
            cardClientId={card.card_client_id}
            service={service}
            key={service.id}
            {...((!!card.limit || card.limit === 0) &&
              (!!card.used || card.used === 0) && {
                withLimit: false,
              })}
          />
        );
      })}
      {!!servicesList.tail && (
        <>
          <Collapse isOpened={isCollapsed}>
            {servicesList.tail.map((service) => {
              return (
                <CardInfoServicesItem
                  cardClientId={card.card_client_id}
                  service={service}
                  key={service.id}
                  {...((!!card.limit || card.limit === 0) &&
                    (!!card.used || card.used === 0) && {
                      withLimit: false,
                    })}
                />
              );
            })}
          </Collapse>
          <div
            className={style.expand}
            onClick={() => setCollapsedStatus((prev) => !prev)}>
            {isCollapsed ? 'Скрыть' : 'Показать еще'}
            <span
              className={classNames(
                style['expand__icon'],
                isCollapsed && style['expand__icon-opened'],
              )}
            />
          </div>
        </>
      )}
    </>
  ) : (
    <EmptyInfo />
  );
};
