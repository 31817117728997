import { NavLink } from 'react-router-dom';
import style from './empty.module.scss';
import { Button } from '@ui';

export const EmptyInfo = () => {
  return (
    <div className={style.empty}>
      <div className={style['empty__title']}>У вас нет доступных услуг. </div>
      Перейдите в раздел «Выбрать тариф»
      <NavLink to="tariffs" className={style['empty__button']}>
        <Button title="Выбрать тариф" />
      </NavLink>
    </div>
  );
};
