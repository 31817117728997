import React from 'react';
import { MessageFile, MessageUI } from '@eus/react-client/src/chat_v2/types';
import { useFileAccess } from '@features/chat/hooks';
import { AddFile, Svg } from '@ui';
import { ChatMessageSenderInfo } from '../chat/chat-message-sender-info';
import { MessageType } from '@eus/chat-default-logic/src/messages';
import { getFileUrl, VariedFile } from './chat-message';
import { AddFileInterface } from '@teledoctor/common/dist/features/audio-player/types';

interface Props {
  user: MessageUI['user'];
  file: MessageFile | VariedFile;
  messageType: MessageType;
}

export const ChatDocFile: React.FC<Props> = ({
  file,
  user,
  messageType,
  children,
}) => {
  const fileUrl = getFileUrl(file);
  const { path, loading } = useFileAccess(fileUrl);

  const fileElem: AddFileInterface = {
    fileType: 'document',
    name: file.name ?? '',
    size: file.size ?? 0,
    src: path ?? '',
    modClass: 'chat__message-file-inner',
  };

  return (
    <div className="chat__message chat__message-file">
      <AddFile file={fileElem} />
      {path && (
        <a
          href={fileElem.src}
          target="_blank"
          download=""
          className="chat__message-file-download">
          <Svg name="download" />
        </a>
      )}
      <ChatMessageSenderInfo user={user} messageType={messageType} />
      {children}
    </div>
  );
};
