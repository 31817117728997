import { DataMob } from '../../health-detail/metric-viewer/';
import {
  CHECKUP_INTERPRETATION_DISCLAIMER,
  CHECKUP_WAIT_PROCESSING,
} from '@teledoctor/common/dist/lib/text-constants';
import { ImgPreview, ExplanationGray, Svg, AddFileAudio } from '@ui';
import { useCoughData } from './use-cough-data';

export const CoughModal = () => {
  const { cough, tableData, audio, isError, isPending, isSuccess, comment } =
    useCoughData();

  return cough ? (
    <>
      <div>
        <DataMob data={tableData} />

        {audio && (
          <div className="cough-detail">
            <div className="cough-detail__audio-label">Прикрепленный файл</div>

            {isError && (
              <div className="cough-detail__audio-warning">
                <Svg name="warning" />
              </div>
            )}
            <div className="cough-detail__audio-line">
              <AddFileAudio file={audio} />
            </div>
          </div>
        )}
      </div>

      <div className="metric-viewer__modal-detail-info">
        {comment && (
          <>
            <div className="metric-viewer__modal-detail-title">
              <span> {comment.title} </span>
            </div>

            <p className="metric-viewer__modal-detail-text">{comment.text}</p>
          </>
        )}

        {isPending && (
          <p className="metric-viewer__detail-description--empty">
            {CHECKUP_WAIT_PROCESSING}
          </p>
        )}

        {isSuccess && (
          <ExplanationGray text={CHECKUP_INTERPRETATION_DISCLAIMER} />
        )}
      </div>
    </>
  ) : (
    <div className="-font__700 -text__red -text__center">Запись не найдена</div>
  );
};
