import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@ui';
import { ModulesManagerList } from '@features/health/ui';

import './modules-manager-modal.scss';
import { useStore } from 'effector-react';
import { $testimonyModules } from '../model';
import { MetricsWebInterface } from '@features/health/models/metrics/summary-modules';
import { Metrics as MetricType } from '@eus/health-default-logic/dist/modules/constants';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';

interface ModulesManagerModalInterface {
  isOpen: boolean;
  onClose: () => void;
}

export const ModulesManagerModal: React.FC<ModulesManagerModalInterface> = ({
  isOpen,
  onClose,
}) => {
  const modulesStore = useStore($testimonyModules);
  const [modules, setModules] = useState<MetricsWebInterface[]>(modulesStore);
  const {
    metric: { storeModuleConfig, load: loadSummary },
  } = useMetric({ metric: 'summary' });
  useEffect(() => {
    setModules(modulesStore);
  }, [modulesStore]);
  const onChangeItemHandler = (changedId: MetricType) => {
    setModules((modules) =>
      modules.map((module) =>
        module.id === changedId
          ? { ...module, hidden: !module.hidden }
          : module,
      ),
    );
  };
  const onChangeListHandler = (changedModules: MetricsWebInterface[]) => {
    setModules(changedModules);
  };
  const closeModalHandler = () => {
    setModules(modulesStore);
    onClose();
  };
  const saveItemsHandler = () => {
    const codes = modules.reduce(
      (tot, cur) => {
        return {
          ...tot,
          state: {
            ...tot.state,
            active: [...tot.state.active, ...(!cur.hidden ? [cur.id] : [])],
            additional: [
              ...tot.state.additional,
              ...(cur.hidden ? [cur.id] : []),
            ],
          },
        };
      },
      {
        state: { active: [] as MetricType[], additional: [] as MetricType[] },
      },
    ).state;
    storeModuleConfig(codes);
    onClose();
  };
  const shownModules = modules.filter((item) => !item.developing);
  return (
    <Modal
      onClose={closeModalHandler}
      isOpen={isOpen}
      short={true}
      header="Менеджер модулей"
      footer={
        <Button
          title="Сохранить"
          className="-width__xs-100"
          onClick={saveItemsHandler}
          data-cy="HEALTH_MODULES_MANAGER_SAVE_BTN"
        />
      }
      className="modules-manager__modal">
      <div className="modules-manager__modal-wrapper">
        <div className="description -mb__xxtiny">
          <p>
            Вы можете настраивать видимость модулей, а также их порядок на
            главном экране.
          </p>
        </div>
        <ModulesManagerList
          modules={shownModules}
          onChangeItemHandler={onChangeItemHandler}
          onChangeListHandler={onChangeListHandler}
        />
      </div>
    </Modal>
  );
};
