import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { TitleLine } from '@ui';
import { ProfileInfo, EditPassword } from '@features/user/ui';
import { useProfileTdLk } from '../../features/api';

export const ProfilePage = () => {
  const { profile, load } = useProfileTdLk();
  const initialWithoutPass = useRef(!!profile?.without_pass).current;

  useEffect(() => {
    if (!profile) {
      load();
    }
  }, [load, profile]);

  return (
    <React.Fragment>
      <TitleLine titleText="Профиль" className="titleline--profile">
        {initialWithoutPass && (
          <EditPassword
            login={profile?.phone}
            type="create"
            triggerClassName="titleline__button"
          />
        )}
      </TitleLine>
      {profile && <ProfileInfo user={profile} />}
    </React.Fragment>
  );
};
