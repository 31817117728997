import { Controller, FieldValues } from 'react-hook-form';

import { InputSelect, InputSelectProps } from '../../../atoms';
import { FieldWrapper } from '../../../molecules';

import { FieldProps } from './types';

export interface FieldSelectProps<T extends FieldValues>
  extends FieldProps<T>,
    InputSelectProps {
  onFieldChange?: (value) => void;
}

export function FieldSelect<T extends FieldValues>({
  name,
  label,
  methods,
  className,
  errorsClassName,
  labelClassName,
  inputClassName,
  disabled,
  onFieldChange,
  defaultValue,
  ...inputSelectProps
}: FieldSelectProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputSelectProps.dataCy}>
            <InputSelect
              {...inputSelectProps}
              disabled={disabled}
              defaultValue={value ?? defaultValue}
              className={inputClassName}
              onChange={(value) => {
                onChange(value);
                onFieldChange?.(value);
                onBlur();
              }}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
