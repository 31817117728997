import { useEffect, useState } from 'react';
import './circle.scss';

interface Props {
  indexData: number;
  calcValueForProgressBar: (index: number) => number;
}

export const CircleGraph = ({ indexData, calcValueForProgressBar }: Props) => {
  const radius = 180;
  const size = 400;
  const position = size / 2;
  const circleProps = {
    cx: position,
    cy: position,
    r: radius,
  };
  const length = 2 * Math.PI * radius;
  const dashOffset =
    length - calcValueForProgressBar(indexData) * (length / 100);

  const [dashOffSetVal, setDashOffSetVal] = useState<number>(length);

  useEffect(() => {
    setTimeout(() => {
      setDashOffSetVal(dashOffset);
    }, 100);
  }, [dashOffset]);

  return (
    <div className="circle-graph">
      <svg
        version="1.1"
        viewBox={`0 0 ${size} ${size}`}
        preserveAspectRatio="xMinYMin meet"
        className="circle-graph__content">
        <circle
          className="circle-graph__item circle-graph__item-basis"
          {...circleProps}
        />
        <circle
          className="circle-graph__item circle-graph__item-progress"
          strokeDashoffset={dashOffSetVal}
          strokeDasharray={length}
          strokeLinecap="round"
          {...circleProps}
        />
      </svg>
    </div>
  );
};
