import React from 'react';
import './data-mob.scss';

interface Props {
  data: {
    label: string;
    value?: string | null;
    modClass?: string;
  }[];
}

export const DataMob = ({ data }: Props) => {
  return (
    <div className="data-mob">
      {data.map((item, index) => (
        <div className="data-mob__item" key={index}>
          <p className="data-mob__label">{item.label}</p>
          <p className={`data-mob__value ${item.modClass || ''}`}>
            {item.value || ''}
          </p>
        </div>
      ))}
    </div>
  );
};
