import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import {
  Table as TableData,
  Chart,
  ValuesList,
  NoMeasures,
  Toggler,
} from '@features/health/ui/health-detail/metric-chart/ui/';
import {
  CommonValuesProps,
  CommonTableProps,
} from '@teledoctor/common/dist/metrics/type';
import { MetricModel } from '@teledoctor/common/dist/features/metrics';
import { useSomePeriodHook } from '@features/health/models/metrics/hooks';
import './metric-chart.scss';
import { ChartBaseProps } from '@features/health/ui/health-detail/metric-chart';
import classnames from 'classnames';

type MetricChartInterface<
  T extends CommonTableProps,
  B extends CommonValuesProps,
> = ChartBaseProps & {
  metric: keyof MetricModel;
  renderMetricTable?: (props: T) => ReactNode;
  renderMetricValues?: (props: B) => ReactNode;
  modClass?: string;
};

export type MetricChartCommonProps<
  T extends CommonTableProps,
  B extends CommonValuesProps,
> = Omit<MetricChartInterface<T, B>, 'period'>;

export const MetricChart = <
  T extends CommonTableProps,
  B extends CommonValuesProps,
>({
  metric: metricType,
  period,
  renderMetricTable,
  renderMetricValues,
  modClass,
  ...chartProps
}: MetricChartInterface<T, B>) => {
  const { metric } = useMetric({ metric: metricType });
  const metricByPeriod = useSomePeriodHook(period, metric)();
  const [metricData, , onLeft, onRight, , formatXLabel, togglerText] =
    metricByPeriod;

  const [newData, setNewData] = useState(metricData);

  useEffect(() => {
    if (metricData) {
      setNewData(metricData);
    }
  }, []);

  useEffect(() => {
    if (metricData?.items) {
      if (JSON.stringify(metricData.items) !== JSON.stringify(newData?.items)) {
        setNewData(metricData);
      }
    } else if (JSON.stringify(metricData) !== JSON.stringify(newData)) {
      setNewData(metricData);
    }
  }, [metricData]);

  const dataMap = (data) => {
    return data.map(({ datetime, ...item }, index) => ({
      datetime: formatXLabel(index),
      ...item,
    }));
  };

  const savedData = useMemo(() => {
    if (newData) {
      if (period === 'day') {
        return dataMap(dayPeriodDataEditor(newData));
      }
      return dataMap(newData);
    }
    return null;
  }, [newData]);

  const data = useMemo(() => {
    const dataPeriod =
      period === 'day' && newData !== undefined
        ? dayPeriodDataEditor(newData)
        : newData;
    return dataPeriod;
  }, [metricData, newData]);

  const valuesData =
    period === 'day' && newData !== undefined ? newData.items : metricData;

  const values: CommonValuesProps = {
    data,
    valuesListRender: ({ data }) => <ValuesList data={data} />,
  };
  const table: CommonTableProps = {
    data: valuesData,
    noMeasuresRender: ({ canBeMeasured, noMeasureText }) => (
      <NoMeasures canBeMeasured={canBeMeasured} noMeasureText={noMeasureText} />
    ),
    tableDataRender: ({ data, nameStyle, valueStyle, testScope }) => (
      <TableData data={data} testScope={testScope} />
    ),
  };
  const metricChartClass = classnames('metric-chart', modClass);

  if (newData === undefined) {
    return null;
  }

  return (
    <div className={metricChartClass}>
      <div className="block metric-chart__block">
        <Toggler onLeft={onLeft} onRight={onRight} text={togglerText} />
        <div className="metric-chart__container">
          <Chart metricData={savedData} period={period} {...chartProps} />
        </div>
      </div>
      <div className="metric-chart__info">
        {renderMetricValues ? renderMetricValues(values as any) : null}
        {renderMetricTable
          ? renderMetricTable({
              ...table,
              type: period,
              last: !onRight,
            } as any)
          : null}
      </div>
    </div>
  );
};

const dayPeriodDataEditor = (metricData) =>
  metricData.grouped.map((item) => (item ? item : { value: null }));
