import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ReferenceLine,
} from 'recharts';
import {
  AxisYLine,
  AxisXTick,
  ChartProps,
} from '@features/health/ui/health-detail/metric-chart';

export const StitchGraph = React.memo((props: ChartProps) => {
  const {
    period,
    range,
    tickCount,
    showValues,
    gradientSidebar,
    labelText,
    metricData,
    shape,
    referenceBorder,
  } = props;

  const customizedMetric = useMemo(
    () =>
      metricData.reduce((newArray, { datetime, ...item }, index) => {
        const timeValue =
          period !== 'day' || (period === 'day' && datetime)
            ? datetime
            : `time__${index}`;
        newArray.push(
          {
            value: item.low,
            datetime: timeValue,
            [timeValue]: item.low,
          },
          {
            value: item.high,
            datetime: timeValue,
            [timeValue]: item.high,
          },
        );
        return newArray;
      }, []),
    [metricData, period],
  );

  return (
    <ResponsiveContainer>
      <LineChart
        className="chart"
        margin={{ top: 6, right: 20, bottom: 0, left: 0 }}
        data={customizedMetric}>
        <XAxis
          dataKey="datetime"
          interval={0}
          tick={<AxisXTick showAllTicks={period === 'week'} />}
          tickLine={false}
          strokeWidth={2}
          height={36}
          allowDuplicatedCategory={false}
          tickCount={metricData.length}
          padding={{ right: 20, left: 22 }}
        />
        <YAxis
          domain={range}
          width={48}
          dataKey="value"
          strokeWidth={2}
          tickSize={12}
          padding={{ bottom: 7 }}
          tickCount={tickCount}
          {...(showValues ? { tickMargin: 10 } : '')}
          {...(!showValues
            ? {
                label: (
                  <AxisYLine
                    gradientSidebar={gradientSidebar}
                    {...(labelText?.length ? { labelText: labelText } : {})}
                  />
                ),
                tickFormatter: () => '',
              }
            : '')}
        />
        <CartesianGrid strokeDasharray="4" />
        {customizedMetric.map(({ datetime, value }, index) => {
          return value ? (
            <Line
              dataKey={datetime}
              key={`stitch__${index}`}
              strokeWidth={4}
              className="chart__stitch-line"
              dot={{ strokeWidth: 0, r: 6 }}
              isAnimationActive={false}
            />
          ) : null;
        })}
        {referenceBorder && (
          <ReferenceLine y={referenceBorder} strokeWidth={2} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
});
