import { Notifications, AlertButtonsProps, Button, Modal } from '@ui';
import React from 'react';
import { ButtonProps } from '../../../../../ui';

export const ChatMarkModal = ({ onClose, isOpen, setMarkHandler }) => {
  const buttons: ButtonProps[] = [
    {
      title: 'Отправить',
      short: true,
      onClick: setMarkHandler,
      'data-cy': 'SET_RATING_MODAL_CONFIRM',
    },
    {
      title: 'Отмена',
      appearance: 'outline',
      short: true,
      onClick: onClose,
      'data-cy': 'SET_RATING_MODAL_CANCEL',
    },
  ];

  return (
    <Modal
      short
      className="alert"
      isOpen={isOpen}
      header="Оценка"
      root={<Notifications target="chat-mark-modal" />}
      footer={<ModalButtons onClose={onClose} buttons={buttons} />}
      onClose={onClose}>
      Вы хотите изменить оценку консультации?
    </Modal>
  );
};

const ModalButtons = ({ buttons, onClose }: AlertButtonsProps) => {
  return (
    <div className="alert__buttons">
      {buttons.map((buttonProps, index) => {
        const handleClick = () => {
          buttonProps?.onClick?.();
        };

        return (
          <div className="alert__button" key={index}>
            <Button {...buttonProps} onClick={handleClick} />
          </div>
        );
      })}
    </div>
  );
};
