import { Alert } from '@ui';
import React from 'react';
import './styles.scss';

interface ModalAboutSupportProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ModalAboutSupport: React.FC<ModalAboutSupportProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Alert
      message={<ModalAboutSupportContent />}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={'Информация о разделе'}
      buttons={[]}
    />
  );
};

function ModalAboutSupportContent() {
  return (
    <p>
      В данном разделе Вы можете направить запрос в техническую поддержку в
      случае:
      <ul className={'support-about__list'}>
        <li>если Вы столкнулись с проблемой работы личного кабинета</li>
        <li>
          если у Вас имеются вопросы по работе и функционалу личного кабинета
        </li>
        <li>
          если у Вас есть пожелания и рекомендации по работе личного кабинета
        </li>
      </ul>
    </p>
  );
}
