import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';

import { Pagination } from '../../../ui';

interface Props {
  array: any[];
  perPage: number;
  renderListComponent: (props: any[]) => ReactNode;
  showMoreBtn?: boolean;
  isPagerMobile?: boolean;
}

export const PaginatedList = ({
  array,
  perPage = 6,
  renderListComponent,
  showMoreBtn = true,
  isPagerMobile = false,
}: Props) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const [{ begining, end }, setPaginationMeta] = useState({
    begining: 0,
    end: perPage,
  });

  const currentPageData = array.slice(begining, end);

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const handleShowMore = useCallback(() => {
    timer.current = setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 0);
  }, []);

  return (
    <div className="paginated-list -d__flex -column" ref={listRef}>
      {renderListComponent(currentPageData)}
      <Pagination
        perPage={perPage}
        total={array.length}
        onShowMore={handleShowMore}
        onChange={setPaginationMeta}
        showMoreBtn={showMoreBtn}
        isPagerMobile={isPagerMobile}
      />
    </div>
  );
};
