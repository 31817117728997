import { useMemo } from 'react';
import { useQuery } from '@router';
import { useStoreMap } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';
import {
  XRayErrorStatuses,
  XRayGetStatus,
} from '@eus/health-default-logic/dist/x-ray/functions';
import {
  XRayErrorMessages,
  XRayDeviationsList,
  XRayStatuses,
  XRayStatusesText,
} from '@eus/health-default-logic/dist/x-ray/types';
import classnames from 'classnames';

export const useXRayData = () => {
  interface TableData {
    label: string;
    value: string;
    modClass?: string;
  }

  const query = useQuery();

  const xRayId = query.get('id') || '';

  const {
    metric: { $xRayCrud },
  } = useMetric({ metric: 'xRay' });

  const xRay = useStoreMap({
    store: $xRayCrud,
    keys: [xRayId],
    fn: (xRay, [id]) =>
      xRay.list.find((item) => `${item.id}` === `${id}`) ?? null,
  });

  const getStatusColorClass = (status: XRayStatuses | undefined): string => {
    switch (status) {
      case 'new':
        return '-text__blue';
      case 'done':
        return '-text__green';
      case 'inQueue':
        return '-text__purple';
      default:
        return '';
    }
  };

  // TODO: generate and work out a list of styles by colors and weights

  const tableData: TableData[] = useMemo(() => {
    const status: XRayStatuses = XRayGetStatus(xRay?.status);
    const isError = xRay?.status
      ? XRayErrorStatuses.includes(xRay.status)
      : false;
    const isNotDiagnosis = isError || status === 'inQueue' || status === 'new';
    const statusText: string = !isError
      ? XRayStatusesText[`${status}`]
      : 'Ошибка';
    const diagnosisStatus = isNotDiagnosis
      ? 'Не установлено'
      : xRay?.is_healthy
      ? 'Патологий не выявлено'
      : 'Выявлены патологические изменения';
    const statusColorClass = classnames(
      'status -font__700',
      isError ? '-text__red' : getStatusColorClass(status),
    );
    return [
      { label: 'Статус заявки', value: statusText, modClass: statusColorClass },
      {
        label: 'Дата загрузки',
        value: xRay
          ? formatTime(new Date(xRay.date), 'dd.MM.yyyy HH:mm')
          : 'Не установлена',
      },
      {
        label: 'Диагноз заявки',
        value: diagnosisStatus,
        ...(isNotDiagnosis ? { modClass: '-text__gray -font__400' } : ''),
      },
    ];
  }, [xRay]);

  const status: XRayStatuses = XRayGetStatus(xRay?.status);
  const isError = xRay?.status
    ? XRayErrorStatuses.includes(xRay.status)
    : false;
  const isInProcessing = status === 'inQueue' || status === 'new';
  const withDeviations = status === 'done' && !xRay?.is_healthy;
  const withoutDeviations = status === 'done' && xRay?.is_healthy;

  const deviationsKeys =
    withDeviations && xRay?.deviations ? Object.keys(xRay?.deviations) : [];

  const deviationsData = deviationsKeys.map((item) => {
    return {
      label: XRayDeviationsList[item],
      value: xRay?.deviations && xRay?.deviations[item] ? 'Есть' : 'Нет',
    };
  });

  const recommendationTitle = isError
    ? XRayStatusesText[status]
    : isInProcessing
    ? 'Результат'
    : withDeviations
    ? 'Выявленные патологии'
    : '';

  const errorMessagesHtml = XRayErrorMessages[status];

  return {
    tableData,
    xRay,
    withDeviations,
    deviationsData,
    withoutDeviations,
    isError,
    recommendationTitle,
    isInProcessing,
    errorMessagesHtml,
  };
};
