import { yupResolver } from '@hookform/resolvers/yup';
import {
  PhoneFormValues,
  useCheckPhoneFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { AgreeWithPrivacyPolicy, Button, FField, FormWrapper } from '@ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LoginTypes } from '../..';
import styles from './styles.module.scss';

export interface Props {
  phone?: string | null;
  onSuccessPhoneCheckHandler: (
    { phone }: PhoneFormValues,
    type: LoginTypes,
  ) => Promise<void>;
}

export const PhoneCheckSimple = ({
  onSuccessPhoneCheckHandler,
  phone,
}: Props) => {
  const { CheckPhoneFormSchema } = useCheckPhoneFormSchema();
  const methods = useForm<PhoneFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(CheckPhoneFormSchema),
    mode: 'onChange',
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const isDisabledSubmit = !isValid || isSubmitting;

  useEffect(() => {
    if (phone) {
      setValue('phone', phone);
    }
  }, [phone, setValue]);

  const onSubmit = (type: LoginTypes) =>
    handleSubmit((values) => onSuccessPhoneCheckHandler(values, type));

  return (
    <FormWrapper onSubmit={(e) => e.preventDefault()}>
      <FField.Phone
        id="enter-form-phone"
        name="phone"
        label="Номер телефона"
        methods={methods}
        className="form__field field--no-border"
        dataCyCodeSelect="AUTH_PHONE_CODE_SELECT"
        data-cy="AUTH_PHONE_INPUT"
      />
      <AgreeWithPrivacyPolicy className={styles.policy} />
      <Button
        fullWidth
        title="Продолжить по СМС"
        type="button"
        disabled={isDisabledSubmit}
        className={styles['continue-by-sms']}
        onClick={onSubmit('code')}
      />
      <Button
        fullWidth
        title="Продолжить по паролю"
        type="button"
        disabled={isDisabledSubmit}
        appearance="transparent"
        onClick={onSubmit('password')}
      />
    </FormWrapper>
  );
};
