import { useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import './form.scss';
import { Button, FField, FormWrapper } from '@ui';

import {
  CommonFormProps,
  useSexSelectValues,
} from '@teledoctor/common/dist/features/shared/forms';
import {
  useAddMemberFormSchema,
  AddMemberFormValues,
} from '@teledoctor/common/dist/features/cards/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { ServerErrorsHandler } from '@eus/react-client/src/forms/serverErrorsHandler';
import { addNotification } from '../../../../../ui/organisms/notifications/model';
import { SelectCountry } from '../../../../shared/ui';

interface Props
  extends Omit<CommonFormProps<AddMemberFormValues>, 'onSuccess'> {
  onSuccess: (values: AddMemberFormValues) => Promise<void>;
}

export const AddUserForm = ({ onSuccess, defaultValues }: Props) => {
  const { AddMemberFormSchema } = useAddMemberFormSchema();
  const methods = useForm<AddMemberFormValues>({
    resolver: yupResolver(AddMemberFormSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const { sexSelectValues } = useSexSelectValues();

  const { setError, register, getValues, setValue } = methods;

  useEffect(() => {
    register('cardClientId');
    register('account_phone_reference');
    if (defaultValues?.account_phone_reference) {
      setValue(
        'account_phone_reference',
        defaultValues.account_phone_reference,
      );
    }
  }, [register, setValue, defaultValues?.account_phone_reference]);

  const sendForm = useCallback(
    (values: AddMemberFormValues) => {
      return onSuccess(values).catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText) =>
              addNotification({
                id: 'add-card-member' + Date.now(),
                type: 'error',
                message: errorText,
                target: 'global',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: ServerErrorsHandler(setError),
          },
        }),
      );
    },
    [onSuccess, setError],
  );

  return (
    <FormWrapper
      className="add-user-form"
      onSubmit={methods.handleSubmit(sendForm)}
      data-cy="CARD_ADD_USER_FORM">
      <div className="add-user-form__fields">
        <FField.Input
          methods={methods}
          name="last_name"
          label="Фамилия"
          className="form__field"
          placeholder="Введите фамилию"
          data-cy="CARD_ADD_USER_LASTNAME_INPUT"
        />
        <FField.Input
          methods={methods}
          name="first_name"
          label="Имя"
          className="form__field"
          placeholder="Введите имя"
          data-cy="CARD_ADD_USER_FIRSTNAME_INPUT"
        />
        <FField.Input
          methods={methods}
          name="middle_name"
          label="Отчество"
          className="form__field"
          placeholder="Введите отчество"
          data-cy="CARD_ADD_USER_MIDDLENAME_INPUT"
        />

        <FField.Phone
          methods={methods}
          name="phone"
          placeholder="Телефон"
          label="Номер телефона"
          className="form__field"
          data-cy="CARD_ADD_USER_PHONE_INPUT"
        />
        <FField.Select
          name="sex"
          label="Пол"
          className="form__field"
          methods={methods}
          options={sexSelectValues}
          dataCy="CARD_ADD_USER_SEX_SELECT"
        />
        <FField.Input
          methods={methods}
          name="email"
          label="e-mail"
          type="email"
          className="form__field"
          placeholder="Введите email"
          data-cy="CARD_ADD_USER_EMAIL_INPUT"
        />
        <FField.Date
          methods={methods}
          name="date_of_birth"
          className="form__field"
          label="Дата рождения"
          placeholder="Введите Вашу дату рождения"
          maxDate={new Date()}
        />
        <SelectCountry
          name="country_code"
          control={methods.control}
          label="Страна"
          errors={methods.formState.errors}
          fieldClassname="form__field"
          isClearable={true}
          dataCy="card_add_user_select_country"
        />
      </div>

      <Button
        title="Добавить пользователя"
        className="-width__xs-100"
        data-cy="CARD_ADD_FORM_SUBMIT_BTN"
      />
    </FormWrapper>
  );
};
