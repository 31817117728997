import { useBoolean } from '@teledoctor/common/dist/hooks';
import './style.scss';
import {
  $activeDynamicPopup,
  closedPopup,
} from '@teledoctor/common/dist/features/popups';

import { isPopupCongrats } from '@eus/health-default-logic/dist/popup/filters';
import { useStore } from 'effector-react';
import { PopupDangerActions } from '@eus/health-default-logic/dist/popup/constants';
import React from 'react';
import { Modal } from '../../atoms/modal';

export const Congrats = () => {
  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(true);

  const popup = useStore($activeDynamicPopup);

  if (popup && isPopupCongrats(popup)) {
    const { image, text, id, title } = popup;

    const paragraphs = text.split('\n');

    const handleClose = () => {
      closedPopup({ id, type: PopupDangerActions.close });
      close();
    };
    return (
      <Modal
        contentClassNames={'congrats__modal__content'}
        onClose={handleClose}
        isOpen={isVisible}
        style={{
          content: {
            padding: 0,
          },
        }}>
        <div className="congrats__block">
          <div className="congrats__block-text">
            <div>
              <div className="congrats__text-header">{title}</div>
              <div className="congrats__text">
                {paragraphs.map((text, idx) =>
                  text ? <p key={idx}>{text}</p> : <br />,
                )}
              </div>
              <div className="congrats__text-footer">
                С уважением, Ваш Теледоктор24.
              </div>
            </div>
          </div>
          <div className="congrats__block-image">
            <img className="congrats__image" src={image} />
          </div>
        </div>
      </Modal>
    );
  } else return null;
};
