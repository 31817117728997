import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';

interface FieldInputMaskProps<T> extends FieldProps<T> {
  className?: string;
  defaultValue?: string;
  mask: string | RegExp;
  onFieldChange?: (value: string | null) => void;
}

export function FieldInputMask<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  disabled,
  onFieldChange,
  ...inputProps
}: FieldInputMaskProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            dataCy={inputProps['data-cy']}>
            <IMaskInput
              {...inputProps}
              defaultValue={value}
              className={classnames('input-text', inputClassName)}
              onAccept={(val: string) => {
                onChange(val);
                onFieldChange?.(val);
              }}
              onBlur={onBlur}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
