import { Anamnesis } from '@eus/health-default-logic/dist/medcard/types';

interface AnamnesisFields {
  code: keyof Anamnesis;
  label?: string;
  tooltip?: string;
}

export const anamnesisFields: AnamnesisFields[] = [
  {
    code: 'chronicles_disease',
    label: 'Страдаете ли вы хроническими заболеваниями? Какими?',
    tooltip:
      'Под хроническими заболеваниями понимается заболевания, чьи симптомы сохраняются более чем 3 месяца, рецидив и чаще всего повторяются (например: гипертония, бронхиальная астма, эпилепсия и т.п.)',
  },
  {
    code: 'allergic_disease',
    label: 'Были ли у вас аллергические реакции и заболевания? Какие и на что?',
    tooltip:
      'Под аллергическими реакциями заболеваниями понимается реакция организма (гиперчувствительность) человека к определенным чужеродным веществам – аллергенам (насморк, кожный зуд и т.п.)',
  },
  {
    code: 'infection_disease',
    label:
      'Какими инфекционными заболеваниями вы болели? Носителями каких инфекций вы являетесь?',
    tooltip:
      'Под инфекционными заболеваниями понимается группа заболеваний, вызываемых проникновением в организм патогенных (болезнетворных) микроорганизмов, вирусов и прионов (например: грипп, дизентерия и т.п.)',
  },
  {
    code: 'traumas',
    label: 'Какие заболевания были перенесены? Травмы, операции?',
    tooltip:
      'Под перенесенными заболеваниями понимается существующая хроническая патология со стороны различных органов и систем органов (например: хирургические вмешательства, травмы и т.п.)',
  },
  {
    code: 'relative_disease',
    label: 'Какими заболеваниями страдали ваши близкие и родственники?',
    tooltip:
      'Хронические заболевания (например: диабет, онкология, психические заболевания и др.)',
  },
  {
    code: 'bad_habits',
    label: 'Есть ли у вас вредные привычки? Какие?',
    tooltip:
      'Под вредными привычками понимается целый ряд различных форм социального поведения, регулярно совершаемых человеком и приносящих ему вред. (например: курение, потребление алкоголя и т.п.)',
  },
  {
    code: 'medicines',
    label: 'Принимаете ли вы постоянно какие-то лекарства? Какие?',
  },
];
