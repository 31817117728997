import React from 'react';
import './styles.scss';
import { LegendType } from '@teledoctor/common/dist/metrics/type';
import {
  AxisYTextGroupsLabels,
  ScatterGraph,
  StitchGraph,
} from '@features/health/ui/health-detail/metric-chart';

type GraphTypes = 'scatter' | 'stitch';
export interface ChartBaseProps {
  type?: GraphTypes;
  period: LegendType;
  range: [number, number];
  shape?: 'dot' | 'heart' | 'blob' | 'dotOutline';
  gradientSidebar?: boolean;
  labelText?: AxisYTextGroupsLabels;
  showValues?: boolean;
  tickCount?: number;
  referenceBorder?: number;
}
export interface ChartProps extends ChartBaseProps {
  metricData: any;
}

export const Chart = React.memo(
  ({
    shape = 'dot',
    type = 'scatter',
    gradientSidebar = false,
    showValues = true,
    tickCount = 9,
    ...props
  }: ChartProps) => {
    const checkGraphType = ({
      type,
      metricData,
      period,
      range,
      ...props
    }): JSX.Element => {
      if (metricData) {
        switch (type) {
          case 'scatter':
            return (
              <ScatterGraph
                metricData={metricData}
                period={period}
                range={range}
                {...props}
              />
            );
          case 'stitch':
            return (
              <StitchGraph
                metricData={metricData}
                period={period}
                range={range}
                {...props}
              />
            );
        }
      }
      return <></>;
    };

    return checkGraphType({
      type,
      shape,
      gradientSidebar,
      showValues,
      tickCount,
      ...props,
    });
  },
);
