import { useEffect } from 'react';
import styles from './style.module.scss';
import { Button, FField, FormWrapper } from '@ui';
import { CheckFormCode } from '@eus/react-client';
import { getTimeDigit } from '@teledoctor/common/dist/lib/formatters/format-date';
import {
  OnSuccessForm,
  useCheckCodeForm,
  useTimer,
} from '@teledoctor/common/dist/hooks';
import classnames from 'classnames';

interface Props {
  requestCodeAgain: (sourceCode: string) => void;
  onSuccessForm: OnSuccessForm<CheckFormCode>;
  placeholder?: string;
  description?: string;
  label?: string;
  sourceCode?: string;
  sendButtonClassName?: string;
  inputCodeClassName?: string;
  descriptionClassName?: string;
  className?: string;
}

const $TIMER_INTERVAL = 300;

export const CodeForm = (props: Props) => {
  const {
    requestCodeAgain,
    onSuccessForm,
    placeholder,
    description,
    label,
    sourceCode,
    inputCodeClassName,
    className,
    descriptionClassName,
    sendButtonClassName,
  } = props;

  const { startTimer, timerSecondsLeft } = useTimer();

  useEffect(() => {
    startTimer($TIMER_INTERVAL);
  }, [startTimer]);

  const { methods, isDisabledSubmit, onSubmitForm } = useCheckCodeForm({
    onValid: onSuccessForm,
    mode: 'onChange',
  });

  const requestCodeAgainHandler = () => {
    startTimer($TIMER_INTERVAL);
    if (sourceCode) {
      requestCodeAgain(sourceCode);
    }
  };

  return (
    <FormWrapper
      onSubmit={onSubmitForm}
      className={classnames(styles.wrapper, className)}>
      <FField.Input
        name="code"
        methods={methods}
        label={label}
        placeholder={placeholder}
        className={classnames('form__field', inputCodeClassName)}
        data-cy="ENTER_CODE_INPUT"
      />
      <div className={classnames(styles.description, descriptionClassName)}>
        {timerSecondsLeft ? (
          <>
            {description && (
              <>
                {description}
                <br />
              </>
            )}
            Запросить новый код можно через {getTimeDigit(timerSecondsLeft)}
          </>
        ) : (
          <div className={styles.repeat} onClick={requestCodeAgainHandler}>
            Запросить код повторно
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          fullWidth
          title="Подтвердить код"
          className={classnames(styles.continue, sendButtonClassName)}
          disabled={isDisabledSubmit}
        />
      </div>
    </FormWrapper>
  );
};
