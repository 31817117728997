import { StorageAdapter } from 'effector-storage';
export { createCustomAdapter } from '@teledoctor/common/dist/lib/effector/with-persist';
import {
  createCustomAdapter,
  Storage,
} from '@teledoctor/common/dist/lib/effector/with-persist';

export const localStoragePromisified: Storage = {
  getItem: (key) => Promise.resolve(localStorage.getItem(key)),
  setItem: (key, value) => Promise.resolve(localStorage.setItem(key, value)),
  removeItem: (key) => Promise.resolve(localStorage.removeItem(key)),
  clear: () => Promise.resolve(localStorage.clear()),
};

export const asyncStorageAdapter: StorageAdapter = createCustomAdapter({
  serialize: JSON.stringify,
  deserialize: JSON.parse,
  storage: localStoragePromisified,
  name: 'ASYNC STORAGE ADAPTER',
});
