import React from 'react';
import './info-list.scss';
import classnames from 'classnames';

export interface InfoListItem {
  label: string;
  value: string | JSX.Element;
}

export interface InfoListGroups {
  label: string;
  props: InfoListItem[];
}

interface InfoListInterface {
  groups: InfoListGroups[];
}

export const InfoList = ({ groups }: InfoListInterface) => {
  return groups.length ? (
    <>
      {groups.map((group, index) => (
        <div className="info__group" key={`info-group-${index}`}>
          {group.label && (
            <div className="info__group-label">{group.label}</div>
          )}
          {group.props.length && (
            <div className="info__list">
              {group.props.map((item, itemIndex) => {
                const labelClasses = classnames('info__label', {
                  'info__label-empty': !item.label.length,
                });
                return (
                  <div
                    className="info__item"
                    key={`info-item-${index}-${itemIndex}`}>
                    <div className={labelClasses}>{item.label}</div>
                    <div
                      className={`info__value${
                        item.value === 'Не указано' ? ' info__value_unset' : ''
                      }`}>
                      {item.value}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </>
  ) : null;
  // return groups.length ? (
  //     {groups.map((group, index) => (
  //           <div className="info__group" key={`info-group-${index}`}>
  //             {group.label && (
  //                 <div className="info__group-label">{group.label}</div>
  //             )}
  //             {group.props.length && (
  //                 <div className="info__list">
  //                   {group.props.map((item, itemIndex) => {
  //                     const labelClasses = classnames('info__label', {
  //                       'info__label-empty': !item.label.length,
  //                     });
  //                     return (
  //                         <div
  //                             className="info__item"
  //                             key={`info-item-${index}-${itemIndex}`}>
  //                           <div className={labelClasses}>{item.label}</div>
  //                           <div className="info__value">{item.value}</div>
  //                         </div>
  //                     );
  //                   })}
  //                 </div>
  //             )}
  //           </div>
  //       ))}
  // ) : null;
};
