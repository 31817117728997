import { Alert } from '@ui';
import React from 'react';

interface ChatCancelModal {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  successHandler: () => void;
  taskTypeText?: string;
}

export const ChatCancelModal: React.FC<ChatCancelModal> = ({
  isOpen,
  setIsOpen,
  successHandler,
  taskTypeText,
}) => {
  return (
    <Alert
      message=""
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      short={true}
      title={`Отменить ${taskTypeText ?? 'консультацию'}?`}
      buttons={[
        {
          title: 'Подтвердить',
          short: true,
          onClick: successHandler,
          'data-cy': 'GET_CONSULT_CANCEL_MODAL_CONFIRM_BTN',
        },
        {
          title: 'Отменить',
          short: true,
          appearance: 'outline',
          'data-cy': 'GET_CONSULT_CANCEL_MODAL_CANCEL_BTN',
        },
      ]}
    />
  );
};
