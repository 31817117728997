import React from 'react';
import { Svg } from '@ui';
import { Analyze } from '@eus/health-default-logic/dist/analyze/types';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import './analysis-box.scss';
import { CATEGORIES_LIST } from '@eus/health-default-logic/dist/analyze/constants';

interface Props {
  analysis: Analyze;
  onDownloadFile: (id) => void;
}

export const AnalysisBox = ({ analysis, onDownloadFile }: Props) => {
  const getFileExtension = (name) => {
    return '.' + name.split('.').pop().toLowerCase();
  };
  const category = CATEGORIES_LIST.find(
    (item) => item.value === analysis.category_id,
  );
  const categoryName = category ? category.label : 'Без категории';

  return (
    <div className="analysis-box">
      <Svg name={'document'} modClass="analysis-box__icon" />
      <div className="analysis-box__content">
        <div className="analysis-box__line">
          <div className="analysis-box__title">
            {(analysis.custom_name ? analysis.custom_name : analysis.name) +
              getFileExtension(analysis.name)}
          </div>
          <button
            title="Скачать"
            onClick={() => onDownloadFile(analysis.id)}
            className="analysis-box__download">
            <Svg name="download" />
          </button>
        </div>
        <div className="analysis-box__line">
          <span className="analysis-box__category">{categoryName}</span>
          <span className="analysis-box__date">
            <span className="analysis-box__date-day">
              {formatDateShort(new Date(analysis.timestamp), 'dd.MM.yyyy')}
            </span>
            <span className="analysis-box__date-time">
              {' '}
              {formatDateShort(new Date(analysis.timestamp), 'HH:MM')}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
