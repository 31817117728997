import React from 'react';

import { getFamilyRelName } from '@teledoctor/common/dist/features/user/medcard';
import { useStore } from 'effector-react';
import { ProfilePropsList, ProfileInfoView } from '@features/user/ui';
import { HealthPersonalInfo } from '@eus/health-default-logic/src/medcard/types';

import { useSexSelectValues } from '@teledoctor/common/dist/features/shared/forms';

export const MedicalCard = ({ user, medCard }) => {
  const { sexSelectValues } = useSexSelectValues();

  const noData = <span className="info__value-undefined">Нет данных</span>;

  const avatar = user?.avatar_path || '';

  const sexObj = sexSelectValues.find((item) => item.value === user?.sex);
  const sexString = sexObj?.label;

  const contacts = medCard?.contacts?.length
    ? medCard?.contacts.map((contact) => {
        const label = getFamilyRelName(contact.family_rel);
        return {
          label: label.length ? label[0].toUpperCase() + label.slice(1) : '',
          value: `${contact.fio} (${contact.phone})`,
        };
      })
    : [
        {
          label: '',
          value: noData,
        },
      ];

  const buttonState: HealthPersonalInfo = {
    anamnesis: medCard?.anamnesis ?? {},
    health: medCard?.health ?? {},
    contacts: medCard?.contacts ?? [],
  };

  const propsList: ProfilePropsList = {
    name: [user?.last_name, user?.first_name, user?.middle_name]
      .filter(Boolean)
      .join(' '),
    avatar: {
      pathname: avatar,
      sex: user?.sex,
    },
    button: {
      text: 'Редактировать',
      to: {
        pathname: 'health/edit-medical-card',
        state: buttonState,
      },
    },
    propsGroups: [
      {
        label: '',
        props: [
          {
            label: 'Пол',
            value: sexString || '',
          },

          {
            label: 'Вес, кг',
            value: `${medCard?.health?.weight ?? ''}` || noData,
          },
          {
            label: 'Рост, см',
            value: `${medCard?.health?.height ?? ''}` || noData,
          },
        ],
      },
      {
        label: 'Нормальное значение параметров',
        props: [
          {
            label: 'Артериальное давление',
            value: medCard?.health?.arterial_pressure?.length
              ? medCard?.health?.arterial_pressure
              : noData,
          },
          {
            label: 'Пульс (уд./мин)',
            value: `${medCard?.health?.pulse ?? ''}` || noData,
          },
        ],
      },
      {
        label: 'Контакты на случай ЧП',
        props: contacts,
      },
      {
        label: 'Анкета',
        props: [
          {
            label: 'Хронические заболевания',
            value: medCard?.anamnesis?.chronicles_disease?.length
              ? medCard?.anamnesis?.chronicles_disease
              : noData,
          },
          {
            label: 'Аллергия',
            value: medCard?.anamnesis?.allergic_disease?.length
              ? medCard?.anamnesis?.allergic_disease
              : noData,
          },
          {
            label: 'Инфекция',
            value: medCard?.anamnesis?.infection_disease?.length
              ? medCard?.anamnesis?.infection_disease
              : noData,
          },
          {
            label: 'Заболевания, травма, операции',
            value: medCard?.anamnesis?.traumas?.length
              ? medCard?.anamnesis?.traumas
              : noData,
          },
          {
            label: 'Риск заболевания',
            value: medCard?.anamnesis?.relative_disease?.length
              ? medCard?.anamnesis?.relative_disease
              : noData,
          },
          {
            label: 'Вредные привычки',
            value: medCard?.anamnesis?.bad_habits?.length
              ? medCard?.anamnesis?.bad_habits
              : noData,
          },
          {
            label: 'Лекарства',
            value: medCard?.anamnesis?.medicines?.length
              ? medCard?.anamnesis?.medicines
              : noData,
          },
        ],
      },
    ],
  };
  return <ProfileInfoView {...propsList} />;
};
