import React from 'react';

interface GradientYLegend {
  width: number;
  height: number;
  yPos: number;
}

export const GradientYLegend = ({ width, height, yPos }: GradientYLegend) => {
  return (
    <>
      <defs>
        <linearGradient id="axisYlegend" x1={0} y1={0} x2={0} y2="100%">
          <stop offset="0" stopColor="#F02660" stopOpacity="1" />
          <stop offset="25%" stopColor="#DAC32F" stopOpacity="1" />
          <stop offset="50%" stopColor="#30DE40" stopOpacity="1" />
          <stop offset="75%" stopColor="#DAC32F" stopOpacity="1" />
          <stop offset="100" stopColor="#F02660" stopOpacity="1" />
        </linearGradient>
      </defs>
      <rect
        rx="5"
        ry="5"
        x={22}
        y={yPos - 6}
        width={width}
        height={height + 6}
        fill="url(#axisYlegend)"
      />
    </>
  );
};
