import React from 'react';

export const AxisXTick = (props) => {
  const { x, y, stroke, payload, showAllTicks } = props;
  const checkpoint = !showAllTicks && payload.index % 3 !== 0;
  const tickLineMargin = 8;
  const tickCheckpointLength = 12;
  const tickCurrentLength = checkpoint
    ? tickCheckpointLength / 2
    : tickCheckpointLength;
  const labelMargin = 4;
  return (
    <g transform={`translate(${x},${y})`}>
      {!checkpoint ? (
        <text x={-20} y={tickCheckpointLength + tickLineMargin + labelMargin}>
          {payload.value}
        </text>
      ) : (
        <></>
      )}
      <line
        key={'bottom' + payload.index}
        x1={0}
        x2={0}
        y1={-tickLineMargin}
        y2={tickCurrentLength - tickLineMargin}
        strokeWidth={2}
      />
    </g>
  );
};
