import {
  useClientCrm,
  useAuthTd,
  useTasksTd,
  useCardsTd,
  useProfileTd,
  useChatTd,
  useQuestionsTd,
  useSupportTd,
  useSchemaTd,
  useTariffsForBuyTd,
  useTariffsForBannersTd,
  useGetTariffsMerchantLinkTd,
} from '@teledoctor/common/dist/hooks';
import { useEffect, useMemo } from 'react';
import { uiParamsLk } from './wrappers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';

export const useCardsTdLk = () => useCardsTd(uiParamsLk);

export const useTasksTdLk = () => useTasksTd(uiParamsLk);

export const useAuthTdLk = () => useAuthTd(uiParamsLk);

export const useSupportTdLk = () => useSupportTd(uiParamsLk);

export const useQuestionsTdLk = () => useQuestionsTd(uiParamsLk);

export const useChatTdLk = () => useChatTd(uiParamsLk);

export const useTariffsForBuyTdLk = () => useTariffsForBuyTd(uiParamsLk);

export const useGetTariffsMerchantLinkTdLk = () => {
  const { loadTariffsMerchantLink, loading } =
    useGetTariffsMerchantLinkTd(uiParamsLk);

  const getTariffsMerchantLink = async (id: number, promocode?: string) => {
    await loadTariffsMerchantLink(
      { tariff_id: id, promocode },
      new FeedParams({
        throwNext: true,
      }),
    )
      .then((response) => {
        if (response.status === 200) {
          return response.data.link;
        } else {
          throw new Error();
        }
      })
      .then((link) => {
        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return { getTariffsMerchantLink, loading };
};

export const useTariffsForBannersTdLk = () =>
  useTariffsForBannersTd(uiParamsLk);

export const useProfileTdLk = () => {
  const { profile, load, ...rest } = useProfileTd(uiParamsLk);
  const exp = useMemo(() => {
    const avatarLink = profile?.avatar_path || '';
    const exp = avatarLink?.split('expired=')[1]?.split('&')[0];
    return exp;
  }, [profile?.avatar_path]);

  useEffect(() => {
    const now = Date.now();
    if (now > Number(exp)) {
      load();
    }
  }, [exp, load]);

  return { profile, load, ...rest };
};

export const useSchemaTdLk = () => {
  const clientCrm = useClientCrm();
  return useSchemaTd({ client: clientCrm }, uiParamsLk);
};
