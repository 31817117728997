import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { IMaskInput } from 'react-imask';
import { InputPhoneModal } from './modal';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { usePhoneCodes, ID_RU, Phone } from '@eus/react-client/src/phone-codes';
import { formatPhoneWithPlus } from '@teledoctor/common/dist/helpers/phone';
import { removeMaskFromPhone } from '@teledoctor/common/dist/lib/formatters/remove-mask-from-phone';

import './input-phone.scss';

const applyMask = (string: string, mask: string) => {
  let i = 0;
  return mask.replace(/0/g, (_) => string[i++] ?? '');
};

const getMaskedFullPhone = (
  phonePart: string,
  mask: string,
  countryCode: string,
) => {
  const maskedPhone = applyMask(phonePart, mask);
  return formatPhoneWithPlus(`${countryCode} ${maskedPhone}`);
};

export interface InputPhoneProps {
  hasError?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
  placeholder?: string;
  id?: string;
  dataCyCodeSelect?: string;
  disabled?: boolean;
  readOnly?: boolean;
  ['data-cy']?: string;
}

const mapMask = (mask: string) => mask.replace(/9/g, '0');

export const InputPhone = (props: InputPhoneProps) => {
  const {
    hasError,
    onChange,
    onBlur,
    onFocus,
    value = '',
    id = '',
    className,
    placeholder,
    dataCyCodeSelect,
    disabled,
    readOnly,
    'data-cy': dataCy,
    ...otherProps
  } = props;
  const {
    value: isModalOpen,
    setTruthy: openModal,
    setFalsy: closeModal,
  } = useBoolean(false);
  const formattedValue = formatPhoneWithPlus(removeMaskFromPhone(value));

  const { byId, getCountryForPhone } = usePhoneCodes();

  const [phoneCode, setPhoneCode] = useState(byId(ID_RU).data!);
  const [maskedFullValue, setMaskedFullValue] = useState<string>();

  const mask = mapMask(phoneCode.pm);

  const phonePart = ((formattedValue as string) || '').substring(
    phoneCode.pc.length + 1,
  );

  const setCode = useCallback(
    (newValue: Phone) => {
      setPhoneCode(newValue);
      onChange?.(formatPhoneWithPlus(`${newValue.pc} ${phonePart}`));
      closeModal();
    },
    [closeModal, onChange, phonePart],
  );

  const onPhoneChange = useCallback(
    (phone) => {
      onChange?.(formatPhoneWithPlus(`${phoneCode.pc} ${phone}`));
    },
    [onChange, phoneCode],
  );

  useEffect(() => {
    if (formattedValue) {
      getCountryForPhone(formattedValue, true).then((codeData) => {
        if (codeData) {
          setPhoneCode(codeData);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readOnly) {
      const maskedFullPhone = getMaskedFullPhone(phonePart, mask, phoneCode.pc);
      setMaskedFullValue(maskedFullPhone);
      onChange?.(maskedFullPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phonePart, phoneCode.pc, mask, readOnly]);

  useEffect(() => {
    if (phonePart && !readOnly) {
      const maskedFullPhone = getMaskedFullPhone(phonePart, mask, phoneCode.pc);
      onChange?.(maskedFullPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputClassnames = classnames('input-phone', className, {
    'input-phone--has-error': hasError,
    'input-phone--is-modal-open': isModalOpen,
  });

  return (
    <div className={inputClassnames}>
      {!disabled && !readOnly && (
        <>
          <button
            className="input-phone__select"
            onClick={openModal}
            type="button"
            {...(dataCy ? { 'data-cy': `${dataCy}_CODE_SELECT` } : '')}>
            <span className="input-text">{`+${phoneCode.pc}`}</span>
            <span className="input-phone__select-arrow" />
          </button>
          <IMaskInput
            mask={mask}
            name="input-phone"
            unmask={false}
            value={phonePart}
            onAccept={onPhoneChange}
            className="input-text input-phone__input"
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder ?? ''}
            {...(id ? { id } : '')}
            {...otherProps}
          />
          <InputPhoneModal
            current={phoneCode}
            visible={isModalOpen}
            onClose={closeModal}
            onSelectCountryCode={setCode}
          />
        </>
      )}

      {(readOnly || disabled) && (
        <input
          className="input-text"
          onFocus={disabled ? undefined : onFocus}
          disabled={disabled}
          readOnly={readOnly}
          value={maskedFullValue}
        />
      )}
    </div>
  );
};
