import classnames from 'classnames';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

import './input-textarea.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputTextareaProps extends TextareaAutosizeProps {}

export const InputTextarea = ({ className, ...props }: InputTextareaProps) => {
  const textAreaClassnames = classnames('input-text', 'textarea', className);

  return <TextareaAutosize {...props} className={textAreaClassnames} />;
};
