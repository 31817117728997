import classnames from 'classnames';
import React from 'react';

import './titleline.scss';

interface TitleLineProps {
  titleText?: string;
  className?: string;
}

export const TitleLine: React.FC<TitleLineProps> = ({
  titleText,
  className,
  children,
}) => {
  const classNames = classnames('titleline', className);

  return (
    <div className={classNames}>
      {Boolean(titleText) && (
        <h1 className="h1 titleline__title" data-cy="TITLE_H1">
          {titleText}
        </h1>
      )}
      {children}
    </div>
  );
};
