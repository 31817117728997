const phoneFactory = (value) => {
  switch (value.length) {
    case 11: {
      return '+# ### ###-##-##';
    }
    case 6: {
      return '######';
    }
    case 12: {
      return '+### ## ###-##-##';
    }
    default:
      return '';
  }
};

function formatPhoneBase(
  value: string | number | null,
  factoryPattern: (val: string) => string,
) {
  if (!value) {
    return '';
  }
  const v = value.toString().replace(/\D/g, () => '');
  const pattern = factoryPattern(v);

  let i = 0;

  return pattern.replace(/#/g, (_) => v[i++] || '');
}

const formatPhone = (phone: string | number) =>
  formatPhoneBase(phone, phoneFactory) as string;

export default formatPhone;
