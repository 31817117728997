// eslint-disable-next-line spaced-comment
/// <reference types="@welldone-software/why-did-you-render" />
import './wdyr';
import '@ui/styles';
import '@eus/react-client/src/utils/suppressMessages';
import { render } from 'react-dom';
import Modal from 'react-modal';

import { App } from './app';

Modal.setAppElement('#root');

render(<App />, document.getElementById('root'));
