import { Button } from '@ui';
import './chat-buttons.scss';
import {
  RenderBtnForDateTimePicker,
  ScheduleConsultation,
} from '@features/consultations/ui';

export type ChatButton =
  | {
      title: string;
      type: 'datetime';
      chatId: string;
    }
  | {
      title: string;
      type: 'regular';
      onClick?: () => void;
    };

export interface ChatButtonsProps {
  buttons: ChatButton[];
}

const ChatButton = (props: ChatButton) => {
  if (props.type === 'datetime') {
    const renderBtnForPicker: RenderBtnForDateTimePicker = (
      onClick,
      isDisabled,
    ) => {
      return (
        <Button
          className="chat-buttons__btn"
          title={props.title}
          onClick={onClick}
          appearance="transparent"
          disabled={isDisabled}
          noUppercase
        />
      );
    };

    return (
      <ScheduleConsultation
        chatId={props.chatId}
        renderBtn={renderBtnForPicker}
      />
    );
  } else
    return (
      <Button
        className="chat-buttons__btn"
        title={props.title}
        onClick={props.onClick}
        appearance="transparent"
        noUppercase
      />
    );
};

export const ChatButtons = ({ buttons }: ChatButtonsProps) => {
  return (
    <div className="chat-buttons">
      <div className="chat-buttons__grid">
        {buttons.map((button) => {
          return (
            <div className="chat-buttons__btn-item" key={button.title}>
              <ChatButton {...button} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
