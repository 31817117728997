import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileImage, FileImageCover, ImageModal } from '@ui';

import './chat-image-wrapper.scss';
import {
  MessageModalInterface,
  showMessageModal,
} from '../../../../ui/organisms/modal/model';
import { useAuth } from '@eus/react-client';
import { useFileAccess } from '@features/chat/hooks';

export const ChatImageWrapper: React.FC<FileImage> = ({ src, children }) => {
  const { path, loading } = useFileAccess(src);

  const imageModalShowHandler = () => {
    const messageModal: MessageModalInterface = {
      component: <ImageModal src={path ?? ''} showButtons={true} />,
      closeIcon: false,
      className: 'image-modal chat-message__image-modal',
    };
    showMessageModal(messageModal);
  };

  return (
    <div className="chat-message__image-wrapper">
      <FileImageCover src={path ?? ''} onClick={imageModalShowHandler} />
      {children}
    </div>
  );
};
