import { addNotification, AuthTemplate, FormWrapper, Loader } from '@ui';
import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  RegistrationInfoFormValues,
  useRegistrationInfoFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { useProfileTdLk } from '@features/api';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Steps } from './types';
import { AuthBack, WelcomeModalContext } from '@features/user/ui';
import { RegisterFirstStepPage } from './first-step';
import { RegisterSecondStepPage } from './second-step';
import { AuthBlock } from '@features/user/ui/block';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { ServerErrorsHandler } from '@eus/react-client';
import { RegisterInfoTypes } from '@teledoctor/common/dist/types/auth';
import {
  setDefaultProfileValues,
  trimOptionalValues,
} from '@teledoctor/common/dist/helpers/register/register-info';

export const RegisterDetailPage = () => {
  const [isProfileLoading, setProfileLoadingStatus] = useState<boolean>(true);
  const [step, setStep] = useState<Steps>(Steps.FIRST);
  const { RegistrationInfoFormSchema } = useRegistrationInfoFormSchema();

  const { sendEditedProfile, profile, load } = useProfileTdLk();

  useEffect(() => {
    if (!profile) {
      load().then(() => {
        setProfileLoadingStatus(false);
      });
    }
    setProfileLoadingStatus(false);
  }, [profile, load]);

  const welcome = useContext(WelcomeModalContext);

  const defaultCountryValue = useMemo(
    () =>
      profile?.country_code?.[0].value
        ? [profile.country_code[0].value]
        : undefined,
    [profile],
  );

  const methods = useForm<RegistrationInfoFormValues>({
    resolver: yupResolver(RegistrationInfoFormSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (profile) {
      setDefaultProfileValues(profile, methods);
    }
  }, [profile, methods]);

  const onNextHandler = () => {
    setStep(Steps.SECOND);
  };

  const onSubmit = (
    values: RegistrationInfoFormValues,
    type: RegisterInfoTypes,
  ) => {
    const formattedValues = trimOptionalValues(values, type);

    sendEditedProfile(
      // @ts-ignore
      formattedValues,
      new FeedParams({
        needShowLoader: true,
        throwNext: true,
        ignoreError: true,
      }),
    )
      .then(() => {
        welcome?.showModal(type);
      })
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText) =>
              addNotification({
                id: 'profile' + new Date(),
                type: 'error',
                message: errorText,
                target: 'global',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback:
              ServerErrorsHandler<RegistrationInfoFormValues>(methods.setError),
          },
        }),
      );
  };

  return (
    <AuthTemplate>
      {isProfileLoading ? (
        <Loader isVisible />
      ) : (
        <AuthBlock>
          <div className={styles.head}>
            {step !== Steps.FIRST && (
              <AuthBack
                onClick={() => {
                  setStep(Steps.FIRST);
                }}
              />
            )}
            <div className={styles.steps}>{step} из 2 шагов</div>
          </div>
          <FormWrapper onSubmit={() => {}}>
            {step === Steps.FIRST ? (
              <RegisterFirstStepPage
                onNextClick={onNextHandler}
                methods={methods}
              />
            ) : (
              <RegisterSecondStepPage
                methods={methods}
                onButtonClick={onSubmit}
              />
            )}
          </FormWrapper>
        </AuthBlock>
      )}
    </AuthTemplate>
  );
};
