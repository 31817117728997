import classnames from 'classnames';
import React, { FormEventHandler } from 'react';
import './form.scss';

interface FormWrapperProps {
  className?: string;
  children?: React.ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
  id?: string;
}

export const FormWrapper = ({
  className,
  children,
  onSubmit,
  ...props
}: FormWrapperProps) => {
  const classNames = classnames('form', className);

  return (
    <form className={classNames} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};
