import { useMemo } from 'react';
import {
  AnalyzeDecryption,
  ErrorType,
  AnalyzeState,
  AnalyzePersonal,
} from '@eus/health-default-logic/dist/analyze-decryption/types';
import { SexType } from '@eus/health-default-logic/dist/common/types';
import { useQuery } from '@router';
import { useStoreMap } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import {
  getTitleByStatus,
  getTitleDescriptionByType,
} from '@teledoctor/common/dist/features/metrics/model/analyze-decryption';
import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import type {
  DataTableItemProps,
  DataTableWithModalItemProps,
} from '../../health-detail/metric-viewer/ui/data-table/';
import {
  ANALYSIS_DECRYPTION_COMMON_ERROR_MSG,
  ANALYSIS_DECRYPTION_UNKNOWN_TYPE_ANALYZE_MSG,
  ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG,
} from '@teledoctor/common/dist/lib/text-constants';

export interface AnalysisItemsProps {
  isSuccessful: boolean;
  basicInfoData: DataTableItemProps[];
  dangerousResultsData: DataTableWithModalItemProps[] | null;
  normalResultsData: DataTableItemProps[] | null;
  unreadResultsData: DataTableItemProps[] | null;
}

// !TODO take out in common, because this hook is common
export const useAnalysisDecryptionData = () => {
  const query = useQuery();
  const decryptionId = query.get('id') || '';
  const {
    metric: { $analyzeDecryptionCrud, loadSingleAnalyzeDecryption },
  } = useMetric({ metric: 'analyzeDecryption' });

  const decryption = useStoreMap({
    store: $analyzeDecryptionCrud,
    keys: [decryptionId],
    fn: (analyze, [id]) =>
      analyze.list.find((item) => `${item.id}` === `${id}`) ?? null,
  });

  return useMemo(() => {
    const getStatusColorClass = (status: AnalyzeState) => {
      switch (status) {
        case 'fail':
          return '-text__red';
        case 'done':
          return '-text__green';
        case 'processing':
          return '-text__purple';
        default:
          return '';
      }
    };
    const getSexName = (sex: SexType) => {
      switch (sex) {
        case 'male':
          return 'Мужской';
        case 'female':
          return 'Женский';
        default:
          return '-';
      }
    };
    const getTextByStatus = (errorType: ErrorType) => {
      switch (errorType) {
        case 'unknown-type':
          return ANALYSIS_DECRYPTION_UNKNOWN_TYPE_ANALYZE_MSG;
        case 'unknown-clinic':
          return ANALYSIS_DECRYPTION_UNKNOWN_CLINIC_MSG;
        default:
          return ANALYSIS_DECRYPTION_COMMON_ERROR_MSG;
      }
    };

    const isDone = decryption?.status === 'done';
    const isError = decryption?.status === 'fail';
    const isInProcessing = decryption?.status === 'processing';

    const errorType = (decryption?.error?.errorType ??
      'unknown-error') as ErrorType;
    const errorText = getTextByStatus(errorType);

    const date = decryption?.createdAt
      ? formatDateShort(new Date(decryption?.createdAt), 'dd.MM.yyyy HH:MM')
      : '';
    const name = decryption?.customName
      ? decryption?.customName
      : decryption?.nameFile;
    const commonInfo = [
      {
        label: 'Дата загрузки',
        value: date,
      },
      {
        label: 'Id',
        value: decryption?.id.toString(),
      },
      {
        label: 'Название',
        value: name,
      },
    ];
    let analysisItems: AnalysisItemsProps[] = [];
    if (decryption?.result) {
      analysisItems = decryption.result.map((item) => {
        let { measures, type } = item;
        let { sex, birthDate: age } = item.personal as AnalyzePersonal;

        const isSuccessful =
          decryption?.status === 'done' && type !== 'unknown';

        let localStatus: AnalyzeState = isSuccessful
          ? decryption?.status
          : 'fail';

        const personalData = isSuccessful
          ? [
              { label: 'Пол', value: getSexName(sex) as string },
              { label: 'Возраст', value: age.toString() },
            ]
          : [];

        const basicInfoData = [
          {
            label: 'Статус заявки',
            value: getTitleByStatus(localStatus) as string,
            modClass: `status -font__700 ${getStatusColorClass(localStatus)}`,
          },
          ...commonInfo,
          {
            label: 'Тип анализа',
            value: getTitleDescriptionByType(item.type) as string,
          },
          ...personalData,
        ];

        const dangerousResults = measures?.filter(
          (item) => item.state !== 'normal' && item.state !== 'empty',
        );
        const dangerousResultsData = dangerousResults?.map((item) => {
          const { measureName, measureReadValue, message, measureUnitName } =
            item;
          const measureUnit =
            measureUnitName === '%' ? measureUnitName : ` ${measureUnitName}`;
          return {
            label: measureName,
            value: measureReadValue.toString() + measureUnit,
            modClass: '-text__red',
            infoMessage: message,
          };
        });

        const normalResults = measures?.filter(
          (item) => item.state === 'normal',
        );
        const normalResultsData = normalResults?.map((item) => {
          const { measureName, value, measureReadValue, measureUnitName } =
            item;

          const measureUnit =
            measureUnitName === '%' ? measureUnitName : ` ${measureUnitName}`;
          return {
            label: measureName,
            value: measureReadValue.toString() + measureUnit,
            modClass: '-text__green',
          };
        });

        const unreadResults = measures?.filter(
          (item) => item.state === 'empty',
        );
        const unreadResultsData = unreadResults?.map((item) => {
          const { measureName } = item;
          return {
            label: measureName,
            value: '-',
          };
        });

        return {
          isSuccessful: isSuccessful,
          basicInfoData,
          dangerousResultsData: dangerousResultsData?.length
            ? dangerousResultsData
            : null,
          normalResultsData: normalResultsData?.length
            ? normalResultsData
            : null,
          unreadResultsData: unreadResultsData?.length
            ? unreadResultsData
            : null,
        };
      });
    }
    return {
      decryption,
      isError,
      isDone,
      isInProcessing,
      errorText: errorText,
      commonInfo: [
        {
          label: 'Статус заявки',
          value: decryption?.status
            ? (getTitleByStatus(decryption.status) as string)
            : '',
          modClass: decryption?.status
            ? `status -font__700 ${getStatusColorClass(decryption?.status)}`
            : '',
        },
        ...commonInfo,
      ],
      analysisItems,
    };
  }, [decryption]);
};
