import classNames from 'classnames';
import detailStyles from './../styles.module.scss';
import { Button, ExplanationDark, FField } from '@ui';
import { UseFormReturn } from 'react-hook-form';
import { RegistrationInfoFormValues } from '@teledoctor/common/dist/features/user/forms';
import { useSexSelectValues } from '@teledoctor/common/dist/features/shared/forms';
import styles from './style.module.scss';

interface Props {
  methods: UseFormReturn<RegistrationInfoFormValues, any>;
  onButtonClick: (
    values: RegistrationInfoFormValues,
    type: 'register-full' | 'register-partial',
  ) => void;
}

export const RegisterSecondStepPage = ({ methods, onButtonClick }: Props) => {
  const { sexSelectValues } = useSexSelectValues();

  return (
    <>
      <h1 className={classNames('h1', detailStyles.title)}>
        Завершите регистрацию
      </h1>
      <div className={detailStyles.description}>
        <div className={detailStyles['description__bold']}>Почти готово!</div>
        <br />
        Заполните последние детали, чтобы мы могли собрать более детальный
        анамнез <span className={styles.special}>для передачи врачам.</span>
      </div>

      <div className={styles.row}>
        <FField.Select
          name="sex"
          methods={methods}
          label="Пол"
          placeholder="Выберите пол"
          options={sexSelectValues}
          className={classNames('form__field', styles.field)}
          dataCy="PROFILE_SEX_SELECT"
        />
        <ExplanationDark
          modClass={styles.tooltip}
          tooltip="Нам важно указать ваш пол в медицинской документации."
        />
      </div>
      <div className={styles.row}>
        <FField.Date
          name="date_of_birth"
          methods={methods}
          label="Дата рождения"
          className={classNames('form__field', styles.field)}
          maxDate={new Date()}
        />
        <ExplanationDark
          modClass={styles.tooltip}
          tooltip="Нам важно указать вашу дату рождения в медицинской документации."
        />
      </div>
      <div className={styles.row}>
        <FField.Input
          name="email"
          methods={methods}
          label="E-mail"
          placeholder="Введите email"
          type="email"
          className={classNames('form__field', styles.field)}
          data-cy="PROFILE_EMAIL_INPUT"
        />
        <ExplanationDark
          modClass={styles.tooltip}
          tooltip="Просим предоставить дополнительные контактные данные на случай, если вы потеряете доступ к своему телефону."
        />
      </div>
      <div className={styles.buttons}>
        <Button
          type="button"
          title="Завершить регистрацию"
          className={styles.button}
          onClick={() =>
            methods.handleSubmit((values) => {
              onButtonClick(values, 'register-full');
            })()
          }
        />
        <Button
          type="button"
          appearance="textual"
          title="Заполнить позже"
          className={styles.button}
          onClick={() =>
            methods.handleSubmit((values) =>
              onButtonClick(values, 'register-partial'),
            )()
          }
        />
      </div>
    </>
  );
};
