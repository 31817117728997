import { useEffect, useMemo } from 'react';
import { useQuery } from '@router';
import { useStoreMap } from 'effector-react';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';

export const useBirthmarkData = () => {
  const query = useQuery();

  const birthmarkId = query.get('id') || '';

  const {
    metric: {
      $birthmarksCrud,
      $photoBirthmarkLinksCrud,
      loadPhotoBirthmarkLink,
    },
  } = useMetric({ metric: 'birthmarks' });

  const birthmark = useStoreMap({
    store: $birthmarksCrud,
    keys: [birthmarkId],
    fn: (birthmarks, [id]) =>
      birthmarks.list.find((item) => `${item.id}` === `${id}`) ?? null,
  });

  const file = useStoreMap({
    store: $photoBirthmarkLinksCrud,
    keys: [birthmarkId],
    fn: (links, [entityId]) =>
      links.list.find((item) => `${item.entityId}` === `${entityId}`) ?? null,
  });

  useEffect(() => {
    if (birthmark) {
      loadPhotoBirthmarkLink({
        unique: birthmarkId,
        uiBehavior: {
          noLoader: true,
        },
      });
    }
  }, [birthmarkId, loadPhotoBirthmarkLink]);

  const tableData = useMemo(() => {
    return [
      {
        label: 'Результат',
        value: birthmark?.disease,
        modClass: '-text__purple -font__700',
      },
      {
        label: 'Дата загрузки',
        value:
          birthmark &&
          formatTime(new Date(birthmark.timestamp), 'dd.MM.yyyy HH:mm'),
      },
      { label: 'Вероятность диагноза', value: `${birthmark?.probability}%` },
      { label: 'Точный диагноз', value: birthmark?.diagnosisObtain },
      { label: 'Лечение', value: birthmark?.therapy },
      { label: 'Совет', value: birthmark?.advice },
    ];
  }, [birthmark]);

  return { file, tableData, birthmark };
};
