import { createStore, createEvent } from 'effector';
import { MetricsWebInterface } from '@features/health/models/metrics/summary-modules';

export const $testimonyModules = createStore<MetricsWebInterface[]>([]);

export const setTestimonyModule = createEvent<MetricsWebInterface[]>(
  'testimonyModules:set',
);

$testimonyModules.on(setTestimonyModule, (store, modules) => modules);
