import './styles.scss';
import { AuthTemplate } from '@ui';

export const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found__message">
        <h1 className="not-found__code">404</h1>
        <h1 className="not-found__text">Ничего не найдено</h1>
      </div>
    </div>
  );
};

export const NotFoundPage = () => {
  return (
    <AuthTemplate>
      <NotFound />
    </AuthTemplate>
  );
};
