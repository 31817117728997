interface ConsultationListHeaderProps {
  hideServicesColumn?: true;
}

export const ConsultationListHeader = (props: ConsultationListHeaderProps) => {
  const { hideServicesColumn } = props;
  return (
    <div className="consultations-list__header labels-header">
      <div className="labels-header__item labels-header__item--id">Номер</div>
      <div className="labels-header__item labels-header__item--status">
        Статус
      </div>
      <div className="labels-header__item labels-header__item--created">
        Дата
      </div>
      {!hideServicesColumn ? (
        <div className="labels-header__item labels-header__item--name">
          Услуга
        </div>
      ) : null}
      <div className="labels-header__item labels-header__item--description">
        Суть запроса
      </div>
    </div>
  );
};
