import { ChatMessageText } from '@features/chat/ui/chat-message-text';
import { ChatMessageSenderInfo } from '@features/chat/ui/chat/chat-message-sender-info';
import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { MessageType } from '@eus/chat-default-logic/src/messages';
import { ReactNode } from 'react';

interface Props {
  text: MessageUI['text'];
  user: MessageUI['user'];
  time: string;
  messageType: MessageType;
  chatMessageClasses: string;
  additionalContent?: ReactNode;
}

export const ChatSimpleText = (props: Props) => {
  const {
    chatMessageClasses,
    text,
    time,
    messageType,
    user,
    additionalContent,
  } = props;
  return (
    <div className={chatMessageClasses}>
      <div className="chat__message">
        <ChatMessageText text={text} />
        {!!additionalContent && additionalContent}
        <div className="chat__message-time">{time}</div>
        <ChatMessageSenderInfo user={user} messageType={messageType} />
      </div>
    </div>
  );
};
