import React from 'react';
import SelectSimpleBase from '@eus/selects/src/SelectSimple';
import SelectMulti from '@eus/selects/src/SelectMulti';
import { SelectOption } from '@eus/selects/src/types';
import componentsBase from '@eus/selects/src/components';

import './styles.scss';
import styles from './styles';

export interface InputSelectProps {
  defaultValue?: any;
  disabled?: boolean;
  placeholder?: string;
  options: SelectOption[];
  className?: string;
  multiple?: boolean;
  isClearable?: boolean;
  onChange?: (value: any) => void;
  dataCy?: string;
  menuIsOpen?: boolean;
}

export function InputSelect<T = any>({
  className,
  options,
  defaultValue = undefined,
  placeholder = 'Не выбрано',
  multiple = false,
  disabled,
  isClearable = false,
  onChange,
  dataCy,
}: InputSelectProps) {
  if (!options.length) {
    console.log('Provide options to select');
  }

  const handleSelection = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      onChange?.(selectedOption.value);
    }
  };

  const handleSelectionMultiple = (selectedOptions: SelectOption[] | null) => {
    if (selectedOptions) {
      onChange?.(selectedOptions);
    }
  };

  const CustomSingleValue = (props: any) => {
    const className = 'input-text';
    let value = props.data.label;

    if (!value) {
      return (
        <componentsBase.Placeholder {...props} className={className}>
          {props.selectProps.placeholder}
        </componentsBase.Placeholder>
      );
    }

    return (
      <componentsBase.SingleValue {...props} className={className}>
        {value}
      </componentsBase.SingleValue>
    );
  };

  const CustomMultiValue = (props: any) => {
    let value = props.data.label;
    return (
      <componentsBase.MultiValue {...props}>{value}</componentsBase.MultiValue>
    );
  };

  const CustomControl = (props) => (
    <componentsBase.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-cy': dataCy,
      }}
    />
  );

  const CustomOption = (props) => {
    const optionIndex = props.innerProps.id.split('-').pop();
    return (
      <componentsBase.Option
        {...props}
        innerProps={{
          ...props.innerProps,
          'data-cy': `${dataCy}_OPTION_${optionIndex}`,
        }}
      />
    );
  };

  return !multiple ? (
    <SelectSimpleBase
      styles={styles}
      value={defaultValue}
      options={options}
      placeholder={placeholder}
      components={{
        ...componentsBase,
        IndicatorSeparator: false,
        Input: false,
        SingleValue: CustomSingleValue,
        Control: CustomControl,
        Option: CustomOption,
        ...(disabled && { DropdownIndicator: null }),
      }}
      isClearable={isClearable}
      className={className}
      classNamePrefix="input-select"
      onChange={(selectedOption) => handleSelection(selectedOption)}
      isDisabled={disabled}
    />
  ) : (
    <SelectMulti
      styles={styles}
      value={defaultValue}
      options={options}
      placeholder={placeholder}
      components={{
        ...componentsBase,
        IndicatorSeparator: false,
        Input: false,
        DropdownIndicator: false,
        MultiValue: CustomMultiValue,
      }}
      isClearable={isClearable}
      className={className}
      classNamePrefix="input-select"
      onChange={(selectedOptions) => handleSelectionMultiple(selectedOptions)}
    />
  );
}
