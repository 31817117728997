import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { Modal } from '../../atoms';
import {
  $messageModal,
  closeMessageModal,
  MessageModalInterface,
} from './model';

export const MessageModal = () => {
  const modal: MessageModalInterface | null = useStore($messageModal);
  if (modal) {
    const {
      component: modalComponent,
      onClose: onCloseFunction,
      ...params
    } = modal;

    const onCloseHandler = () => {
      const onClose = onCloseFunction ?? closeMessageModal;
      onClose();
    };

    return (
      <Modal isOpen={true} onClose={onCloseHandler} {...params}>
        {modalComponent}
      </Modal>
    );
  }
  return null;
};
