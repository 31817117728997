import React from 'react';
import { FField, Explanation } from '@ui';
import { anamnesisFields } from './anamnesis-model';
import { EditMedCardSection } from '@features/health/ui';
import './style.scss';

export const AnamnesisSection: React.FC<EditMedCardSection> = ({ methods }) => {
  return (
    <div className="edit-medcard__section edit-medcard__paddingX">
      <div className="edit-medcard__text">
        По возможности ответьте на приведенные ниже вопросы, чтобы у врачей было
        больше информации о вашем здоровье.
      </div>
      {!!anamnesisFields.length &&
        anamnesisFields.map((item, index) => (
          <React.Fragment key={`anamnesis-item__${index}`}>
            {item.label && (
              <div className="label field__label">
                <span className="edit-medcard__anamnesis-text">
                  {item.label}
                </span>
                {item.tooltip && (
                  <Explanation
                    modClass="edit-medcard__anamnesis-explanation"
                    tooltip={item.tooltip}
                  />
                )}
              </div>
            )}
            <FField.Input
              name={`anamnesis.${item.code}` as const}
              methods={methods}
              label=""
              placeholder="Введите текст"
              className="form__field"
              data-cy={`HEALTH_USER_ANAMNESIS_${item.code}_INPUT`}
            />
          </React.Fragment>
        ))}
    </div>
  );
};
