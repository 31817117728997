import React from 'react';
import { Svg } from '@ui';
import './metric-wrapper.scss';

interface Props {
  bottomText?: string;
}

export const MetricWrapper: React.FC<Props> = ({ bottomText, children }) => {
  return (
    <div className="metric-wrapper">
      {children}

      {bottomText && (
        <div className="metric-wrapper__bottom">
          <span className="metric-wrapper__bottom-icon">
            <Svg name="info" />
          </span>
          <span className="metric-wrapper__bottom-text">{bottomText}</span>
        </div>
      )}
    </div>
  );
};
