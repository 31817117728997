import React, { useRef } from 'react';
import './switcher.scss';
import classnames from 'classnames';

interface SwitcherInterface {
  text?: string;
  mobClass?: string;
  checked?: boolean;
  onChange?: () => void;
  switcherRef?: React.Ref<HTMLLabelElement>;
  textName?: string;
}

export const Switcher: React.FC<SwitcherInterface> = ({
  text,
  mobClass,
  checked = false,
  onChange,
  switcherRef,
  textName,
}) => {
  const switcherChangeHandler = () => {
    if (onChange) {
      onChange();
    }
  };
  const switcherClasses = classnames('switcher', mobClass);
  return (
    <label
      className={switcherClasses}
      ref={switcherRef}
      data-cy={`HEALTH_MODULES_MANAGER_SWITCH_${textName}`}>
      <input
        checked={checked}
        type="checkbox"
        className="hide-check switcher__original"
        onChange={switcherChangeHandler}
      />
      <span className="switcher__line" />
      {text && <div className="switcher__text">{text}</div>}
    </label>
  );
};
