import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { formatTime } from '@eus/crm-default-logic/src/formatters/date';
import {
  getSvgForAnalysisDecryptionStatus,
  getTitleByStatus,
} from '@teledoctor/common/dist/features/metrics/model/analyze-decryption';

import { Svg } from '@ui';
import './data-list.scss';

interface Props {
  data: MetricViewerDataItem[];
  activeElementId: number | string;
  onItemClick: (id: string) => void;
}

export interface MetricViewerDataItem {
  id: number | string;
  name: string;
  timestamp: number;
  modClass?: string;
  errorStatus?: boolean;
  status?: string;
  statusSvg?: string;
}

export const DataList: React.FC<Props> = ({
  data,
  activeElementId,
  onItemClick,
}) => {
  return (
    <ul className="data-list">
      {data.map((item) => {
        const itemClass = classnames(
          'data-list__item-container',
          item.modClass,
          {
            active: `${item.id}` === activeElementId,
          },
        );

        return (
          <li key={item.id} className={itemClass}>
            <div
              className="data-list__item"
              onClick={() => {
                onItemClick(item.id.toString());
              }}
              data-cy={`METRIC_VIEWER_ITEM_${item.id}`}>
              <div className="data-list__block">
                <div className="data-list__text">
                  <div className="data-list__title">
                    <p className="data-list__name">{item.name}</p>
                    {item.errorStatus && (
                      <div className="data-list__circle data-list__circle--error">
                        <Svg name="warning" />
                      </div>
                    )}
                  </div>
                  {item.status && (
                    <p className="data-list__status">
                      {item.statusSvg && (
                        <span className="data-list__status-svg">
                          <Svg name={item.statusSvg} />{' '}
                        </span>
                      )}
                      {item.status}
                    </p>
                  )}
                  <p className="data-list__date">
                    {formatTime(new Date(item.timestamp), 'dd.MM.yyyy HH:mm')}
                  </p>
                </div>
              </div>
              <div className="data-list__arrow-btn" />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
