import React, { useEffect } from 'react';
import { TitleLine } from '@ui';
import { Tariffslist } from '@features/tariffs/ui';

export const TariffsPage = () => {
  return (
    <React.Fragment>
      <h1 className="h1">Тарифы</h1>
      <Tariffslist />
    </React.Fragment>
  );
};
