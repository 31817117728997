import { LoginCodeForm } from '@features/user/ui/forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { HOST_CRM_API } from '@teledoctor/common/dist/config/api';
import { useCheckEmailFormSchema } from '@teledoctor/common/dist/features/user/forms';
import { Button, FField, FormWrapper } from '@ui';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckFormCode } from '@eus/react-client';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { OnSuccessForm } from '@teledoctor/common/dist/hooks';

interface Props {
  verifyCode: OnSuccessForm<CheckFormCode>;
  getCodeAgainHandler: () => Promise<void>;
  restorePasswordByEmail: (email?: string | undefined) => Promise<unknown>;
}

interface RestorePasswordByEmail {
  type: 'phone' | 'email';
  email: string;
  isCrypt?: boolean;
}

export const PARAMS = {
  baseURL: HOST_CRM_API,
  needLogout: false,
};

export interface EmailSchema {
  email: string;
}

export const RestorePasswordByEmail = ({
  verifyCode,
  getCodeAgainHandler,
  restorePasswordByEmail,
}: Props) => {
  const [isShowCodeForm, setShowCodeForm] = useState<boolean>(false);

  const { CheckEmailFormSchema } = useCheckEmailFormSchema();

  const methods = useForm<EmailSchema>({
    resolver: yupResolver(CheckEmailFormSchema),
    mode: 'onChange',
  });

  const { handleSubmit, setError, watch } = methods;

  const email = watch('email');

  const onSubmit = useCallback(
    ({ email }: EmailSchema) => {
      restorePasswordByEmail(email)
        .then(() => {
          setShowCodeForm(true);
        })
        .catch(
          formServerErrorHandler({
            commonErrorCase: {
              commonErrorCallback: (error) => {
                setError('email', {
                  type: 'server',
                  message: error,
                });
              },
            },
            fieldsErrorsCase: {
              fieldsErrorsCallback: () => {},
            },
          }),
        );
    },
    [restorePasswordByEmail, setError],
  );

  return !isShowCodeForm ? (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <FField.Input
        name="email"
        methods={methods}
        label="E-mail"
        placeholder="Введите email"
        type="email"
        className="form__field"
        data-cy="PROFILE_EMAIL_INPUT"
      />
      <Button fullWidth title="Продолжить" />
    </FormWrapper>
  ) : (
    <LoginCodeForm
      requestCodeAgain={getCodeAgainHandler}
      onSuccessForm={verifyCode}
      email={email}
    />
  );
};
