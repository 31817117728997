import { useStore } from 'effector-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './notifications.scss';

import {
  $notifications,
  NotificationTarget,
  removeNotification,
} from './model';

interface NotificationsProps {
  target: NotificationTarget;
}

export const Notifications = ({ target }: NotificationsProps) => {
  const notifications = useStore($notifications);

  const filtered = notifications.filter(
    (notification) => notification.target === target,
  );

  return (
    <div
      className={`notifications notifications__position-${
        target === 'global' ? 'top' : 'bottom'
      }`}>
      {filtered.map(({ id, message, type, onClear }) => {
        return (
          <div key={id} className="notifications-anim">
            <div className={`notifications__item notifications__item-${type}`}>
              <div
                className="notifications__item-delete"
                onClick={() => {
                  removeNotification(id);
                  onClear?.();
                }}
                data-cy="NOTIFICATION_CLOSE_BTN"
              />
              <div
                className="notifications__item-text"
                data-cy="NOTIFICATION_TEXT">
                {message}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
