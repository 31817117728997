import React, { useState } from 'react';
import { Button } from '@ui';
import { ChatFeedback } from '@features/chat/ui/chat-feedback/chat-feedback';
import { ChatClaimModal } from '@features/chat/ui/chat-feedback/modals/chat-claim-modal';
import { useBoolean } from '@teledoctor/common/dist/hooks';

export const ChatClaim: React.FC<ChatFeedback> = ({ text, taskId }) => {
  const {
    value: isClaimModalOpen,
    setFalsy: closeClaimModalHandler,
    setTruthy: showClaimModalHandler,
  } = useBoolean(false);

  return text ? (
    <div className="consultation-end__label">
      <div className="label">Претензия</div>
      {text}
    </div>
  ) : (
    <>
      <Button
        onClick={showClaimModalHandler}
        appearance="solid"
        title="Оставить претензию"
        className="consultation-end__detail-button"
        data-cy="CONSULT_FEEDBACK_CLAIM_BTN"
      />
      <ChatClaimModal
        onCloseHandler={closeClaimModalHandler}
        isOpen={isClaimModalOpen}
        taskId={taskId}
      />
    </>
  );
};
