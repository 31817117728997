import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import './breadcrumbs.scss';

export interface BreadcrumbsItem {
  path: string;
  breadcrumb?: string;
}

interface Breadcrumbs {
  props: BreadcrumbsItem[];
  additionalClassName?: string;
}

export const Breadcrumbs: React.FC<Breadcrumbs> = ({
  props,
  additionalClassName,
}) => {
  const options = {
    disableDefaults: true,
  };
  const breadcrumbs = useBreadcrumbs(props, options);

  return (
    <div
      className={`breadcrumbs${
        additionalClassName ? ' ' + additionalClassName : ''
      }`}>
      <div className="breadcrumbs__list">
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          let last = index === breadcrumbs.length - 1;
          let linkKey = match.url.replace('/lk/', '').toUpperCase();
          return (
            <div className="breadcrumbs__item" key={match.url}>
              {!last ? (
                <Link
                  className="breadcrumbs__text breadcrumbs__link"
                  to={match.url}
                  data-cy={`BREADCRUMBS_LINK_${linkKey}`}>
                  {breadcrumb}
                </Link>
              ) : (
                <div
                  className="breadcrumbs__text"
                  data-cy="BREADCRUMBS_CURRENT_ITEM">
                  {breadcrumb}
                </div>
              )}
              {last ? '' : '/'}
            </div>
          );
        })}
      </div>
    </div>
  );
};
