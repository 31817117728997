import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import classnames from 'classnames';
import { ChatMessageText } from '../chat-message-text/chat-message-text';
import format from 'date-fns/esm/format';
import { FileInputInterface } from '@ui';
import { ChatMessageSenderInfo } from '../chat/chat-message-sender-info';
import { ChatCustomMessage } from './chat-custom-message';
import { ChatFiles } from './chat-files';

export type MessageSource = 'incoming' | 'outgoing';
interface Props {
  message: MessageUI;
  source: MessageSource;
}

export type VariedFile = FileInputInterface | MessageUI['files'][number];

export const getFileUrl = (fileElem: VariedFile): string => {
  if ('src' in fileElem) {
    return fileElem.src;
  }
  return fileElem.url;
};

export const ChatMessage = ({ message, source }: Props, key) => {
  const {
    text,
    files,
    type: messageType,
    user,
    localTimestamp,
    additionalPayload,
    chatId,
  } = message;

  let chatMessageClass = classnames(
    'chat__message-group',
    `chat__message-group-${source}`,
  );
  const time = format(new Date(localTimestamp), 'HH:mm');

  if (additionalPayload) {
    return (
      <ChatCustomMessage
        text={text}
        additionalPayload={additionalPayload}
        time={time}
        messageType={messageType}
        user={user}
        chatMessageClasses={chatMessageClass}
        chatId={chatId}
      />
    );
  } else {
    return (
      <div className={chatMessageClass}>
        {!!text && (
          <div className="chat__message">
            <ChatMessageText text={text} />
            <div className="chat__message-time">{time}</div>
            <ChatMessageSenderInfo user={user} messageType={messageType} />
          </div>
        )}

        {!!files?.length && (
          <ChatFiles
            files={files}
            messageType={messageType}
            user={user}
            time={time}
          />
        )}
      </div>
    );
  }
};
