import React from 'react';
import { ChatThanksForm } from '@features/chat/ui/chat-feedback/forms/chat-thanks-form';
import { Modal, Notifications } from '@ui';

interface Props {
  onCloseHandler: () => void;
  isOpen: boolean;
  taskId: number;
}

export const ChatThanksModal = ({ onCloseHandler, isOpen, taskId }: Props) => {
  return (
    <Modal
      onClose={onCloseHandler}
      isOpen={isOpen}
      header="Благодарность"
      root={<Notifications target="chat-thanks-modal" />}>
      <div className="description -mb__xsmall -mb__xs-small">
        <p>Оставьте комментарий о работе наших специалистов</p>
      </div>
      <ChatThanksForm taskId={taskId} onSuccess={onCloseHandler} />
    </Modal>
  );
};
