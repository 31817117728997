import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
}

const personalDataLink =
  'https://api.teledoctor24.ru/static/documents/personal-data.html';

export const AgreeWithPrivacyPolicy = (props: Props) => {
  const { className } = props;

  return (
    <p className={classNames(styles.policy, className)}>
      <span className={styles.text}>
        Авторизируясь, я соглашаюсь с условиями
      </span>
      <a className={styles.link} target="_blank" href={personalDataLink}>
        {' '}
        пользовательского соглашения
      </a>
    </p>
  );
};
