import { Controller } from 'react-hook-form';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import { FieldWrapper } from '../../../molecules';
import { FieldProps } from './types';
import { months } from '@eus/crm-default-logic/src/formatters/date';

export interface FieldDateProps<T>
  extends FieldProps<T>,
    Omit<DatePickerProps, 'name' | 'className' | 'disabled'> {
  className?: string;
  onFieldChange?: (value: Date | Date[]) => void;
  formatChangedValue?: (value: Date | Date[]) => void;
  formatCurrentValue?: (value: any) => Date | null | [Date | null, Date | null];
}

const ClearIcon = ({ dataCy = 'field-date' }) => (
  <svg
    data-cy={`${dataCy}_clear`}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    stroke="black"
    strokeWidth="2"
    className={
      'react-date-picker__clear-button__icon react-date-picker__button__icon'
    }>
    <line x1="4" x2="15" y1="4" y2="15" />
    <line x1="15" x2="4" y1="4" y2="15" />
  </svg>
);

export function FieldDate<T>({
  name,
  label,
  methods,
  className,
  inputClassName,
  errorsClassName,
  labelClassName,
  onFieldChange,
  disabled,
  format = 'dd.MM.yyyy',
  formatChangedValue,
  formatCurrentValue,
  ...inputDateProps
}: FieldDateProps<T>) {
  return (
    <Controller
      name={name}
      control={methods.control}
      // @ts-ignore
      defaultValue={inputDateProps.value}
      render={({ field: { onChange, onBlur, value, name } }) => {
        return (
          <FieldWrapper
            label={label}
            errors={methods.formState.errors}
            name={name}
            disabled={disabled}
            errorsClassName={errorsClassName}
            className={className}
            labelClassName={labelClassName}
            inputClassName="input-date"
            dataCy={inputDateProps['data-cy']}>
            <DatePicker
              format={format}
              locale="ru"
              prev2Label={null}
              next2Label={null}
              prevLabel=""
              nextLabel=""
              dayPlaceholder="ДД"
              monthPlaceholder="ММ"
              yearPlaceholder="ГГГГ"
              formatMonthYear={(_, date) => {
                return `${months[date.getMonth()]} ${date.getFullYear()}г.`;
              }}
              {...inputDateProps}
              className={inputClassName}
              value={formatCurrentValue ? formatCurrentValue(value) : value}
              disabled={disabled}
              onChange={(value) => {
                const formattedValue = formatChangedValue
                  ? formatChangedValue(value)
                  : value;
                onChange(formattedValue);
                onFieldChange?.(formattedValue);
              }}
              onCalendarClose={onBlur}
              clearIcon={<ClearIcon dataCy={inputDateProps['data-cy']} />}
            />
          </FieldWrapper>
        );
      }}
    />
  );
}
