import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './chat.scss';
import { ChatMessaging } from '@features/chat/ui/chat-messaging';
import { ChatArea } from '@features/chat/ui/chat-area';
import { MessageWithMeta } from '@teledoctor/common/dist/features/chat';
import {
  Chat as TChat,
  ChatItem,
  MessageUI,
} from '@eus/react-client/src/chat_v2/types';
import { Task } from '@eus/react-client/src/support/types';
import { processChatMessages as baseProcessChatMessages } from '@eus/react-client/src/utils/chat';
import { ChatButtonsProps } from '../chat-area/chat-buttons';
import { useChatTdLk, useTasksTdLk } from '@features/api';
import { useChatStore } from '@eus/react-client/src/chat_v2';

interface Chat {
  taskIsClosed?: boolean;
  task: Task | undefined;
  chatId: TChat['externalId'];
  chatButtons?: ChatButtonsProps;
  processChatMessages?: (messages?: MessageUI[]) => ChatItem[];
  limit?: number;
}

export const Chat: React.FC<Chat> = ({
  taskIsClosed = false,
  task,
  chatId,
  chatButtons,
  processChatMessages = baseProcessChatMessages,
  limit = 20,
}) => {
  const state = useChatStore();
  const { connect, loadChats, loadFromChat, readChat } = useChatTdLk();
  const { loadTasksList } = useTasksTdLk();

  const [chatItems, setChatItems] = useState<ChatItem[]>([]);

  const lastLoadedMessage = useMemo(() => {
    const messages = state?.messages?.[chatId] ?? [];
    return messages?.[messages.length - 1];
  }, [state?.messages, chatId]);

  useEffect(() => {
    connect();
    loadChats({ filter: { ids: [chatId] } });
    loadFromChat(chatId, limit);
  }, [chatId, connect, loadChats, loadFromChat, limit]);

  useEffect(() => {
    if (task?.new_messages_in_chat && task?.new_messages_in_chat > 0) {
      readChat(chatId);
      loadTasksList();
    }
  }, [chatId, readChat]);

  const loadMore = useCallback(() => {
    loadFromChat(chatId, limit, lastLoadedMessage?.id);
  }, [chatId, loadFromChat, limit, lastLoadedMessage]);

  useEffect(() => {
    const messages = processChatMessages(state?.messages?.[chatId]);
    setChatItems(messages);
  }, [processChatMessages, chatId, setChatItems, state?.messages]);

  return (
    <div className="chat__wrapper">
      <section className="chat">
        <ChatArea chatItems={chatItems} chatButtons={chatButtons} />
        {!taskIsClosed && task && <ChatMessaging taskId={task.id.toString()} />}
      </section>
    </div>
  );
};
