import React from 'react';
import FileReaderInput from 'react-file-reader-input';
import './file-input.scss';
import {
  addNotification,
  clearNotifications,
} from '../organisms/notifications';

import {
  isDoc,
  isImage,
  isAudio,
  isMedical,
  getExtentionFromName,
  isVideo,
} from '@teledoctor/common/dist/lib/detectors/detect-file-type';
import { FileGroups } from '@teledoctor/common/dist/features/audio-player/types';
type Result = [ProgressEvent, FileInputInterface];
type As = 'buffer' | 'binary' | 'url' | 'text';

export interface FileInputInterface extends File {
  src: string;
  fileType: FileGroups;
  id: number;
}

export interface FileInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  fileType?: FileGroups[];
  as?: As;
  maxFiles?: number;
  maxFileSize?: number;
  onChange: (files: FileInputInterface[]) => void;
}

export const FileInput: React.FC<FileInputProps> = ({
  as = 'url',
  children,
  onChange = () => console.log('fileInput change'),
  fileType,
  maxFiles = 10,
  maxFileSize = 4,
  ...props
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    results: Result[],
  ) => {
    clearNotifications();
    let filesExcess = false;

    const files = results.reduce<FileInputInterface[]>((all, result, index) => {
      if (all.length >= maxFiles) {
        if (!filesExcess) filesExcess = true;
        return all;
      }

      const [e, file] = result;

      const fileId = Date.now() + index;

      // 1. standart validation for image, audio and doc

      const type = checkFileType(file.name);

      if (!type) {
        addNotification({
          id: 'file-input' + fileId ?? Date.now(),
          type: 'error',
          message: `Файл ${
            file.name
          } не загружен! Файлы с расширением ${getExtentionFromName(
            file.name,
          )} недопустимы к загрузке`,
          target: 'global',
        });

        return all;
      }

      // 2. narrow file type

      const typeForCompliance = fileType
        ? checkTypeForCompliance({
            type: type,
            neededType: fileType,
          })
        : true;

      if (!typeForCompliance) {
        addNotification({
          id: 'file-input' + fileId,
          type: 'error',
          message: `Файл ${
            file.name
          } не загружен! Файл должен относиться к типу ${(fileType || [])
            .map(mapFileTypeToHumanLike)
            .map((v) => `"${v}"`)
            .join(', ')}`,
          target: 'global',
        });

        return all;
      }

      // 3. validate size

      const correctSize = checkSize({
        filesize: file.size,
        maxFileSize,
      });

      if (!correctSize) {
        addNotification({
          id: 'file-input' + fileId,
          type: 'error',
          message: `Файл ${file.name} не загружен! Максимально допустимый размер файла ${maxFileSize} Мб`,
          target: 'global',
        });

        return all;
      }

      file.fileType = type;
      // @ts-ignore
      file.src = e.target.result;
      file.id = fileId;
      all.push(file);

      return all;
    }, []);

    if (filesExcess) {
      addNotification({
        id: 'file-input-excess',
        type: 'error',
        message: `Ограничение по загрузке файлов. Нельзя одновременно загрузить более ${maxFiles}`,
        target: 'global',
      });
    }

    if (files.length) {
      onChange(files as any);
    }
  };

  return (
    <FileReaderInput as={as} onChange={handleChange} {...props}>
      {children}
    </FileReaderInput>
  );
};

const fileType: Record<FileGroups, string> = {
  audio: 'аудио',
  document: 'документ',
  image: 'изображение',
  medical: 'медицинский файл (dcm)',
  video: 'видео',
};

const mapFileTypeToHumanLike = (type: FileGroups) => {
  return fileType[type];
};

export const checkFileType = (fileName: string): FileGroups | undefined => {
  if (isDoc(fileName)) {
    return 'document';
  } else if (isAudio(fileName)) {
    return 'audio';
  } else if (isImage(fileName)) {
    return 'image';
  } else if (isMedical(fileName)) {
    return 'medical';
  } else if (isVideo(fileName)) {
    return 'video';
  } else {
    return undefined;
  }
};

interface CheckTypeForComplianceProps {
  type: FileGroups;
  neededType: FileGroups[];
}

const checkTypeForCompliance = ({
  type,
  neededType,
}: CheckTypeForComplianceProps) => {
  return neededType.includes(type);
};

interface CheckSizeProps {
  filesize: number;
  maxFileSize: number;
}

const checkSize = ({ filesize, maxFileSize }: CheckSizeProps) => {
  return filesize <= maxFileSize * 1024 * 1024;
};
