import { useAxiosInstance } from '@eus/react-client/src/hooks';
import { HOST_CRM_API } from '@teledoctor/common/dist/config/api';
import { useCallback, useEffect, useState } from 'react';

export const PARAMS = {
  baseURL: HOST_CRM_API,
  needLogout: false,
  url: '/api/v1/cp/tasks?filter=%7B%7D&sort=[%22new_messages_client%22,%22DESC%22]&range=[0,19]',
};

export function useCountNonReadConsultation() {
  const [count, setCount] = useState<number>(0);

  const axiosInstance = useAxiosInstance(PARAMS);

  const loadTaskList = useCallback(
    () =>
      axiosInstance({
        url: '/api/v1/cp/tasks?filter=%7B%7D&sort=[%22new_messages_client%22,%22DESC%22]&range=[0,19]',
        headers: {
          app: 'td_web',
        },
      })
        .then((data) => {
          const arr = data.data.filter((item) => item.new_messages_in_chat > 0);
          const unreadCount =
            arr?.reduce((sum, task) => {
              const currentUnread = task?.new_messages_in_chat;
              if (currentUnread) {
                return sum + currentUnread;
              } else return sum;
            }, 0) ?? 0;
          setCount(unreadCount);
        })
        .catch((err) => {
          console.log('err', err);
        }),
    [axiosInstance],
  );

  useEffect(() => {
    loadTaskList();
  }, [loadTaskList]);

  useEffect(() => {
    const reload = () => {
      loadTaskList();
    };

    const timerID = setInterval(() => reload(), 30000);
    return () => clearInterval(timerID);
  }, [loadTaskList]);

  return count;
}
