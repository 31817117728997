import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import './item.scss';
import { Favorite, Status } from '@features/shared/ui';

import { formatDateShort } from '@eus/crm-default-logic/src/formatters/date';
import { Task } from '@eus/react-client/src/types/tasks';
import { STATUSES_ALL } from '@eus/els-api-types/src/task-status-cp';
import { useTasksTdLk } from '@features/api';

interface ConsultationItem {
  task: Task;
  pathNamespace: string;
  isRegularTasks: boolean;
  hideServicesColumn?: true;
}

export const TaskItem = React.memo(
  ({
    task,
    pathNamespace,
    isRegularTasks,
    hideServicesColumn,
  }: ConsultationItem) => {
    const {
      id: taskId,
      new_messages_in_chat,
      service_name,
      is_favorite,
      task_status_id,
      description,
      created_at,
    } = task;

    const { setFavorite } = useTasksTdLk();

    const handleToggleFavorite = useCallback(
      (taskId: number) => {
        setFavorite(taskId);
      },
      [setFavorite],
    );

    return (
      <div className="consultations-item">
        {isRegularTasks && (
          <div className="consultations-item__toggle">
            <Favorite
              isFavorite={is_favorite}
              onToggle={(isFavorite) => handleToggleFavorite(taskId)}
              id={taskId}
            />
          </div>
        )}
        <NavLink
          to={{ pathname: `${pathNamespace}/${taskId}` }}
          className="list-card"
          data-cy={`CONSULT_ITEM_${taskId}`}>
          <article className="list-card__inner">
            <div className="list-card__row list-card__row--status">
              <Status id={task_status_id} text={STATUSES_ALL[task_status_id]} />
            </div>
            {!hideServicesColumn ? (
              <div className="list-card__row list-card__row--name">
                <span>{service_name}</span>
              </div>
            ) : null}
            <div className="list-card__row list-card__row--description">
              {description}
            </div>
            <div className="list-card__row list-card__row--half list-card__row--id">
              №{taskId}
              {!!new_messages_in_chat && new_messages_in_chat > 0 && (
                <span className="list-card__unread-messages">
                  {new_messages_in_chat}
                </span>
              )}
            </div>
            <div className="list-card__row list-card__row--half list-card__row--created">
              {formatDateShort(new Date(created_at * 1000))}
            </div>
          </article>
        </NavLink>
      </div>
    );
  },
);
