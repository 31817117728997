import { withPersist } from '@lib/with-persist';
import { $token as $tokenBase } from '@teledoctor/common/dist/features/session';

/**
 * Profile
 */

withPersist($tokenBase, { key: 'session:local-token' });
export const $tokenLk = $tokenBase.map((token) => token);

// withPersist($profile, { adapter: profileAdapter(localStoragePromisified) });
