import React, { useEffect } from 'react';
import getTime from 'date-fns/esm/getTime';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { useQuery } from '@router';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import { formatResult } from '@eus/health-default-logic/dist/ecg/results';
import { MetricViewer, MetricWrapper } from '@features/health/ui/health-detail';
import { EcgModal, EcgDetail } from '@features/health/ui/metrics/ecg';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье 2.0',
  },
  {
    path: '/lk/health/ecg',
    breadcrumb: 'ЭКГ',
  },
];

export const EcgPage = () => {
  const query = useQuery();

  const ecgId = query.get('id') || '';

  const {
    metric: { $store, loadList, loadDetail },
  } = useMetric({ metric: 'ecg' });

  const ecgList = useStore($store) || [];

  useEffect(() => {
    loadList();
  }, [ecgId, loadDetail, loadList]);

  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MetricWrapper bottomText="Чтобы сделать ЭКГ, создайте новый запрос в мобильном приложении">
        <MetricViewer
          data={ecgList.map((item) => {
            return {
              id: item.id,
              name: formatResult(item).title,
              timestamp: getTime(item.datetime),
            };
          })}
          elementId={ecgId}
          emptyDataText={
            'Чтобы сделать ЭКГ, создайте новый запрос в мобильном приложении'
          }
          chooseElementText={'Выберите ЭКГ из списка слева'}
          rightColumnRender={() => <EcgDetail />}
          modalRender={() => <EcgModal />}
        />
      </MetricWrapper>
    </>
  );
};
