import { validation } from '@eus/react-client/src/phone-codes';
import { useErrorMessage } from '@teledoctor/common/dist/features/shared/forms';
import { useMemo } from 'react';
import { array, number, object, string } from 'yup';

const useNumberType = () => {
  const { ErrorMessage } = useErrorMessage();
  const numberType = useMemo(() => {
    return number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .typeError(ErrorMessage.type_number);
  }, [ErrorMessage]);
  return { numberType };
};

export const useSchema = () => {
  const { ErrorMessage } = useErrorMessage();
  const { numberType } = useNumberType();
  const schema = useMemo(() => {
    return object().shape({
      health: object().shape({
        height: numberType,
        weight: numberType,
        arterial_pressure: string().nullable(),
        pulse: numberType,
      }),
      contacts: array().of(
        object().shape({
          fio: string()
            .min(1, ErrorMessage.min_length)
            .required(ErrorMessage.required),
          phone: string()
            .required(ErrorMessage.required)
            .test('phone', ErrorMessage.phone, (value) => {
              if (!value) return true;
              return validation(value);
            }),
          family_rel: number().required(ErrorMessage.required),
        }),
      ),
    });
  }, [
    ErrorMessage.min_length,
    ErrorMessage.phone,
    ErrorMessage.required,
    numberType,
  ]);
  return { schema };
};
