import React from 'react';
import { useLocation } from 'react-router';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { MedicalCardEditForm } from '@features/health/ui';
import { HealthPersonalInfo } from '@eus/health-default-logic/src/medcard/types';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/health/',
    breadcrumb: 'Здоровье',
  },
  {
    path: '/lk/health/edit-medical-card',
    breadcrumb: 'Редактировать-медкарту',
  },
];

export const EditMedicalCard = () => {
  const { state } = useLocation();
  const { ...healthValues } = (state ?? {}) as HealthPersonalInfo;
  return (
    <>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      <MedicalCardEditForm {...healthValues} />
    </>
  );
};
