import React, { useEffect, useState } from 'react';

import promo from '@assets/img/present.png';
import { ServerErrorsHandler } from '@eus/react-client';
import { Card } from '@eus/react-client/src/cards/types';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { CRM_ERRORS_RUSSIAN } from '@teledoctor/common/dist/lib/text-constants';
import { addNotification, Button, Modal, Notifications, Svg } from '@ui';
import { useCardsTdLk } from '../../../../features/api';
import { AddCardForm } from './../forms';
import { AddCardSuccessModal } from './../modals';
import { history } from '@features/app/navigation.model';
import styles from './style.module.scss';
import classNames from 'classnames';

export const AddCard = () => {
  const {
    value: isVisible,
    setTruthy: openForm,
    setFalsy: closeForm,
  } = useBoolean(history.location.state?.showAddCard ?? false);
  const {
    value: isSuccessVisible,
    setTruthy: openSuccess,
    setFalsy: closeSuccess,
  } = useBoolean(false);

  useEffect(() => {
    if (history.location.state?.showAddCard) {
      openForm();
      history.replace({ state: null });
    }
  }, [openForm]);

  const [newlyAddedcard, setNewlyAddedCard] = useState<Card | null>();

  const { addCard } = useCardsTdLk();

  const onSubmit = (values, setError) => {
    addCard(
      { data: values },
      new FeedParams({ ignoreError: true, throwNext: true }),
    )
      .then((newlyAddedCard) => {
        setNewlyAddedCard(newlyAddedCard);
        closeForm();
        openSuccess();
      })
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText: string) =>
              addNotification({
                id: 'add-card' + Date.now(),
                type: 'error',
                message: errorText,
                target: 'add-card',
              }),
            translatingObject: CRM_ERRORS_RUSSIAN,
            defaultErrorText: 'При добавлении карты произошла ошибка',
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: ServerErrorsHandler(setError),
          },
        }),
      );
  };

  const reset = () => {
    setNewlyAddedCard(null);
  };

  return (
    <React.Fragment>
      <Button
        className={classNames(
          'button__primary titleline__button -ml__xmedium -ml__md-small -ml__xs-none',
          styles.button,
        )}
        appearance="outline"
        onClick={openForm}
        title=""
        data-cy="CARDS_ADD_CARD_BTN">
        <Svg name="plus" modClass={styles['button-icon']} />
        Добавить карту
      </Button>
      <Modal
        onClose={closeForm}
        isOpen={isVisible}
        header="Добавить карту"
        image={promo}
        root={<Notifications target="add-card" />}>
        <AddCardForm
          onSuccess={(values, setError) => onSubmit(values, setError)}
        />
      </Modal>
      <AddCardSuccessModal
        isOpen={isSuccessVisible}
        onClose={() => {
          reset();
          closeSuccess();
        }}
        card={`“${newlyAddedcard?.tariff_name}” (${newlyAddedcard?.number})`}
        image={newlyAddedcard?.logo}
      />
    </React.Fragment>
  );
};
