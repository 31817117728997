import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from '@eus/react-web-client/src/hooks/useHistory';
import styles from './styles.module.scss';
import { BannerType } from '@features/banners/types';
import classNames from 'classnames';
import { Button, Svg, showLoader, hideLoader } from '@ui';
import { numberFormat } from '@teledoctor/common/dist/lib/formatters/format-number';
import {
  getButtonTitle,
  getDescription,
  getTitle,
  TariffBannerCommon,
} from '@teledoctor/common/dist/features/tariffs';
import { useGetTariffsMerchantLinkTdLk } from '@features/api';

interface Props extends TariffBannerCommon {
  type: BannerType;
  id?: number;
  discount?: number;
}

export const BannerItem = ({ type, id, discount, withDiscount }: Props) => {
  const history = useHistory();

  const { getTariffsMerchantLink, loading } = useGetTariffsMerchantLinkTdLk();

  useEffect(() => {
    loading ? showLoader() : hideLoader();
  }, [loading]);

  const buttonTitle = useMemo(() => getButtonTitle(type), [type]);

  const title = useMemo(
    () => getTitle(type, withDiscount, discount),
    [withDiscount, type, discount],
  );

  const description = useMemo(
    () => getDescription(type, withDiscount),
    [withDiscount, type],
  );

  const getLink = useCallback(async () => {
    if (id) {
      await getTariffsMerchantLink(id);
    }
  }, [id, getTariffsMerchantLink]);

  return (
    <div
      className={classNames(
        'grid__item grid__item-6x grid__item-sm-12x',
        styles.grid,
      )}>
      <div
        className={classNames(
          styles.banner,
          styles[type],
          !withDiscount ? styles['without-discount'] : styles['with-discount'],
        )}>
        {withDiscount && (
          <div className={styles['present__wrapper']}>
            <Svg name="present" modClass={styles.present} />
          </div>
        )}
        <div className={styles.content}>
          {withDiscount ? (
            <div className={styles.discount}>{`-${discount}%`}</div>
          ) : (
            <>
              <div className={styles.label} />
              <Svg name="crown" modClass={styles.label__icon} />
            </>
          )}
          {!withDiscount && type === 'subscription' && (
            <div className={styles.info}>Выгодно</div>
          )}
          <div className={styles.title}>{title}</div>
          {!!description && (
            <div className={styles.description}>{description}</div>
          )}
          {type === 'single' && !withDiscount && (
            <div className={styles.prices}>
              <div className={styles.price__description}>1 консультация</div>
              <div className={styles.price__line}>
                <div className={styles.price__current}>
                  {numberFormat(2700, 0, '', ' ')} ₽
                </div>
              </div>
            </div>
          )}
          <Button
            className={styles.button}
            title={buttonTitle}
            {...(withDiscount && { appearance: 'outline' })}
            onClick={() =>
              type === 'subscription' ? history.push('tariffs') : getLink()
            }
          />
        </div>
      </div>
    </div>
  );
};
