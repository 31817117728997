import { useEffect } from 'react';
import { useQuery } from '@router';
import { useMetric } from '@teledoctor/common/dist/features/metrics/hooks/use-metric';
import { useStore } from 'effector-react';
import { formatResult } from '@eus/health-default-logic/dist/ecg/results';

export const useEcgData = () => {
  const query = useQuery();

  const ecgId = query.get('id') || '';

  const {
    metric: { $store, loadList, loadDetail },
  } = useMetric({ metric: 'ecg' });

  const ecgList = useStore($store) || [];
  const ecg = ecgList.find((item) => item.id == ecgId);
  const result = ecg && formatResult(ecg);

  useEffect(() => {
    if (!ecg) {
      loadList();
    } else if (!ecg.measureData || !ecg.measureData.length) {
      loadDetail(ecgId);
    }
  }, [ecg, ecgId, loadDetail, loadList]);

  const ecgTable = ecg
    ? [
        {
          id: '',
          name: '',
          min: 'Минимальное',
          medium: 'Среднее',
          max: 'Максимальное',
        },
        {
          id: 'pulse',
          name: 'Пульс',
          min: `${ecg.pulse.min} уд/мин`,
          medium: `${ecg.pulse.medium} уд/мин`,
          max: `${ecg.pulse.max} уд/мин`,
        },
        {
          id: 'qt',
          name: 'Период QT',
          min: `${ecg.qt.min} м/сек`,
          medium: `${ecg.qt.medium} м/сек`,
          max: `${ecg.qt.max} м/сек`,
        },
        {
          id: 'hrv',
          name: 'HRV',
          min: `${ecg.hrv.min} м/сек`,
          medium: `${ecg.hrv.medium} м/сек`,
          max: `${ecg.hrv.max} м/сек`,
        },
      ]
    : [];

  return {
    ecgTable,
    ecg,
    result,
  };
};
