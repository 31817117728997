import { ServerErrorsHandler } from '@eus/react-client';
import { useAuthTdLk, useProfileTdLk } from '@features/api';
import { ChangePasswordAfterRestoreFormValues } from '@teledoctor/common/dist/features/user/forms';
import {
  OnSuccessForm,
  usePasswordCreateForm,
} from '@teledoctor/common/dist/hooks';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { addNotification } from '@ui';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { UseFormSetError } from 'react-hook-form';

const feedParams = new FeedParams({
  throwNext: true,
  ignoreError: true,
  needShowLoader: true,
});

const handleError = (
  setError: UseFormSetError<ChangePasswordAfterRestoreFormValues>,
) =>
  formServerErrorHandler({
    commonErrorCase: {
      commonErrorCallback: (errorText) =>
        addNotification({
          id: 'create-password' + Date.now(),
          type: 'error',
          message: errorText,
          target: 'create-password',
        }),
    },
    fieldsErrorsCase: {
      fieldsErrorsCallback: ServerErrorsHandler(setError),
    },
  });

interface Params {
  onSuccess: () => void;
  login: string;
}

export const useCreatePassword = (params: Params) => {
  const { onSuccess, login } = params;
  const { load: loadProfile } = useProfileTdLk();
  const { addPassword } = useAuthTdLk();

  const onSubmit: OnSuccessForm<ChangePasswordAfterRestoreFormValues> = async (
    { new_password: password, confirm_new_password: confirm_password },
    setError,
  ) => {
    try {
      await addPassword({ password, phone: login }, feedParams);
      await loadProfile(feedParams);
      onSuccess();
    } catch (error) {
      handleError(setError)(error);
    }
  };

  return usePasswordCreateForm({
    onValid: onSubmit,
  });
};
